import React from "react";
import { Img } from 'react-image';
import placeholder from '../assets/images/placeholder.svg'
const Placeholder = () => <img src={placeholder} alt="product img loader" />;

const Image =({url, alt = 'placeholder', className, style})=>{

    return(
        <Img
            draggable={false}
            style={style}
            src={url}
            alt={alt}
            loader={<Placeholder />}
            unloader={<Placeholder />}
            className={className}
        />
    )

}

export default Image
