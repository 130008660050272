import React, {useState, useEffect} from 'react';
import Button from '../../../components/NewButtonSubmit';
import Title from "../../../components/Title";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {Form, Row, Col} from 'react-bootstrap'
import {handleAuthModal, setPhone, forgetReq} from "../../../redux/actions/auth";
import {connect} from 'react-redux'
import {withStyles} from "@material-ui/core";
import TimerMixin from 'react-timer-mixin'

import {
  validatePhone,
  confirmPassValidator,
} from "../../../helpers/validators";

import {authStyle} from "../authStyle";
import CrossIcon from "../../../assets/icons/cross-icon.svg";

let styles = theme => authStyle(theme)

let ForgetPassword = (props) => {
  let {
    openModal,
    handleAuthModal,
    classes,
    forgetReq,
    setAuthPhone,
    passwordReq,
    passwordReqLoading
  } = props

  let [phone, setPhone] = useState('');
  let [phoneErr, setPhoneErr] = useState('');
  let [submitErr, setSubmitErr] = useState('')
  let [success, setSuccess] = useState('')

  let handlePhone= e => {
    setPhone(e.target.value)
  }

  let handlePhoneBlur = () => {
    let valid = validatePhone(phone)

    if(valid) {
      setPhoneErr('')
    } else {
      setPhoneErr('Phone number is not valid')
    }
  }

  const handleClose = () => {
    handleAuthModal('')
  };

  let handleSubmit = () => {
    handlePhoneBlur();

    if(!phoneErr.length) {
      let data = {
        user_phone: phone
      }

      forgetReq(data)
    }
  }

  useEffect(() => {
    if(passwordReq) {
      if(passwordReq.status) {
        setAuthPhone(phone)
        setSuccess(passwordReq.message)

        TimerMixin.setTimeout(() => {
          handleAuthModal('verify')
          setSuccess('')
          setSubmitErr('')
        }, 5000)
      } else {
        setSubmitErr(passwordReq.message)
      }
    }
  }, [passwordReq])

  return (
    <div>
      <Dialog
        open={openModal === 'forget'}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className={classes.main}>
          <Title
            text={'Password Reset Request'}
            color={'#313132'}
            fontSize={24}
            lineHeight={40}
            fontWeight={'500'}
            textAlign={'left'}
            textTransform={'capitalize'}
            // margin={'5px 0px 15px 0px'}
          />
          <img className={classes.crossIcon} src={CrossIcon} onClick={handleClose} />
          <Form>
            <Form.Group>
              <Row>
                <Col className={classes.formField} md={12}>
                  <Form.Label className={classes.formLabel}>Enter Your Phone Number</Form.Label>
                  <Form.Control
                    type='text'
                    name="phone"
                    value={phone}
                    className={classes.formInput}
                    onChange={handlePhone}
                    placeholder={'Your Phone No'}
                    onBlur={handlePhoneBlur}
                  />
                  {phoneErr.length ? (
                    <Title
                      text={phoneErr}
                      fontWeight={200}
                      fontSize={14}
                      lineHeight={20}
                      color={'red'}
                    />
                  ) : null}
                  {success.length ? (
                    <Title
                      text={success}
                      fontWeight={200}
                      fontSize={14}
                      lineHeight={20}
                      color={'green'}
                    />
                  ) : null}
                  {submitErr.length ? (
                    <Title
                      text={submitErr}
                      fontWeight={200}
                      fontSize={14}
                      lineHeight={20}
                      color={'red'}
                    />
                  ) : null}
                </Col>
              </Row>
            </Form.Group>
          </Form>
          <Button text={'Submit'} handleSubmit={handleSubmit} loading={passwordReqLoading} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    openModal: state.authReducer.openModal,
    passwordReq: state.authReducer.passwordReq,
    passwordReqLoading: state.authReducer.passwordReqLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleAuthModal: data => dispatch(handleAuthModal(data)),
    setAuthPhone: data => dispatch(setPhone(data)),
    forgetReq: data => dispatch(forgetReq(data))
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ForgetPassword))
