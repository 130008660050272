import React from "react";
import { Row, Col } from "react-bootstrap";
import Title from "../../../components/Title";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import CartIcon from "../../../assets/icons/shopping-bag.svg";
import CrossBlack from "../../../assets/icons/cross-icon.svg";
import { useHistory, Link } from "react-router-dom";
import { connect } from "react-redux";

import {
  addItemToCart,
  addCartServer,
  removeItemFromWishlist,
  removeWishlistServer,
  handleRightDrawer
} from "../../../redux/actions/rightDrawer";
import {numberWithCommas} from "../../../helpers/globalFunc";
import TimerMixin from "react-timer-mixin";


function isObjectEmpty(obj) {
  if (typeof obj === 'object' && obj !== null) {
    return true
  } else return false
}


let WishlistItem = (props) => {
  let {
    product,
    authData,
    addItemToCart,
    removeItemFromWishlist,
    addCartServer,
    removeWishlistServer,
    handleRightDrawer,
  } = props;

  let history = useHistory();
  //console.log('wishliiist',product)
  let userId = authData ? authData.id : null;
  let addToCart = () => {
    if (userId) {
      const data = {
        user_id: userId,
        product_id: product.id.toString(),
        count: "1",
      };
      addCartServer(data, product.slug);
    } else {
      product.addCount = 1;
      addItemToCart(product);
    }
  };

  let handleRemove = () => {
    if (userId) {
      let data = {
        user_id: userId,
        product_id: product.id.toString(),
      };
      removeWishlistServer(data);
    } else {
      removeItemFromWishlist(product);
    }
  };
  // let color = product.attributes[0]?.pa_color.slug
  // let material = product.attributes[0]?.pa_materials.slug
  let onCartClick = (slug, parent) => {
    //console.log('product slug',slug)
    //console.log('product parent',parent)
    handleRightDrawer('')
    TimerMixin.setTimeout(() => {
      // if(parent) {
      //   history.push(`/product/${parent}?index=${slug}`)
      // } else {
      //   history.push(`/product/${slug}`)
      // }
      history.push(`/product/${slug}`)
    }, 500)
  }

  //console.log("Product", product)

  return (
    <StyledDiv>
      <div className="main-div">
        <div className="sub-div" onClick={() => onCartClick(product.slug, product.color_attr)}>
          <img
            src={product.image ? product.image : isObjectEmpty(product.image_id) ? product.image_id?.url : product.image_id}
            alt=""
            style={{ width: 90, height: 70 }}
          />
        </div>
        <div className="body-div">
          <Link onClick={() => onCartClick(product.slug, product.color_attr)}>
            <div>
              <Title
                text={product.name}
                fontSize={14}
                lineHeight={20}
                fontFamily={'Poppins'}
                fontWeight={"600"}
                color={"#313132"}
                textAlign={"left"}
              />
            </div>
          </Link>
          <div>
            <Title
              text={`SKU: ${product.sku}`}
              fontSize={12}
              lineHeight={20}
              fontFamily={'Poppins'}
              fontWeight={"300"}
              color={"#707070"}
              textAlign={"left"}
            />
          </div>
          <div className="price-div">
            <div className="price-text">
              {
                product?.stock === 'instock' ?
                    <>
                      <Title
                          text={'BDT'}
                          fontSize={8}
                          lineHeight={20}
                          fontFamily={'Poppins'}
                          fontWeight={"600"}
                          color={"#313132"}
                          textAlign={"right"}
                          margin={"9px 3px 0 0"}
                      />
                      <Title
                          text={`${numberWithCommas(
                              parseFloat(product.price)
                          )}`}
                          fontSize={12}
                          lineHeight={20}
                          fontFamily={'Poppins'}
                          fontWeight={"600"}
                          color={"#313132"}
                          textAlign={"right"}
                          margin={"10px 0 0 0"}
                      />
                    </> :
                    <Title
                        text={'Out of Stock'}
                        fontSize={12}
                        lineHeight={20}
                        fontFamily={'Poppins'}
                        fontWeight={"600"}
                        color={"#313132"}
                        textAlign={"right"}
                        margin={"10px 0 0 0"}
                    />
              }

            </div>
            <div className="buttonGroup">
              {
                product?.stock === 'instock' &&
                <IconButton className="icon-button" onClick={addToCart}>
                  <img src={CartIcon} alt="" style={{width: 16, height: 16}}/>
                </IconButton>
              }

              <IconButton className="icon-button-clear" onClick={handleRemove}>
                <img src={CrossBlack} alt="" style={{width: 12}}/>
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};

let StyledDiv = styled.div`
  .main-div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .sub-div {
    margin-right: 16px;
  }
  .body-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 200px;
  }
  .sku-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .price-div {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .price-text {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .icon-button {
    padding: 6px;
  }
  .icon-button-clear{
    padding: 6px;
    position: absolute;
    right: 0px;
    bottom: 50px;
  }
  .icon {
    width: 18px;
  }
  .buttonGroup {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    right: 16px;
  }
`;

function mapStateToProps(state) {
  return {
    authData: state.authReducer.authData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addItemToCart: (data) => dispatch(addItemToCart(data)),
    addCartServer: (data, slug) => dispatch(addCartServer(data, slug)),
    removeItemFromWishlist: (data) => dispatch(removeItemFromWishlist(data)),
    removeWishlistServer: (data) => dispatch(removeWishlistServer(data)),
    handleRightDrawer: data => dispatch(handleRightDrawer(data))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WishlistItem);
