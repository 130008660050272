import React, {useEffect, useState} from "react";
import {PageAnimation} from "../../components/PageAnimation";
import AllProduct from "./components/AllProduct";
import styled from 'styled-components';
import {motion} from "framer-motion";
import {useParams} from "react-router-dom";
import {
    fetchFiltered,
    fetchCategoryProducts,
    filterInit,
    categoryProductInit, fetchCategoryProductsWithPost
} from "../../redux/actions/home";
import {connect} from 'react-redux'
import CircularProgress from "@material-ui/core/CircularProgress";

const ProductList = (props) => {
    let {
        categories,
        allProducts,
        fetchFiltered,
        filteredProduct,
        filterInit,
        categoryProductInit,
        categoryProducts,
        fetchCategoryProducts,
        loading,
        fetchCategoryProductsWithPost
    } = props;

    const {id} = useParams();

    let [isSearch, setSearch] = useState(false)
    let [data, setData] = useState(
        {
            category_id: null,
            colors: [],
            height: [],
            material: [],
            pageNum: 1,
            post_per_page: '20',
            price: [],
            sort: [],
            width: []
        }
    )

    useEffect(() => {
        window.scrollTo(0, 0)
        categoryProductInit()
    }, [id])
    const [pageNum, setPageNumber] = useState(1);
    useEffect(() => {
        if (id) {
            if (!isSearch) {
                fetchCategoryProductsWithPost({id: id, pageNum: pageNum, post_per_page: "20"})
            } else {
                data.category_id = id
                data.pageNum = pageNum
                fetchCategoryProductsWithPost({id: id, pageNum: pageNum, post_per_page: "20"},[],true,data)
            }
        }
    }, [id])

    return (
        <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
            <StyledProductList id={!loading}>
                {!loading ? (
                    <AllProduct
                        pageNumber={pageNum}
                        changePageNumber={setPageNumber}
                        catId={id}
                        categories={categories}
                        allProducts={allProducts}
                        fetchFiltered={fetchFiltered}
                        filteredProduct={filteredProduct}
                        fetchCategoryProducts={fetchCategoryProductsWithPost}
                        categoryProducts={categoryProducts}
                        filterInit={filterInit}
                        categoryProductInit={ categoryProductInit }
                        setData={setData}
                        data={data}
                        setSearch={setSearch}
                        isSearch={isSearch}
                    />
                ) : (
                    <div className="loader">
                        <CircularProgress/>
                    </div>
                )}
            </StyledProductList>
        </motion.div>
    )
}

const StyledProductList = styled.div`
  margin-top: 100px;
  padding-bottom: 64px;
  height: ${(props) => (props.id ? "auto" : "120vh")};

  .loader {
    margin-top: 25%;
    text-align: center;

    @media (max-width: 991px) {
      margin-top: 40%;
    }
  }
`

function mapStateToProps(state) {
    return {
        categories: state.homeReducer.categories,
        featuredProducts: state.homeReducer.featuredProducts,
        allProducts: state.homeReducer.allProducts,
        filteredProduct: state.homeReducer.filteredProduct,
        categoryProducts: state.homeReducer.categoryProducts,
        loading: state.homeReducer.categoryProductsLoading
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchFiltered: data => dispatch(fetchFiltered(data)),
        fetchCategoryProducts: data => dispatch(fetchCategoryProducts(data)),
        fetchCategoryProductsWithPost: (data, prev_data_list, isSearch, search) => dispatch(fetchCategoryProductsWithPost(data, prev_data_list, isSearch, search)),
        categoryProductInit: () => dispatch(categoryProductInit()),
        filterInit: () => dispatch(filterInit())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductList)
