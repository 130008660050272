import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Product from "../../home/components/Product";
import Text from "../../../components/Text";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { loadMore } from "../../../helpers/globalFunc";
import { connect } from "react-redux";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 2,
    width: 170,
    marginBottom: 13,
    marginTop: 8,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#989899",
  },
}))(LinearProgress);

const AvailableProduct = (props) => {
  let { featuredProducts, title, subTitle, loadNum } = props;
  const isPreBook = subTitle === "Pre Book Now";

  let [last, setLast] = useState(loadNum ? loadNum : 20);

  let [productList, setProductList] = useState([]);

  const handleLoad = () => {
    let retData = loadMore(featuredProducts, last, null);

    setProductList(retData.retArr);

    setLast(retData.len);
  };

  useEffect(() => {
    if (featuredProducts.length) {
      setProductList(featuredProducts.slice(0, last));
    }
  }, [featuredProducts]);

  return (
    <div>
      <Row>
        <Col>
          <div className="subTitle-text">{title}</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 24 }}
          >
            <div className="top-title-text">{subTitle}</div>
          </div>
        </Col>
      </Row>
      <Row>
        {productList.length
          ? productList.map((item, key) => {
              return (
                <Col key={key} lg={4} md={4} sm={6} className="column-cl">
                  <Product item={item} isPreBook={isPreBook} />
                </Col>
              );
            })
          : null}
      </Row>
      {featuredProducts.length >= last ? (
        <center>
          <Text
            text={`Showing ${productList.length} of ${featuredProducts.length}`}
            fontWeight={"normal"}
            fontFamily={"Poppins"}
            fontSize={10}
          />
          <BorderLinearProgress
            variant="determinate"
            value={
              parseFloat(productList.length / featuredProducts.length) * 100
            }
          />
          <div className="read-more" onClick={() => handleLoad()}>
            <div className="load-button">Load more</div>
          </div>
        </center>
      ) : null}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    loadNum: state.homeReducer.loadNum,
  };
}

export default connect(mapStateToProps)(AvailableProduct);
