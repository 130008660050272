export const AUTH = {
  OPEN_MODAL: "OPEN_MODAL",
  SET_OPEN: {
    MAIN: "SET_OPEN",
    SUCCESS: "SET_OPEN_SUCCESS",
    FAILURE: "SET_OPEN_FAILURE",
  },
  SET_LOGIN: {
    MAIN: "SET_LOGIN",
    SUCCESS: "SET_LOGIN_SUCCESS",
    FAILURE: "SET_LOGIN_FAILURE",
  },
  SEND_OTP: {
    MAIN: "SEND_OTP",
    SUCCESS: "SEND_OTP_SUCCESS",
    FAILURE: "SEND_OTP_FAILURE",
  },
  SET_SIGN_UP: {
    MAIN: "SET_SIGN_UP",
    SUCCESS: "SET_SIGN_UP_SUCCESS",
    FAILURE: "SET_SIGN_UP_FAILURE",
  },
  FORGET_PASS_REQ: {
    MAIN: "FORGET_PASS_REQ",
    SUCCESS: "FORGET_PASS_REQ_SUCCESS",
    FAILURE: "FORGET_PASS_REQ_FAILURE",
  },
  VERIFY_CODE: {
    MAIN: "VERIFY_CODE",
    SUCCESS: "VERIFY_CODE_SUCCESS",
    FAILURE: "VERIFY_CODE_FAILURE",
  },
  FORGET_PASSWORD_CHANGE: {
    MAIN: "FORGET_PASSWORD_CHANGE",
    SUCCESS: "FORGET_PASSWORD_CHANGE_SUCCESS",
    FAILURE: "FORGET_PASSWORD_CHANGE_FAILURE"
  },
  CHANGE_PASSWORD: {
    MAIN: "CHANGE_PASSWORD",
    SUCCESS: "CHANGE_PASSWORD_SUCCESS",
    FAILURE: "CHANGE_PASSWORD_FAILURE",
  },
  SET_PROFILE_DATA: {
    MAIN: "SET_PROFILE_DATA",
    SUCCESS: "SET_PROFILE_DATA_SUCCESS",
    FAILURE: "SET_PROFILE_DATA_FAILURE",
  },
  UPDATE_PROFILE: {
    MAIN: "UPDATE_PROFILE",
    SUCCESS: "UPDATE_PROFILE_SUCCESS",
    FAILURE: "UPDATE_PROFILE_FAILURE",
  },
  REVIEW_PRODUCT: {
    MAIN: "REVIEW_PRODUCT",
    SUCCESS: "REVIEW_PRODUCT_SUCCESS",
    FAILURE: "REVIEW_PRODUCT_FAILURE"
  },
  SET_PHONE: 'SET_PHONE',
  SET_CODE: 'SET_CODE',
  VERIFY_INIT: 'VERIFY_INIT',
  RESET_PASS_INIT: "RESET_PASS_INIT",
  REVIEW_INIT: 'REVIEW_INIT',
  LOGOUT_USER: "LOGOUT_USER",
};
