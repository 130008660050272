import { RIGHT_DRAWER } from "../../constants/right-drawer";

let initState = {
  openDrawer: "",
  cart: [],
  cartLoading: false,
  cartErr: null,
  allCart: [],
  cartInfo: '',
  allCartLoading: false,
  allCartErr: null,
  wishlist: [],
  wishlistLoading: false,
  wishlistErr: null,
  allWishlist: [],
  allWishlistLoading: false,
  allWishlistErr: null,
  checkout: [],
  tempOrder: null,
};

export default function rightDrawerReducer(state = initState, action) {
  switch (action.type) {
    case RIGHT_DRAWER.OPEN_DRAWER:
      return {
        ...state,
        openDrawer: action.data,
      };

    case RIGHT_DRAWER.INIT_CARTLIST:
      return {
        ...state,
        allCart: [],
      };

    case RIGHT_DRAWER.FETCH_ALL_CART_ITEMS.MAIN:
      return {
        ...state,
        allCartLoading: true,
      };

    case RIGHT_DRAWER.FETCH_ALL_CART_ITEMS.SUCCESS:
      let fetched = action.result;
      let cartInfo = {
        partial_payment: fetched.partial_payment,
        delivery_fee: fetched.delivery_fee,
        tax_rate: fetched.tax_rate
      }

      let len = fetched.cart.length;

      let formatted = [];

      for (let i = 0; i < len; i++) {
        let item = fetched.cart[i];
        let newObj = {
          count: parseInt(item.count),
          cartItem: {
            id: item.product_id,
            parent_id: item.parent_id,
            name: item.product_name,
            image_id: item.image_id,
            sku: item.sku,
            price: parseFloat(item.price),
            stock: item.stock,
            slug: item.slug
          },
        };

        formatted.push(newObj);
      }

      return {
        ...state,
        allCart: formatted,
        cartInfo: cartInfo,
        cart: [],
        allCartLoading: false,
      };

    case RIGHT_DRAWER.FETCH_ALL_CART_ITEMS.FAILURE:
      return {
        ...state,
        allCartLoading: false,
        allCartErr: "Error from Cart Fetching",
      };

    case RIGHT_DRAWER.FETCH_ALL_WISHLIST.MAIN:
      return {
        ...state,
        allWishlistLoading: true,
      };

    case RIGHT_DRAWER.FETCH_ALL_WISHLIST.SUCCESS:
      let fetchedWishlist = action.result;

      let lenWish = fetchedWishlist.length;

      let formattedWishlist = [];

      for (let i = 0; i < lenWish; i++) {
        let item = fetchedWishlist[i];
        let newObj = {
          id: item.product_id,
          parent_id: item.parent_id,
          name: item.product_name,
          image_id: item.image_id,
          sku: item.sku,
          price: parseFloat(item.price),
          stock: item.stock,
          slug: item.slug
        };

        formattedWishlist.push(newObj);
      }
      return {
        ...state,
        allWishlist: formattedWishlist,
        allWishlistLoading: false,
        allWishlistErr: null,
      };

    case RIGHT_DRAWER.FETCH_ALL_WISHLIST.FAILURE:
      return {
        ...state,
        allWishlistLoading: false,
        allWishlistErr: "Error from Wishlist fetching",
      };

    case RIGHT_DRAWER.MERGE_CART_ITEMS.MAIN:
      return {
        ...state,
        allCartLoading: true,
      };

    case RIGHT_DRAWER.MERGE_CART_ITEMS.SUCCESS:
      let merged = action.result;

      let l = merged.length;

      let formatMerge = [];

      for (let i = 0; i < l; i++) {
        let item = merged[i];
        let newObj = {
          count: parseInt(item.count),
          cartItem: {
            id: item.product_id,
            parent_id: item.parent_id,
            name: item.product_name,
            image_id: item.image_id,
            sku: item.sku,
            price: parseFloat(item.price),
            stock: item.stock,
            slug: item.slug
          },
        };

        formatMerge.push(newObj);
      }

      return {
        ...state,
        allCart: formatMerge,
        cart: [],
        allCartLoading: false,
      };

    case RIGHT_DRAWER.MERGE_CART_ITEMS.FAILURE:
      return {
        ...state,
        allCartLoading: false,
        allCartErr: "Error from cart Merging",
      };

    case RIGHT_DRAWER.MERGE_WISHLIST.MAIN:
      return {
        ...state,
        allWishlistLoading: true,
        allWishlistErr: null,
      };

    case RIGHT_DRAWER.MERGE_WISHLIST.SUCCESS:
      let mergedWish = action.result;

      let lw = mergedWish.length;

      let formatMergeWish = [];

      for (let i = 0; i < lw; i++) {
        let item = mergedWish[i];
        let newObj = {
          id: item.product_id,
          parent_id: item.parent_id,
          name: item.product_name,
          image_id: item.image_id,
          sku: item.sku,
          price: parseFloat(item.price),
          stock: item.stock,
          slug: item.slug
        };

        formatMergeWish.push(newObj);
      }
      return {
        ...state,
        allWishlistLoading: false,
        allWishlist: formatMergeWish,
        wishlist: [],
      };

    case RIGHT_DRAWER.MERGE_WISHLIST.FAILURE:
      return {
        ...state,
        allWishlistLoading: false,
        allWishlistErr: "Error from Wishlist merge",
      };

    case RIGHT_DRAWER.CLEAR_CART_SERVER.MAIN:
      return {
        ...state,
        allCartLoading: true,
      };

    case RIGHT_DRAWER.CLEAR_CART_SERVER.SUCCESS:
      return {
        ...state,
        allCartLoading: false,
        allCart: action.result,
      };

    case RIGHT_DRAWER.CLEAR_CART_SERVER.FAILURE:
      return {
        ...state,
        allCartLoading: false,
        allCartErr: "Error from clear all Cart Items",
      };

    case RIGHT_DRAWER.CLEAR_ALL_WISHLIST.MAIN:
      return {
        ...state,
        allWishlistLoading: true,
      };

    case RIGHT_DRAWER.CLEAR_ALL_WISHLIST.SUCCESS:
      return {
        ...state,
        allWishlistLoading: false,
        allWishlist: action.result,
      };

    case RIGHT_DRAWER.CLEAR_ALL_WISHLIST.FAILURE:
      return {
        ...state,
        allWishlistLoading: false,
        allWishlistErr: "Err from clear wishlist",
      };

    case RIGHT_DRAWER.ADD_ITEM_TO_CART:
      let cartItem = action.data; // action.result = {whole cart item}

      let found = state.cart.length
        ? state.cart.find((item) => item.cartItem.id === cartItem.id)
        : null;

      let newCart = [];

      if (found) {
        newCart = [...state.cart];
        let idx = state.cart.findIndex(
          (item) => item.cartItem.id === cartItem.id
        );

        newCart[idx].count += cartItem.addCount;
      } else {
        let addCart = {
          count: cartItem.addCount,
          cartItem: cartItem,
        };

        newCart = [...state.cart];

        newCart.push(addCart);
      }

      return {
        ...state,
        cart: newCart,
      };

    case RIGHT_DRAWER.ADD_CART_SERVER.MAIN:
      return {
        ...state,
        allCartLoading: true,
      };

    case RIGHT_DRAWER.ADD_CART_SERVER.SUCCESS:
      let newItem = action.result;
      let slug = action.slug;

      let temp = [...state.allCart];

      let isIndex = state.allCart.length ? state.allCart.findIndex(
        (item) => item.cartItem.id.toString() === newItem.product_id.toString()
      ) : -1;

      if (isIndex >= 0) {
        temp[isIndex].count += parseInt(newItem.count);
      } else {
        let newObj = {
          count: parseInt(newItem.count),
          cartItem: {
            id: newItem.product_id,
            parent_id: newItem.parent_id,
            name: newItem.product_name,
            image_id: newItem.image_id,
            sku: newItem.sku,
            price: parseFloat(newItem.price),
            stock: 'instock',
            slug: slug
          },
        };
        temp.push(newObj);
      }

      return {
        ...state,
        allCart: temp,
        allCartLoading: false,
      };

    case RIGHT_DRAWER.ADD_CART_SERVER.FAILURE:
      return {
        ...state,
        allCartLoading: false,
        allCartErr: "Error Occured from adding to cart",
      };

    case RIGHT_DRAWER.ADD_WISHLIST_SERVER.MAIN:
      return {
        ...state,
        allWishlistLoading: true,
        allWishlistErr: null,
      };

    case RIGHT_DRAWER.ADD_WISHLIST_SERVER.SUCCESS:
      let newWishItem = action.result;
      let slugs = action.slug;

      let tempWish = [...state.allWishlist];

      let isIdx = state.allWishlist.findIndex(
        (item) => item.id.toString() === newWishItem.product_id.toString()
      );

      if (isIdx < 0) {
        let newObj = {
          id: newWishItem.product_id,
          name: newWishItem.product_name,
          parent_id: newWishItem.parent_id,
          image_id: newWishItem.image_id,
          sku: newWishItem.sku,
          price: parseFloat(newWishItem.price),
          stock: newWishItem.stock,
          slug: slugs
        };
        tempWish.push(newObj);
      }
      return {
        ...state,
        allWishlistLoading: false,
        allWishlist: tempWish,
      };

    case RIGHT_DRAWER.ADD_WISHLIST_SERVER.FAILURE:
      return {
        ...state,
        allWishlistErr: "Error from Wishlist Add",
        allWishlistLoading: false,
      };

    case RIGHT_DRAWER.UPDATE_CART.MAIN:
      return {
        ...state,
        allCartLoading: true,
      };

    case RIGHT_DRAWER.UPDATE_CART.SUCCESS:
      let update = action.result.count ? action.result : action.req;

      let tempUpdate = [...state.allCart];

      let index = state.allCart.findIndex(
        (item) => item.cartItem.id.toString() === update.product_id.toString()
      );

      if (update.count) {
        if (index >= 0) {
          tempUpdate[index].count = parseInt(update.count);
        } else {
          let newObj = {
            count: parseInt(update.count),
            cartItem: {
              id: update.product_id,
              parent_id: update.parent_id,
              name: update.product_name,
              image_id: update.image_id,
              sku: update.sku,
              price: update.price,
              stock: update.stock
            },
          };
          temp.push(newObj);
        }
      } else {
        tempUpdate = tempUpdate.filter(
          (item) => item.cartItem.id.toString() !== update.product_id.toString()
        );
      }

      return {
        ...state,
        allCart: tempUpdate,
        allCartLoading: false,
      };

    case RIGHT_DRAWER.UPDATE_CART.FAILURE:
      return {
        ...state,
        allCartLoading: false,
        allCartErr: "Error occured from cart updating",
      };

    case RIGHT_DRAWER.REMOVE_WISHLIST_SERVER.MAIN:
      return {
        ...state,
        allWishlistLoading: true,
      };

    case RIGHT_DRAWER.REMOVE_WISHLIST_SERVER.SUCCESS:
      let removeWishItem = action.result;

      let tempRemove = [...state.allWishlist];

      tempRemove = tempRemove.filter(
        (item) => item.id.toString() !== removeWishItem.product_id.toString()
      );

      return {
        ...state,
        allWishlistLoading: false,
        allWishlist: tempRemove,
      };

    case RIGHT_DRAWER.REMOVE_WISHLIST_SERVER.FAILURE:
      return {
        ...state,
        allWishlistLoading: false,
        allWishlistErr: "Error from remove wishlist item",
      };

    case RIGHT_DRAWER.REMOVE_ITEM_FROM_CART:
      let removeItem = action.data; // action.result = { count, id }

      let updated = [...state.cart];

      let isAvailable = updated.length
        ? updated.find((item) => item.cartItem.id === removeItem.id)
        : null;

      if (isAvailable) {
        let inx = updated.findIndex(
          (item) => item.cartItem.id === removeItem.id
        );

        updated[inx].count -= removeItem.count;

        if (updated[inx].count < 1) {
          updated = updated.filter(
            (item) => item.cartItem.id !== removeItem.id
          );
        }
      }

      return {
        ...state,
        cart: updated,
      };

    case RIGHT_DRAWER.CLEAR_CART:
      return {
        ...state,
        cart: [],
      };

    case RIGHT_DRAWER.ADD_ITEM_TO_WISHLIST:
      let addWishItem = action.data; // action.result = {whole item}
      let newList = [...state.wishlist];

      let isWish = newList.find((item) => item.id === addWishItem.id);

      if (!isWish) newList.push(addWishItem);

      return {
        ...state,
        wishlist: newList,
      };

    case RIGHT_DRAWER.REMOVE_ITEM_FROM_WISHLIST:
      let removeWishList = action.data; // action.result = {whole item}

      let updatedList = state.wishlist.filter(
        (item) => item.id !== removeWishList.id
      );

      return {
        ...state,
        wishlist: updatedList,
      };

    case RIGHT_DRAWER.CLEAR_WISHLIST:
      return {
        ...state,
        wishlist: [],
      };

    case RIGHT_DRAWER.SET_CHECKOUT:
      let checkoutList = action.data;

      let list = checkoutList.filter((item) => item.cartItem.stock === "instock");

      return {
        ...state,
        checkout: list,
      };

    case RIGHT_DRAWER.TEMP_ORDER:
      return {
        ...state,
        tempOrder: action.data,
      };

    case RIGHT_DRAWER.CLEAR_CHECKOUT:
      return {
        ...state,
        checkout: [],
      };

    default:
      return state;
  }
}
