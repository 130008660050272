import { combineReducers } from "redux";
import homeReducer from "./home";
import authReducer from "./auth";
import rightDrawerReducer from "./right-drawer";
import leftDrawerReducer from "./left-drawer";
import orderReducer from "./orders";
import prebookReducer from "./prebooking";
import customerServiceReducer from "./customer-service";

export default combineReducers({
  homeReducer,
  authReducer,
  rightDrawerReducer,
  leftDrawerReducer,
  orderReducer,
  prebookReducer,
  customerServiceReducer
});
