import React, {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Title from "../../../components/Title";
import OrderStatus from "./OrderStatus";
import OrderItems from "./OrderItems";
import moment from "moment";
import FileUpload from "../file/FileUpload";
import Button from "@material-ui/core/Button";
import {orderAttachment} from "../../../redux/actions/orders";
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";

let OrderDetailsMobile = (props) => {
    const classes = useStyles();
    let { statusCheck, active, mobile, ordersAmounts } = props;

    const schedule = active.meta_data ? active.meta_data.find(item => item.key === 'order_schedule') : null

    const [newFilesInfo, setNewFilesInfo] = useState({
        prescripFiles: [],
    });

    const updateUploadedFiles = (files) => {
        setNewFilesInfo({...newFilesInfo, prescripFiles: files});
    }

    let handleUpload = () =>{
        let formData = new FormData();
        formData.append("file", newFilesInfo.prescripFiles[0]);
        formData.append("order_id", active.id);

        props.orderAttachment(formData)
    }

    useEffect(()=>{
        if (props.orderFile) {
            setNewFilesInfo({...newFilesInfo, prescripFiles: []});
        }
    },[])

    return (
        <div className={classes.main}>
            <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                    <OrderStatus activeOrder={active} statusCheck={statusCheck} />
                </CardContent>
            </Card>
            <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                    <div className={classes.header}>
                        <Title
                            text={"Shipping Details:"}
                            fontSize={16}
                            lineHeight={24}
                            fontFamily={'Poppins'}
                            fontWeight={"600"}
                            color={"#313132"}
                            margin={"0 0 10px 0"}
                        />
                        <Title
                            text={
                                "Date: " + moment(active.date_created).format("DD-MMM-YYYY")
                            }
                            fontSize={14}
                            lineHeight={24}
                            fontFamily={'Poppins'}
                            fontWeight={"500"}
                            color={"#313132"}
                            margin={"0 0 10px 0"}
                        />
                    </div>
                    <Divider />
                    <Title
                        text={"Address:"}
                        fontSize={14}
                        lineHeight={24}
                        fontFamily={'Poppins'}
                        fontWeight={"600"}
                        color={"#313132"}
                        margin={"15px 0 0 0"}
                    />
                    <Title
                        text={`${active.billing.address_1}`}
                        fontSize={12}
                        lineHeight={24}
                        fontFamily={'Poppins'}
                        fontWeight={"500"}
                        color={"#313132"}
                        margin={"0 0 0 0"}
                    />
                    <Title
                        text={`${active.billing.address_2}`}
                        fontSize={12}
                        lineHeight={24}
                        fontFamily={'Poppins'}
                        fontWeight={"500"}
                        color={"#313132"}
                        margin={"0 0 0 0"}
                    />
                    <Title
                        text={`${active.billing.city}`}
                        fontSize={12}
                        lineHeight={24}
                        fontFamily={'Poppins'}
                        fontWeight={"500"}
                        color={"#313132"}
                        margin={"0 0 20px 0"}
                    />
                    <Title
                        text={"Delivery Schedule:"}
                        fontSize={14}
                        lineHeight={24}
                        fontFamily={'Poppins'}
                        fontWeight={"600"}
                        color={"#313132"}
                        margin={"15px 0 0 0"}
                    />
                    <Title
                        text={schedule ? schedule.value : "Approx. Within 45-60 days"}
                        fontSize={12}
                        lineHeight={24}
                        fontFamily={'Poppins'}
                        fontWeight={"500"}
                        color={"#313132"}
                        margin={"0 0 0 0"}
                    />
                    <Title
                        text={"Contact Details:"}
                        fontSize={14}
                        lineHeight={24}
                        fontFamily={'Poppins'}
                        fontWeight={"600"}
                        color={"#313132"}
                        margin={"15px 0 0 0"}
                    />
                    <Title
                        text={`${active.billing.phone}`}
                        fontSize={12}
                        lineHeight={24}
                        fontFamily={'Poppins'}
                        fontWeight={"500"}
                        color={"#313132"}
                        margin={"0 0 20px 0"}
                    />
                </CardContent>
            </Card>
            <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                    <OrderItems classes={classes} active={active} mobile={mobile} ordersAmount={ordersAmounts} />
                </CardContent>
            </Card>
            <Card className={classes.root}>
                <CardContent className={classes.cardContentUp}>
                    <p>Upload Bank copy</p>
                    <div className={classes.body}>
                        <FileUpload
                            accept=".jpg,.png,.jpeg,.pdf"
                            multiple
                            updateFilesCb={updateUploadedFiles}
                        />
                        <Button
                            onClick={handleUpload}
                            className={classes.button}>UPLOAD</Button>
                    </div>
                    {props.orderFile?.message}
                </CardContent>
            </Card>
            {
                props.orderFileLoading ?
                    <StyleLoader>
                        <div className="loader">
                            <CircularProgress />
                        </div>
                    </StyleLoader>: null
            }
        </div>
    );
};


let StyleLoader = styled.div`
  .loader {
    z-index: 1;
    height: 40px;
    width: 40px;
    position: absolute;
    left: 50%;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

const useStyles = makeStyles(theme => ({
    main: {
        margin: "0px",
        [theme.breakpoints.down(767)]: {
            margin: 0,
            width: '100%'
        }
    },
    root: {
        padding: 15,
        boxShadow: "0px 0px 0px #0000000D",
        minWidth: 200,
        marginBottom: 17,
    },
    cardContent: {
        paddingBottom: "0px !important",
    },
    cardContentUp: {
        paddingBottom: "15px !important",
    },
    body: {
        display: "grid"
    },
    button: {
        backgroundColor: '#ededed',
        width: '100%',
        height: 46,
        marginTop: 20,
        borderRadius: 8
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
}));

function mapStateToProps(state) {
    return {
        orderFile: state.orderReducer.orderFile,
        orderFileLoading: state.orderReducer.orderFileLoading,
        orderFileErr: state.orderReducer.orderFileErr
    };
}

function mapDispatchToProps(dispatch) {
    return {
        orderAttachment: (data) => dispatch(orderAttachment(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailsMobile);
