export const HOME = {
  FETCH_HOME_BANNER_SLIDER: {
    MAIN: "FETCH_HOME_BANNER_SLIDER",
    SUCCESS: "FETCH_HOME_BANNER_SLIDER_SUCCESS",
    FAILURE: "FETCH_HOME_BANNER_SLIDER_FAILURE",
  },
  FETCH_PRODUCT_CATEGORIES: {
    MAIN: "FETCH_PRODUCT_CATEGORIES",
    SUCCESS: "FETCH_PRODUCT_CATEGORIES_SUCCESS",
    FAILURE: "FETCH_PRODUCT_CATEGORIES_FAILURE",
  },
  CATEGORIES_BRAND: {
    MAIN: "CATEGORIES_BRAND",
    SUCCESS: "CATEGORIES_BRAND_SUCCESS",
    FAILURE: "CATEGORIES_BRAND_FAILURE",
  },
  SCOPE_OF_WORK: {
    MAIN: "SCOPE_OF_WORK",
    SUCCESS: "SCOPE_OF_WORK_SUCCESS",
    FAILURE: "SCOPE_OF_WORK_FAILURE",
  },
  FETCH_ALL_PRODUCTS: {
    MAIN: "FETCH_ALL_PRODUCTS",
    SUCCESS: "FETCH_ALL_PRODUCTS_SUCCESS",
    FAILURE: "FETCH_ALL_PRODUCTS_FAILURE",
  },
  FETCH_FEATURED_PRODUCTS: {
    MAIN: "FETCH_FEATURED_PRODUCTS",
    SUCCESS: "FETCH_FEATURED_PRODUCTS_SUCCESS",
    FAILURE: "FETCH_FEATURED_PRODUCTS_FAILURE",
  },
  FETCH_WEB_SERIES_POST: {
    MAIN: "FETCH_WEB_SERIES_POST",
    SUCCESS: "FETCH_WEB_SERIES_POST_SUCCESS",
    FAILURE: "FETCH_WEB_SERIES_POST_FAILURE",
  },
  FETCH_WEB_SERIES_POST_CATEGORY: 'FETCH_WEB_SERIES_POST_CATEGORY',
  FETCH_WEB_SERIES_POST_LOAD_MORE: {
    MAIN: "FETCH_WEB_SERIES_POST_LOAD_MORE",
    SUCCESS: "FETCH_WEB_SERIES_POST_LOAD_MORE_SUCCESS",
    FAILURE: "FETCH_WEB_SERIES_POST_LOAD_MORE_FAILURE",
  },
  FETCH_ALL_POSTS: {
    MAIN: "FETCH_ALL_POSTS",
    SUCCESS: "FETCH_ALL_POSTS_SUCCESS",
    FAILURE: "FETCH_ALL_POSTS_FAILURE",
  },
  FETCH_ABOUT_US_BOD_POSTS: {
    MAIN: "FETCH_ALL_BOD_POSTS",
    SUCCESS: "FETCH_ALL_BOD_POSTS_SUCCESS",
    FAILURE: "FETCH_ALL_BOD_POSTS_FAILURE",
  },
  FETCH_PAGES: {
    MAIN: "FETCH_PAGES",
    SUCCESS: "FETCH_PAGES_SUCCESS",
    FAILURE: "FETCH_PAGES_FAILURE",
  },
  FETCH_FILTERED: {
    MAIN: "FETCH_FILTERED",
    SUCCESS: "FETCH_FILTERED_SUCCESS",
    FAILURE: "FETCH_FILTERED_FAILURE",
  },
  FETCH_TEXT_FILTERED: {
    MAIN: "FETCH_TEXT_FILTERED",
    SUCCESS: "FETCH_TEXT_FILTERED_SUCCESS",
    FAILURE: "FETCH_TEXT_FILTERED_FAILURE",
  },
  FETCH_PRODUCT_DETAILS: {
    MAIN: "FETCH_PRODUCT_DETAILS",
    SUCCESS: "FETCH_PRODUCT_DETAILS_SUCCESS",
    FAILURE: "FETCH_PRODUCT_DETAILS_FAILURE",
  },
  FETCH_PRODUCT_DETAILS_3D: {
    MAIN: "FETCH_PRODUCT_DETAILS_3D",
    SUCCESS: "FETCH_PRODUCT_DETAILS_3D_SUCCESS",
    FAILURE: "FETCH_PRODUCT_DETAILS_3D_FAILURE",
  },
  SUBSCRIBE_NEWSLETTER: {
    MAIN: "SUBSCRIBE_NEWSLETTER",
    SUCCESS: "SUBSCRIBE_NEWSLETTER_SUCCESS",
    FAILURE: "SUBSCRIBE_NEWSLETTER_FAILURE",
  },
  FETCH_CATEGORY_PRODUCTS: {
    MAIN: "FETCH_CATEGORY_PRODUCTS",
    MAIN_POST: "FETCH_CATEGORY_PRODUCTS_POST",
    SUCCESS: "FETCH_CATEGORY_PRODUCTS_SUCCESS",
    FAILURE: "FETCH_CATEGORY_PRODUCTS_FAILURE"
  },
  CATEGORY_PRODUCT_INIT: 'CATEGORY_PRODUCT_INIT',
  FILTERED_INIT: "FILTERED_INIT",
  NEWSLETTER_INIT: "NEWSLETTER_INIT",
};
