import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Title from "../../../components/Title";
import WishlistButton from "../../../components/WishlistButton";
import NewButtonSubmit from "../../../components/NewButtonSubmit";
import { authStyle } from "../../auth/authStyle";
import { withStyles } from "@material-ui/styles";
import { validateEmail } from "../../../helpers/validators";

let ContactField = (props) => {
  let {
    classes,
    phone,
    email,
    handlePhone,
    handleEmail,
    handleCancel,
    updateUser,
    userId,
    profileData,
    profileDataLoading,
  } = props;

  const [emailErr, setEmailErr] = useState("");

  const handleEmailBlur = () => {
    const isEmail = email.length ? validateEmail.test(email) : true;

    if (!isEmail) {
      setEmailErr("Email is not valid");
    } else {
      setEmailErr("");
    }
  };

  const handleSubmit = () => {
    const isEmail = validateEmail.test(email);

    if (isEmail) {
      setEmailErr("");
      const data = {
        user_id: userId,
        email: email,
      };

      updateUser(data);
    } else {
      setEmailErr("Email is not valid");
    }
  };

  return (
    <Form>
      <Form.Group>
        <Row>
          <Col className={classes.formField} md={6} sm={12}>
            <Form.Label className={classes.formLabel}>Phone</Form.Label>
            <Form.Control
              type="text"
              className={classes.formControl}
              value={phone}
              onChange={handlePhone}
              placeholder={"Your Phone Number"}
              disabled={
                profileData
                  ? profileData.user_phone.length > 0
                    ? true
                    : false
                  : false
              }
            />
          </Col>
          <Col className={classes.formField} md={6} sm={12}>
            <Form.Label className={classes.formLabel}>Email</Form.Label>
            <Form.Control
              type="text"
              className={classes.formControl}
              value={email}
              placeholder={"Your Email"}
              onChange={handleEmail}
              onBlur={handleEmailBlur}
              disabled={
                profileData
                  ? profileData.email.length > 0
                    ? true
                    : false
                  : false
              }
            />
            {emailErr.length ? (
              <Title
                text={emailErr}
                fontSize={14}
                fontWeight={"400"}
                lineHeight={20}
                color={"red"}
                margin={"20px 0"}
              />
            ) : null}
          </Col>
          <Col className="button-field" md={12} sm={12}>
            <Row>
              <Col md={6} sm={12}>
                <NewButtonSubmit
                  text={"Save Changes"}
                  handleSubmit={handleSubmit}
                  loading={profileDataLoading}
                />
              </Col>
              <Col md={6} sm={12}>
                <WishlistButton handleSubmit={handleCancel} text={"Cancel"} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form.Group>
    </Form>
  );
};

let styles = (theme) => authStyle(theme);

export default withStyles(styles)(ContactField);
