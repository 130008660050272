import React, { Component, useLayoutEffect } from "react";
import Error from "./components/404";
import Menu from "../../components/Menu";
import logoBlack from "../../assets/images/logo-black.svg";
import hamburgerBlack from "../../assets/images/hamburger-black.svg";
import { PageAnimation } from "../../components/PageAnimation";
import { motion } from "framer-motion";

const ErrorPage = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <motion.div
      exit="exit"
      animate="show"
      variants={PageAnimation}
      initial="hidden"
    >
      <Error />
    </motion.div>
  );
};

export default ErrorPage;
