import React, { useState } from "react";
import styled from "styled-components";
import Title from "../../components/Title";
import { Row, Col, Container } from "react-bootstrap";

import UpdateAccount from "./components/update-account";
import UpdateAddress from "./components/update-address";

import { connect } from "react-redux";
import {
  updateUser,
  updatePassword,
  resetPassInit,
} from "../../redux/actions/auth";
import Divider from "@material-ui/core/Divider";

const ProfileMain = (props) => {
  const {
    profileData,
    authData,
    updateUser,
    profileDataLoading,
    updatePassword,
    passwordReset,
    passwordResetLoading,
    resetPassInit,
  } = props;
  const [active, setActive] = useState(0);
  const userId = authData ? authData.id : null;
  const userToken = authData ? authData.token : null;
  return (
    <ProfileStyle>
      <Container>
        <Row className="change-view">
          <Col>
            <div
              style={{ display: "flex", marginTop: 0, alignItems: "center" }}
            >
              <div className="text-breadcrumb">Category</div>
              <div className="text-breadcrumb-point" />
              <div className="text-breadcrumb">Brands</div>
              <div className="text-breadcrumb-point" />
              <div className="text-breadcrumb">Bedroom</div>
            </div>
            <Divider style={{ marginTop: 6, marginBottom: 30 }} />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="subTitle">Profile</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 24,
              }}
            >
              <div className="top-title">Manage Your Account</div>
            </div>
          </Col>
        </Row>
        <Row className="row-view">
          <Col md={4} sm={6} xs={6}>
            <div className="tab" onClick={() => setActive(0)}>
              <Title
                text={"Account"}
                color={active === 0 ? "#313132" : "#989898"}
                fontSize={16}
                lineHeight={24}
                fontFamily={"Poppins"}
                fontWeight={"600"}
                textAlign={"left"}
                margin={"0 0 10px 0"}
              />
              <div className={`divider ${active === 0 ? "active" : ""}`} />
            </div>
          </Col>
          <Col md={4} sm={6} xs={6}>
            <div className="tab" onClick={() => setActive(1)}>
              <Title
                text={"Address"}
                color={active === 1 ? "#313132" : "#989898"}
                fontSize={16}
                lineHeight={24}
                fontFamily={"Poppins"}
                fontWeight={"600"}
                textAlign={"left"}
                margin={"0 0 10px 0"}
              />
              <div className={`divider ${active === 1 ? "active" : ""}`} />
            </div>
          </Col>
        </Row>
        {active === 0 ? (
          <UpdateAccount
            profileData={profileData}
            updateUser={updateUser}
            userId={userId}
            userToken={userToken}
            profileDataLoading={profileDataLoading}
            updatePassword={updatePassword}
            passwordReset={passwordReset}
            passwordResetLoading={passwordResetLoading}
            resetPassInit={resetPassInit}
          />
        ) : (
          <UpdateAddress
            profileData={profileData}
            updateUser={updateUser}
            userId={userId}
            profileDataLoading={profileDataLoading}
          />
        )}
      </Container>
    </ProfileStyle>
  );
};

const ProfileStyle = styled.div`
  margin: 100px 0;
  height: auto;

  .container {
    max-width: 95%;
  }

  .text-breadcrumb {
    font-weight: normal;
    font-size: 10px;
    color: #313132;
    font-family: "Poppins", sans-serif;
  }

  .text-breadcrumb-point {
    width: 3px;
    height: 3px;
    color: #313132;
    border-radius: 50%;
    background-color: #313132;
    margin-right: 8px;
    margin-left: 8px;
  }

  .change-view {
    display: block;
    @media (max-width: 991px) {
      display: none;
    }
  }

  .subTitle {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom: 2px;
    margin-top: 10px;
    color: rgb(156, 148, 52);
    @media (max-width: 991px) {
      margin-top: 30px;
    }
  }

  .top-title {
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom: 8px;
    color: #313132;
  }

  .row-view {
    padding-left: 20px;
    padding-bottom: 20px;
  }

  .tab {
    cursor: pointer;
    width: 270px;
    height: 33px;
  }

  .divider {
    width: 100%;
    border-bottom: 2px solid #989898;
  }

  .active {
    border-bottom: 2px solid #313132 !important;
  }

  .button-field {
    margin-top: 20px;
  }

  .edit-address {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  @media (max-width: 991px) {
    padding: 0 15px;
    .tab {
      width: 140px;
      height: 30px;
    }

    .container {
      max-width: 100%;
      padding: 5px;
    }

    .row-view {
      padding-left: 0px;
      padding-bottom: 20px;
    }
  }

  @media (max-width: 767px) {
    padding: 0 15px;

    .tab {
      width: 140px;
      height: 30px;
    }

    .container {
      max-width: 100%;
    }

    .row-view {
      padding-left: 0px;
      padding-bottom: 20px;
    }
  }
`;

function mapStateToProps(state) {
  return {
    profileData: state.authReducer.profileData,
    profileDataLoading: state.authReducer.profileDataLoading,
    authData: state.authReducer.authData,
    passwordReset: state.authReducer.passwordReset,
    passwordResetLoading: state.authReducer.passwordResetLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateUser: (data) => dispatch(updateUser(data)),
    updatePassword: (data) => dispatch(updatePassword(data)),
    resetPassInit: () => dispatch(resetPassInit()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMain);
