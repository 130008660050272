import React, {useState} from "react";
import styled from "styled-components";
import {Row, Col} from "react-bootstrap";
import ExpandSection from "./ExpandSection";
import RightSection from "./RightSection";
import MobileGallery from "./MobileGallery";
import AddReview from './AddReview'
import Divider from "@material-ui/core/Divider";
import Iframe from 'react-iframe'
import Model from "./Model";
import {onlyUnique} from "../../../helpers/globalFunc";

let LeftSection = (props) => {
    let {
        product,
        productDetail3D,
        textures_section,
        active,
        addToCart,
        addToWishlist,
        handleVariable,
        variation,
        preBook,
        handlePreBook,
        handleAuthModal,
        authData,
        initReview,
        addReview,
        newReview,
        newReviewErr,
        newReviewLoading,
        addWishlistServer,
        addCartServer,
        color,
        material,
        setMaterials,
        setColors,
        userId,
        setId,
        idx
    } = props;

    let availableColors = variation.map((item) => {
        let key = item.attributes.attribute_pa_color;
        return key;
    });

    let availableMaterials = variation.map((item) => {
        let key = item.attributes.attribute_pa_materials;
        return key;
    });

    let filtered = availableColors.filter(onlyUnique);
    let materials = availableMaterials.filter(onlyUnique);
    let colAttr = product?.attributes[0].pa_color
        ? product?.attributes[0].pa_color
        : null;

    let gallery = active
        ? active.variation_gallery_images
            ? active.variation_gallery_images
            : []
        : [];

    const [open, setOpen] = useState(false)

    return (
        <LeftCol>
            <AddReview
                open={open}
                handleClickOpen={setOpen}
                authData={authData}
                productId={product?.id}
                submitReview={addReview}
                initReview={initReview}
                newReview={newReview}
                newReviewErr={newReviewErr}
                newReviewLoading={newReviewLoading}
            />
            <div className="change-view">
                <div style={{display: 'flex' , marginTop: 0, alignItems: 'center'}}>
                    <div className="text-breadcrumb">Product</div>
                    <div className="text-breadcrumb-point"/>
                    {/*<div className="text-breadcrumb">Brands</div>*/}
                    {/*<div className="text-breadcrumb-point"/>*/}
                    <div className="text-breadcrumb">{product?.name}</div>
                </div>
                <Divider style={{marginTop: 6, marginBottom: 64}}/>
            </div>

            <div className="gallery">
                {/*<Iframe url="http://192.168.7.60:3000/3d/3733?color=titanium&material=titanium"*/}
                {/*        width="100%"*/}
                {/*        height="500px"*/}
                {/*        id="myId"*/}
                {/*        overflow ="hidden"*/}
                {/*        allow*/}
                {/*        className="myClassname"*/}
                {/*        display="initial"*/}
                {/*        position="relative"/>*/}

                {
                    productDetail3D && productDetail3D[0]?.acf?.["3d_model"]?.url ?
                        <Model
                            product={productDetail3D ? productDetail3D[0]: []}
                            color={color ? color : '#000000&&Black&&black'}
                            material={material ? material : 'default'}/>
                        :
                        <Row>
                            {
                                gallery.map((item, key)=>(
                                    <Col md={6} className="col-single" key={key}>
                                        <img src={item} className="image-single" alt=""/>
                                    </Col>
                                ))
                            }
                        </Row>
                }
                {/*<Row>*/}
                {/*    {gallery.map((item) => (*/}
                {/*        <Col md={6} className="col-single">*/}
                {/*            <img src={item} className="image-single" alt={`${product?.name} Detail`}/>*/}
                {/*        </Col>*/}
                {/*    ))}*/}
                {/*</Row>*/}
            </div>
            <div className="mobile-gallery">
                <MobileGallery gallery={gallery}
                               productDetail3D={productDetail3D}
                               active={active}
                               color={color}
                               material={material}
                />
            </div>
            <div className="mobile-mid">
                <RightSection
                    textures_section={textures_section}
                    product={product}
                    addToCart={addToCart}
                    addCartServer={addCartServer}
                    addWishlistServer={addWishlistServer}
                    addToWishlist={addToWishlist}
                    userId={userId}
                    preBook={preBook}
                    setColors={setColors}
                    setMaterials={setMaterials}
                    color={color}
                    material={material}
                    active={active}
                    handleVariable={handleVariable}
                    variation={variation}
                    handlePreBook={handlePreBook}
                    handleAuthModal={handleAuthModal}
                    idx={idx}
                    setId={setId}
                />
            </div>
            <ExpandSection
                product={product}
                active={active}
                handleOpen={setOpen}
                authData={authData}
            />
        </LeftCol>
    );
};

let LeftCol = styled.div`
  .image-single {
    width: 100%;
  }

  .col-single {
    margin-bottom: 30px;
  }

  .text-breadcrumb {
    font-weight: normal;
    font-size: 10px;
    color: #313132;
    font-family: "Poppins", sans-serif;
  }

  .text-breadcrumb-point{
    width: 3px;
    height: 3px;
    color: #313132;
    border-radius: 50%;
    background-color: #313132;
    margin-right: 8px;
    margin-left: 8px;
  }

  .myClassname {
    &::-webkit-scrollbar {
      width: 0px;
    }
    border: 0px;
  }

  .gallery {
    min-height: 450px;
    model-viewer {
      min-height: 450px;
      width: 100%;
    }
  }

  .change-view {
    display: block;
    @media(max-width: 991px){
      display: none;
    }
  }

  .mobile-mid {
    display: none;
  }

  .mobile-gallery {
    display: none;
  }

  @media (max-width: 991px) {
    .mobile-mid {
      display: block;
    }

    .gallery {
      display: none;
    }

    .mobile-gallery {
      display: block;
      margin-top: 20px;
      model-viewer{
        min-height: 300px;
        width: 100%;
      }
    }
    .image-gallery-slide .image-gallery-image{
      object-fit: cover;
    }
  }
`;

export default LeftSection;