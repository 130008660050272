import styled from "styled-components";

export const FileUploadContainer = styled.section`
  position: relative;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  background-color: #F7F7F7;
  width: 100%;
  border: 1px solid #EEEEEE;
  border-radius: 5px;
  padding: 11px 20px 11px 20px;
`;

export const FormField = styled.input`
  font-size: 18px;
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  &:focus {
    outline: none;
  }
`;

export const UploadFileBtn = styled.button`
  background-color: rgba(183, 46, 99, 0);
  cursor: pointer;
  text-align: center;
  text-transform: none;
  color: #A1A1A1;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  position: relative;
  overflow: hidden;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;

  &:hover {
    outline: 0;

    &:after {
      width: 110%;
    }
  }

  &:disabled {
    opacity: 0.4;
    filter: grayscale(100%);
    pointer-events: none;
  }
`;

export const FilePreviewContainer = styled.article`
  margin-bottom: 35px;
  span {
    font-size: 14px;
  }
`;

export const PreviewList = styled.section`
  display: flex;
  flex-direction: column;
  //   flex-wrap: wrap;
  margin-top: 10px;
  @media only screen and (max-width: 400px) {
    flex-direction: column;
  }
`;

export const FileMetaData = styled.div`
  display: ${(props) => (props.isImageFile ? "none" : "flex")};
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  background-color: rgba(5, 5, 5, 0.55);
  aside {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
  }
`;

export const PreviewContainer = styled.section`
  padding: 0.25rem;
  width: auto;
  border-radius: 6px;
  box-sizing: border-box;
  &:hover {
    opacity: 0.55;
    ${FileMetaData} {
      display: flex;
    }
  }
  & > div:first-of-type {
    height: 100%;
    position: relative;
  }
`;
