import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import {withStyles} from "@material-ui/core";
import Title from "../../../components/Title";
import Button from "../../../components/NewButtonSubmit";
import {authStyle} from "../../auth/authStyle";
import CrossIcon from "../../../assets/icons/cross-icon.svg";
import {Col, Form, Row} from "react-bootstrap";
import Rating from '@material-ui/lab/Rating';
import ReactHtmlParser from "react-html-parser";
import Box from '@material-ui/core/Box';

let style = (theme) => authStyle(theme);

const FormDialog = (props) => {
    const {
        open,
        handleClickOpen,
        classes,
        productId,
        authData,
        submitReview,
        initReview,
        newReview,
        newReviewErr,
        newReviewLoading
    } = props

    const [review, setReview] = useState('')
    const [rating, setRating] = useState(0)

    const handleReview = e => {
        setReview(e.target.value)
    }

    const handleClose = () => {
        handleClickOpen(false)
        initReview()
    }

    const handleSubmit = () => {
        let data = {
            product_id: productId,
            reviewer: authData.display_name,
            reviewer_email: authData.user_email ? authData.user_email : authData.user_phone,
            review: review.length ? review : ' ',
            rating: rating,
            status: "hold"
        }
        submitReview(data)
    }
    //console.log('rating data', rating)
    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                style={{width: 'auto !important'}}
            >
                <DialogContent className={classes.main}>
                    <Title
                        text={"Review"}
                        color={"#9C9434"}
                        fontSize={12}
                        lineHeight={14}
                        textAlign={"left"}
                        fontFamily={'Poppins'}
                        fontWeight={'500'}
                    />
                    <Title
                        text={"Add a Review"}
                        color={"#313132"}
                        fontSize={24}
                        lineHeight={26}
                        textAlign={"left"}
                        fontWeight={'500'}
                        textTransform={"uppercase"}
                        // margin={'5px 0px 15px 0px'}
                    />
                    <img
                        className={classes.crossIcon}
                        src={CrossIcon}
                        alt=""
                        onClick={handleClose}
                    />
                    <Box component="fieldset" mb={3} borderColor="transparent" style={{marginBottom: 0}}>
                        <Title
                            text={"Product Rating:"}
                            color={"#313132"}
                            fontSize={14}
                            lineHeight={20}
                            textAlign={"left"}
                            margin={'15px 0px 0 0px'}
                            fontWeight={'500'}
                            fontFamily={'Poppins'}
                        />
                        <Rating
                            name="simple-controlled"
                            style={{color: "#9C9434"}}
                            value={rating}
                            onChange={(event, newValue) => {
                                setRating(newValue);
                            }}
                        />
                    </Box>
                    <Form>
                        <Form.Group>
                            <Row>
                                <Col className={classes.formField} md={12} style={{marginTop: 0}}>
                                    <Form.Label className={classes.formLabel}>
                                        Product Review
                                    </Form.Label>
                                    <Form.Control
                                        disabled={rating === 0 ? true : false}
                                        type="text"
                                        value={review}
                                        className={classes.formInput}
                                        onChange={handleReview}
                                        placeholder={"Write Your Review"}
                                        as="textarea"
                                        rows="4"
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                    <Button
                        disabled={rating === 0 ? true : false}
                        text={"Submit"}
                        loading={newReviewLoading}
                        handleSubmit={handleSubmit}
                    />
                    {newReview ? (
                        <Title
                            text={'Review is submitted successfully'}
                            fontWeight={200}
                            fontSize={14}
                            lineHeight={20}
                            color={'green'}
                        />
                    ) : null}
                    {newReviewErr ? (
                        <Title
                            text={ReactHtmlParser(newReviewErr)}
                            fontWeight={200}
                            fontSize={14}
                            lineHeight={20}
                            color={'red'}
                        />
                    ) : null}
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default withStyles(style)(FormDialog);
