import { HOME } from "../../constants/home";
import { onlyUnique } from "../../../helpers/globalFunc";

const initialState = {
  bannerData: [],
  bannerLoading: false,
  bannerErr: "",
  categories: [],
  categoryLoading: false,
  categoryErr: null,
  scope: [],
  scopeLoading: false,
  scopeErr: null,
  allProducts: [],
  allProductsLoading: false,
  allProductsErr: null,
  featuredProducts: [],
  featuredLoading: false,
  featuredErr: null,
  posts: [],
  postLoading: false,
  postErr: null,
  brandData: [],
  brandDataLoading: false,
  brandDataErr: null,
  pages: '',
  pageLoading: false,
  pageErr: null,
  productDetail: null,
  productDetailLoading: false,
  productDetailErr: null,
  productDetail3D: null,
  productDetail3DLoading: false,
  productDetail3DErr: null,
  filteredProduct: [],
  filteredLoading: false,
  filteredErr: null,
  newsletter: null,
  newsletterLoading: false,
  newsletterErr: null,
  categoryProducts: [],
  categoryProductsLoading: false,
  categoryProductsErr: null,
  loadNum: 20,
  loadMoreLoading: false,
  bod_list: [],
  webSeries: '',
  webSeriesPost: [],
  webSeriesCat: [],
  webSeriesLoading: false,
  webSeriesErr: null
};

export default function homeReducer(state = initialState, action) {
  switch (action.type) {
    case HOME.CATEGORIES_BRAND.MAIN:
      return {
        ...state,
        brandData: [],
        brandDataLoading: true,
        brandDataErr: "",
      };
    case HOME.CATEGORIES_BRAND.SUCCESS:
      // //console.log('result from action index',action.result)
      return {
        ...state,
        brandData: action.result,
        brandDataLoading: false,
        brandDataErr: "",
      };
    case HOME.CATEGORIES_BRAND.FAILURE:
      return {
        ...state,
        brandData: [],
        brandDataLoading: false,
        brandDataErr: "Error in banner slider",
      };



    case HOME.SCOPE_OF_WORK.MAIN:
      return {
        ...state,
        scope: [],
        scopeLoading: true,
        scopeErr: "",
      };
    case HOME.SCOPE_OF_WORK.SUCCESS:
      // //console.log('result from action index',action.result)
      return {
        ...state,
        scope: action.result,
        scopeLoading: false,
        scopeErr: "",
      };
    case HOME.SCOPE_OF_WORK.FAILURE:
      return {
        ...state,
        scope: [],
        scopeLoading: false,
        scopeErr: "Error in banner slider",
      };








    case HOME.FETCH_WEB_SERIES_POST_CATEGORY:
      return {
        webSeriesCat: action.webSeriesCat
      }
    case HOME.FETCH_WEB_SERIES_POST.MAIN:
      return {
        ...state,
        webSeries: state.webSeries,
        webSeriesPost: state.webSeriesPost,
        webSeriesCat: state.webSeriesCat,
        webSeriesLoading: true,
        webSeriesErr: "",
      };
    case HOME.FETCH_WEB_SERIES_POST.SUCCESS:
      return {
        ...state,
        webSeries: action.result,
        webSeriesPost: action.posts,
        webSeriesCat: action.Cats,
        webSeriesLoading: false,
        webSeriesErr: "",
      };
    case HOME.FETCH_WEB_SERIES_POST.FAILURE:
      return {
        ...state,
        webSeries: state.webSeries,
        webSeriesPost: state.webSeriesPost,
        webSeriesCat: state.webSeriesCat,
        webSeriesLoading: false,
        webSeriesErr: action.result,
      };
    case HOME.FETCH_WEB_SERIES_POST_LOAD_MORE.MAIN:
      return {
        ...state,
        webSeries: state.webSeries,
        webSeriesPost: state.webSeriesPost,
        webSeriesCat: state.webSeriesCat,
        loadMoreLoading: true,
        webSeriesErr: "",
      };
    case HOME.FETCH_WEB_SERIES_POST_LOAD_MORE.SUCCESS:
      return {
        ...state,
        webSeries: action.result,
        webSeriesPost: action.posts,
        webSeriesCat: action.Cats,
        loadMoreLoading: false,
        webSeriesErr: "",
      };
    case HOME.FETCH_WEB_SERIES_POST_LOAD_MORE.FAILURE:
      return {
        ...state,
        webSeries: state.webSeries,
        webSeriesPost: state.webSeriesPost,
        webSeriesCat: state.webSeriesCat,
        loadMoreLoading: false,
        webSeriesErr: action.result,
      };
    case HOME.FETCH_HOME_BANNER_SLIDER.MAIN:
      return {
        ...state,
        bannerData: [],
        bannerLoading: true,
        bannerErr: "",
        message: "",
      };
    case HOME.FETCH_HOME_BANNER_SLIDER.SUCCESS:
      return {
        ...state,
        bannerData: action.result,
        bannerLoading: false,
        bannerErr: "",
        message: action.message,
      };
    case HOME.FETCH_HOME_BANNER_SLIDER.FAILURE:
      return {
        ...state,
        bannerData: [],
        bannerLoading: false,
        bannerErr: "Error in banner slider",
        message: "failure",
      };

    case HOME.FETCH_PRODUCT_CATEGORIES.MAIN:
      return {
        ...state,
        categories: [],
        categoryLoading: true,
        categoryErr: null,
      };

    case HOME.FETCH_PRODUCT_CATEGORIES.SUCCESS:
      return {
        ...state,
        categories: action.result,
        categoryLoading: false,
        categoryErr: null,
      };

    case HOME.FETCH_PRODUCT_CATEGORIES.FAILURE:
      return {
        ...state,
        categories: [],
        categoryLoading: false,
        categoryErr: action.result,
      };

    case HOME.FETCH_ALL_PRODUCTS.MAIN:
      return {
        ...state,
        allProducts: [],
        allProductsLoading: true,
        allProductsErr: null,
      };

    case HOME.FETCH_ALL_PRODUCTS.SUCCESS:
      return {
        ...state,
        allProducts: action.result,
        allProductsLoading: false,
        allProductsErr: null,
      };

    case HOME.FETCH_ALL_PRODUCTS.FAILURE:
      return {
        ...state,
        allProducts: [],
        allProductsLoading: false,
        allProductsErr: action.result,
      };

    case HOME.FETCH_FEATURED_PRODUCTS.MAIN:
      return {
        ...state,
        featuredProducts: [],
        featuredLoading: true,
        featuredErr: null,
      };

    case HOME.FETCH_FEATURED_PRODUCTS.SUCCESS:
      return {
        ...state,
        featuredProducts: action.result,
        featuredLoading: false,
        featuredErr: null,
      };

    case HOME.FETCH_FEATURED_PRODUCTS.FAILURE:
      return {
        ...state,
        featuredProducts: [],
        featuredLoading: false,
        featuredErr: action.result,
      };

    case HOME.FETCH_ALL_POSTS.MAIN:
      return {
        ...state,
        posts: [],
        postLoading: true,
        postErr: null,
      };

    case HOME.FETCH_ALL_POSTS.SUCCESS:
      return {
        ...state,
        posts: action.result,
        postLoading: false,
        postErr: null,
      };

    case HOME.FETCH_ALL_POSTS.FAILURE:
      return {
        ...state,
        posts: [],
        postLoading: false,
        postErr: action.result,
      };

    case HOME.FETCH_PAGES.MAIN:
      return {
        ...state,
        pages: null,
        pageLoading: true,
        pageErr: null,
      };

    case HOME.FETCH_PAGES.SUCCESS:
      return {
        ...state,
        pageLoading: false,
        pages: action.result,
        pageErr: null,
      };

    case HOME.FETCH_PAGES.FAILURE:
      return {
        ...state,
        pages: null,
        pageLoading: false,
        pageErr: "Error from page fetch",
      };

    case HOME.FETCH_PRODUCT_DETAILS.MAIN:
      return {
        ...state,
        productDetail: null,
        productDetailLoading: true,
        productDetailErr: null,
      };

    case HOME.FETCH_PRODUCT_DETAILS.SUCCESS:
      return {
        ...state,
        productDetail: action.result,
        productDetailLoading: false,
      };

    case HOME.FETCH_PRODUCT_DETAILS.FAILURE:
      return {
        ...state,
        productDetailErr: "Error From Product Fetching",
        productDetailLoading: false,
      };

    case HOME.FETCH_PRODUCT_DETAILS_3D.MAIN:
      return {
        ...state,
        productDetail3D: null,
        productDetail3DLoading: true,
        productDetail3DErr: null,
      };

    case HOME.FETCH_PRODUCT_DETAILS_3D.SUCCESS:
      return {
        ...state,
        productDetail3D: action.result,
        productDetail3DLoading: false,
      };

    case HOME.FETCH_PRODUCT_DETAILS_3D.FAILURE:
      return {
        ...state,
        productDetail3DErr: "Error From Product Fetching",
        productDetail3DLoading: false,
      };

    case HOME.FETCH_FILTERED.MAIN:
      return {
        ...state,
        filteredLoading: true,
        filteredErr: null,
      };

    case HOME.FETCH_FILTERED.SUCCESS:
      let result = action.result;
      let newArr = [];

      let len = result.length;

      for (let i = 0; i < len; i++) {
        let find = newArr.find((item) => item.id === result[i].id);
        if (find) continue;
        else newArr.push(result[i]);
      }

      return {
        ...state,
        filteredLoading: false,
        filteredProduct: newArr,
        filteredErr: null,
      };

    case HOME.FETCH_FILTERED.FAILURE:
      return {
        ...state,
        filteredLoading: false,
        filteredErr: "Error from Product Filtering",
      };

    case HOME.FETCH_TEXT_FILTERED.MAIN:
      return {
        ...state,
        filteredLoading: true,
        filteredErr: null,
      };

    case HOME.FETCH_TEXT_FILTERED.SUCCESS:
      let results = action.result;
      let newArrr = [];

      let lenn = results.length;

      for (let i = 0; i < lenn; i++) {
        let find = newArrr.find((item) => item.id === results[i].id);
        if (find) continue;
        else newArrr.push(results[i]);
      }

      return {
        ...state,
        filteredLoading: false,
        filteredProduct: newArrr,
        filteredErr: null,
      };

    case HOME.FETCH_TEXT_FILTERED.FAILURE:
      return {
        ...state,
        filteredLoading: false,
        filteredErr: "Error from Product Filtering",
      };

    case HOME.SUBSCRIBE_NEWSLETTER.MAIN:
      return {
        ...state,
        newsletterLoading: true,
        newsletterErr: null,
      };

    case HOME.SUBSCRIBE_NEWSLETTER.SUCCESS:
      return {
        ...state,
        newsletter: action.result,
        newsletterLoading: false,
      };

    case HOME.SUBSCRIBE_NEWSLETTER.FAILURE:
      return {
        ...state,
        newsletterErr: "Error from Newsletter submit",
        newsletterLoading: false,
      };

    case HOME.FILTERED_INIT:
      return {
        ...state,
        filteredProduct: [],
        productDetail: null,
        productDetail3D: null
      }

    case HOME.NEWSLETTER_INIT:
      return {
        ...state,
        newsletterLoading: false,
        newsletterErr: null,
        newsletter: null,
      };

    case HOME.FETCH_CATEGORY_PRODUCTS.MAIN:
      return {
        ...state,
        categoryProductsLoading: true,
        categoryProductsErr: null,
        categoryProducts: []
      }

    case HOME.FETCH_CATEGORY_PRODUCTS.MAIN_POST:
      return {
        ...state,
        categoryProductsLoading: Number(action.data.pageNum) > 1 ? false : true,
        loadMoreLoading: true,
        categoryProductsErr: null,
        categoryProducts: Number(action.data.pageNum) > 1 ? action.prev_data : []
      }

    case HOME.FETCH_CATEGORY_PRODUCTS.SUCCESS:
      return {
        ...state,
        categoryProducts: action.result,
        categoryProductsErr: null,
        categoryProductsLoading: false,
        loadMoreLoading: false
      }

    case HOME.FETCH_CATEGORY_PRODUCTS.FAILURE:
      return {
        ...state,
        categoryProductsLoading: false,
        categoryProductsErr: null,
        categoryProducts: []
      }

      case HOME.FETCH_ABOUT_US_BOD_POSTS.SUCCESS:
      return {
        ...state,
        bod_list: action.result,
      }

    case HOME.FETCH_ABOUT_US_BOD_POSTS.FAILURE:
      return {
        ...state,
        bod_list: []
      }

    case HOME.CATEGORY_PRODUCT_INIT:
      return {
        ...state,
        categoryProducts: [],
        categoryProductsLoading: true,
      }

    default:
      return state;
  }
}
