import { HOME } from "../../constants/home";

export function fetchHomeBannerSlider() {
  return {
    type: HOME.FETCH_HOME_BANNER_SLIDER.MAIN,
  };
}

export function fetchCategoryData() {
  return {
    type: HOME.FETCH_PRODUCT_CATEGORIES.MAIN,
  };
}

export function fetchBrand() {
  return {
    type: HOME.CATEGORIES_BRAND.MAIN,
  };
}

export function fetchProductData() {
  return {
    type: HOME.FETCH_ALL_PRODUCTS.MAIN,
  };
}

export function fetchFeaturedProducts(id, pageNum = 1, prev_data = [], isSearch = false, search = {}) {
  return {
    type: HOME.FETCH_FEATURED_PRODUCTS.MAIN,
    id, pageNum, prev_data, isSearch, search
  };
}

export function fetchCategoryProducts(id) {
  return {
    type: HOME.FETCH_CATEGORY_PRODUCTS.MAIN,
    id
  };
}

export function fetchCategoryProductsWithPost(data,prev_data = [], isSearch = false, search = {}) {
  return {
    type: HOME.FETCH_CATEGORY_PRODUCTS.MAIN_POST,
    data,
    prev_data,
    isSearch,
    search
  };
}

export function getWebSeriesCategory(webSeriesCat){
  return {
    type: HOME.FETCH_WEB_SERIES_POST_CATEGORY,
    webSeriesCat
  }
}

export function getWebSeries(params, prev_data){
  return {
    type: HOME.FETCH_WEB_SERIES_POST.MAIN,
    params, prev_data
  }
}

export function getWebSeriesLoadMore(params, prev_data){
  return {
    type: HOME.FETCH_WEB_SERIES_POST_LOAD_MORE.MAIN,
    params, prev_data
  }
}

export function getAllPosts() {
  return {
    type: HOME.FETCH_ALL_POSTS.MAIN,
  };
}

export function getBodListData() {
  return {
    type: HOME.FETCH_ABOUT_US_BOD_POSTS.MAIN,
  };
}


export function getScopeOfWork() {
  return {
    type: HOME.SCOPE_OF_WORK.MAIN,
  };
}

export function fetchPages(data) {
  return {
    type: HOME.FETCH_PAGES.MAIN,
    data
  };
}

export function resetPages() {
  return {
    type: HOME.FETCH_PAGES.SUCCESS,
    result: null,
  };
}

export function fetchProductDetails(slug) {
  return {
    type: HOME.FETCH_PRODUCT_DETAILS.MAIN,
    slug,
  };
}

export function fetchProductDetails3D(slug) {
  return {
    type: HOME.FETCH_PRODUCT_DETAILS_3D.MAIN,
    slug,
  };
}

export function submitNewsletter(data) {
  return {
    type: HOME.SUBSCRIBE_NEWSLETTER.MAIN,
    data,
  };
}

export function initNewsLetter() {
  return {
    type: HOME.NEWSLETTER_INIT,
  };
}

export function fetchFiltered(data) {
  return {
    type: HOME.FETCH_FILTERED.MAIN,
    data,
  };
}

export function fetchTextFiltered(param) {
  return {
    type: HOME.FETCH_TEXT_FILTERED.MAIN,
    param,
  };
}

export function categoryProductInit() {
  return {
    type: HOME.CATEGORY_PRODUCT_INIT,
  };
}

export function filterInit() {
  return {
    type: HOME.FILTERED_INIT,
  };
}
