import React, {useState, useEffect} from 'react';
import Button from '../../../components/NewButtonSubmit';
import Title from "../../../components/Title";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Form, Row, Col} from 'react-bootstrap'
import {handleAuthModal, setCode, verifyCode} from "../../../redux/actions/auth";
import {connect} from 'react-redux'
import {withStyles} from "@material-ui/core";
import TimerMixin from 'react-timer-mixin'

import {authStyle} from "../authStyle";
import CrossIcon from "../../../assets/icons/cross-icon.svg";

let styles = theme => authStyle(theme)

let Login = (props) => {
  let {
    openModal,
    handleAuthModal,
    classes,
    codeVerify,
    codeVerifyLoading,
    setAuthCode,
    verifyCode,
    phone
  } = props

  let [code, setCode] = useState('');
  let [success, setSuccess] = useState('');
  let [error, setError] = useState('')

  let handleCode = e => {
    setCode(e.target.value)
  }

  const handleClose = () => {
    handleAuthModal('')
  };

  let handleSubmit = () => {
    let data = {
      user_phone: phone,
      otp: code
    }
    verifyCode(data)

  }

  useEffect(() => {
    if(codeVerify) {
      if(codeVerify.status) {
        setAuthCode(code)
        setSuccess(codeVerify.message)

        TimerMixin.setTimeout(() => {
          handleAuthModal('change-pass')
          setSuccess('')
          setError('')
        }, 5000)
      } else {
        setError(codeVerify.message)
      }
    }
  }, [codeVerify])

  return (
    <div>
      <Dialog
        open={openModal === 'verify'}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className={classes.main}>
          <Title
            text={'Verify code'}
            color={'#313132'}
            fontSize={24}
            lineHeight={40}
            fontWeight={'500'}
            textAlign={'left'}
            textTransform={'normal'}
            // margin={'5px 0px 15px 0px'}
          />
          <img className={classes.crossIcon} src={CrossIcon} onClick={handleClose} />
          <Form>
            <Form.Group>
              <Row>
                <Col className={classes.formField} md={12}>
                  <Form.Label className={classes.formLabel}>Code</Form.Label>
                  <Form.Control
                    type='text'
                    name="code"
                    value={code}
                    className={classes.formInput}
                    onChange={handleCode}
                    placeholder={'Enter code'}
                    //onBlur={this.handleEmailBlur}
                  />
                  {success.length ? (
                    <Title
                      text={success}
                      fontWeight={200}
                      fontSize={14}
                      lineHeight={20}
                      color={'green'}
                    />
                  ) : null}
                  {error.length ? (
                    <Title
                      text={error}
                      fontWeight={200}
                      fontSize={14}
                      lineHeight={20}
                      color={'red'}
                    />
                  ) : null}
                </Col>
              </Row>
            </Form.Group>
          </Form>
          <Button text={'Submit'} handleSubmit={handleSubmit} loading={codeVerifyLoading} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    openModal: state.authReducer.openModal,
    codeVerify: state.authReducer.codeVerify,
    codeVerifyLoading: state.authReducer.codeVerifyLoading,
    phone: state.authReducer.phoneNum
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleAuthModal: data => dispatch(handleAuthModal(data)),
    verifyCode: data => dispatch(verifyCode(data)),
    setAuthCode: data => dispatch(setCode(data))
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Login))
