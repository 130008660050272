import React, { useState, useEffect } from "react";
import Button from "../../../components/NewButtonSubmit";
import Title from "../../../components/Title";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { Form, Row, Col } from "react-bootstrap";
import { handleAuthModal, login } from "../../../redux/actions/auth";
import {
  mergeCart,
  fetchAllCart,
  getAllWishlist,
  mergeWishlist,
} from "../../../redux/actions/rightDrawer";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { authStyle } from "../authStyle";
import CrossIcon from "../../../assets/icons/cross-icon.svg";

let styles = (theme) => authStyle(theme);

const convertCart = (data) => {
  let len = data.length;
  let products = [];

  for (let i = 0; i < len; i++) {
    let item = data[i];
    let retData = {
      product_id: item.cartItem.id.toString(),
      count: item.count,
    };

    products.push(retData);
  }

  return products;
};

let Login = (props) => {
  let {
    openModal,
    handleAuthModal,
    classes,
    authData,
    authDataErr,
    authDataLoading,
    login,
    mergeCart,
    cart,
    fetchAllCart,
    wishlist,
    mergeWishlist,
    getAllWishlist,
    allCartLoading,
    allWishlistLoading,
  } = props;

  let [phone, setPhone] = useState("");
  let [password, setPassword] = useState("");
  let [authError, setAuthError] = useState("");

  let handlePhone = (e) => {
    setPhone(e.target.value);
  };

  let handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleClose = () => {
    handleAuthModal("");
  };

  let handleSubmit = () => {
    if (phone.length && password.length) {
      let data = {
        user_phone: phone,
        user_pass: password,
      };
      login(data);
    } else {
      setAuthError("Phone or email field is empty");
    }
  };

  useEffect(() => {
    if (authData && openModal === "login") {
      if (authData.token) {
        let userId = authData.id;

        if (cart.length) {
          let mergeData = {
            user_id: userId.toString(),
            product_cart: convertCart(cart),
          };

          mergeCart(mergeData);
        } else {
          let data = { user_id: userId };
          fetchAllCart(data);
        }

        if (wishlist.length && !allWishlistLoading) {
          let productIds = wishlist.map((item) => {
            let obj = {
              product_id: item.id.toString(),
            };

            return obj;
          });
          let mergeData = {
            user_id: userId.toString(),
            product_wish_cart: productIds,
          };

          mergeWishlist(mergeData);
        } else {
          let data = { user_id: userId };
          getAllWishlist(data);
        }

        handleAuthModal("auth-modal");
        setPhone("");
        setPassword("");
      } else {
        setAuthError(authData.message);
      }
    } else {
      setAuthError("");
    }
  }, [authData]);

  useEffect(() => {
    if (authDataErr) {
      setAuthError(authError);
    }
  }, [authDataErr]);

  return (
    <div>
      <Dialog
        open={openModal === "login"}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className={classes.main}>
          <Title
            text={"Welcome Back"}
            color={"#9C9434"}
            fontSize={12}
            lineHeight={18}
            textAlign={"left"}
            fontFamily={"Poppins"}
            fontWeight={"500"}
            letterSpacing={60}
            textTransform={"uppercase"}
            // margin={'15px 0px 15px 0px'}
          />
          <Title
            text={"SIGN IN TO CONTINUE"}
            color={"#313132"}
            fontSize={24}
            fontWeight={"500"}
            lineHeight={40}
            textAlign={"left"}
            textTransform={"uppercase"}
            // margin={'5px 0px 15px 0px'}
          />
          <img
            className={classes.crossIcon}
            src={CrossIcon}
            alt=""
            onClick={handleClose}
          />
          <Form>
            <Form.Group>
              <Row>
                <Col className={classes.formField} md={12}>
                  <Form.Label className={classes.formLabel}>
                    Phone Number
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    value={phone}
                    onChange={handlePhone}
                    className={classes.formInput}
                    placeholder={"Your phone number"}
                    //onBlur={this.handleEmailBlur}
                  />
                  {/*{emailError.length ? (*/}
                  {/*  <Title*/}
                  {/*    text={emailError}*/}
                  {/*    fontWeight={200}*/}
                  {/*    fontSize={14}*/}
                  {/*    lineHeight={20}*/}
                  {/*    color={'red'}*/}
                  {/*  />*/}
                  {/*) : null}*/}
                </Col>
                <Col className={classes.formField} md={12}>
                  <Form.Label className={classes.formLabel}>
                    Password
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={password}
                    className={classes.formInput}
                    placeholder={"Password"}
                    onChange={handlePassword}
                  />
                  {authError.length ? (
                    <Title
                      text={authError}
                      fontWeight={200}
                      fontFamily={"Poppins"}
                      fontSize={14}
                      lineHeight={20}
                      margin={"10px 0 0 0"}
                      color={"red"}
                    />
                  ) : null}
                </Col>
              </Row>
            </Form.Group>
          </Form>
          <Button
            text={"Log In"}
            loading={authDataLoading}
            handleSubmit={handleSubmit}
          />
          <div className={classes.linkText}>
            <Title
              text={"Don't have any account?"}
              color={"#313132"}
              fontSize={14}
              lineHeight={20}
              fontFamily={"Poppins"}
              fontWeight={400}
              textAlign={"left"}
              textTransform={"capitalize"}
              margin={"20px 0px 0px 0px"}
            />
            <Link
              onClick={() => handleAuthModal("signup-verify")}
              style={{ textDecoration: "underline", color: "#9C9434" }}
            >
              <Title
                text={"Sign Up"}
                color={"#9C9434"}
                fontSize={14}
                fontFamily={"Poppins"}
                fontWeight={400}
                lineHeight={20}
                textAlign={"left"}
                textTransform={"capitalize"}
                margin={"20px 0 0 5px"}
              />
            </Link>
          </div>
          <div className={classes.divider} />
          <div className={classes.linkText}>
            <Title
              text={"Forgot your password?"}
              color={"#313132"}
              fontSize={14}
              lineHeight={20}
              fontFamily={"Poppins"}
              fontWeight={400}
              textAlign={"left"}
              margin={"20px 0px 0px 0px"}
            />
            <Link
              onClick={() => handleAuthModal("forget")}
              style={{ textDecoration: "underline", color: "#9C9434" }}
            >
              <Title
                text={"Reset It"}
                color={"#9C9434"}
                fontSize={14}
                fontFamily={"Poppins"}
                fontWeight={400}
                lineHeight={20}
                textAlign={"left"}
                textTransform={"capitalize"}
                margin={"20px 0 0 5px"}
              />
            </Link>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    openModal: state.authReducer.openModal,
    authData: state.authReducer.authData,
    authDataLoading: state.authReducer.authDataLoading,
    authDataErr: state.authReducer.authDataErr,
    cart: state.rightDrawerReducer.cart,
    wishlist: state.rightDrawerReducer.wishlist,
    allCartLoading: state.rightDrawerReducer.allCartLoading,
    allWishlistLoading: state.rightDrawerReducer.allWishlistLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleAuthModal: (data) => dispatch(handleAuthModal(data)),
    login: (data) => dispatch(login(data)),
    mergeCart: (data) => dispatch(mergeCart(data)),
    fetchAllCart: (data) => dispatch(fetchAllCart(data)),
    getAllWishlist: (data) => dispatch(getAllWishlist(data)),
    mergeWishlist: (data) => dispatch(mergeWishlist(data)),
  };
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Login)
);
