import React, {Component, useState} from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import VisibilitySensor from "react-visibility-sensor";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import RightIcon from "../../../assets/images/navRightBlack.svg";
import LeftIcon from "../../../assets/images/navLeftBlack.svg";
import ReactHtmlParser from "react-html-parser";
import {Link, useHistory} from "react-router-dom";
import {connect} from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import rightArrowIcon from "../../../assets/images/btn-arrow-black.svg";
import rightArrowColored from "../../../assets/images/rightIconArrowColored.svg";
import Button from "../../../components/NewButtonSubmit";

const ShopByScope = (props) => {
    let {scope} = props;

    let history = useHistory();

    let [progress, setProgress] = useState(20)

    let handleProgress = value => {
        if (value > 100) setProgress(100)
        else if (value < 20) setProgress(20)
        else setProgress(value)
    }


    let brand = scope


    let len = brand.length;


    const params = {
        on: {
            progress: (swiper, progress) => handleProgress(Math.floor(swiper * 100))
        },
        slidesPerView: 1,
        spaceBetween: 20,
        speed: 1000,
        // pagination: {
        //   el: '.swiper-pagination',
        //   type: 'progressbar',
        //   clickable: true
        // },
        // scrollbar: {
        //   el: '.swiper-scrollbar',
        //   hide: false,
        // },
        //mousewheel: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false
        },
        breakpoints: {
            575: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            576: {
                slidesPerView: 2,
                spaceBetween: 30,
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
        },
        navigation: {
            nextEl: '.swiper-shop-by-scope-button-next',
            prevEl: '.swiper-shop-by-scope-button-prev'
        },
    };

    let handleRoute = (slug) => {
        history.push(`/${slug}`);
    };

    const triggerSwiperArrowClick = (clickNext) => {
        const swiper_next_item = document.getElementsByClassName(clickNext === true ? 'swiper-shop-by-scope-button-next' : 'swiper-shop-by-scope-button-prev');
        swiper_next_item[0]?.click();
    }


    return (
        <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
            {({isVisible}) => (
                <StyledBrand>
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginTop: 32,
                                        marginBottom: 24,
                                    }}
                                >
                                    <div className="title">Scope of Work</div>
                                    {brand.length> 3 &&
                                    <div className="custom-slider-arrow">
                                        <button onClick={() => triggerSwiperArrowClick(false)}
                                                className="custom-prev-btn">
                                            <img src={rightArrowColored} alt=""/>
                                        </button>
                                        <button onClick={() => triggerSwiperArrowClick(true)}
                                                className="custom-next-btn">
                                            <img src={rightArrowColored} alt=""/>
                                        </button>
                                    </div>
                                    }
                                    <div
                                        style={{
                                            marginLeft: "auto",
                                            backgroundColor: "#EFEFEF",
                                            padding: "5px 18px 5px 18px",
                                             borderRadius: "50px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <div
                                            style={{
                                                color: "#313132",
                                                fontSize: 12,
                                                fontWeight: 400,
                                            }}
                                        >
                                            <li>
                                                <Link to="/scope-of-work">See all</Link>
                                            </li>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${isVisible ? "anim-active" : ""} fade-up`}>
                                    {

                                        brand.length && brand.length === len ? (

                                        <>
                                            <Swiper {...params}>
                                                {brand.map((data) => (
                                                    <div
                                                        key={data.term_id}
                                                        className="client-single"
                                                        onClick={() => handleRoute('book-appointment')}
                                                    >
                                                        <div className="client-single__inner">
                                                            <img src={data.full_path}
                                                                 alt={`${ReactHtmlParser(data.name)} image`}/>
                                                            <div className="background">
                                                                <div className="middle-data">
                                                                    <div className="middle-data-inner">
                                                                        {/* <img src={brand} alt="" /> */}
                                                                        <div className="brand-name">
                                                                            {ReactHtmlParser(data.name)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="link">
                                                                <Button
                                                                    text={"Book An Appointment"}
                                                                    onClick={() => handleRoute('book-appointment')}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Swiper>
                                            <LinearProgress
                                                style={{marginTop: 30, height: 2}}
                                                variant="determinate"
                                                value={progress}
                                            />
                                        </>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </StyledBrand>
            )}
        </VisibilitySensor>
    );
};

const StyledBrand = styled.div`
    padding-bottom: 32px;
    background-color: #fff;

    .container {
        max-width: 95%;
    }

    .subTitle {
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        padding-left: 8px;
        padding-bottom: 2px;
        color: rgb(156, 148, 52);
        padding-top: 32px;
    }

    .title {
        font-size: 18px;
        font-weight: 600;
        text-transform: capitalize;
        padding-left: 8px;
        color: #313132;
    }

    .client-single {
        &__inner {
            padding-top: 100%;
            border: 0 solid #707070;
            cursor: pointer;
            position: relative;

            &:after {
                position: absolute;
                inset: 0;
                background: rgba(0, 0, 0, 0.3);
                content: '';
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: auto;
                bottom: 0;
                object-fit: cover;
                width: 100%;
            }

            .background {
                //background-color: rgba(40, 44, 52, 0.6);
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }

            .link {
                position: absolute;
                z-index: 1;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
                color: #282c34;
                display: inline-block;
                opacity: 0;
                visibility: hidden;

                .custom-bg-button{
                    background: #4e4e50;
                }
                @media (max-width: 767px) {
                    opacity: 1 !important;
                    visibility: visible !important;
                    width: 70%;
                    margin: 0 auto;
                }

                .custom-bg-button {
                    padding-left: 10px;
                    padding-right: 10px;
                }

                transition: 1.2s all ease;

                &:hover {
                    color: rgb(156, 148, 52);
                }
            }

            .middle-data {
                z-index: 1;
                //border: 1px solid #9c9434;
                position: absolute;
                top: 20px;
                left: 20px;
                bottom: 0;
                right: 20px;

                @media (max-width: 767px) {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 20px;
                    right: 0;
                    width: 100%;
                }

                .middle-data-inner {
                    padding-top: 80%;
                    @media (min-width: 1500px) {
                        padding-top: 80%;

                    }

                    @media (max-width: 767px) {
                        padding-top: 70%;
                    }

                    img {
                        object-fit: cover;
                        width: 50%;
                        height: 30%;
                    }

                    .brand-name {
                        color: #fff;
                        text-transform: capitalize;
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 400;
                        text-align: center;
                        opacity: 0;
                        transition: 0.7s all ease;
                        visibility: hidden;
                        @media (max-width: 767px) {
                            opacity: 1 !important;
                            visibility: visible !important;
                            font-size: 20px !important;
                            line-height: 22px !important;
                            width: 85%;
                            margin: 0 auto;
                        }
                    }
                }


                &:hover {
                    .link {
                        opacity: 1;
                        visibility: visible;
                    }

                    .brand-name {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }


            &:hover {
                .link {
                    opacity: 1 !important;
                    visibility: visible !important;
                }

                .brand-name {
                    opacity: 1 !important;
                    visibility: visible !important;
                }
            }
        }
    }

    .Products-slider__bottom {
        position: relative;
        z-index: 3;
        margin-top: 100px;
    }

    .swiper-slide {
        margin-bottom: 10px;
    }

    .swiper-container {
        //margin-left: -50px;
        //margin-right: -50px;
        //padding-right: 15px;
        //padding-left: 15px;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            height: 100%;
            width: 50px;
            background-color: rgba(255, 255, 255, 0);
            left: 0;
            top: 0;
            z-index: 2;
        }

        &:after {
            content: "";
            position: absolute;
            height: 100%;
            width: 50px;
            background-color: rgba(255, 255, 255, 0);
            right: 0;
            top: 0;
            z-index: 2;
        }
    }

    .swiper-button-next {
        position: absolute;
        right: -14px;
        bottom: 0px;
        margin: auto;
        z-index: 3;
        height: 50px;
        width: 50px;
        background-image: url(${RightIcon});
        background-position: center;
        background-size: contain;
        top: 0;
        left: auto;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }

    .swiper-button-prev {
        position: absolute;
        right: auto;
        bottom: 0px;
        margin: auto;
        z-index: 3;
        height: 50px;
        width: 50px;
        top: 0;
        left: -14px;
        cursor: pointer;
        background-image: url(${LeftIcon});
        background-size: contain;

        &:focus {
            outline: none;
        }
    }

    .swiper-wrapper {
        margin-bottom: 0px;
    }

    .custom-slider-arrow {
        margin-left: 8px;
        display: flex;
        column-gap: 8px;

        .custom-next-btn, .custom-prev-btn {
            background: rgb(239, 239, 239);
            border-radius: 50%;
            border: unset;
            padding: 0;
            //background: #EFEFEF;
            //width: 30px;
            //height: 30px;
            //border-radius: 50%;
            //border: unset;
            //display: flex;
            //align-items: center;
            //justify-content: center;
            //img{
            //  width: 20px;
            //}
        }

        .custom-prev-btn {
            transform: rotate(180deg);
        }
    }

    @media (max-width: 1024px) {
        .swiper-button-next {
            position: absolute;
            right: 12px;
            bottom: 0px;
            margin: auto;
            z-index: 3;
            height: 50px;
            width: 50px;
            background-image: url(${RightIcon});
            background-position: center;
            background-size: contain;
            top: 0;
            left: auto;
            cursor: pointer;

            &:focus {
                outline: none;
            }
        }

        .client-single__inner .middle-data .middle-data-inner .brand-name {
            color: #fff;
            text-transform: uppercase;
            font-size: 24px;
            font-weight: 400;
            text-align: center;
        }

        .swiper-button-prev {
            position: absolute;
            right: auto;
            bottom: 0px;
            margin: auto;
            z-index: 3;
            height: 50px;
            width: 50px;
            top: 0;
            left: 12px;
            cursor: pointer;
            background-image: url(${LeftIcon});
            background-size: contain;

            &:focus {
                outline: none;
            }
        }
    }

    @media (min-width: 769px) {
        .swiper-pagination {
            display: none !important;
        }
    }

    @media (max-width: 768px) {
        .client-single__inner {
            padding-top: 100%;
        }

        .swiper-pagination {
            display: block !important;
        }

        //.swiper-container{
        //  padding-bottom: 45px;
        //}
        .swiper-pagination {
            bottom: 0px;

            .swiper-pagination-bullet {
                height: 5px;
                width: 5px;
                background-color: #000;
                position: relative;
                margin-right: 20px;
                opacity: 1;

                &:after {
                    content: "";
                    height: 17px;
                    width: 17px;
                    border-radius: 50%;
                    background-color: rgba(0, 0, 0, 0.5);
                    position: absolute;
                    left: -6px;
                    top: -6px;
                    opacity: 0;
                    z-index: -1;
                }

                &.swiper-pagination-bullet-active {
                    &:after {
                        opacity: 1;
                    }
                }
            }
        }

        .sub-title {
            text-align: center;
        }
    }
`;

function mapStateToProps(state) {
    return {
        scope: state.homeReducer.scope,
    };
}

export default connect(mapStateToProps)(ShopByScope);
