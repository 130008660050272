import React from "react";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core";

const NewButton = (props) => {
  let { classes, loading, text, handleSubmit } = props;
  return (
    <div className={classes.booking} onClick={handleSubmit}>
      {text}
    </div>
  );
};

const bookingStyle = (theme) => ({
  booking: {
    backgroundColor: "#d8d8d8",
    margin: "0px 0px",
    marginTop: 24,
    borderRadius: "25px",
    cursor: "pointer",
    width: "100%",
    padding: "8px 0px",
    textAlign: "center",
  },
});

let style = (theme) => bookingStyle(theme);

export default withStyles(style)(NewButton);
