import {takeLatest, put, call} from "redux-saga/effects";
import Logger from "../../../helpers/logger";
import {AxiosServices} from "../../../network/AxiosServices";
import {ApiServices} from "../../../network/ApiServices";
import {CustomerService} from "../../constants/customer-service";

function* postCustomerService(actions) {
    try {
        const result = yield call(
            AxiosServices.post,
            ApiServices.ADD_CUSTOMER_SERVICE,
            actions.data
        );
        yield put({
            type: CustomerService.ADD_CUSTOMER_SERVICE.SUCCESS,
            response_result: result.data,
        });
    } catch (err) {
        Logger(err);
        yield put({
            type: CustomerService.ADD_CUSTOMER_SERVICE.FAILURE,
            result: err.response.data,
        });
    }
}

export default function* customerServiceSagas() {
    yield takeLatest(CustomerService.ADD_CUSTOMER_SERVICE.MAIN, postCustomerService);
}
