import { PRE_BOOK } from "../../constants/prebooking";

const initState = {
  slots: [],
  slotsLoading: false,
  slotsErr: null,
  submit: null,
  submitLoading: false,
  submitErr: null,
  openPreBook: false,
  productId: null,
};

export default function prebookReducer(state = initState, action) {
  switch (action.type) {
    case PRE_BOOK.SEARCH_SLOT.MAIN:
      return {
        ...state,
        slotsLoading: true,
      };

    case PRE_BOOK.SEARCH_SLOT.SUCCESS:
      return {
        ...state,
        slots: action.result,
        slotsLoading: false,
      };

    case PRE_BOOK.SEARCH_SLOT.FAILURE:
      return {
        ...state,
        slotsLoading: false,
        slotsErr: "Error From Slot Searching",
      };

    case PRE_BOOK.SUBMIT_SLOT.MAIN:
      return {
        ...state,
        submitLoading: true,
      };

    case PRE_BOOK.SUBMIT_SLOT.SUCCESS:
      return {
        ...state,
        submitLoading: false,
        submit: action.result,
      };

    case PRE_BOOK.SUBMIT_SLOT.FAILURE:
      return {
        ...state,
        submitLoading: false,
        submitErr: "Error From Slot Submit",
      };

    case PRE_BOOK.CLEAR_SUBMIT:
      return {
        ...state,
        submit: null,
      };

    case PRE_BOOK.HANDLE_PREBOOK:
      return {
        ...state,
        openPreBook: action.open,
        productId: action.id,
      };

    case PRE_BOOK.CLEAR_SLOT:
      return {
        ...state,
        slots: [],
      };

    default:
      return state;
  }
}
