import React from "react";
import Drawer from "@material-ui/core/Drawer";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { handleAuthModal, logout } from "../../../redux/actions/auth";
import {
  handleRightDrawer,
  initCartList,
} from "../../../redux/actions/rightDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import CrossIcon from "../../../assets/icons/cross-button-white.svg";
import { authStyle } from "../authStyle";
import Title from "../../../components/Title";
import TimerMixin from "react-timer-mixin";

import { withRouter } from "react-router-dom";
import styled from "styled-components";

let style = (theme) => authStyle(theme);

let AuthDrawer = (props) => {
  let {
    classes,
    openModal,
    handleAuthModal,
    authData,
    logout,
    handleRightDrawer,
    history,
    initCartList,
  } = props;

  let handleClose = () => {
    handleAuthModal("");
  };

  let handleLogout = () => {
    handleAuthModal("");
    initCartList();
    TimerMixin.setTimeout(() => {
      logout();
    }, 500);
  };

  let handleClick = (data) => {
    handleRightDrawer(data);
    handleClose();
  };

  let handleRoute = (route) => {
    handleAuthModal("");
    TimerMixin.setTimeout(() => {
      history.push(`/${route}`);
    }, 500);
  };

  let drawerHeader = authData
    ? authData.display_name
      ? authData.display_name
      : "User Profile"
    : "User Profile";
  let subHeader = authData
    ? authData.user_phone
      ? authData.user_phone
      : ""
    : "";

  return (
    <div>
      <Drawer
        anchor={"right"}
        open={openModal === "auth-modal"}
        onClose={handleClose}
      >
        <div className={classes.drawerHeader}>
          <div className={classes.drawerHeaderText}>
            <Title
              text={drawerHeader}
              color={"#fff"}
              fontSize={14}
              lineHeight={24}
              fontFamily={'Poppins'}
              fontWeight={"600"}
              margin={"0 0 10px 0"}
              textTransform={"capitalize"}
            />
            <div style={{ borderBottom: "2px solid #4A494A", width: "100%" }} />
            <Title
              text={`Phone: ${subHeader}`}
              color={"#d8d8d8"}
              fontSize={12}
              lineHeight={20}
              fontFamily={'Poppins'}
              fontWeight={"300"}
              margin={"10px 0"}
              textTransform={"capitalize"}
            />
          </div>
          <div onClick={handleClose} className={classes.crossIcon}>
            <img src={CrossIcon} />
          </div>
        </div>
        <div className={classes.drawerBody}>
          <StyledDiv role="presentation">
            <List>
              <ListItem
                className={classes.listDiv}
                onClick={() => handleRoute("profile")}
              >
                Profile
              </ListItem>
              <ListItem
                className={classes.listDiv}
                onClick={() => handleClick("cart")}
              >
                Cart
              </ListItem>
              <ListItem
                className={classes.listDiv}
                onClick={() => handleClick("wishlist")}
              >
                Wishlist
              </ListItem>
              <ListItem
                className={classes.listDiv}
                onClick={() => handleRoute("orders")}
              >
                Track Order
              </ListItem>
              <ListItem className={classes.listDiv} onClick={handleLogout}>
                Log Out
              </ListItem>
            </List>
          </StyledDiv>
        </div>
      </Drawer>
    </div>
  );
};

let StyledDiv = styled.div`
  .MuiListItem-gutters {
    padding-left: 12px !important;
  }
`

function mapStateToProps(state) {
  return {
    openModal: state.authReducer.openModal,
    authData: state.authReducer.authData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleAuthModal: (data) => dispatch(handleAuthModal(data)),
    logout: () => dispatch(logout()),
    handleRightDrawer: (data) => dispatch(handleRightDrawer(data)),
    initCartList: () => dispatch(initCartList()),
  };
}

export default withStyles(style)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthDrawer))
);
