import {takeLatest, put, call} from "redux-saga/effects";
import Logger from "../../../helpers/logger";
import {AxiosServices} from "../../../network/AxiosServices";
import {ApiServices} from "../../../network/ApiServices";
import {HOME} from "../../constants/home";

function* fetchHomeBannerSliderData(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.GET_HOME_BANNER,
            actions.params,
            false
        ); //true when Json Server
        yield put({
            type: HOME.FETCH_HOME_BANNER_SLIDER.SUCCESS,
            result: result.data,
            message: "Data Fetched",
        });
    } catch (err) {
        Logger(err);
        yield put({
            type: HOME.FETCH_HOME_BANNER_SLIDER.FAILURE,
            error: "something went wrong",
        });
    }
}

function* fetchProductData(actions) {
    try {
        // //console.log('fetch product data params', actions.params);
        let result = yield call(
            AxiosServices.get,
            ApiServices.FETCH_ALL_PRODUCTS,
            actions.params
        );
        // //console.log('fetch product data list', result);
        yield put({
            type: HOME.FETCH_ALL_PRODUCTS.SUCCESS,
            result: result.data,
        });
    } catch (err) {
        Logger(err);
        yield put({
            type: HOME.FETCH_ALL_PRODUCTS.FAILURE,
            result: err.response.data,
        });
    }
}

function* fetchCategoryBrand(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.CATEGORIES_BRAND,
            '',
            false
            ); //true when Json Server
        yield put({
            type: HOME.CATEGORIES_BRAND.SUCCESS,
            result: result.data,
            message: "Data Fetched",
        });
    } catch (err) {
        Logger(err);
        yield put({
            type: HOME.CATEGORIES_BRAND.FAILURE,
            result: err.response.data,
        });
    }
}


function* fetchScopeOfWork(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.SCOPE_OF_WORK,
            '',
            false
        ); //true when Json Server
        yield put({
            type: HOME.SCOPE_OF_WORK.SUCCESS,
            result: result.data,
            message: "Data Fetched",
        });
    } catch (err) {
        Logger(err);
        yield put({
            type: HOME.SCOPE_OF_WORK.FAILURE,
            result: err.response.data,
        });
    }
}





function* fetchCategoryData(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.FETCH_CATEGORIES,
            actions.params,
            false
        ); //true when Json Server
        yield put({
            type: HOME.FETCH_PRODUCT_CATEGORIES.SUCCESS,
            result: result.data,
            message: "Data Fetched",
        });
    } catch (err) {
        Logger(err);
        yield put({
            type: HOME.FETCH_PRODUCT_CATEGORIES.FAILURE,
            result: err.response.data,
        });
    }
}

function* fetchFeaturedProducts(actions) {
    try {
        if (!actions.isSearch) {
            if (actions.id !== null) {
                let result = yield call(
                    AxiosServices.get,
                    ApiServices.FETCH_PRODUCTS + actions.id,
                    null,
                    false
                );
                let final_result = [];
                actions.pageNum > 1 ? final_result = final_result.concat(actions.prev_data,result.data) : final_result = result.data
                yield put({
                    type: HOME.FETCH_FEATURED_PRODUCTS.SUCCESS,
                    result: final_result,
                });
            }
        } else {
            if (actions.id !== null) {
                const result = yield call(
                    AxiosServices.post,
                    ApiServices.FETCH_FILTERED,
                    actions.search
                );
                let final_result = [];
                actions.pageNum > 1 ? final_result = final_result.concat(actions.prev_data,result.data) : final_result = result.data
                yield put({
                    type: HOME.FETCH_FEATURED_PRODUCTS.SUCCESS,
                    result: final_result,
                });
            }
        }

    } catch (err) {
        Logger(err);
        yield put({
            type: HOME.FETCH_ALL_PRODUCTS.FAILURE,
            result: err.response.data,
        });
    }
}

function* fetchCategoryProducts(actions) {
    try {
        let result = yield call(
            AxiosServices.get,
            ApiServices.FETCH_PRODUCTS + actions.id,
            null,
            false
        );

        yield put({
            type: HOME.FETCH_CATEGORY_PRODUCTS.SUCCESS,
            result: result.data,
        });
    } catch (err) {
        Logger(err);
        yield put({
            type: HOME.FETCH_CATEGORY_PRODUCTS.FAILURE,
            result: err.response.data,
        });
    }
}

function* fetchCategoryProductsPost(actions) {
    // //console.log('fetch category post called');
    try {
        // //console.log('fetch category post called');
        if (!actions.isSearch) {
            const result = yield call(
                AxiosServices.post,
                ApiServices.FETCH_PRODUCTS_SINGLE,
                actions.data
            );
            let final_result = [];
            actions.data?.pageNum > 1 ? final_result = final_result.concat(actions.prev_data,result.data) : final_result = result.data
            yield put({
                type: HOME.FETCH_CATEGORY_PRODUCTS.SUCCESS,
                result: final_result,
            });
        } else {
            const result = yield call(
                AxiosServices.post,
                ApiServices.FETCH_FILTERED,
                actions.search
            );
            let final_result = [];
            actions.data?.pageNum > 1 ? final_result = final_result.concat(actions.prev_data,result.data) : final_result = result.data
            yield put({
                type: HOME.FETCH_CATEGORY_PRODUCTS.SUCCESS,
                result: final_result,
            });
        }

    } catch (err) {
        Logger(err);
        yield put({
            type: HOME.FETCH_CATEGORY_PRODUCTS.FAILURE,
            result: err.response.data,
        });
    }
}

function* getWebSeries(actions) {
    try {
        let result = yield call(
            AxiosServices.get,
            ApiServices.WEB_SERIES,
            actions.params
        );

        let final_result = [];
        actions.params?.Limit > 1 ? final_result = final_result.concat(actions.prev_data,result.data.posts) : final_result = result.data.posts

        yield put({
            type: HOME.FETCH_WEB_SERIES_POST.SUCCESS,
            result: result.data,
            posts: final_result,
            Cats: result.data.child_cat
        });
    } catch (err) {
        yield put({
            type: HOME.FETCH_WEB_SERIES_POST.FAILURE,
            result: err.response.data,
        });
    }
}

function* getWebSeriesLoadMore(actions) {
    try {
        let result = yield call(
            AxiosServices.get,
            ApiServices.WEB_SERIES,
            actions.params
        );

        let final_result = [];
        actions.params?.Limit > 1 ? final_result = final_result.concat(actions.prev_data,result.data.posts) : final_result = result.data.posts

        yield put({
            type: HOME.FETCH_WEB_SERIES_POST_LOAD_MORE.SUCCESS,
            result: result.data,
            posts: final_result,
            Cats: result.data.child_cat
        });
    } catch (err) {
        yield put({
            type: HOME.FETCH_WEB_SERIES_POST_LOAD_MORE.FAILURE,
            result: err.response.data,
        });
    }
}

function* getAllPosts(actions) {
    try {
        let result = yield call(
            AxiosServices.get,
            ApiServices.FETCH_POSTS,
            actions.param
        );

        yield put({
            type: HOME.FETCH_ALL_POSTS.SUCCESS,
            result: result.data,
        });
    } catch (err) {
        yield put({
            type: HOME.FETCH_ALL_POSTS.FAILURE,
            result: err.response.data,
        });
    }
}

function* getAboutUsBodList(actions) {
    try {
        let result = yield call(
            AxiosServices.get,
            ApiServices.FETCH_BOD_LIST,
            actions.param
        );

        yield put({
            type: HOME.FETCH_ABOUT_US_BOD_POSTS.SUCCESS,
            result: result.data,
        });
    } catch (err) {
        yield put({
            type: HOME.FETCH_ABOUT_US_BOD_POSTS.FAILURE,
            result: err.response.data,
        });
    }
}

function* fetchPages(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.FETCH_PAGES + actions.data.id,
            ''
        );

        yield put({
            type: HOME.FETCH_PAGES.SUCCESS,
            result: result.data,
        });
    } catch (err) {
        Logger(err);
        yield put({
            type: HOME.FETCH_PAGES.FAILURE,
        });
    }
}

function* fetchProductDetails(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.PRODUCT_DETAILS_SLUG + actions.slug,
            null,
            false
        );

        yield put({
            type: HOME.FETCH_PRODUCT_DETAILS.SUCCESS,
            result: result.data,
        });
    } catch (err) {
        Logger(err);
        yield put({
            type: HOME.FETCH_PRODUCT_DETAILS.FAILURE,
        });
    }
}

function* fetchProductDetails3D(actions) {
  try {
    const result = yield call(
      AxiosServices.getWc,
      ApiServices.PRODUCT_DETAILS_SLUG_3D + actions.slug,
      null
    );

    yield put({
      type: HOME.FETCH_PRODUCT_DETAILS_3D.SUCCESS,
      result: result.data,
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: HOME.FETCH_PRODUCT_DETAILS_3D.FAILURE,
    });
  }
}

function* fetchFiltered(actions) {
    try {
        const result = yield call(
            AxiosServices.post,
            ApiServices.FETCH_FILTERED,
            actions.data
        );

        yield put({
            type: HOME.FETCH_FILTERED.SUCCESS,
            result: result.data,
        });
    } catch (err) {
        Logger(err);
        yield put({
            type: HOME.FETCH_FILTERED.FAILURE,
        });
    }
}

function* fetchTextFiltered(actions) {
    try {
        const result = yield call(
            AxiosServices.get,
            ApiServices.FETCH_FILTERED_TEXT,
            actions.param
        );

        yield put({
            type: HOME.FETCH_TEXT_FILTERED.SUCCESS,
            result: result.data,
        });
    } catch (err) {
        Logger(err);
        yield put({
            type: HOME.FETCH_TEXT_FILTERED.FAILURE,
        });
    }
}

function* submitNewsletter(actions) {
    try {
        const result = yield call(
            AxiosServices.post,
            ApiServices.NEWSLETTER_SUBSCRIBE,
            actions.data
        );

        yield put({
            type: HOME.SUBSCRIBE_NEWSLETTER.SUCCESS,
            result: result.data,
        });
    } catch (err) {
        Logger(err);
        yield put({
            type: HOME.SUBSCRIBE_NEWSLETTER.FAILURE,
        });
    }
}

export default function* homeSagas() {
    yield takeLatest(
        HOME.FETCH_HOME_BANNER_SLIDER.MAIN,
        fetchHomeBannerSliderData
    );
    yield takeLatest(HOME.FETCH_PRODUCT_CATEGORIES.MAIN, fetchCategoryData);
    yield takeLatest(HOME.FETCH_FEATURED_PRODUCTS.MAIN, fetchFeaturedProducts);
    yield takeLatest(HOME.CATEGORIES_BRAND.MAIN, fetchCategoryBrand);
    yield takeLatest(HOME.SCOPE_OF_WORK.MAIN, fetchScopeOfWork);
    yield takeLatest(HOME.FETCH_ALL_POSTS.MAIN, getAllPosts);
    yield takeLatest(HOME.FETCH_ALL_PRODUCTS.MAIN, fetchProductData);
    yield takeLatest(HOME.FETCH_PAGES.MAIN, fetchPages);
    yield takeLatest(HOME.FETCH_PRODUCT_DETAILS.MAIN, fetchProductDetails);
    yield takeLatest(HOME.FETCH_PRODUCT_DETAILS_3D.MAIN, fetchProductDetails3D);
    yield takeLatest(HOME.FETCH_FILTERED.MAIN, fetchFiltered);
    yield takeLatest(HOME.FETCH_TEXT_FILTERED.MAIN, fetchTextFiltered);
    yield takeLatest(HOME.SUBSCRIBE_NEWSLETTER.MAIN, submitNewsletter);
    yield takeLatest(HOME.FETCH_CATEGORY_PRODUCTS.MAIN, fetchCategoryProducts);
    yield takeLatest(HOME.FETCH_CATEGORY_PRODUCTS.MAIN_POST, fetchCategoryProductsPost);
    yield takeLatest(HOME.FETCH_ABOUT_US_BOD_POSTS.MAIN, getAboutUsBodList);
    yield takeLatest(HOME.FETCH_WEB_SERIES_POST.MAIN, getWebSeries);
    yield takeLatest(HOME.FETCH_WEB_SERIES_POST_LOAD_MORE.MAIN, getWebSeriesLoadMore);
}
