import React, {Component} from "react";
import '../../App.css';
import {motion} from "framer-motion";
import {PageAnimation} from "../../components/PageAnimation";
import ShopByBrand from "../home/components/ShopByBrand";
import AllBrand from "./components/AllBrand";

class BrandIndex extends Component{
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                <AllBrand/>
            </motion.div>
        )
    }
}

export default BrandIndex;
