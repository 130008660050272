import React, {useEffect} from 'react'
import Title from '../../components/Title'
import styled from 'styled-components'
import AccordionSection from './components/accordions'
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Col, Container, Row} from "react-bootstrap";
import Divider from "@material-ui/core/Divider";
import {fetchPages, resetPages} from "../../redux/actions/home";
import ReactHtmlParser from "react-html-parser";

const convertContent = (faq) => {
  let newArr = []

  for (let key in faq) {
    let retObj = {
      title: key.replace(/_/g, ' '),
      list: faq[key]
    }
    newArr.push(retObj)
  }

  return newArr
}

const FAQ = (props) => {
  const {pages} = props

  useEffect(() => {
    window.scrollTo(0, 0);

    let data = {
      id: 85
    }
    props.fetchPages(data)
  }, []);

  //console.log(pages)

 // let newPage = faqPage ? convertContent(faqPage.meta) : []

  return (
    <StyledFaq>
      <div>
        {ReactHtmlParser(pages?.post_content)}
      </div>
      {
        props.pageLoading ?
            <StyleLoader>
              <div className="loader">
                <CircularProgress />
              </div>
            </StyleLoader>: null
      }
    </StyledFaq>
  )
}

const StyledFaq = styled.div`
  margin-top: 104px;
  margin-bottom: 50px;
  padding: 40px;
  min-height: 60vh;
  position: relative;
  
  span {
    font-size: 18px;
  }
`

let StyleLoader = styled.div`
  .loader {
    z-index: 1;
    height: 40px;
    width: 40px;
    position: absolute;
    left: 50%;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

function mapStateToProps(state) {
  return {
    pages: state.homeReducer.pages,
    pageLoading: state.homeReducer.pageLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchPages: (data) => dispatch(fetchPages(data)),
    resetPages: () => dispatch(resetPages()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FAQ)
