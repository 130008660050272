import React, {useEffect} from 'react'
import styled from 'styled-components'
import {connect} from 'react-redux'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Title from '../../../components/Title'
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import {makeStyles} from "@material-ui/core/styles";
import {ordersAmount} from "../../../redux/actions/orders";

let OrderList = (props) => {
    const classes = useStyles();
    let {orders, active, setActive, statusCheck} = props

    let handleActive = (id, key) => {
        setActive(key)
        let data = {
            order_id: id
        }
        props.ordersAmount(data)
    }

    useEffect(() => {
        let first = orders ? orders.length > 0 ? orders[0] : '' : ''
        if (first) {
            let data = {
                order_id: first.id
            }
            props.ordersAmount(data)
        }

    }, [])

    return (
        <StyledList>
            <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                    <List className="list" component="nav" aria-label="main mailbox folders">
                        {orders ? orders.length ? orders.map((item, key) => {
                            let status = statusCheck(item.status)
                            return (
                                <>
                                    <ListItem
                                        key={key}
                                        button
                                        selected={active === key}
                                        onClick={() => handleActive(item.id, key)}
                                        className="list-text"
                                    >
                                        <Title
                                            text={`Order ID# ${item.id}`}
                                            color={'#313132'}
                                            fontSize={16}
                                            lineHeight={24}
                                            fontFamily={'Poppins'}
                                            fontWeight={'500'}
                                            textAlign={'left'}
                                        />
                                        <Title
                                            text={status.name}
                                            color={'#313132'}
                                            fontSize={10}
                                            fontFamily={'Poppins'}
                                            lineHeight={14}
                                            fontWeight={'400'}
                                            textAlign={'left'}
                                        />
                                    </ListItem>
                                    <div style={{backgroundColor: 'rgba(122,121,121,0.1)', height: 2, width: '100%'}}/>
                                </>
                            )
                        }) : null : null}
                    </List>
                </CardContent>
            </Card>
        </StyledList>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 200,
        boxShadow: "0px 0px 10px #0000000D",
    },
    cardContent: {
        padding: "0px",
    }
}));

let StyledList = styled.div`
  .MuiCardContent-root:last-child {
    padding: 0px !important;
  }

  .MuiList-padding {
    padding: 0px !important;
  }

  .list-text {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
    height: 63px;
  }

  .list {
    max-width: 100%;
  }

  @media (max-width: 767px) {
    .list {
      max-width: 100%;
    }
  }
`

function mapStateToProps(state) {
    return {
        orders: state.orderReducer.orders
    }
}

function mapDispatchToProps(dispatch) {
    return {
        ordersAmount: (data) => dispatch(ordersAmount(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderList)
