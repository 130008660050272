import React, {useEffect} from "react";
import {PageAnimation} from "../../components/PageAnimation";
import AllProduct from "./components/AllProduct";
import styled from 'styled-components';
import {motion} from "framer-motion";
import {useParams} from "react-router-dom";

const ProductList = (props) => {
  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
      <StyledProductList>
        <AllProduct catId={id}/>
      </StyledProductList>
    </motion.div>
  )
}

const StyledProductList = styled.div`
  margin-top: 100px;
  min-height: 80vh;
`

export default ProductList
