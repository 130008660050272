import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, Input } from "@material-ui/core";
import clsx from "clsx";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import CheckIcon from "../assets/icons/Icon feather-check.svg";
import BlackCheckIcon from "../assets/icons/Icon feather-check-black.svg";

const MobileFilter = (props) => {
  let { subName, handleFilters, handleClear, id, marginBottom, materials, colors } = props;
  let colorList = colors ? colors : color;
  let classes = useStyles();

  const [priceView, setPrice] = useState(false);
  const [sortView, setSort] = useState(false);
  const [sizeView, setSize] = useState(false);
  const [colorView, setColor] = useState(false);
  const [materialView, setMaterial] = useState(false);

  let handleViewChange = (name, e) => {
    if (name === "Price") {
      setPrice(!priceView);
      setSort(false);
      setSize(false);
      setColor(false);
      setMaterial(false);
    }
    if (name === "Sort") {
      setSort(!sortView);
      setPrice(false);
      setSize(false);
      setColor(false);
      setMaterial(false);
    }
    if (name === "Size") {
      setSize(!sizeView);
      setPrice(false);
      setSort(false);
      setColor(false);
      setMaterial(false);
    }
    if (name === "Color") {
      setColor(!colorView);
      setPrice(false);
      setSort(false);
      setSize(false);
      setMaterial(false);
    }
    if (name === "Material") {
      setMaterial(!materialView);
      setPrice(false);
      setSort(false);
      setSize(false);
      setColor(false);
    }
  };

  const [filterSort, setSortFilter] = useState([]);
  const [filterPrice, setPriceList] = useState([]);
  const [filterWidth, setWidthList] = useState([]);
  const [filterHeight, setHeightList] = useState([]);
  const [filterColor, setColorList] = useState([]);
  const [filterMaterial, setMaterialList] = useState([]);
  const [customStart, setCustomStart] = useState("");
  const [customEnd, setCustomEnd] = useState("");
  // const [clear, setClear] = useState(null)

  const clearAll = () => {
    setSortFilter([]);
    setPriceList([]);
    setWidthList([]);
    setHeightList([]);
    setColorList([]);
    setMaterialList([]);
    setMaterial(false);
    setPrice(false);
    setSort(false);
    setSize(false);
    setColor(false);
    setCustomStart("");
    setCustomEnd("");

    if (handleClear) handleClear();
  };

  const handleSort = (data) => {
    let state = JSON.parse(JSON.stringify(filterSort));

    let found = state.find((item) => item.name === data.name);

    if (found) {
      state = state.filter((item) => item.name !== data.name);
    } else {
      state.push(data);
      state = state.filter((item) => item.name === data.name);
    }
    setSortFilter(state);
  };

  const sortCheck = (data) => {
    let state = JSON.parse(JSON.stringify(filterSort));

    let found = state.find((item) => item.name === data.name);
    if (found) return true;
    else return false;
  };

  const handlePrice = (data) => {
    let state = JSON.parse(JSON.stringify(filterPrice));

    let found = state.find((item) => item.start === data.start);

    if (found) {
      state = state.filter((item) => item.start !== data.start);
    } else {
      state.push(data);
    }
    setPriceList(state);
  };

  const priceCheck = (data) => {
    let state = JSON.parse(JSON.stringify(filterPrice));

    let found = state.find((item) => item.start === data.start);
    if (found) return true;
    else return false;
  };

  const handleWidth = (data) => {
    let state = JSON.parse(JSON.stringify(filterWidth));

    let found = state.find((item) => item.start === data.start);

    if (found) {
      state = state.filter((item) => item.start !== data.start);
    } else {
      state.push(data);
    }
    setWidthList(state);
  };

  const widthCheck = (data) => {
    let state = JSON.parse(JSON.stringify(filterWidth));

    let found = state.find((item) => item.start === data.start);
    if (found) return true;
    else return false;
  };

  const handleHeight = (data) => {
    let state = JSON.parse(JSON.stringify(filterHeight));

    let found = state.find((item) => item.start === data.start);

    if (found) {
      state = state.filter((item) => item.start !== data.start);
    } else {
      state.push(data);
    }
    setHeightList(state);
  };

  const heightCheck = (data) => {
    let state = JSON.parse(JSON.stringify(filterHeight));

    let found = state.find((item) => item.start === data.start);
    if (found) return true;
    else return false;
  };

  const handleColor = (data) => {
    let state = JSON.parse(JSON.stringify(filterColor));

    let found = state.find((item) => item.slug === data.slug);

    if (found) {
      state = state.filter((item) => item.slug !== data.slug);
    } else {
      state.push(data);
    }
    setColorList(state);
  };

  const colorCheck = (slug) => {
    let state = JSON.parse(JSON.stringify(filterColor));

    let found = state.find((item) => item.slug === slug);

    if (found) return true;
    else return false;
  };

  const handleMaterial = (data) => {
    let state = JSON.parse(JSON.stringify(filterMaterial));

    let found = state.find((item) => item.slug === data.slug);

    if (found) {
      state = state.filter((item) => item.slug !== data.slug);
    } else {
      state.push(data);
    }
    setMaterialList(state);
  };

  const materialCheck = (data) => {
    let state = JSON.parse(JSON.stringify(filterMaterial));

    let found = state.find((item) => item.slug === data.slug);
    if (found) return true;
    else return false;
  };
  let handleSubmit = () => {
    let filter = {
      category_id: id ? id : 54,
      sort: filterSort,
      price: filterPrice.length
        ? filterPrice.map((item) => {
            return { start: item.start, end: item.end };
          })
        : [],
      colors: filterColor.length
        ? filterColor.map((item) => {
            return { slug: item.slug };
          })
        : [],
      material: filterMaterial.length
        ? filterMaterial.map((item) => {
            return { slug: item.slug };
          })
        : [],
      width: filterWidth.length
        ? filterWidth.map((item) => {
            return { start: item.start, end: item.end };
          })
        : [],
      height: filterHeight.length
        ? filterHeight.map((item) => {
            return { start: item.start, end: item.end };
          })
        : [],
    };

    handleFilters(filter);
  };

  const handleCustomStart = (e) => {
    setCustomStart(e.target.value);
  };

  const handleCustomEnd = (e) => {
    setCustomEnd(e.target.value);
  };

  const submitCustom = () => {
    let state = JSON.parse(JSON.stringify(filterPrice));

    if (parseInt(customStart) && parseInt(customEnd)) {
      let data = {
        start: parseInt(customStart),
        end: parseInt(customEnd),
      };

      state.push(data);

      setPriceList(state);
    }
  };

  return (
    <StyledFilter>
      <Container className="filter-mobile-view" style={{marginBottom: `${marginBottom}`}}>
        <Row>
          <Col>
            <div
              style={{ display: "flex", marginTop: 30, alignItems: "center" }}
            >
              <div className="text-filter">Shop for {subName}</div>
            </div>
            <div
              style={{
                display: "flex",
                marginTop: 30,
                alignItems: "center",
                overflow: "auto",
              }}
            >
              <div
                className="text-filter-menu"
                onClick={handleViewChange.bind(null, "Price")}
              >
                Price
              </div>
              <div
                className="text-filter-menu"
                onClick={handleViewChange.bind(null, "Sort")}
              >
                Sort by:
              </div>
              <div
                className="text-filter-menu"
                onClick={handleViewChange.bind(null, "Size")}
              >
                Size
              </div>
              {/*<div*/}
              {/*  className="text-filter-menu"*/}
              {/*  onClick={handleViewChange.bind(null, "Color")}*/}
              {/*>*/}
              {/*  Color*/}
              {/*</div>*/}
              {/*<div*/}
              {/*  className="text-filter-menu"*/}
              {/*  onClick={handleViewChange.bind(null, "Material")}*/}
              {/*>*/}
              {/*  Material*/}
              {/*</div>*/}
            </div>
          </Col>
        </Row>
        {priceView === true ? (
          <Row>
            <Col xs={12}>
              <div style={{ marginTop: 24 }}>
                {price.map((item, key) => {
                  return (
                    <div key={key} style={{ display: "flex", marginBottom: 18 }}>
                      <div className={classes.headingValue}>{item.name}</div>
                      <StyledCheckbox
                        data={item}
                        handleCheck={handlePrice}
                        filterCheck={priceCheck}
                      />
                    </div>
                  );
                })}
                <div className={classes.headingValue}>Customize Price</div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 5,
                  }}
                >
                  <form
                    noValidate
                    autoComplete="off"
                    style={{ marginRight: 5 }}
                  >
                    <Input
                      placeholder="Min"
                      type="number"
                      inputProps={{ "aria-label": "description" }}
                      value={customStart}
                      onChange={handleCustomStart}
                    />
                  </form>
                  <div
                    style={{
                      fontFamily: "Renner, sans-serif",
                      fontSize: "8px",
                      color: "#5D5D5D",
                      marginLeft: 14,
                      marginRight: 14,
                    }}
                  >
                    to
                  </div>
                  <form noValidate autoComplete="off" style={{ marginLeft: 5 }}>
                    <Input
                      placeholder="Max"
                      type="number"
                      inputProps={{ "aria-label": "description" }}
                      value={customEnd}
                      onChange={handleCustomEnd}
                    />
                  </form>
                  <div className={classes.apply} onClick={submitCustom}>
                    Apply
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12}>
              <center>
                <div
                  style={{
                    display: "flex",
                    marginTop: 64,
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className={classes.clearAll} onClick={clearAll}>
                    Clear all
                  </div>
                  <div className={classes.viewAll} onClick={handleSubmit}>
                    View
                  </div>
                </div>
              </center>
            </Col>
          </Row>
        ) : null}
        {sortView === true ? (
          <Row>
            <Col xs={12}>
              <div style={{ marginTop: 24 }}>
                {sortBy.map((item, key) => {
                  return (
                    <div key={key} style={{ display: "flex", marginBottom: 18 }}>
                      <div className={classes.headingValue}>{item.name}</div>
                      <StyledCheckbox
                        data={item}
                        handleCheck={handleSort}
                        filterCheck={sortCheck}
                      />
                    </div>
                  );
                })}
              </div>
            </Col>
            <Col xs={12}>
              <center>
                <div
                  style={{
                    display: "flex",
                    marginTop: 64,
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className={classes.clearAll} onClick={clearAll}>
                    Clear all
                  </div>
                  <div className={classes.viewAll} onClick={handleSubmit}>
                    View
                  </div>
                </div>
              </center>
            </Col>
          </Row>
        ) : null}
        {sizeView === true ? (
          <Row>
            <Col xs={12}>
              <div style={{ marginTop: 24 }}>
                <div className={classes.headingSub}>Width</div>
                {width.map((item, key) => {
                  return (
                    <div
                        key={key}
                      style={{
                        display: "flex",
                        marginBottom: 18,
                        marginLeft: 13,
                      }}
                    >
                      <div className={classes.headingValue}>{item.name}</div>
                      {/*<div className={classes.countValue}>{item.count}</div>*/}
                      <StyledCheckbox
                        data={item}
                        handleCheck={handleHeight}
                        filterCheck={heightCheck}
                      />
                    </div>
                  );
                })}
                <div className={classes.headingSub}>Height</div>
                {height.map((item, key) => {
                  return (
                    <div
                        key={key}
                      style={{
                        display: "flex",
                        marginBottom: 18,
                        marginLeft: 13,
                      }}
                    >
                      <div className={classes.headingValue}>{item.name}</div>
                      {/*<div className={classes.countValue}>{item.count}</div>*/}
                      <StyledCheckbox
                        data={item}
                        handleCheck={handleWidth}
                        filterCheck={widthCheck}
                      />
                    </div>
                  );
                })}
              </div>
            </Col>
            <Col xs={12}>
              <center>
                <div
                  style={{
                    display: "flex",
                    marginTop: 64,
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className={classes.clearAll} onClick={clearAll}>
                    Clear all
                  </div>
                  <div className={classes.viewAll} onClick={handleSubmit}>
                    View
                  </div>
                </div>
              </center>
            </Col>
          </Row>
        ) : null}
        {colorView === true ? (
          <Row style={{ marginTop: 24 }}>
            {colorList.map((item, key) => {
              return (
                <Col key={key} xs={3} className="column-cl">
                  <center onClick={() => handleColor(item)}>
                    <div
                      style={{
                        display: "table-row",
                        alignItems: "center",
                        textAlign: "center",
                        marginRight: 28,
                      }}
                    >
                      <div
                        className="color-view"
                        style={{ backgroundColor: `${item.value}` }}
                      >
                        {colorCheck(item.slug) ? (
                          item.slug === "white" ? (
                            <img src={BlackCheckIcon} alt="color" />
                          ) : (
                            <img src={CheckIcon} alt="color" />
                          )
                        ) : (
                          ""
                        )}
                      </div>
                      <div
                        style={{
                          fontSize: 10,
                          fontWeight: 400,
                          fontFamily: "Renner, sans-serif",
                          color: "#313132",
                          paddingTop: 4,
                        }}
                      >
                        {item.name}
                      </div>
                      {/*<div*/}
                      {/*  style={{*/}
                      {/*    fontSize: 10,*/}
                      {/*    fontWeight: 400,*/}
                      {/*    fontFamily: "Poppins",*/}
                      {/*    color: "#313132",*/}
                      {/*    paddingTop: 4,*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  {item.count}*/}
                      {/*</div>*/}
                    </div>
                  </center>
                </Col>
              );
            })}
            <Col xs={12}>
              <center>
                <div
                  style={{
                    display: "flex",
                    marginTop: 64,
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className={classes.clearAll} onClick={clearAll}>
                    Clear all
                  </div>
                  <div className={classes.viewAll} onClick={handleSubmit}>
                    View
                  </div>
                </div>
              </center>
            </Col>
          </Row>
        ) : null}
        {materialView === true ? (
          <Row>
            <Col xs={12}>
              <div style={{ marginTop: 24 }}>
                {materials
                  ? materials.map((item, key) => {
                      return (
                        <div key={key} style={{ display: "flex", marginBottom: 18 }}>
                          <div className={classes.headingValue}>
                            {item.name}
                          </div>
                          {/* <div className={classes.countValue}>{item.count}</div> */}
                          <StyledCheckbox
                            data={item}
                            handleCheck={handleMaterial}
                            filterCheck={materialCheck}
                          />
                        </div>
                      );
                    })
                  : material.map((item, key) => {
                      return (
                        <div key={key} style={{ display: "flex", marginBottom: 18 }}>
                          <div className={classes.headingValue}>
                            {item.name}
                          </div>
                          {/*<div className={classes.countValue}>{item.count}</div>*/}
                          <StyledCheckbox
                            data={item}
                            handleCheck={handleMaterial}
                            filterCheck={materialCheck}
                          />
                        </div>
                      );
                    })}
              </div>
            </Col>
            <Col xs={12}>
              <center>
                <div
                  style={{
                    display: "flex",
                    marginTop: 64,
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div className={classes.clearAll} onClick={clearAll}>
                    Clear all
                  </div>
                  <div className={classes.viewAll} onClick={handleSubmit}>
                    View
                  </div>
                </div>
              </center>
            </Col>
          </Row>
        ) : null}
      </Container>
    </StyledFilter>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    right: 12,
  },
  heading: {
    fontFamily: "Renner, sans-serif",
    fontSize: "12px",
    fontWeight: "600",
    color: "#000000",
  },
  apply: {
    fontFamily: "Renner, sans-serif",
    fontSize: "8px",
    marginLeft: "64px",
    backgroundColor: "#D8D8D8",
    padding: "5px 14px 4px 14px",
    border: "1px solid #D8D8D8",
    borderRadius: 15,
    height: 22,
    width: 50,
    cursor: "pointer",
    color: "#313132",
  },
  clearAll: {
    fontFamily: "Renner, sans-serif",
    fontSize: "10px",
    marginRight: "9px",
    backgroundColor: "#D8D8D8",
    padding: "7px 20px 7px 20px",
    border: "1px solid #D8D8D8",
    borderRadius: 15,
    height: 30,
    width: 120,
    cursor: "pointer",
    color: "#313132",
    "@media (max-width: 1200px)": {
      width: 90,
    },
  },
  viewAll: {
    fontFamily: "Poppins",
    fontSize: "10px",
    marginLeft: "9px",
    backgroundColor: "#313132",
    padding: "7px 20px 7px 20px",
    border: "1px solid #313132",
    borderRadius: 15,
    height: 30,
    width: 120,
    cursor: "pointer",
    color: "#fff",
    "@media (max-width: 1200px)": {
      width: 90,
    },
  },
  headingValue: {
    marginTop: 10,
    fontFamily: "Poppins",
    fontSize: "10px",
    fontWeight: "400",
    cursor: "pointer",
    color: "#313132",
  },
  countValue: {
    position: "absolute",
    right: 48,
    marginTop: 10,
    fontFamily: "Poppins",
    fontSize: "10px",
    fontWeight: "400",
    cursor: "pointer",
    color: "#313132",
  },
  headingSub: {
    marginTop: 34,
    marginBottom: 28,
    fontFamily: "Poppins",
    fontSize: "10px",
    fontWeight: "500",
    cursor: "pointer",
    color: "#313132",
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: "#313132",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#313132",
    },
  },
}));

function StyledCheckbox(props) {
  const classes = useStyles();
  const { data, handleCheck, filterCheck } = props;

  const handleChange = () => {
    if (data && handleCheck) {
      handleCheck(data);
    }
  };

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checked={filterCheck(data)}
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ "aria-label": "decorative checkbox" }}
      onChange={handleChange}
      {...props}
    />
  );
}

const sortBy = [
  {
    name: "Default",
    value: "default",
  },
  {
    name: "Price: low to high",
    value: "low to high",
  },
  {
    name: "Price: high to low",
    value: "high to low",
  },
  {
    name: "Newest",
    value: "newest",
  },
  {
    name: "Most Popular",
    value: "most popular",
  },
];

const price = [
  {
    name: "BDT 50,000 - 1,99,999",
    value: "BDT 50,000 - 1,99,999",
    start: 50000,
    end: 199999,
  },
  {
    name: "BDT 2,00,000 - 3,99,999",
    value: "BDT 2,00,000 - 3,99,999",
    start: 200000,
    end: 399999,
  },
  {
    name: "BDT 4,00,000 - 6,99,999",
    value: "BDT 4,00,000 - 6,99,999",
    start: 400000,
    end: 699999,
  },
  {
    name: "BDT 7,00,000 - 9,99,999",
    value: "BDT 7,00,000 - 9,99,999",
    start: 700000,
    end: 999999,
  },
  {
    name: "BDT 10,00,000 +",
    value: "BDT 10,00,000 +",
    start: 1000000,
    end: 5000000,
  },
];

const width = [
  {
    name: "0 - 39 cm",
    value: "0 - 39 cm",
    count: "15",
    start: 0,
    end: 39,
  },
  {
    name: "40 - 59 cm",
    value: "40 - 59 cm",
    count: "35",
    start: 40,
    end: 59,
  },
  {
    name: "60-79 cm",
    value: "60-79 cm",
    count: "30",
    start: 60,
    end: 79,
  },
  {
    name: "100 cm +",
    value: "100 cm +",
    count: "10",
    start: 100,
    end: 1000,
  },
];

const height = [
  {
    name: "0 - 39 cm",
    value: "0 - 39 cm",
    count: "20",
    start: 0,
    end: 39,
  },
  {
    name: "40 - 59 cm",
    value: "40 - 59 cm",
    count: "35",
    start: 40,
    end: 59,
  },
  {
    name: "60-79 cm",
    value: "60-79 cm",
    count: "30",
    start: 60,
    end: 79,
  },
  {
    name: "100 cm +",
    value: "100 cm +",
    count: "10",
    start: 100,
    end: 1000,
  },
];

const color = [
  {
    name: "White",
    value: "#FCFCFC",
    count: "10",
    slug: "white",
  },
  {
    name: "Off-White",
    value: "#D5C9B9",
    count: "20",
    slug: "off-white",
  },
  {
    name: "Black",
    value: "#000000",
    count: "100",
    slug: "black",
  },
  {
    name: "Green",
    value: "#21905C",
    count: "39",
    slug: "green",
  },
  {
    name: "Grey",
    value: "#9B9CA0",
    count: "59",
    slug: "grey",
  },
  {
    name: "Maroon",
    value: "#750000",
    count: "20",
    slug: "maroon",
  },
  {
    name: "Dark Grey",
    value: "#313132",
    count: "88",
    slug: "dark-grey",
  },
  {
    name: "Light blue",
    value: "#016EB7",
    count: "10",
    slug: "light-blue",
  },
];

const material = [
  {
    name: "Fabric",
    value: "fabric",
    count: "320",
  },
  {
    name: "Wood",
    value: "wood",
    count: "535",
  },
  {
    name: "Leather",
    value: "leather",
    count: "430",
  },
  {
    name: "Steel",
    value: "steel",
    count: "510",
  },
  {
    name: "Plastic",
    value: "plastic",
    count: "10",
  },
];

const StyledFilter = styled.div`
  display: none;
  @media(max-width: 991px){
    display: block;
  }
  .MuiIconButton-root:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  .MuiInputBase-root {
    color: #313132;
  }

  .MuiInputBase-input {
    font-family: Poppins, sans-serif;
    font-size: 8px;
  }

  .MuiInput-underline:before {
    left: 0;
    right: 0;
    bottom: 0;
    content: "\\00a0";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    pointer-events: none;
  }

  .MuiInput-underline:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    pointer-events: none;
  }

  .column-cl {
    margin-bottom: 30px;
  }

  .color-view {
    width: 40px;
    height: 40px;
    border: 1px solid rgba(128, 128, 128, 0.12);
    border-radius: 50%;
    position: relative;

    img {
      position: absolute;
      top: 37%;
      right: 32%;
    }
  }
`;

export default MobileFilter;
