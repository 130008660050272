import ImageGallery from "react-image-gallery";
import Model from "./Model";
import React from "react";

const convertGalleryItem = (gallery) => {
  let returnItems = gallery.map((item) => {
    let obj = {
      original: item,
      thumbnail: item,
      thumbnailHeight: "60px",
      thumbnailWidth: "86px",
    };

    return obj;
  });

  return returnItems;
};

const MobileGallery = (props) => {
  let { gallery,  productDetail3D, active, color, material } = props;

  let items = convertGalleryItem(gallery);

  return (
      <>
        {productDetail3D && productDetail3D[0]?.acf?.["3d_model"]?.url ? (
            <Model
                product={productDetail3D ? productDetail3D[0] : []}
                color={color ? color : "#000000"}
                material={material ? material : "default"}
            />
        ) : (
            <ImageGallery
                items={items}
                showNav={false}
                showBullets={false}
                showPlayButton={false}
                showFullscreenButton={false}
            />
        )}
      </>
  );
};

export default MobileGallery;