import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {fetchPages, resetPages} from "../../redux/actions/home";
import {motion} from "framer-motion";
import {PageAnimation} from "../../components/PageAnimation";
import StoryList from "../stories/components/StoryList";
import Datas from "./components/Datas";
import {useHistory, useLocation} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";

const PageData = (props) => {

    let location = useLocation()
    let {pathname} = location

    useEffect(()=>{
        props.resetPages()
    },[])

    useEffect(() => {
        window.scrollTo(0, 0);
        props.resetPages()
        if (pathname === '/privacy-policy') {
            let data = {
                id: 3
            }
            props.fetchPages(data)
        }
        if (pathname === '/terms-conditions') {
            let data = {
                id: 2240
            }
            props.fetchPages(data)
        }

    }, [pathname]);

    return (
        <div style={{position: 'relative'}}>

            <Datas data={props.pages?.post_content}/>
            {
                props.pageLoading ?
                    <StyleLoader>
                        <div className="loader">
                            <CircularProgress />
                        </div>
                    </StyleLoader>: null
            }
        </div>
    );
};

let StyleLoader = styled.div`
  .loader {
    z-index: 1;
    height: 40px;
    width: 40px;
    position: absolute;
    left: 50%;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`


function mapStateToProps(state) {
    return {
        pages: state.homeReducer.pages,
        pageLoading: state.homeReducer.pageLoading
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fetchPages: (data) => dispatch(fetchPages(data)),
        resetPages: () => dispatch(resetPages()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PageData)