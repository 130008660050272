import React, {useEffect} from "react";
import {PageAnimation} from "../../components/PageAnimation";
import AllProduct from "./components/AllProduct";
import styled from 'styled-components';
import {motion} from "framer-motion";
import {useParams} from "react-router-dom";
import {
  fetchCategoryProducts,
  fetchFeaturedProducts,
  fetchFiltered,
  categoryProductInit,
  filterInit
} from "../../redux/actions/home";
import {connect} from 'react-redux'
import CircularProgress from "@material-ui/core/CircularProgress";

const ProductList = (props) => {
  let {
    categories,
    allProducts,
    fetchCategoryProducts,
    fetchFiltered,
    filteredProduct,
    categoryProducts,
    categoryProductInit,
    filterInit,
    loading
  } = props;

  let id = null;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!id) {
      categoryProductInit()
    }
  }, [])

  const newArrivalCat = categories
    ? categories.length
      ? categories.find((item) => item.slug === 'pre-book-now')
      : null
    : null;

  id = newArrivalCat ? newArrivalCat.term_id : null;

  useEffect(() => {
    if (id) {
      fetchCategoryProducts(id)
    }
  }, [id]);

  return (
    <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
      <StyledProductList id={!loading}>
        {!loading ? (
          <AllProduct
            catId={id}
            categories={categories}
            allProducts={allProducts}
            fetchFiltered={fetchFiltered}
            filteredProduct={filteredProduct}
            fetchCategoryProducts={fetchCategoryProducts}
            categoryProducts={categoryProducts}
            filterInit={filterInit}
            newArrivalCat={newArrivalCat}
          />
        ) : (
          <div className="loader">
            <CircularProgress/>
          </div>
        )}
      </StyledProductList>
    </motion.div>
  )
}

const StyledProductList = styled.div`
  margin-top: 100px;
  padding-bottom: 64px;
  height: ${(props) => (props.id ? "auto" : "120vh")};

  .loader {
    margin-top: 25%;
    text-align: center;

    @media(max-width: 991px){
      margin-top: 40%;
    }
  }
`

function mapStateToProps(state) {
  return {
    categories: state.homeReducer.categories,
    featuredProducts: state.homeReducer.featuredProducts,
    allProducts: state.homeReducer.allProducts,
    filteredProduct: state.homeReducer.filteredProduct,
    categoryProducts: state.homeReducer.categoryProducts,
    loading: state.homeReducer.categoryProductsLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchFeatured: (data) => dispatch(fetchFeaturedProducts(data)),
    fetchFiltered: data => dispatch(fetchFiltered(data)),
    fetchCategoryProducts: data => dispatch(fetchCategoryProducts(data)),
    categoryProductInit: () => dispatch(categoryProductInit()),
    filterInit: () => dispatch(filterInit())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductList)
