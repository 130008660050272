import React, {useState} from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Image from "../../../components/Image";
import moment from "moment";
import ModalVideo from "react-modal-video";
import '../../../../node_modules/react-modal-video/css/modal-video.min.css'
import playBtn from '../../../assets/images/Group_4239.svg'

const Story = ({ item }) => {
    let [open, setOpen] = useState(false);
    let [videoId, setVideo] = useState('');
    videoId=item.link.replaceAll('https://youtu.be/','');

    let handelOpen = (open, id) => {
        setOpen(open);
        setVideo(id);
    };

  return (
      <>
          <ModalVideo channel='youtube' isOpen={open} videoId={videoId}
                      onClose={() => handelOpen(false, '')}/>
          <ProductCardWrapper onClick={() => handelOpen(true, `${item?.videoId}`)}>
              <ProductImageWrapper>
                  <Image
                      url={item.thumbnail}
                      className="product-image"
                      style={{ position: "relative" }}
                      alt={"Product"}
                  />
                  <div className='play-btn'>
                      <Image url={playBtn} alt={"Play-Button"}/>
                  </div>
              </ProductImageWrapper>

              <ProductInfo>
                  <div className="date-text">
                      {moment(item.date).format("DD.MM.YYYY")}
                  </div>
                  <h3 className="product-title">{item.title}</h3>
              </ProductInfo>
          </ProductCardWrapper>
      </>

  );
};

const ProductCardWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: #FFFFFF;
  position: relative;
  border-radius: 0;
  cursor: pointer;
  margin-right: 30px;
  cursor: pointer;
  box-shadow: 0 2px 10px rgba(0,0,0,0.05); 
  ;

  &:nth-last-of-type(1) {
    margin-right: 0;
  }
`;

const ProductImageWrapper = styled.div`
  padding-top: 59.46%;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  
  &:after {
    width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0px;
    content: '';
    background: linear-gradient(180deg, rgba(0,0,0,0.15) 0%, rgba(255,255,255,0.15172006302521013) 15%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 80%, rgba(0,0,0,0.15) 100%);
  }
  
  img {
    width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0px;
    object-fit: cover;
  }
  
.play-btn{
  margin: auto;
  position: absolute;
  display: flex;
  left: 47%;
  top: 40%;
  img{
    width: 60px;
    height: 60px;
    z-index: 1;
    position: unset !important;
    top: unset;
    object-fit: unset;
    }

  @media(max-width: 1366px){
    left: 42%;
  }

  @media(max-width: 991px){
    left: 42%;
    img {
      width: 40px;
      height: 40px;
    }
  }
  @media(max-width: 768px){
    left: 40%;
  }
  @media(max-width: 575px){
    left: 45%;
    img {
      width: 50px;
      height: 50px;
    }
  }
  @media(max-width: 375px){
    left: 42%;
    img {
      width: 40px;
      height: 40px;
    }
  }
}
  @media (max-width: 767px) {
    height: 145px;
  }
`;

const ProductInfo = styled.div`
  padding: 15px 20px 15px;
  @media (max-width: 767px) {
    padding: 15px 20px;
  }

  .date-text {
    font-size: 12px;
    font-family: Renner, sans-serif;
    font-weight: normal;
    color: #707070;
  }

  .product-title {
    font-size: 16px;
    font-weight: 600;
    font-family: Renner, sans-serif;
    color: #313132;
    width: 100%;
    max-lines: 2;
    margin: 13px 0 42px 0px;
    text-align: left;
    overflow: hidden;
    //white-space: nowrap;
    height: 58px;
    @media (max-width: 767px) {
      font-size: 14px;
      margin: 8px 0 5px 0;
    }
  }

  .read-more {
    margin-top: 10px;
    background-color: #fff;
    border-radius: 50px;
    border: 1px solid #313132;
    cursor: pointer;
    text-align: center;
    width: 76px;

    .load-button {
      color: #313132;
      font-size: 10px;
      font-weight: normal;
      font-family: Renner, sans-serif;
      padding: 3px 8px 3px 8px;
    }
  }
`;

export default Story;
