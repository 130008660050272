import React from 'react'
import MainLeft from './components/MainLeft'
import Products from "./components/ProductsDrawer";
import RoomDrawer from "./components/RoomDrawer";
import BrandDrawer from "./components/Brands";

let LeftDrawer = props => {
  return (
    <>
      <MainLeft />
      <Products />
      <RoomDrawer />
      <BrandDrawer />
    </>
  )
}

export default LeftDrawer
