import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {numberWithCommas, onlyUnique} from "../../../helpers/globalFunc";
import Title from "../../../components/Title";
import Text from "../../../components/Text";
import ReactHtmlParser from "react-html-parser";
import Divider from "@material-ui/core/Divider";
import Button from "../../../components/NewButtonSubmit";
import WishlistBorder from "../../../assets/images/wishlist-border.svg";
import IconButton from "@material-ui/core/IconButton";
import SimpleCounter from "./SimpleCounter";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import CheckIcon from "../../../assets/icons/Icon feather-check.svg";
import ShareButtons from "./ShareButtons";
import {withStyles} from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import {useLocation} from "react-router-dom";
import moment from "moment";
import rightArrowColored from "../../../assets/images/rightIconArrowColored.svg";
import RightIcon from "../../../assets/images/navRightBlack.svg";
import LeftIcon from "../../../assets/images/navLeftBlack.svg";
import {forEach} from "react-bootstrap/ElementChildren";

const ratingCount = (review) => {
    let ratingLen = review.length;

    if (ratingLen) {
        let count = 0;

        for (let i = 0; i < ratingLen; i++) {
            count += parseInt(review[i].rating);
        }

        return count / ratingLen;
    } else {
        return 0;
    }
};

const StyledRating = withStyles({
    iconFilled: {
        color: "rgba(156,148,52,0.9)",
        height: 10,
        width: 11,
        marginRight: 6,
    },
    iconEmpty: {
        color: "rgba(122,121,121,0.51)",
        height: 10,
        width: 11,
        marginRight: 6,
    },
    iconHover: {
        color: "#9C9434",
        height: 10,
        width: 11,
        marginRight: 6,
    },
})(Rating);

let RightSection = (props) => {
    let {
        product,
        textures_section,
        addToCart,
        addToWishlist,
        active,
        handleVariable,
        variation,
        addCartServer,
        userId,
        preBook,
        setColors,
        setMaterials,
        handlePreBook,
        handleAuthModal,
        submitSlot,
        submitSlotExtra,
        idx,
        setId,
        addWishlistServer,
        authData,
        submit_pre_book_loading,
        prebook_submit
    } = props;

    let [count, setCount] = useState(1);
    let [material, setMaterial] = useState(
        active ? active.attributes.attribute_pa_materials : ""
    );
    let [color, setColor] = useState(
        active ? active.attributes.attribute_pa_color : props.query_color !== null && props.query_color !== undefined ? props.query_color : ""
    );


    let location = useLocation();
    let {search} = location
    const params = new URLSearchParams(search);

    let [Material3DColor, setMaterial3DColor] = useState([])

    let colorByMaterial = (material) =>{
        return textures_section && textures_section.filter((p)=> p.material_name === material)
    }

    useEffect(() => {
        if (active) {
            setColor(active.attributes.attribute_pa_color);
            setColors(active?.color_code)
            //   console.log('------------',active?.color_code);
            if (active.attributes.attribute_pa_materials) {
                setMaterial(active.attributes.attribute_pa_materials);
                setMaterials(active.attributes.attribute_pa_materials)
            } else {
                setMaterial("");
            }
        }
    }, [active]);

    useEffect(()=>{
        if (textures_section.length > 0) {
            let array = []
            let color3D = colorByMaterial(material)
            color3D.forEach(function(color) {
                let data = color.color
                array.push(data)
            });
            let uniqColor3D = array.filter(onlyUnique);
            setTimeout(()=>{
                setMaterial3DColor(uniqColor3D)
            }, 600)
        }
    },[active?.attributes?.attribute_pa_materials, textures_section, material])

    useEffect(() => {
        if (props.query_color !== null && props.query_color !== undefined) {
            setColor(props.query_color.toString());
        }

    }, [props.query_color])

    let colAttr = product?.attributes[0].pa_color
        ? product?.attributes[0].pa_color
        : null;
    let matAttr = product?.attributes[0].pa_materials
        ? product?.attributes[0].pa_materials
        : null;

    let handleActive = (color, material) => {
        let found = variation.find((item) => {
            let attr = item.attributes;
            if (attr.attribute_pa_color) {
                if (attr.attribute_pa_color.toLowerCase() === color && color.toLowerCase()) {
                    if (attr.attribute_pa_materials) {
                        if (
                            attr.attribute_pa_materials.toLowerCase() === material && material.toLowerCase()
                        ) {
                            return item;
                        }
                    } else {
                        return item;
                    }
                } else return null;
            } else {
                if (attr.attribute_pa_materials) {
                    if (
                        attr.attribute_pa_materials.toLowerCase() === material.toLowerCase()
                    ) {
                        return item;
                    }
                } else {
                    return item;
                }
            }
        });

        if (found) {
            //let indx = variation.findIndex(item => item.variation_id === found.variation_id)
            setId(found.variation_id);
            handleVariable(found);
        }
    };

    let handleMaterial = (value) => {
        if (textures_section.length > 0) {
            let array = []
            let color3D = colorByMaterial(value)
            color3D.forEach(function(color) {
                let data = color.color
                array.push(data)
            });
            let uniqColor3D = array.filter(onlyUnique);
            setTimeout(()=>{
                setMaterial3DColor(uniqColor3D)
            }, 600)
        }

        setMaterial(value);
        setMaterials(value)
        handleActive(color, value);
    };

    let handleColor = (value, colHex) => {
        //console.log('color slug',colHex)
        setColors(colHex)
        setColor(value);
        handleActive(value, material);
    };

    let availableColors = variation.map((item) => {
        let key = item.attributes.attribute_pa_color;
        return key;
    });

    let availableMaterials = variation.map((item) => {
        let key = item?.attributes?.attribute_pa_materials;
        return key;
    });

    let filtered = availableColors.filter(onlyUnique);
    let newArray = availableMaterials.filter(onlyUnique);
    let materials = newArray ?
        newArray.length ?
            newArray.length > 0 ?
                newArray.filter(value => value !== '') : [] : [] : [];



    // console.log(colAttr)
    // console.log(filtered)
    //  console.log(active?.attributes?.attribute_pa_materials)
    //  console.log(textures_section);
    //  console.log(Material3DColor);

    let price = active ? active.display_price.toString() : product?.price;

    let addCount = () => {
        setCount(count + 1);
    };

    let removeCount = () => {
        if (count > 1) {
            setCount(count - 1);
        } else {
            setCount(1);
        }
    };

    let addItemToCart = () => {
        if (preBook) {
            // handlePreBook(true, product.id)
            if (props.authData) {
                let date = new Date();
                date = moment(date.getDate()).format("YYYY-MM-DD");
                const data = {
                    appoint_name: props.authData.display_name,
                    apoint_email: props.authData.user_email,
                    appoint_phone: props.authData.user_phone,
                    appoint_visit_type: "",
                    appoint_slot_id: "",
                    appoint_slot_time: "",
                    appoint_slot_start: "",
                    appoint_slot_end: "",
                    quantity: ""+count,
                    appoint_product: product?.id,
                    appoint_status: "2",
                    appoint_date: date,
                };
                submitSlot(data,"prebook");
                setTimeout(()=>{
                    submitSlotExtra()
                },3000)
            } else {
                handleAuthModal('login')
            }

        } else {
            if (userId) {
                const data = {
                    user_id: userId,
                    product_id: active?.variation_id.toString(),
                    count: count,
                };
                addCartServer(data, product?.slug);
            } else {
                let newProduct = {
                    price: price,
                    addCount: count,
                    sku: product?.sku,
                    slug: product?.slug,
                    stock: active?.is_in_stock === true ? "instock": "outofstock",
                    image_id: active.variation_gallery_images.length
                        ? active.variation_gallery_images[0]
                        : active.image.full_src,
                    parent_id: product?.id,
                    id: active.variation_id.toString(),
                    name:
                        product?.name +
                        "-" +
                        active.attributes.attribute_pa_color +
                        (active.attributes.attribute_pa_materials
                            ? "," + active.attributes.attribute_pa_materials
                            : ""),
                };

                addToCart(newProduct);
            }
        }
    };

    let addItemToWishlist = () => {
        if (userId) {
            const data = {
                user_id: userId,
                product_id: active?.variation_id.toString(),
            };
            addWishlistServer(data, product?.slug);
        } else {
            let newProduct = {
                price: price,
                id: active.variation_id.toString(),
                color_attr: color,
                parent_id: product?.id,
                sku: product?.sku,
                slug: product?.slug,
                stock: active?.is_in_stock === true ? "instock": "outofstock",
                image_id: active.variation_gallery_images.length
                    ? active.variation_gallery_images[0]
                    : active.image.full_src,
                name:
                    product?.name +
                    "-" +
                    active.attributes.attribute_pa_color +
                    (active.attributes.attribute_pa_materials
                        ? "," + active.attributes.attribute_pa_materials
                        : ""),

            };

            addToWishlist(newProduct);
        }
    };

    let rating = product?.review ? ratingCount(product?.review) : 0;

    const paramsColor = {
        slidesPerView: 'auto',
        // spaceBetween: 20,
        navigation: {
            nextEl: '.swiper-color-button-next',
            prevEl: '.swiper-color-button-prev'
        },
    };

    const paramsMaterial = {
        slidesPerView: 'auto',
        // spaceBetween: 20,
        navigation: {
            nextEl: '.swiper-material-button-next',
            prevEl: '.swiper-material-button-prev'
        },
    };

    return (
        <RightDiv>
            <div className="right-top">
                <Title
                    text={product?.name}
                    color={"#313132"}
                    fontSize={24}
                    lineHeight={31}
                    fontWeight={"500"}
                    textTransform={"uppercase"}
                />
                {/*<div className="price-text">*/}
                {/*    <Title*/}
                {/*        text="BDT"*/}
                {/*        fontWeight={"500"}*/}
                {/*        color={"#313132"}*/}
                {/*        fontSize={14}*/}
                {/*        lineHeight={18}*/}
                {/*        margin={"4px 0 0 0"}*/}
                {/*    />*/}
                {/*    <Title*/}
                {/*        text={numberWithCommas(price)}*/}
                {/*        color={"#313132"}*/}
                {/*        fontSize={24}*/}
                {/*        lineHeight={31}*/}
                {/*        fontWeight={"500"}*/}
                {/*    />*/}
                {/*</div>*/}
            </div>
            <Title
                text={`SKU: ${product?.sku}`}
                fontWeight={"400"}
                fontFamily={"Poppins"}
                color={"#707070"}
                fontSize={14}
                lineHeight={18}
                margin={"10px 0"}
            />
            {rating ? (
                <StyledRating name="size-small" defaultValue={rating} size="small"/>
            ) : (
                ""
            )}
            <Text
                color={"#707070"}
                fontSize={14}
                lineHeight={18}
                fontWeight={"400"}
                fontFamily={"Poppins"}
                margin={"30px 0"}
            >
                <p>{ReactHtmlParser(product?.short_description)}</p>
            </Text>
            <Divider style={{marginTop: 30}}/>
            {active ? (
                <>
                    <div className="variation-div firstchildBlock">
                        {
                            colAttr ?
                                <Title
                                    text={"Color"}
                                    fontSize={14}
                                    lineHeight={18}
                                    fontWeight={"500"}
                                /> : null
                        }

                        <div className="color-div">
                            {
                                Material3DColor.length && Material3DColor.length > 0 ? (
                                    <>
                                        {
                                            Material3DColor.length && Material3DColor.length > 0 ? (
                                                <>
                                                    <Swiper {...paramsColor}>
                                                        {
                                                            colAttr ? Material3DColor.map((item, key)=>{
                                                                let value = item.split('&&');
                                                                let colHex = value[0]
                                                                let name = value[1]
                                                                let slug = value[2]
                                                                return(
                                                                    <div
                                                                        key={`${slug}-${colHex}`}
                                                                        className="color-desc"
                                                                        id={`${slug}-${colHex}`}
                                                                        onClick={() => handleColor(slug,item)}
                                                                    >
                                                                        <Circle color={colHex}>
                                                                            {slug && slug.toLowerCase() === color && color.toLowerCase() ? (
                                                                                <img src={CheckIcon} alt="color"/>
                                                                            ) : ("")}
                                                                        </Circle>
                                                                        <Title
                                                                            text={name}
                                                                            color={"#707070"}
                                                                            fontSize={12}
                                                                            lineHeight={16}
                                                                            fontWeight={"400"}
                                                                            fontFamily={"Poppins"}
                                                                            margin={"10px 5px"}
                                                                            textAlign={"center"}
                                                                        />
                                                                    </div>
                                                                )
                                                            }) : null
                                                        }
                                                    </Swiper>
                                                </>
                                            ) : null
                                        }
                                    </>
                                ) : <>
                                    {filtered.length && filtered.length > 0 ? (
                                        <>
                                            <Swiper {...paramsColor}>
                                                {colAttr
                                                    ? filtered.map((item) => {
                                                        let colHex = colAttr[item]?.description;
                                                        let name = colAttr[item]?.name;
                                                        let slug = colAttr[item]?.slug;
                                                        return (
                                                            <div
                                                                key={`${slug}-${colHex}`}
                                                                className="color-desc"
                                                                id={`${slug}-${colHex}`}
                                                                onClick={() => handleColor(slug,colHex)}
                                                            >
                                                                <Circle color={colHex}>
                                                                    {slug && slug.toLowerCase() === color && color.toLowerCase() ? (
                                                                        <img src={CheckIcon} alt="color"/>
                                                                    ) : ("")}
                                                                </Circle>
                                                                <Title
                                                                    text={name}
                                                                    color={"#707070"}
                                                                    fontSize={12}
                                                                    lineHeight={16}
                                                                    fontWeight={"400"}
                                                                    fontFamily={"Poppins"}
                                                                    margin={"10px 5px"}
                                                                    textAlign={"center"}
                                                                />
                                                            </div>
                                                        );
                                                    })
                                                    : ""}
                                            </Swiper>
                                        </>
                                    ) : null}
                                </>
                            }
                        </div>
                    </div>
                    {materials ? (
                        <div className="variation-div firstchildBlock" style={{marginTop: 20}}>
                            {
                                matAttr ?
                                    <Title
                                        text={"Material"}
                                        fontSize={14}
                                        lineHeight={18}
                                        fontWeight={"500"}
                                    /> : null
                            }

                            <div className="material-option">
                                {
                                    materials && materials.length > 0 ?
                                        <>
                                            <Swiper {...paramsMaterial}>
                                                {matAttr ?
                                                    materials.map((item,key) => {
                                                        let name = matAttr ? matAttr[item]?.name : 'None';
                                                        return (
                                                            <div
                                                                key={key}
                                                                className="material-div"
                                                                onClick={() => handleMaterial(item)}
                                                            >
                                                                <FormControl>
                                                                    <FormControlLabel
                                                                        value="cash"
                                                                        control={
                                                                            <Radio
                                                                                color="primary"
                                                                                checked={material === item}
                                                                            />
                                                                        }
                                                                        label={name}
                                                                    />
                                                                </FormControl>
                                                            </div>
                                                        );
                                                    }) : null }
                                            </Swiper>
                                        </> : null
                                }
                            </div>
                        </div>
                    ) : null}
                </>
            ) : null}
            {/*<div className="variation-div">*/}
            {/*    <Title*/}
            {/*        text={"Quantity"}*/}
            {/*        fontSize={14}*/}
            {/*        lineHeight={18}*/}
            {/*        fontWeight={"500"}*/}
            {/*        margin={"0 0 20px 0"}*/}
            {/*    />*/}
            {/*    <SimpleCounter*/}
            {/*        variant="lightHorizontal"*/}
            {/*        value={count}*/}
            {/*        onIncrement={addCount}*/}
            {/*        onDecrement={removeCount}*/}
            {/*    />*/}
            {/*</div>*/}
            {/*<div className="button-div">*/}
            {/*    <div className="button-inner">*/}
            {/*        {authData ? <Button*/}
            {/*            disabled={prebook_submit !== undefined && prebook_submit !== null ? true : active?.is_in_stock === true ? false : true}*/}
            {/*            text={preBook ? "Pre-Book" : active?.is_in_stock === true ? "Add to cart" : "Out of stock"}*/}
            {/*            handleSubmit={addItemToCart}*/}
            {/*            loading={submit_pre_book_loading}*/}
            {/*        /> : <Button*/}
            {/*            disabled={preBook ? true : active?.is_in_stock === true ? false : true}*/}
            {/*            text={preBook ? "Pre-Book" : active?.is_in_stock === true ? "Add to cart" : "Out of stock"}*/}
            {/*            handleSubmit={addItemToCart}*/}
            {/*            loading={submit_pre_book_loading}*/}
            {/*        />}*/}
            {/*        {prebook_submit !== null && prebook_submit !== undefined &&*/}
            {/*            <Title*/}
            {/*                text={prebook_submit.message}*/}
            {/*                fontWeight={200}*/}
            {/*                fontSize={14}*/}
            {/*                lineHeight={20}*/}
            {/*                color={"green"}*/}
            {/*                fontFamily={'Poppins'}*/}
            {/*                textAlign="center"*/}
            {/*            />*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*    {*/}
            {/*        active?.is_in_stock === true && !preBook &&*/}
            {/*        <IconButton aria-label="menu" className="wish-list" onClick={addItemToWishlist}>*/}
            {/*            <img src={WishlistBorder} alt=""/>*/}
            {/*        </IconButton>*/}
            {/*    }*/}

            {/*</div>*/}
            {/*<Divider/>*/}
            {/*<Title*/}
            {/*    text={preBook ? "Pre-Book Product" : active?.is_in_stock === true ? "Available Stock" : "Out of stock"}*/}
            {/*    fontSize={14}*/}
            {/*    fontWeight={"500"}*/}
            {/*    lineHeight={18}*/}
            {/*    fontFamily={"Poppins"}*/}
            {/*    color={"#707070"}*/}
            {/*    margin={"30px 0 5px 0"}*/}
            {/*/>*/}
            {/*<Title*/}
            {/*    text={"1 year warranty"}*/}
            {/*    fontSize={14}*/}
            {/*    lineHeight={18}*/}
            {/*    fontWeight={"500"}*/}
            {/*    fontFamily={"Poppins"}*/}
            {/*    color={"#707070"}*/}
            {/*    margin={"5px 0"}*/}
            {/*/>*/}
            {/*<Title*/}
            {/*    text={preBook ? "Est. delivery time is 4 - 6 months" : "Est. delivery time is 5 - 7 days"}*/}
            {/*    fontSize={14}*/}
            {/*    lineHeight={18}*/}
            {/*    fontWeight={"500"}*/}
            {/*    fontFamily={"Poppins"}*/}
            {/*    color={"#707070"}*/}
            {/*    margin={"5px 0 30px 0"}*/}
            {/*/>*/}
            <Divider/>
            <ShareButtons name={product?.name} index={idx}/>
        </RightDiv>
    );
};

let Circle = styled.div`
  width: 30px;
  height: 30px;
  position: relative;
  background: ${(props) => props.color};
  border-radius: 50%;
  border: 1px solid hsl(240deg 7% 62%);
  cursor: pointer;

  img {
    position: absolute;
    top: 33%;
    right: 30%;
  }
`;

let RightDiv = styled.div`
  margin-top: 90px;

  .material-option {
    //display: flex;
    //flex-direction: row;
    //justify-content: space-around;
    //align-items: center;
    margin-bottom: 20px;
  }

  .material-div {
    //text-align: center;
    padding-top: 5px;
    cursor: pointer;
    border-radius: 3px;

    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 auto;
    width: auto !important;
  }

  .wish-list {
    margin-top: 20px;
  }

  .swiper-container {
    position: unset;
  }

  .swiper-button-disabled {
    opacity: 1!important;
  }

  .swiper-color-button-next {
    position: absolute;
    right: 0px;
    top: 0px;
    margin: auto;
    z-index: 3;
    height: 24px;
    width: 24px;
    background: rgb(239, 239, 239);
    background-image: url(${rightArrowColored});
    background-size: contain;
    border-radius: 50%;
    border: unset;
    left: auto;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  .swiper-color-button-prev {
    position: absolute;
    margin: auto;
    z-index: 3;
    height: 24px;
    width: 24px;
    top: 0;
    right: 30px;
    cursor: pointer;
    background: rgb(239, 239, 239);
    background-image: url(${rightArrowColored});
    transform: rotate(180deg);
    background-size: contain;
    border-radius: 50%;
    border: unset;

    &:focus {
      outline: none;
    }
  }

  .swiper-material-button-next {
    position: absolute;
    right: 0px;
    top: 0px;
    margin: auto;
    z-index: 3;
    height: 24px;
    width: 24px;
    background: rgb(239, 239, 239);
    background-image: url(${rightArrowColored});
    background-size: contain;
    border-radius: 50%;
    border: unset;
    left: auto;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  .swiper-material-button-prev {
    position: absolute;
    margin: auto;
    z-index: 3;
    height: 24px;
    width: 24px;
    top: 0;
    right: 30px;
    cursor: pointer;
    background: rgb(239, 239, 239);
    background-image: url(${rightArrowColored});
    transform: rotate(180deg);
    background-size: contain;
    border-radius: 50%;
    border: unset;

    &:focus {
      outline: none;
    }
  }

`;

export default RightSection;