import React, {useState, useEffect} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import {Form, Row, Col} from "react-bootstrap";
import {
  handlePreBook,
  searchSlot,
  submitSlot,
  clearSubmit,
  clearSlot,
} from "../../redux/actions/prebooking";
import {withStyles} from "@material-ui/core";
import {authStyle} from "../auth/authStyle";
import {connect} from "react-redux";
import Button from "../../components/NewButtonSubmit";
import CrossIcon from "../../assets/icons/cross-icon.svg";
import Divider from "@material-ui/core/Divider";
import Options from "./components/options";
import DatePicker from "./components/date-select";
import {
  validatePhone,
  validateNameField,
  validateEmail,
} from "../../helpers/validators";
import Select from "react-select";
import TimerMixin from "react-timer-mixin";
import Title from "../../components/Title";

const PreBookDialog = (props) => {
  const {
    openPreBook,
    handlePreBook,
    classes,
    authData,
    searchSlot,
    submitSlot,
    slots,
    submit,
    productId,
    submitLoading,
  } = props;

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [type, setType] = useState("physical");
  const [date, setDate] = useState("");
  const [slot, setSlot] = useState(null);
  const [submitMsg, setSubmitMsg] = useState("");
  const [submitErr, setSubmitErr] = useState("");

  useEffect(() => {
    if (authData) {
      if (authData.token) {
        setName(authData.display_name);
        setPhone(authData.user_phone);
        setEmail(authData.user_email);
      }
    }
  }, []);

  useEffect(() => {
    if (submit) {
      if (submit.status === 0) {
        setSubmitErr(submit.message);
        TimerMixin.setTimeout(() => {
          setSlot(null);
          clearSubmit();
          setType("physical");
          setSubmitErr("");
        }, 4000);
      } else {
        setSubmitMsg(
          "Slot booked successfully. An email/text will be sent to your Email Address/Phone Number"
        );
        TimerMixin.setTimeout(() => {
          handlePreBook(false, null);
          setSubmitMsg("");
          clearSubmit();
          setSlot(null);
          setDate("");
          setType("physical");
          clearSlot();
        }, 4000);
      }
    }
  }, [submit]);

  let slotOptions = slots.length
    ? slots.map((item) => {
      let obj = {
        value: item,
        label: item.slot_time,
      };
      return obj;
    })
    : [];

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handlePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const setPreBook = () => {
    handlePreBook(false, null);
  };

  const handleSubmit = () => {
    let isName = validateNameField(name);
    let isPhone = validatePhone(phone);
    let isEmail = email.length ? validateEmail.test(email) : true;

    if (isName && isPhone && isEmail && slot && date.length) {
      const data = {
        appoint_name: name,
        apoint_email: email,
        appoint_phone: phone,
        appoint_visit_type: type,
        appoint_slot_id: slot.id,
        appoint_slot_time: slot.slot_time,
        appoint_slot_start: slot.slot_start,
        appoint_slot_end: slot.slot_end,
        appoint_product: productId,
        appoint_status: "2",
        appoint_date: date,
      };
      if(authData) {
        if (authData.id) data.user_id = authData.id;
      }
      submitSlot(data);
    }
  };

  return (
    <Dialog
      open={openPreBook}
      onClose={setPreBook}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent style={{maxWidth: 450, padding: 50}}>
        <Title
          text={"Prebook"}
          color={"#9C9434"}
          fontSize={12}
          lineHeight={18}
          fontWeight={500}
          fontFamily={'Poppins'}
          textAlign={"left"}
          letterSpacing={60}
          textTransform={"uppercase"}
          // margin={'15px 0px 15px 0px'}
        />
        <Title
          text={"Pre Book This Item"}
          color={"#313132"}
          fontSize={24}
          lineHeight={40}
          fontWeight={"500"}
          textAlign={"left"}
          textTransform={"uppercase"}
          // margin={'5px 0px 15px 0px'}
        />
        <img
          className={classes.crossIcon}
          alt=""
          src={CrossIcon}
          onClick={setPreBook}
        />
        <Divider/>
        <Form>
          <Form.Group>
            <Row>
              <Col className={classes.formField} md={12}>
                <Form.Label className={classes.formLabel}>Name*</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  className={classes.formControl}
                  value={name}
                  onChange={handleName}
                  placeholder={"Your Name"}
                />
              </Col>
              <Col className={classes.formField} md={12}>
                <Form.Label className={classes.formLabel}>
                  Mobile Number*
                </Form.Label>
                <Form.Control
                  type="text"
                  name="Phone"
                  value={phone}
                  className={classes.formControl}
                  onChange={handlePhone}
                  placeholder={"Your Phone No"}
                />
              </Col>
              <Col className={classes.formField} md={12}>
                <Form.Label className={classes.formLabel}>Email</Form.Label>
                <Form.Control
                  value={email}
                  className={classes.formControl}
                  placeholder={"Your Email"}
                  onChange={handleEmail}
                />
              </Col>
              <Col className={classes.formField} md={12}>
                <Options checked={type} setChecked={setType}/>
              </Col>
              <Col md={12}>
                <DatePicker handleDate={setDate} searchSlot={searchSlot}/>
              </Col>
              <Col className={classes.formField} md={12}>
                <Select
                  styles={customStyles}
                  placeholder="Select Time"
                  onFocus={false}
                  className={classes.formControl}
                  options={slotOptions}
                  onChange={(e) => setSlot(e.value)}
                />
              </Col>
            </Row>
          </Form.Group>
        </Form>
        <Button
          text={"Book An Appointment"}
          handleSubmit={handleSubmit}
          loading={submitLoading}
        />
        {submitErr.length ? (
          <Title
            text={submitErr}
            fontWeight={200}
            fontSize={14}
            lineHeight={20}
            color={"red"}
            fontFamily={'Poppins'}
            textAlign="center"
          />
        ) : null}
        {submitMsg.length ? (
          <Title
            text={submitMsg}
            fontWeight={200}
            fontSize={14}
            lineHeight={20}
            color={"green"}
            fontFamily={'Poppins'}
            textAlign="center"
          />
        ) : null}
      </DialogContent>
    </Dialog>
  );
};

let styles = (theme) => authStyle(theme);

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    color: state.isSelected ? "#FFF" : "#313132",
    backgroundColor: state.isSelected ? "#313132" : "#FFF",
    margin: 0,
  }),
  menu: (provided, state) => ({
    ...provided,
    color: "rgba(0,0,0,0.5)",
    backgroundColor: state.isSelected ? "#000" : "#FFF",
    margin: 0
  }),
  menuList: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#000" : "#FFF",
    borderRadius: 0,
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none",
  }),
};

function mapStateToProps(state) {
  return {
    openPreBook: state.prebookReducer.openPreBook,
    productId: state.prebookReducer.productId,
    authData: state.authReducer.authData,
    slots: state.prebookReducer.slots,
    submit: state.prebookReducer.submit,
    submitLoading: state.prebookReducer.submitLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handlePreBook: (data, id) => dispatch(handlePreBook(data, id)),
    searchSlot: (data) => dispatch(searchSlot(data)),
    submitSlot: (data) => dispatch(submitSlot(data)),
    clearSubmit: () => dispatch(clearSubmit()),
    clearSlot: () => dispatch(clearSlot()),
  };
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(PreBookDialog)
);
