import React from "react";
import styled from "styled-components";
import productImage from "../../../assets/images/productImage.png";
import discountImg from "../../../assets/images/discount.svg";
import Image from "../../../components/Image";
import Star from "../../../assets/icons/star.svg";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import CartIcon from "../../../assets/icons/shopping-bag.svg";
import HeartIcon from "../../../assets/icons/heart.svg";
import WhiteCart from "../../../assets/icons/shopping-bag-white.svg";
import PreBookCart from "../../../assets/icons/shopping-bag-prebook.svg";
import CardGiftcardOutlinedIcon from "@material-ui/icons/CardGiftcardOutlined";
import GreenHeart from "../../../assets/icons/heart-green.svg";
import IconButton from "@material-ui/core/IconButton";
import {numberWithCommas} from "../../../helpers/globalFunc";
import {connect} from "react-redux";
import ReactHtmlParser from "react-html-parser";
import Text from "../../../components/Text";
import {handlePreBook, submitSlot, submitSlotExtra} from "../../../redux/actions/prebooking";
import {
    addItemToCart,
    addItemToWishlist,
    addCartServer,
    addWishlistServer,
} from "../../../redux/actions/rightDrawer";
import {Link} from "react-router-dom";
import ProductCartCounter from "./ProductCartCounter";
import moment from "moment";
import {handleAuthModal} from "../../../redux/actions/auth";

let checkCartAvailable = (cart, id) => {
    if (cart) {
        if (cart.length) {
            let found = cart.find(
                (item) => item.cartItem.id === id
            );
            if (found) {
                return found;
            } else {
                return null;
            }
        }
    }
    return null;
};

let checkWishlistAvailable = (wishlist, id) => {
    if (wishlist) {
        if (wishlist.length) {
            let found = wishlist.find((item) => item.id === id);

            if (found) {
                return true;
            } else {
                return false;
            }
        }
    }
    return false;
};

const Product = ({
                     item,
                     addItemToCart,
                     addCartServer,
                     addItemToWishlist,
                     cart,
                     allCart,
                     wishlist,
                     authData,
                     isPreBook,
                     handlePreBook,
                     setShowPopup,
                     submitSlot, submitSlotExtra,
                     addWishlistServer,
                     allWishlist,
                     handleAuthModal
                 }) => {
    let userId = authData ? authData.id : null;

    let isAuth = authData ? (authData.token ? authData.token : null) : null;

    let activeCart = userId ? allCart : cart;

    let activeWishList = userId ? allWishlist : wishlist;

    let addToCart = (item) => {
        if (userId) {
            const data = {
                user_id: userId,
                product_id: item.id.toString(),
                count: "1",
            };
            // console.log(data)
            addCartServer(data);
        } else {
            item.addCount = 1;
            item.stock = item.stock_status;
            addItemToCart(item);
        }
    };

    let addToWishlist = (item) => {
        if (userId) {
            const data = {
                user_id: userId,
                product_id: item.id.toString(),
            };
            addWishlistServer(data, item.slug);
        } else {
            item.stock = item.stock_status;
            addItemToWishlist(item);
        }
    };

    let preBook = (id) => {
        if (isAuth) {
            let date = new Date();
            date = moment(date.getDate()).format("YYYY-MM-DD");
            const data = {
                appoint_name: authData.display_name,
                apoint_email: authData.user_email,
                appoint_phone: authData.user_phone,
                appoint_visit_type: "",
                appoint_slot_id: "",
                appoint_slot_time: "",
                appoint_slot_start: "",
                appoint_slot_end: "",
                quantity: "1",
                appoint_product: id,
                appoint_status: "2",
                appoint_date: date,
            };

            submitSlot(data, "prebook");
            setTimeout(() => {
                setShowPopup(true)
            }, 3000)

            setTimeout(() => {
                submitSlotExtra()
            }, 6000)
        } else {
            handleAuthModal('login');
        }
    }

    let isCart = activeCart ? checkCartAvailable(activeCart && activeCart, item?.id) : null;
    let isWish = activeWishList
        ? checkWishlistAvailable(activeWishList && activeWishList, item?.id)
        : null;

    let isDiscount = item ? item.sale_price.length : null;

    let discount = isDiscount
        ? parseFloat(item.regular_price) - parseFloat(item.sale_price)
        : 0;
    let discountParcentage = (discount / parseFloat(item.regular_price)) * 100;

    let rating = parseFloat(item.average_rating);

    return (
        <ProductCardWrapper>
            <Link
                to={{pathname: `/product/${item.slug}`, state: isPreBook}}
                className="card-link"
            >
                <ProductImageWrapper>
                    <Image
                        url={item.image_id ? item.image_id : item.image}
                        className="product-image"
                        style={{position: "relative"}}
                        alt={`${item.name} image`}
                    />

                    {/*{discountParcentage ? (*/}
                    {/*  <DiscountPercent>*/}
                    {/*    <img src={discountImg} alt="discount" />*/}
                    {/*    <span>{discountParcentage.toFixed(2)}%</span>*/}
                    {/*  </DiscountPercent>*/}
                    {/*) : null}*/}
                </ProductImageWrapper>
            </Link>
            <ProductInfo>
                <Link
                    to={{pathname: `/product/${item.slug}`, state: isPreBook}}
                    className="card-link"
                >
                    <h3 className="product-title">{item.name}</h3>

                </Link>
                <div className="product-meta">
                    <div className="productPriceWrapper">
                        {<Text
                            color={"#707070"}
                            fontSize={14}
                            lineHeight={18}
                            fontWeight={"400"}
                            fontFamily={"Poppins"}
                            margin={"30px 0"}
                        >
                            <p>{ReactHtmlParser(item?.short_description)}</p>
                        </Text>}
                    </div>
                </div>
            </ProductInfo>
        </ProductCardWrapper>
    )
        ;
};

const ProductCardWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: #fcfcfc;
  border-bottom: 2px solid #efefef;
  position: relative;
  border-radius: 0;
  cursor: pointer;
  margin-right: 30px;

  &:nth-last-of-type(1) {
    margin-right: 0;
  }
`;

const ProductImageWrapper = styled.div`
  height: 270px;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    display: inline-block;
    object-fit: cover;
    overflow: hidden;
  }

  @media (min-width: 1520px) {
    height: 332px;
  }
  //@media (max-width: 540px) {
  //  height: 250px;
  //}
`;

const DiscountPercent = styled.span`
  font-family: Poppins, sans-serif;
  font-size: 12px;
  font-weight: 300;
  color: #000;
  width: 75px;
  height: 25px;
  display: flex;
  align-items: center;
  transform: rotate(90deg);
  line-height: 24px;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 2;

  img {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    text-align: left;
    object-fit: cover;
  }

  span {
    position: absolute;
    left: 4px;
    transform: rotate(180deg);
  }
`;

const ProductInfo = styled.div`
  padding: 15px 20px 15px;
  @media (max-width: 767px) {
    padding: 15px 20px;
    min-height: 123px;
  }

  .buttonGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .icon-button-cart {
    margin-right: 6px;
    padding: 6px;
  }

  .icon-button {
    padding: 6px;
  }

  .product-title {
    font-size: 16px;
    font-weight: normal;
    color: #000;
    //margin: -10px 0 7px 0;
    width: 100%;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    @media (max-width: 767px) {
      font-size: 14px;
      margin: 0 0 5px 0;
    }
  }

  .product-weight {
    text-align: left;
    margin-top: 14px;
    align-items: center;
    font-family: Poppins, sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #898686;
    @media (max-width: 767px) {
      font-size: 12px;
    }
  }

  .product-meta {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 767px) {
      min-height: 32px;
    }

    .productPriceWrapper {
      display: flex;

      p {
        margin-top: 5px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
      }

      .product-price {
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        @media (max-width: 767px) {
          font-size: 14px;
        }
      }

      .discountedPrice {
        font-size: 7px;
        margin-top: 3px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.9);
        padding: 0 5px;
        text-decoration: line-through;
      }
    }

    .cart-button {
      border-radius: 3px;
      height: 30px;
      width: 30px;
      font-weight: 300;
      color: #313132;
      background-color: #efefef;
      text-align: center;
      @media (max-width: 767px) {
        width: 24px;
        height: 24px;
      }

      .btn-text {
        @media (max-width: 767px) {
          display: block;
        }
      }

      &:hover {
        color: #ffffff;
        background-color: #009e7f;
      }

      svg {
        fill: currentColor;
        @media (max-width: 767px) {
          margin: 0;
        }
      }
    }

    @media (max-width: 767px) {
      .quantity {
        width: 32px;
        height: 90px;
        display: block;
        flex-shrink: 0;
        position: absolute;
        bottom: 15px;
        right: 15px;
        z-index: 1;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.16);
      }

      button {
        width: 100%;
        height: 27px;
      }

      .incBtn {
        top: 0;
        justify-content: center;
      }

      .decBtn {
        top: auto;
        bottom: 0;
        justify-content: center;
      }

      input[type="number"] {
        left: 0;
        font-size: 14px;
        height: calc(100% - 54px);
        position: absolute;
        top: 27px;
        width: 100%;
        color: #fff;
      }
    }
  }
`;

const ButtonText = styled.span`
  @media (max-width: 767px) {
    display: block;
  }
`;

function mapStateToProps(state) {
    return {
        cart: state.rightDrawerReducer.cart,
        allCart: state.rightDrawerReducer.allCart,
        wishlist: state.rightDrawerReducer.wishlist,
        allWishlist: state.rightDrawerReducer.allWishlist,
        authData: state.authReducer.authData,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        handleAuthModal: (data) => dispatch(handleAuthModal(data)),
        addItemToCart: (data) => dispatch(addItemToCart(data)),
        addCartServer: (data, slug) => dispatch(addCartServer(data, slug)),
        addItemToWishlist: (data) => dispatch(addItemToWishlist(data)),
        handlePreBook: (data, id) => dispatch(handlePreBook(data, id)),
        submitSlot: (data, appointment_type) => dispatch(submitSlot(data, appointment_type)),
        submitSlotExtra: () => dispatch(submitSlotExtra()),
        addWishlistServer: (data, slug) => dispatch(addWishlistServer(data, slug)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Product);
