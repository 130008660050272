import { AUTH } from "../../constants/auth";

export function handleAuthModal(data) {
  return {
    type: AUTH.OPEN_MODAL,
    data,
  };
}

export function signUp(data) {
  return {
    type: AUTH.SET_SIGN_UP.MAIN,
    data,
  };
}

export function login(data) {
  return {
    type: AUTH.SET_LOGIN.MAIN,
    data,
  };
}

export function sendOTP(data) {
  return {
    type: AUTH.SEND_OTP.MAIN,
    data,
  };
}

export function forgetReq(data) {
  return {
    type: AUTH.FORGET_PASS_REQ.MAIN,
    data,
  };
}

export function verifyCode(data) {
  return {
    type: AUTH.VERIFY_CODE.MAIN,
    data,
  };
}

export function forgetPassUpdate(data) {
  return {
    type: AUTH.FORGET_PASSWORD_CHANGE.MAIN,
    data,
  };
}

export function updatePassword(data) {
  return {
    type: AUTH.CHANGE_PASSWORD.MAIN,
    data,
  };
}

export function resetPassInit() {
  return {
    type: AUTH.RESET_PASS_INIT,
  };
}

export function getUserData(data) {
  return {
    type: AUTH.SET_PROFILE_DATA.MAIN,
    data,
  };
}

export function updateUser(data) {
  return {
    type: AUTH.UPDATE_PROFILE.MAIN,
    data,
  };
}

export function addReview(data) {
  return {
    type: AUTH.REVIEW_PRODUCT.MAIN,
    data,
  };
}

export function initReview() {
  return {
    type: AUTH.REVIEW_INIT,
  };
}

export function setPhone(data) {
  return {
    type: AUTH.SET_PHONE,
    data,
  };
}

export function setCode(data) {
  return {
    type: AUTH.SET_CODE,
    data,
  };
}

export function verifyInit() {
  return {
    type: AUTH.VERIFY_INIT,
  };
}

export function logout() {
  return {
    type: AUTH.LOGOUT_USER,
  };
}
