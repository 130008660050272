import { search } from "ss-search";
import moment from "moment";

const addNew = 20;

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',');
}

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export function sortFilter(mainArr, filter) {
  if (filter.length === 0) {
    return mainArr;
  } else {
    let finalArr = [];
    switch (filter[0].value) {
      case "default":
        return mainArr;

      case "low to high":
        finalArr = mainArr.sort((a, b) =>
          parseFloat(a.price) < parseFloat(b.price) ? -1 : 1
        );

        return finalArr;

      case "high to low":
        finalArr = mainArr.sort((a, b) =>
          parseFloat(a.price) > parseFloat(b.price) ? -1 : 1
        );

        return finalArr;

      case "newest":
        finalArr = mainArr.sort((a, b) =>
          new Date(a.date_created.date) > new Date(b.date_created.date) ? -1 : 1
        );

        return finalArr;

      case "most popular":
        finalArr = mainArr.sort((a, b) =>
          a.total_sales > b.total_sales ? -1 : 1
        );
        return finalArr;

      case "top rated":
        finalArr = mainArr.sort((a, b) =>
          parseFloat(a.average_rating) > parseFloat(b.average_rating) ? -1 : 1
        );

        return finalArr;
      default:
        return mainArr;
    }
  }
}

export const loadMore = (list, last, add) => {
  // //console.log('last data',last)
  let newAdd = add ? add : addNew;
  if (last + newAdd >= list.length) return { retArr: list, len: list.length };

  let len = last + newAdd;

  let retArr = JSON.parse(JSON.stringify(list));

  retArr.length = len;

  return { retArr, len };
};
