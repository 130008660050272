import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import {withStyles} from "@material-ui/core/styles";
import {connect} from 'react-redux'
import {openLeftDrawer} from "../../../redux/actions/left-drawer";
import CrossIcon from '../../../assets/icons/cross-icon.svg'
import {leftDrawerStyle} from "../leftDrawerStyle";
import LogoImage from '../../../assets/images/shanta-logo.png'
import Title from "../../../components/Title";
import TimerMixin from 'react-timer-mixin';

import {useHistory, Link} from "react-router-dom";

let style = theme => leftDrawerStyle(theme)

let Products = (props) => {
  let {classes, openLeft, handleLeftDrawer, categories} = props;

  let products = categories
    ? categories.length
      ? categories.find((item) => item.slug === "products")
      : null
    : null;

  let productsId = products ? products.term_id : null;

  let productsChild = productsId
    ? categories.length
      ? categories.filter((item) => item.parent === productsId)
      : []
    : [];

  let history = useHistory();

  let handleClose = () => {
    handleLeftDrawer('')
  }

  let handleRoute = path => {
    handleClose()
    TimerMixin.setTimeout(() => {
      history.push(`/${path}`)
    }, 500)
  }

  let handleCategoryRoute = id => {
    handleClose()
    TimerMixin.setTimeout(() => {
      history.push(`/product-list/${id}`);
    }, 500)
  }

  // productsChild = productsChild.sort((a, b) =>  a.term_id < b.term_id  ? -1 : 1)
 // productsChild = productsChild.sort((a, b) => a.name < b.name ? -1 : 1)
  productsChild?.length>0 && productsChild.sort((x, y) => x.sort_order - y.sort_order);
  // //console.log('products',productsChild);
  return (
    <div>
      <Drawer
        anchor={'left'}
        open={openLeft === 'left-products'}
        onClose={handleClose}
      >
        <div className={classes.drawerHeader}>
          <div
            onClick={() => handleLeftDrawer('')}
            className={classes.crossIcon}
          >
            <img src={CrossIcon} />
          </div>
          <div onClick={() => handleRoute('')}>
            <img src={LogoImage}/>
          </div>
        </div>
        <div className={classes.drawerBody}>
          <div
            role="presentation"
          >
            <Link onClick={() => handleRoute('new-arrival')}>
              <Title
                text={'New Arrivals'}
                color={'#313132'}
                fontSize={14}
                lineHeight={24}
                fontFamily={'Poppins'}
                fontWeight={'600'}
                textAlign={'left'}
                margin={'0 0 10px 40px'}
                textTransform={'capitalize'}
              />
            </Link>
            <Link onClick={() => handleRoute('featured')}>
              <Title
                text={'Feature Products'}
                color={'#313132'}
                fontSize={14}
                lineHeight={24}
                fontFamily={'Poppins'}
                fontWeight={'600'}
                textAlign={'left'}
                margin={'0 0 10px 40px'}
                textTransform={'capitalize'}
              />
            </Link>
            {productsChild.length ? productsChild.map((item, key) => (
                // item?.slug.toLowerCase() !=="decoration" &&
              <Link key={key} onClick={() => handleCategoryRoute(item.term_id)}>

                <Title
                  text={item.name}
                  color={'#313132'}
                  fontSize={14}
                  lineHeight={24}
                  fontFamily={'Poppins'}
                  fontWeight={'400'}
                  textAlign={'left'}
                  margin={
                    key === 0 ? '30px 0 10px 40px' :
                      key === productsChild.length - 1 ? '0px 0 10px 40px' : '0px 0 10px 40px'
                  }
                  textTransform={'capitalize'}
                />
              </Link>
            )) : ''}
          </div>
        </div>
      </Drawer>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    openLeft: state.leftDrawerReducer.openLeft,
    categories: state.homeReducer.categories,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleLeftDrawer: data => dispatch(openLeftDrawer(data))
  }
}

export default withStyles(style)(connect(mapStateToProps, mapDispatchToProps)(Products))
