import React from 'react'
import {Col, Form, Row} from 'react-bootstrap'
import Title from '../../../components/Title'
import {authStyle} from "../../auth/authStyle";
import {withStyles} from "@material-ui/styles";

let styles = theme => authStyle(theme)

let EmailField = props => {
  let {classes, email, handleEmail, handleBlur, emailErr, submit} = props
  return (
    <Form onSubmit={submit}>
      <Form.Group>
        <Row>
          <Col className={classes.formField} md={12}>
            <Form.Label className={classes.formLabel}>Email:</Form.Label>
            <Form.Control
              type='text'
              value={email}
              className={classes.formInput}
              onChange={handleEmail}
              placeholder={'Your Email Address'}
              onBlur={handleBlur}
              autoComplete="off"
            />
            {emailErr ? emailErr.length ? (
              <Title
                text={emailErr}
                fontWeight={200}
                fontSize={14}
                lineHeight={20}
                fontFamily={'Poppins'}
                color={'red'}
              />
            ) : null : null}
          </Col>
        </Row>
      </Form.Group>
    </Form>
  )
}

export default withStyles(styles)(EmailField)
