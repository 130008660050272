import React, { useEffect } from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import Product from "./Product";
import { featureData } from "../../../assets/data/products";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { fetchFeaturedProducts } from "../../../redux/actions/home";

const FeatureProduct = (props) => {
  let { categories, allProducts, featuredProducts } = props;

  let featured = categories
    ? categories.length
      ? categories.find((item) => item.slug === "featured-products")
      : null
    : null;

  let id = featured ? featured.term_id : null;

  useEffect(()=>{
      props.fetchFeatured(id)
  }, [id])

  // let featuredProducts = allProducts
  //   ? allProducts.length
  //     ? allProducts.filter((item) => {
  //         let found = item.category_ids.find((itm) => itm === id);
  //         if (found) return item;
  //       })
  //     : []
  //   : [];

  return (
    <StyledFeature>
      <Container className="container">
        <Row>
          <Col>
            <div className="subTitle">Category</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 24,
              }}
            >
              <div className="title">Featured Products</div>
              <div
                style={{
                  marginLeft: "auto",
                  backgroundColor: "#EFEFEF",
                  padding: "5px 18px 5px 18px",
                  borderRadius: "50px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{ color: "#313132", fontSize: 12, fontWeight: 400 }}
                >
                  <Link to={'/featured'}>See all</Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          {featuredProducts.length
            ? featuredProducts.map((item, key) => {
                return (
                  <Col  key={key} lg={3} md={6} sm={6} xs={12} className="column-cl">
                    <Product item={item} />
                  </Col>
                );
              })
            : null}
        </Row>
      </Container>
    </StyledFeature>
  );
};

const StyledFeature = styled.div`
  padding-bottom: 32px;

  .container {
    max-width: 95%;
  }

  .subTitle {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 8px;
    padding-bottom: 2px;
    margin-top: 32px;
    color: rgb(156, 148, 52);
    padding-top: 32px;
  }

  .title {
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    padding-left: 8px;
    padding-bottom: 8px;
    color: #313132;
  }

  .column-cl {
    margin-bottom: 30px;
  }

  .card-link {
    text-decoration: none;
    cursor: pointer;
  }
`;

function mapStateToProps(state) {
  return {
    categories: state.homeReducer.categories,
    featuredProducts: state.homeReducer.featuredProducts,
    allProducts: state.homeReducer.allProducts,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchFeatured: (data) => dispatch(fetchFeaturedProducts(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FeatureProduct);
