import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Title from "../../../components/Title";
import BrandBanner from "../../../assets/images/dynamic/brand-banner.png";
import ReactHtmlParser from "react-html-parser";
import {connect} from "react-redux";
import Divider from "@material-ui/core/Divider";
import Text from "../../../components/Text";
import {
  fetchFeaturedProducts,
  fetchFiltered,
  fetchProductData,
  fetchTextFiltered,
  filterInit
} from "../../../redux/actions/home";
import AvailableProduct from "./available-search-products";
import CircularProgress from "@material-ui/core/CircularProgress";
import {filterProducts, onlyUnique} from "../../../helpers/globalFunc";
import {search} from "ss-search"
import {useLocation} from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const AllProduct = (props) => {
  let {categories, allProducts, slug, posts,filteredLoading, fetchFiltered, filteredProduct} = props;
  let query = useQuery()
  let text = query.get('text')

  useEffect(()=>{
    fetchFiltered({
      s: text
    })
  },[text])

  //console.log(filteredProduct)

  // const newArrivalCat = categories
  //   ? categories.length
  //     ? categories.find((item) => item.slug === 'new-arrivals')
  //     : null
  //   : null;
  //
  // const newArrivalId = newArrivalCat ? newArrivalCat.term_id : null
  //
  // const searchKeys = ["name", "tag_ids"]
  // const searchText = text
  //
  // const searchProducts = search(allProducts, searchKeys, searchText)
  //
  // const newArrivals = searchProducts.length ?
  //   searchProducts.filter(item => item.category_ids.some(i => i === newArrivalId))
  //   : []
  //
  // const featuredProducts = searchProducts.length ?
  //   searchProducts.filter(item => item.category_ids.every(i => i !== newArrivalId)) : []

  return (
    <StyledAllProduct product={filteredProduct ? filteredProduct.length : null}>
      {filteredProduct && filteredProduct.length > 0 ? (
        <>
          <Container className="Banner-view">
            <Row>
              <Col>
                <div style={{display: 'flex', marginTop: 90, alignItems: 'center'}}>
                  <div className="text-breadcrumb">Product</div>
                  <div className="text-breadcrumb-point"/>
                  <div className="text-breadcrumb">Search</div>
                  <div className="text-breadcrumb-point"/>
                  <div className="text-breadcrumb">
                    {text}
                  </div>
                </div>
                <Divider style={{marginTop: 6, marginBottom: 30}}/>
              </Col>
            </Row>

            <div style={{
              backgroundColor: 'rgba(122,121,121,0.1)',
              height: 1,
              width: '100%',
              marginTop: 40,
              marginBottom: 40
            }}/>
            {/*<Text*/}
            {/*  fontSize={14}*/}
            {/*  fontFamily={"Poppins"}*/}
            {/*  fontWeight={"normal"}*/}
            {/*  margin={"30px 0 0 0"}*/}
            {/*  text={ReactHtmlParser(postContent)}*/}
            {/*/>*/}
            {filteredProduct.length ? (
              <Title
                text={
                  "Search Results for - " + text
                }
                fontFamily={"Poppins"}
                fontSize={18}
                color={"#313132"}
                textAlign={'center'}
              />) : (
              <Title
                text={
                  "No Results found for - " + text
                }
                fontFamily={"Poppins"}
                fontSize={18}
                color={"#313132"}
                textAlign={'center'}
              />
            )}
          </Container>
          <Container className="body-view">
            <div style={{backgroundColor: 'rgba(122,121,121,0.1)', height: 1, width: '100%', marginTop: 40}}/>

            <Row>
              <Col xs={12} className="column-cl">
                <Container>
                  {filteredProduct.length ? (
                    <AvailableProduct
                      featuredProducts={filteredProduct}
                      title={'Search Result'}
                      //subTitle={"Best Sellers"}
                    />
                  ) : null}
                </Container>
              </Col>
            </Row>
          </Container>
        </>
      ) :
      <>
        {
          filteredLoading === false &&
            <center>
              <h4 style={{marginTop: 300}}>No product found</h4>
            </center>
        }
      </>}
      {
        filteredLoading ?
            <div className="loader">
              <CircularProgress/>
            </div> : null
      }
    </StyledAllProduct>
  );
};

const StyledAllProduct = styled.div`
  padding-bottom: 64px;
  min-height: 50vh;

  .loader {
    margin-top: 25%;
    text-align: center;
  }

  .container {
    max-width: 95%;
  }

  .Banner-view {
    display: block;
  }

  .body-view {
    display: block;
  }

  .text-breadcrumb {
    font-weight: normal;
    font-size: 10px;
    color: #313132;
    font-family: "Poppins", sans-serif;
  }

  .text-breadcrumb-point {
    width: 3px;
    height: 3px;
    color: #313132;
    border-radius: 50%;
    background-color: #313132;
    margin-right: 8px;
    margin-left: 8px;
  }

  .subTitle {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 0px;
    padding-bottom: 2px;
    margin-top: 10px;
    color: rgb(156, 148, 52);
  }

  .top-title {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    padding-left: 0px;
    padding-bottom: 8px;
    color: #313132;
  }

  .subTitle-text {
    font-family: Renner, sans-serif;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 0px;
    padding-bottom: 2px;
    margin-top: 30px;
    color: rgb(156, 148, 52);
  }

  .top-title-text {
    font-size: 18px;
    font-weight: bold;
    font-family: Renner, sans-serif;
    text-transform: uppercase;
    padding-left: 0px;
    padding-bottom: 8px;
    color: #313132;
  }

  .filter-view {
    display: block;
  }

  .column-cl {
    margin-bottom: 30px;

    .container {
      max-width: 100%;
    }
  }

  .read-more {
    background-color: #fff;
    border-radius: 50px;
    border: 1px solid #313132;
    cursor: pointer;
    text-align: center;
    width: 76px;

    .load-button {
      color: #313132;
      font-size: 10px;
      font-weight: normal;
      font-family: "Poppins", sans-serif;
      padding: 3px 8px 3px 8px;
    }
  }

  .client-single {
    &__inner {
      padding-top: 42.74%;
      border: 0 solid #707070;

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 0;
        object-fit: cover;
        width: 98.3%;
      }
    }
  }

  @media (max-width: 991px) {
    .col-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .filter-view {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .col-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .Banner-view {
      //display: none;
    }

    .body-view {
      display: block;
      margin-top: 64px;
    }

    .filter-view {
      display: none;
    }

    .MuiLinearProgress-root {
      display: none;
    }

    .texts {
      display: none;

      p {
        font-size: 10px;
      }
    }

    .read-more {
      background-color: #fff;
      border-radius: 50px;
      border: 1px solid #313132;
      cursor: pointer;
      text-align: center;
      width: 100%;

      .load-button {
        color: #313132;
        font-size: 10px;
        font-weight: normal;
        font-family: "Poppins", sans-serif;
        padding: 8px 16px 8px 16px;
      }
    }
  }
`;

function mapStateToProps(state) {
  return {
    categories: state.homeReducer.categories,
    featuredProducts: state.homeReducer.featuredProducts,
    allProducts: state.homeReducer.allProducts,
    posts: state.homeReducer.posts,
    filteredProduct: state.homeReducer.filteredProduct,
    filteredLoading: state.homeReducer.filteredLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchFiltered: data => dispatch(fetchTextFiltered(data)),
    filterInit: () => dispatch(filterInit()),
    fetchProductData: () => dispatch(fetchProductData()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AllProduct);
