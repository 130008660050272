import React from "react";
import Drawer from "@material-ui/core/Drawer";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  handleRightDrawer,
  setCheckout,
  clearCart,
  clearCartServer,
} from "../../../redux/actions/rightDrawer";
import { handleAuthModal } from "../../../redux/actions/auth";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import CartIcon from "../../../assets/icons/shopping-bag-white.svg";
import CrossIcon from "../../../assets/icons/cross-button-white.svg";
import { rightDrawerStyle } from "../rightDrawerStyle";
import Title from "../../../components/Title";
import CartButton from "../../../components/CartButton";
import TimerMixin from "react-timer-mixin";
import { numberWithCommas } from "../../../helpers/globalFunc";
import CartItem from "./cartItem";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

let style = (theme) => rightDrawerStyle(theme);

let calculatePrice = (carts) => {
  let price = 0;

  if (carts.length) {
    let len = carts.length;
    for (let i = 0; i < len; i++) {
      let calc = carts[i].count * parseFloat(carts[i].cartItem.price);

      price += calc;
    }
    return price;
  } else {
    return 0;
  }
};

let getCartItem = (cart, id) => {
  let found = cart.length
    ? cart.find((item) => item.cartItem.id.toString() === id.toString())
    : null;

  return found;
};

let CartDrawer = (props) => {
  let {
    classes,
    openRight,
    handleRightDrawer,
    cart,
    allCart,
    setCheckout,
    clearCart,
    authData,
    handleAuthModal,
    clearCartServer,
  } = props;

  let history = useHistory();

  let handleClose = () => {
    handleRightDrawer("");
  };

  const activeCart = authData ? (authData.token ? allCart : cart) : cart;
  const userId = authData ? (authData.id ? authData.id : null) : null;

  let price = calculatePrice(activeCart);

  let handleCheckout = () => {
    if (userId) {
      setCheckout(activeCart);
      handleClose();
      if (userId) {
        const clearData = {
          user_id: userId,
        };
        clearCartServer(clearData);
      }
      TimerMixin.setTimeout(() => {
        history.push("/checkout");
      }, 2000);
    } else {
      handleAuthModal("login");
    }
  };

  return (
    <div>
      <Drawer
        anchor={"right"}
        open={openRight === "cart"}
        onClose={handleClose}
      >
        <div className={classes.drawerHeader}>
          <div className={classes.drawerHeaderText}>
            <img src={CartIcon} alt="" />
            <Title
              text={
                activeCart.length
                  ? `${activeCart.length} items in cart`
                  : "No Items in Cart"
              }
              color={"#fff"}
              fontSize={14}
              lineHeight={24}
              fontWeight={"400"}
              margin={"0 0 0 12px"}
              textTransform={"capitalize"}
            />
          </div>
          <div
            onClick={() => handleRightDrawer("")}
            className={classes.crossIcon}
          >
            <img src={CrossIcon} alt=""/>
          </div>
        </div>
        <div className={classes.drawerBody}>
          <StyledDiv role="presentation">
            {activeCart ? (
              activeCart.length ? (
                activeCart.map((item, key) => {
                  //console.log("cart", item)
                  return (
                    <>
                      <List key={key}>
                        <ListItem>
                          <CartItem
                            product={item}
                            cart={activeCart}
                            getCurrent={getCartItem}
                          />
                        </ListItem>
                      </List>
                      <Divider />
                    </>
                  );
                })
              ) : (
                <>
                  <List>
                    <ListItem>
                      <Title
                        text={`No items in cart`}
                        color={"000"}
                        fontSize={14}
                        fontFamily={'Poppins'}
                        lineHeight={24}
                        fontWeight={"400"}
                        margin={"0 0 0 12px"}
                        textTransform={"capitalize"}
                      />
                    </ListItem>
                  </List>
                  <Divider />
                </>
              )
            ) : null}
          </StyledDiv>
        </div>
        {activeCart.length ? (
            <StyledButton>
              <CartButton
                  price={numberWithCommas(parseFloat(price))}
                  handleSubmit={() => handleCheckout()}
              />
            </StyledButton>
        ) : (
            ""
        )}
      </Drawer>
    </div>
  );
};

const StyledDiv = styled.div`
  .MuiListItem-gutters{
    padding-left: 0px !important;
  }
  .cart-button {
    position: absolute;
    bottom: 0px;
    width: 90%;
    @media(max-width: 991px){
      width: 95%;
    }
  }
`

const StyledButton = styled.div`
  position: absolute;
  bottom: 0px;
  width: 90%;
  @media(max-width: 767px){
    width: 95%;
  }
  @media(max-width: 580px){
    width: 94%;
  }
  @media(max-width: 450px){
    width: 92%;
  }
`

function mapStateToProps(state) {
  return {
    openRight: state.rightDrawerReducer.openDrawer,
    cart: state.rightDrawerReducer.cart,
    authData: state.authReducer.authData,
    allCart: state.rightDrawerReducer.allCart,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleRightDrawer: (data) => dispatch(handleRightDrawer(data)),
    clearCart: () => dispatch(clearCart()),
    setCheckout: (data) => dispatch(setCheckout(data)),
    handleAuthModal: (data) => dispatch(handleAuthModal(data)),
    clearCartServer: (data) => dispatch(clearCartServer(data)),
  };
}

export default withStyles(style)(
  connect(mapStateToProps, mapDispatchToProps)(CartDrawer)
);
