export const ORDERS = {
  SET_ORDER: {
    MAIN: "SET_ORDER",
    SUCCESS: "SET_ORDER_SUCCESS",
    FAILURE: "SET_ORDER_FAILURE",
  },
  FETCH_ALL_ORDERS: {
    MAIN: "FETCH_ALL_ORDERS",
    SUCCESS: "FETCH_ALL_ORDERS_SUCCESS",
    FAILURE: "FETCH_ALL_ORDERS_FAILURE",
  },
  SET_HOME: {
    MAIN: "SET_HOME",
    SUCCESS: "SET_HOME_SUCCESS",
    FAILURE: "SET_HOME_FAILURE",
  },
  SET_ADDRESS: {
    MAIN: "SET_ADDRESS",
    SUCCESS: "SET_ADDRESS_SUCCESS",
    FAILURE: "SET_ADDRESS_FAILURE",
  },
  SET_PHONE: {
    MAIN: "SET_PHONE",
    SUCCESS: "SET_PHONE_SUCCESS",
    FAILURE: "SET_PHONE_FAILURE",
  },
  SET_EMAIL: {
    MAIN: "SET_EMAIL",
    SUCCESS: "SET_EMAIL_SUCCESS",
    FAILURE: "SET_EMAIL_FAILURE",
  },
  SET_PAYMENT: {
    MAIN: "SET_PAYMENT",
    SUCCESS: "SET_PAYMENT_SUCCESS",
    FAILURE: "SET_PAYMENT_FAILURE",
  },
  INIT_NEW_ORDER: {
    MAIN: "INIT_NEW_ORDER",
    SUCCESS: "INIT_NEW_ORDER_SUCCESS",
    FAILURE: "INIT_NEW_ORDER_FAILURE",
  },
  PAYMENT_GATEWAY: {
    MAIN: "PAYMENT_GATEWAY",
    SUCCESS: "PAYMENT_GATEWAY_SUCCESS",
    FAILURE: "PAYMENT_GATEWAY_FAILURE",
  },
  PAYMENT_DONE: {
    MAIN: "PAYMENT_DONE",
    SUCCESS: "PAYMENT_DONE_SUCCESS",
    FAILURE: "PAYMENT_DONE_FAILURE",
  },
  PAYMENT_FAILED: {
    MAIN: "PAYMENT_FAILED",
    SUCCESS: "PAYMENT_FAILED_SUCCESS",
    FAILURE: "PAYMENT_FAILED_FAILURE",
  },
  ORDERS_AMOUNT: {
    MAIN: "ORDERS_AMOUNT",
    SUCCESS: "ORDERS_AMOUNT_SUCCESS",
    FAILURE: "ORDERS_AMOUNT_FAILURE",
  },
  ORDERS_FILE: {
    MAIN: "ORDERS_FILE",
    SUCCESS: "ORDERS_FILE_SUCCESS",
    FAILURE: "ORDERS_FILE_FAILURE",
  },
  PAYMENT_INIT: "PAYMENT_INIT",
};
