import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "../assets/icons/search.svg";
import {useHistory} from "react-router-dom";
import {connect} from "react-redux";
import {Form} from "react-bootstrap";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {InputBase} from "@material-ui/core";
import TimerMixin from "react-timer-mixin";
import Typography from "@material-ui/core/Typography";

const CustomSearchBar = (props) => {
  let {categories, allProducts} = props;

  let featured = categories
      ? categories.length
          ? categories.find((item) => item.slug === "featured-products")
          : null
      : null;

  let id = featured ? featured.term_id : null;

  let featuredProducts = allProducts
      ? allProducts.length
          ? allProducts.filter((item) => {
            let found = item.category_ids.find((itm) => itm === id);
            if (found) return item;
          })
          : []
      : [];

  let options = featuredProducts.length
      ? featuredProducts.map((item) => {
        return item.name;
      })
      : [];

  const classes = useStyles();

  const history = useHistory();

  const [searchText, setSearchText] = useState("");

  const handleText = (e) => {
    setSearchText(e.target.value);
  };

  const searchSubmit = (event) => {
    event.preventDefault();
    if (searchText !== '') {
      TimerMixin.setTimeout(() => {
        history.push(`/search?text=${searchText}`);
      }, 100);
    }

  };

  const textChange = (e) => {
    setSearchText(e.target.value)
  }

  return (
      <Form onSubmit={(e) => searchSubmit(e)}>
        <Autocomplete
            id="free-solo-demo"
            freeSolo
            options={options}
            renderOption={(option) => (
                <Typography className={classes.options}>{option}</Typography>
            )}
            // onChange={(event, newValue) => {
            //   if (newValue) {
            //     setSearchText(newValue);
            //     searchSubmit(event.defaultPrevented, newValue);
            //   }
            // }}
            onChange={textChange}
            renderInput={(params) => {
              const {InputLabelProps, InputProps, ...rest} = params;
              return (
                  <Paper component="form" className={classes.root}>
                    <InputBase
                        {...params.InputProps}
                        {...rest}
                        className={classes.input}
                        placeholder="What Are You Looking For?"
                        onChange={handleText}
                    />
                    <button
                        type="submit"
                        onClick={(e) => searchSubmit(e)}
                        style={{
                          height: 0,
                          width: 0,
                          opacity: 0,
                          pointerEvents: "none",
                        }}
                    />
                    <div
                        className={classes.iconButton}
                        onClick={(e) => searchSubmit(e)}
                    >
                      <img src={SearchIcon}/>
                    </div>
                  </Paper>
              );
            }}
        />
      </Form>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 25,
    height: 40,
    boxShadow: "none",
    backgroundColor: "#F5F5F5",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
  },
  autoComplete: {
    width: "100%",
    fontFamily: "Poppins",
    fontSize: 12,
  },
  input: {
    flex: 1,
    marginLeft: theme.spacing(3),
    fontFamily: "Poppins",
    fontSize: 12,
    padding: "5px 0",
    border: "none",
    backgroundColor: "#F5F5F5",
    "&:active": {
      backgroundColor: "#F5F5F5",
    },
  },
  label: {
    display: "none",
  },
  iconButton: {
    padding: "0 15px 4px 0",
    cursor: "pointer",
  },
  divider: {
    height: 28,
    margin: 4,
  },
  options: {
    fontSize: 12,
    fontFamily: "Poppins",
    color: "#313132",
  },
}));

function mapStateToProps(state) {
  return {
    categories: state.homeReducer.categories,
    allProducts: state.homeReducer.allProducts,
  };
}

export default connect(mapStateToProps)(CustomSearchBar);
