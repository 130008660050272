import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Image from "../../../components/Image";
import moment from "moment";

const Story = ({ item }) => {
  return (
    <ProductCardWrapper>
      <Link to={`/story-detail/${item.post_name}`}>
        <ProductImageWrapper>
          <Image
            url={item.post_feature_image}
            className="product-image"
            style={{ position: "relative" }}
            alt={"Product"}
          />
        </ProductImageWrapper>
        <ProductInfo>
          <div className="date-text">
            {moment(item.post_date).format("DD.MM.YYYY")}
          </div>
          <h3 className="product-title">{item.post_title}</h3>
          <div className="read-more">
            <div className="load-button">Read more</div>
          </div>
        </ProductInfo>
      </Link>
    </ProductCardWrapper>
  );
};

const ProductCardWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: #fcfcfc;
  border-bottom: 2px solid #efefef;
  position: relative;
  border-radius: 0;
  cursor: pointer;
  margin-right: 30px;

  &:nth-last-of-type(1) {
    margin-right: 0;
  }
`;

const ProductImageWrapper = styled.div`
  padding-top: 59.46%;
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0px;
    object-fit: cover;
  }

  @media (max-width: 767px) {
    height: 145px;
  }
`;

const ProductInfo = styled.div`
  padding: 15px 20px 15px;
  @media (max-width: 767px) {
    padding: 15px 20px;
  }

  .date-text {
    font-size: 12px;
    font-family: Poppins, sans-serif;
    font-weight: normal;
    color: #707070;
  }

  .product-title {
    font-size: 16px;
    font-weight: 600;
    font-family: Poppins, sans-serif;
    color: #313132;
    width: 100%;
    max-lines: 2;
    margin: 13px 0 5px 0;
    text-align: left;
    overflow: hidden;
    //white-space: nowrap;
    height: 45px;
    @media (max-width: 767px) {
      font-size: 14px;
      margin: 8px 0 5px 0;
    }
  }

  .read-more {
    margin-top: 10px;
    background-color: #fff;
    border-radius: 50px;
    border: 1px solid #313132;
    cursor: pointer;
    text-align: center;
    width: 76px;

    .load-button {
      color: #313132;
      font-size: 10px;
      font-weight: normal;
      font-family: "Poppins", sans-serif;
      padding: 3px 8px 3px 8px;
    }
  }
`;

export default Story;
