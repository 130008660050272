import React, {Component} from "react";
import '../../App.css';
import {motion} from "framer-motion";
import {PageAnimation} from "../../components/PageAnimation";
import ShopByRoom from "../home/components/ShopByRoom";
import AllRoom from "./components/AllRoom";

class RoomIndex extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
        <AllRoom/>
      </motion.div>
    )
  }
}

export default RoomIndex;
