import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Title from "../../components/Title";
import {Row, Col, Container} from "react-bootstrap";
import OrderList from "./components/OrderList";
import OrderDetails from "./components/OrderDetails";
import { connect } from "react-redux";
import { handleAuthModal } from "../../redux/actions/auth";
import CircularProgress from "@material-ui/core/CircularProgress";
import MobileOrderHistory from "../auth/components/MobileOrderHistory";
import Divider from "@material-ui/core/Divider";

let statusCheck = (current) => {
  if (current === "pending" || current === "processing") return { name: "Order Placed", id: 0 };
  else if (current === "confirmed") return { name: "Order Confirmed", id: 1 };
  else if (current === "on-transit") return { name: "Order in Transit", id: 2 };
  else if (current === "on-delivery")
    return { name: "Order Picked Up For Delivery", id: 3 };
  else if (current === "completed") return { name: "Delivered", id: 4 };
  else return { name: "", id: null };
};

let OrderMain = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let { orders, handleAuthModal, orderLoading } = props;
  let [active, setActive] = useState(0);

  return (
      <StyledOrderMain orders={orders.length}>
        <Container>
          <Row className="change-view">
            <Col>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <div className="text-breadcrumb">Home</div>
                <div className="text-breadcrumb-point"/>
                <div className="text-breadcrumb">Track Order</div>
              </div>
              <Divider style={{marginTop: 6}}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <Title
                  text={"Track Order"}
                  color={"#9C9434"}
                  fontSize={12}
                  lineHeight={18}
                  fontFamily={"Poppins"}
                  fontWeight={"500"}
                  letterSpacing={60}
                  textTransform={"uppercase"}
                  textAlign={"left"}
                  margin={'30px 0px 0px 0px'}
              />
              <Title
                  text={"Order history"}
                  color={"#313132"}
                  fontSize={24}
                  lineHeight={40}
                  fontWeight={"500"}
                  letterSpacing={60}
                  textTransform={"uppercase"}
                  textAlign={"left"}
                  margin={"0 0 30px 0"}
              />
            </Col>
          </Row>
          <Row>
            {orderLoading ? (
                <Col>
                  <div className="loader">
                    <CircularProgress />
                  </div>
                </Col>
            ) : orders ? (
                orders.length ? (
                    <>
                      <Col md={4} className="desktop-view">
                        <OrderList
                            active={active}
                            setActive={setActive}
                            statusCheck={statusCheck}
                        />
                      </Col>
                      <Col md={8} className="desktop-view">
                        <OrderDetails
                            active={orders[active]}
                            statusCheck={statusCheck}
                            ordersAmount={props.ordersAmount}
                        />
                      </Col>
                      <Col md={12} className="mobile-view">
                        <MobileOrderHistory
                            active={active}
                            setActive={setActive}
                            orders={orders}
                            ordersAmounts={props.ordersAmount}
                            statusCheck={statusCheck}
                        />
                      </Col>
                    </>
                ) : (
                    <Col>
                      <Title
                          text={"No Order History"}
                          fontSize={16}
                          fontWeight={'500'}
                          fontFamily={'Poppins'}
                          textAlign={'center'}
                          margin={'20px 0 0 0'}
                      />
                    </Col>
                )
            ) : null}
          </Row>
        </Container>
      </StyledOrderMain>
  );
};

let StyledOrderMain = styled.div`
  margin-top: 100px;
  height: ${(props) => (props.orders ? "auto" : "120vh")};

  .container {
    max-width: 95%;
    @media(max-width: 991px){
      max-width: 100%;
      padding: 0px 20px 20px 20px;
    }
  }

  .text-breadcrumb {
    font-weight: normal;
    font-size: 10px;
    color: #313132;
    font-family: "Poppins", sans-serif;
  }

  .text-breadcrumb-point{
    width: 3px;
    height: 3px;
    color: #313132;
    border-radius: 50%;
    background-color: #313132;
    margin-right: 8px;
    margin-left: 8px;
  }

  .change-view {
    display: block;
    @media(max-width: 991px){
      display: none;
    }
  }

  .loader {
    margin-top: 15%;
    text-align: center;
  }

  .desktop-view{
    display: block;

    @media (max-width: 767px) {
      display: none;
    }
  }

  .mobile-view {
    display: none;

    @media (max-width: 767px) {
      display: block;
    }
  }
`;

function mapStateToProps(state) {
  return {
    orders: state.orderReducer.orders,
    orderLoading: state.orderReducer.orderLoading,
    ordersAmount: state.orderReducer.ordersAmount
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleAuthModal: (data) => dispatch(handleAuthModal(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderMain);