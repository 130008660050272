import {takeLatest, put, call} from "redux-saga/effects";
import Logger from "../../../helpers/logger";
import {AxiosServices} from "../../../network/AxiosServices";
import {ApiServices} from "../../../network/ApiServices";
import {AUTH} from "../../constants/auth";

function* signUp(actions) {
  try {
    let result = yield call(
      AxiosServices.post,
      ApiServices.USER_SIGN_UP,
      actions.data
    );

    yield put({
      type: AUTH.SET_SIGN_UP.SUCCESS,
      result: result.data,
    });
  } catch (err) {
    Logger(err);

    yield put({
      type: AUTH.SET_SIGN_UP.FAILURE,
    });
  }
}

function* login(actions) {
  try {
    let result = yield call(
      AxiosServices.post,
      ApiServices.USER_LOGIN,
      actions.data
    );

    yield put({
      type: AUTH.SET_LOGIN.SUCCESS,
      result: result.data,
    });
  } catch (err) {
    Logger(err);

    yield put({
      type: AUTH.SET_LOGIN.FAILURE,
      result: err.response.data,
    });
  }
}

function* reqPasswordChange(actions) {
  try {
    let result = yield call(
      AxiosServices.post,
      ApiServices.PASSWORD_REQ,
      actions.data
    );

    yield put({
      type: AUTH.FORGET_PASS_REQ.SUCCESS,
      result: result.data,
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: AUTH.FORGET_PASS_REQ.FAILURE,
      result: err.response.data,
    });
  }
}

function* verifyCode(actions) {
  try {
    let result = yield call(
      AxiosServices.post,
      ApiServices.CODE_VERIFY,
      actions.data
    );

    yield put({
      type: AUTH.VERIFY_CODE.SUCCESS,
      result: result.data,
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: AUTH.VERIFY_CODE.FAILURE,
      result: err.response.data,
    });
  }
}

function* sendCode(actions) {
  try {
    let result = yield call(
      AxiosServices.post,
      ApiServices.SEND_SMS,
      actions.data
    );

    yield put({
      type: AUTH.SEND_OTP.SUCCESS,
      result: result.data,
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: AUTH.SEND_OTP.FAILURE,
      result: err.response.data,
    });
  }
}

function* forgetPasswordUpdate(actions) {
  try {
    const result = yield call(
      AxiosServices.post,
      ApiServices.UPDATE_FORGET_PASSWORD,
      actions.data
    )

    yield put({
      type: AUTH.FORGET_PASSWORD_CHANGE.SUCCESS,
      result: result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: AUTH.FORGET_PASSWORD_CHANGE.FAILURE,
    })
  }
}

function* profileData(actions) {
  try {
    const result = yield call(
      AxiosServices.post,
      ApiServices.GET_PROFILE,
      actions.data
    );

    yield put({
      type: AUTH.SET_PROFILE_DATA.SUCCESS,
      result: result.data,
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: AUTH.SET_PROFILE_DATA.FAILURE,
    });
  }
}

function* updateProfile(actions) {
  try {
    const result = yield call(
      AxiosServices.post,
      ApiServices.UPDATE_PROFILE,
      actions.data
    );

    yield put({
      type: AUTH.UPDATE_PROFILE.SUCCESS,
      result: result.data,
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: AUTH.UPDATE_PROFILE.FAILURE,
    });
  }
}

function* addReview(actions) {
  try {
    const result = yield call(
      AxiosServices.postWc,
      ApiServices.POST_REVIEW,
      actions.data
    )

    yield put({
      type: AUTH.REVIEW_PRODUCT.SUCCESS,
      result: result.data
    })
  } catch (err) {
    Logger(err)
    yield put({
      type: AUTH.REVIEW_PRODUCT.FAILURE,
      result: err.response.data.message
    })
  }
}

function* updatePassword(actions) {
  try {
    const result = yield call(
      AxiosServices.post,
      ApiServices.CHANGE_PASSWORD,
      actions.data
    )
    yield put({
      type: AUTH.CHANGE_PASSWORD.SUCCESS,
      result: result.data
    })
  } catch(err) {
    Logger(err)
    yield put({
      type: AUTH.CHANGE_PASSWORD.FAILURE
    })
  }
}

export default function* authSagas() {
  yield takeLatest(AUTH.SET_SIGN_UP.MAIN, signUp);
  yield takeLatest(AUTH.SET_LOGIN.MAIN, login);
  yield takeLatest(AUTH.FORGET_PASS_REQ.MAIN, reqPasswordChange);
  yield takeLatest(AUTH.VERIFY_CODE.MAIN, verifyCode);
  yield takeLatest(AUTH.SEND_OTP.MAIN, sendCode);
  yield takeLatest(AUTH.SET_PROFILE_DATA.MAIN, profileData);
  yield takeLatest(AUTH.UPDATE_PROFILE.MAIN, updateProfile);
  yield takeLatest(AUTH.REVIEW_PRODUCT.MAIN, addReview);
  yield takeLatest(AUTH.CHANGE_PASSWORD.MAIN, updatePassword);
  yield takeLatest(AUTH.FORGET_PASSWORD_CHANGE.MAIN, forgetPasswordUpdate)
}
