import { CustomerService } from "../../constants/customer-service";

const initialState = {
    customer_service_loading: false,
    response_result: {}
};

export default function customerServiceReducer(state = initialState, action) {
    switch (action.type) {
        case CustomerService.ADD_CUSTOMER_SERVICE.MAIN:
            return {
                ...state,
                customer_service_loading: true,
            };
        case CustomerService.ADD_CUSTOMER_SERVICE.SUCCESS:
            return {
                ...state,
                customer_service_loading: false,
                response_result: action.response_result
            };
        case CustomerService.ADD_CUSTOMER_SERVICE.FAILURE:
            return {
                ...state,
                customer_service_loading: false,
                response_result: action.response_result,
            };
        default:
            return state;
    }
}
