import React, {Component} from "react";
import '../../App.css';
import {motion} from "framer-motion";
import {PageAnimation} from "../../components/PageAnimation";
import ShopByBrand from "../home/components/ShopByBrand";
import AllScope from "./components/AllScope";

class ScopofWorkIndex extends Component{
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return(
            <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
                <AllScope/>
            </motion.div>
        )
    }
}

export default ScopofWorkIndex;
