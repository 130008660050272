import { AUTH } from "../../constants/auth";

let initState = {
  openModal: "",
  showModal: false,
  authData: null,
  authDataLoading: false,
  authDataErr: null,
  passwordReq: null,
  passwordReqLoading: false,
  passwordReqErr: null,
  codeVerify: null,
  codeVerifyLoading: false,
  codeVerifyErr: null,
  codeVerifyData: null,
  codeVerifyDataLoading: false,
  codeVerifyDataErr: null,
  forgetPass: null,
  forgetPassLoading: false,
  forgetPassErr: null,
  passwordReset: null,
  passwordResetLoading: false,
  passwordResetErr: null,
  profileData: null,
  profileDataLoading: false,
  profileDataErr: null,
  newReview: null,
  newReviewLoading: false,
  newReviewErr: null,
  phoneNum: null,
  code: null
};

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case AUTH.OPEN_MODAL:
      return {
        ...state,
        openModal: action.data,
      };

    case AUTH.SET_LOGIN.MAIN:
      return {
        ...state,
        authData: null,
        authDataLoading: true,
        authDataErr: null,
      };

    case AUTH.SET_LOGIN.SUCCESS:
      return {
        ...state,
        authData: action.result,
        authDataLoading: false,
        authDataErr: null,
      };

    case AUTH.SET_LOGIN.FAILURE:
      return {
        ...state,
        authData: null,
        authDataLoading: false,
        authDataErr: action.result,
      };

    case AUTH.SET_SIGN_UP.MAIN:
      return {
        ...state,
        authData: null,
        authDataLoading: true,
        authDataErr: null,
      };

    case AUTH.SET_SIGN_UP.SUCCESS:
      return {
        ...state,
        authData: action.result,
        authDataLoading: false,
        authDataErr: null,
      };

    case AUTH.SET_SIGN_UP.FAILURE:
      return {
        ...state,
        authData: null,
        authDataLoading: false,
        authDataErr: "Error from Sign Up",
      };

    case AUTH.FORGET_PASS_REQ.MAIN:
      return {
        ...state,
        passwordReq: null,
        passwordReqLoading: true,
        passwordReqErr: null,
      };

    case AUTH.FORGET_PASS_REQ.SUCCESS:
      return {
        ...state,
        passwordReq: action.result,
        passwordReqLoading: false,
        passwordReqErr: null,
      };

    case AUTH.FORGET_PASS_REQ.FAILURE:
      return {
        ...state,
        passwordReq: null,
        passwordReqLoading: false,
        passwordReqErr: action.result,
      };
    case AUTH.SEND_OTP.MAIN:
      return {
        ...state,
        codeVerifyData: null,
        codeVerifyDataLoading: true,
        codeVerifyDataErr: null,
      };

    case AUTH.SEND_OTP.SUCCESS:
      return {
        ...state,
        codeVerifyData: action.result,
        codeVerifyDataLoading: false,
        codeVerifyDataErr: null,
      };

    case AUTH.SEND_OTP.FAILURE:
      return {
        ...state,
        codeVerifyData: null,
        codeVerifyDataLoading: false,
        codeVerifyDataErr: action.result,
      };

    case AUTH.VERIFY_CODE.MAIN:
      return {
        ...state,
        codeVerify: null,
        codeVerifyLoading: true,
        codeVerifyErr: null,
      };

    case AUTH.VERIFY_CODE.SUCCESS:
      return {
        ...state,
        codeVerify: action.result,
        codeVerifyLoading: false,
        codeVerifyErr: null,
      };

    case AUTH.VERIFY_CODE.FAILURE:
      return {
        ...state,
        codeVerify: null,
        codeVerifyLoading: false,
        codeVerifyErr: action.result,
      };

    case AUTH.FORGET_PASSWORD_CHANGE.MAIN:
      return {
        ...state,
        forgetPass: null,
        forgetPassLoading: true,
        forgetPassErr: null
      }

    case AUTH.FORGET_PASSWORD_CHANGE.SUCCESS:
      return {
        ...state,
        forgetPass: action.result,
        forgetPassLoading: false
      }

    case AUTH.FORGET_PASSWORD_CHANGE.FAILURE:
      return {
        ...state,
        forgetPassLoading: false,
        forgetPass: null,
        forgetPassErr: 'Error from Forget Password update'
      }

    case AUTH.SET_PROFILE_DATA.MAIN:
      return {
        ...state,
        profileDataLoading: true,
      };

    case AUTH.SET_PROFILE_DATA.SUCCESS:
      return {
        ...state,
        profileData: action.result,
        profileDataLoading: false,
      };

    case AUTH.SET_PROFILE_DATA.FAILURE:
      return {
        ...state,
        profileDataLoading: false,
        profileDataErr: "Error from fetching Profile Data",
      };

    case AUTH.UPDATE_PROFILE.MAIN:
      return {
        ...state,
        profileDataLoading: true,
      };

    case AUTH.UPDATE_PROFILE.SUCCESS:
      return {
        ...state,
        profileDataLoading: false,
        profileData: action.result,
      };

    case AUTH.UPDATE_PROFILE.FAILURE:
      return {
        ...state,
        profileDataLoading: false,
        profileDataErr: "Error From Profile Update",
      };

    case AUTH.CHANGE_PASSWORD.MAIN:
      return {
        ...state,
        passwordReset: null,
        passwordResetLoading: true,
        passwordResetErr: null,
      };

    case AUTH.CHANGE_PASSWORD.SUCCESS:
      return {
        ...state,
        passwordReset: action.result,
        passwordResetLoading: false,
        passwordResetErr: null,
      };

    case AUTH.CHANGE_PASSWORD.FAILURE:
      return {
        ...state,
        passwordReset: null,
        passwordResetLoading: false,
        passwordResetErr: 'Error From Password reset',
      };

    case AUTH.RESET_PASS_INIT:
      return {
        ...state,
        passwordReset: null,
        passwordResetLoading: false,
        passwordReqErr: null
      }

    case AUTH.REVIEW_PRODUCT.MAIN:
      return {
        ...state,
        newReview: null,
        newReviewLoading: true,
        newReviewErr: null
      }

    case AUTH.REVIEW_PRODUCT.SUCCESS:
      return {
        ...state,
        newReview: action.result,
        newReviewLoading: false,
        newReviewErr: null
      }

    case AUTH.REVIEW_PRODUCT.FAILURE:
      return {
        ...state,
        newReviewLoading: false,
        newReview: null,
        newReviewErr: action.result
      }

    case AUTH.REVIEW_INIT:
      return {
        ...state,
        newReview: null,
        newReviewErr: null
      }

    case AUTH.SET_PHONE:
      return {
        ...state,
        phoneNum: action.data
      }

    case AUTH.SET_CODE:
      return {
        ...state,
        code: action.data
      }

    case AUTH.VERIFY_INIT:
      return {
        ...state,
        passwordReq: null,
        codeVerify: null,
        forgetPass: null,
        phoneNum: null,
        code: null
      }

    case AUTH.LOGOUT_USER:
      return {
        ...state,
        authData: null,
        authDataLoading: false,
        authDataErr: null,
        passwordReq: null,
        passwordReqLoading: false,
        passwordReqErr: null,
        codeVerify: null,
        codeVerifyLoading: false,
        codeVerifyErr: null,
        codeVerifyData: null,
        codeVerifyDataLoading: false,
        codeVerifyDataErr: null,
        forgetPass: null,
        forgetPassLoading: false,
        forgetPassErr: null,
        passwordReset: null,
        passwordResetLoading: false,
        passwordResetErr: null,
        profileData: null,
        profileDataLoading: false,
        profileDataErr: null,
      };

    default:
      return state;
  }
}
