import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Title from "../../../components/Title";
import WishlistButton from "../../../components/WishlistButton";
import NewButtonSubmit from "../../../components/NewButtonSubmit";
import { authStyle } from "../../auth/authStyle";
import { withStyles } from "@material-ui/styles";

let AddressField = (props) => {
  let {
    classes,
    handleCancel,
    address1,
    address2,
    err,
    handleAddress1,
    handleAddress2,
    handleSubmit,
    loading,
    handleCity,
    handleCountry,
    city,
    country,
  } = props;

  return (
    <Form>
      <Form.Group>
        <Row>
          <Col className={classes.formField} md={6} sm={12}>
            <Form.Label className={classes.formLabel}>
              Address Line 1
            </Form.Label>
            <Form.Control
              type="text"
              className={classes.formControl}
              value={address1}
              onChange={handleAddress1}
              placeholder={"Your Address"}
            />
            {err.length ? (
              <Title
                text={err}
                fontSize={14}
                fontWeight={"400"}
                lineHeight={20}
                color={"red"}
                margin={"20px 0"}
              />
            ) : null}
          </Col>
          <Col className={classes.formField} md={6} sm={12}>
            <Form.Label className={classes.formLabel}>
              Address Line 2
            </Form.Label>
            <Form.Control
              type="text"
              className={classes.formControl}
              value={address2}
              placeholder={"Your Address"}
              onChange={handleAddress2}
            />
          </Col>
          <Col className={classes.formField} md={6} sm={12}>
            <Form.Label className={classes.formLabel}>City</Form.Label>
            <Form.Control
              type="text"
              className={classes.formControl}
              value={city}
              onChange={handleCity}
              placeholder={"Your City"}
            />
          </Col>
          <Col className={classes.formField} md={6} sm={12}>
            <Form.Label className={classes.formLabel}>Country</Form.Label>
            <Form.Control
              type="text"
              className={classes.formControl}
              value={country}
              placeholder={"Your Country"}
              onChange={handleCountry}
            />
          </Col>
          <Col className="button-field" md={12} sm={12}>
            <Row>
              <Col md={6} sm={12}>
                <NewButtonSubmit
                  handleSubmit={handleSubmit}
                  loading={loading}
                  text={"Save Changes"}
                />
              </Col>
              <Col md={6} sm={12}>
                <WishlistButton handleSubmit={handleCancel} text={"Cancel"} />
              </Col>
            </Row>
            {err.length ? (
              <Title
                text={err}
                color={"red"}
                margin={"10px 0 0 0"}
                fontSize={14}
                lineHeight={20}
              />
            ) : null}
          </Col>
        </Row>
      </Form.Group>
    </Form>
  );
};

let styles = (theme) => authStyle(theme);

export default withStyles(styles)(AddressField);
