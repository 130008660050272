import React from "react";
import Title from "../../../components/Title";
import Divider from "@material-ui/core/Divider";
import OrderTable from "./OrderTable";
import { numberWithCommas } from "../../../helpers/globalFunc";

let OrderItems = (props) => {
  let { classes, active, mobile, ordersAmount } = props;
  let payment = active
    ? active.payment_method === "cash"
      ? "Cash on Delivery"
      : "Online Payment"
    : null;
  return (
    <>
      <OrderTable active={active} mobile={mobile} />
      <div className={classes.header}>
        <div>
          <Title
            text={"Payment Method"}
            fontSize={14}
            lineHeight={24}
            fontFamily={'Poppins'}
            fontWeight={"600"}
            color={"#313132"}
            margin={"10px 0 0 0"}
          />
          <Title
              text={'Sub Total'}
              fontSize={12}
              lineHeight={24}
              fontFamily={'Poppins'}
              fontWeight={"500"}
              color={"#313132"}
              margin={"0 0 10px 0"}
          />
          <Title
              text={'Delivery fee'}
              fontSize={12}
              lineHeight={24}
              fontFamily={'Poppins'}
              fontWeight={"500"}
              color={"#313132"}
              margin={"0 0 10px 0"}
          />
          <Title
              text={'Tax'}
              fontSize={12}
              lineHeight={24}
              fontFamily={'Poppins'}
              fontWeight={"500"}
              color={"#313132"}
              margin={"0 0 10px 0"}
          />
          <Title
              text={'Total'}
              fontSize={12}
              lineHeight={24}
              fontFamily={'Poppins'}
              fontWeight={"500"}
              color={"#313132"}
              margin={"0 0 10px 0"}
          />

          {
            active.payment_method === 'sslcommerz' ?
                <>
                  {
                    ordersAmount?.due > 0 ?
                        <>
                          <Title
                              text={'Partial Payment'}
                              fontSize={12}
                              lineHeight={24}
                              fontFamily={'Poppins'}
                              fontWeight={"500"}
                              color={"#313132"}
                              margin={"0 0 10px 0"}
                          />

                          <Title
                              text={'Due Payment'}
                              fontSize={12}
                              lineHeight={24}
                              fontFamily={'Poppins'}
                              fontWeight={"500"}
                              color={"#313132"}
                              margin={"0 0 10px 0"}
                          />
                        </> : null
                  }
                </> : null
          }
        </div>
        <div>
          <Title
            text={active.payment_method}
            fontSize={14}
            lineHeight={24}
            fontFamily={'Poppins'}
            fontWeight={"500"}
            color={"#313132"}
            margin={"10px 0 0 0"}
          />
          <Title
              text={`BDT ${numberWithCommas(parseFloat(ordersAmount?.get_subtotal).toFixed(2))}`}
              fontSize={12}
              lineHeight={24}
              textAlign={'right'}
              fontFamily={'Poppins'}
              fontWeight={"500"}
              color={"#313132"}
              margin={"0 0 10px 0"}
          />
          <Title
              text={`BDT ${numberWithCommas(parseFloat(ordersAmount?.deliveryfee).toFixed(2))}`}
              fontSize={12}
              lineHeight={24}
              textAlign={'right'}
              fontFamily={'Poppins'}
              fontWeight={"500"}
              color={"#313132"}
              margin={"0 0 10px 0"}
          />
          <Title
              text={`BDT ${numberWithCommas(parseFloat(ordersAmount?.tax).toFixed(2))}`}
              fontSize={12}
              lineHeight={24}
              textAlign={'right'}
              fontFamily={'Poppins'}
              fontWeight={"500"}
              color={"#313132"}
              margin={"0 0 10px 0"}
          />
          <Title
              text={`BDT ${numberWithCommas(parseFloat(ordersAmount?.total).toFixed(2))}`}
              fontSize={12}
              lineHeight={24}
              textAlign={'right'}
              fontFamily={'Poppins'}
              fontWeight={"500"}
              color={"#313132"}
              margin={"0 0 10px 0"}
          />
          {
            active.payment_method === 'sslcommerz' ?
                <>
                  {
                    ordersAmount?.due > 0 ?
                        <>
                          <Title
                              text={`BDT ${numberWithCommas(parseFloat(ordersAmount?.partial_payment).toFixed(2))}`}
                              fontSize={12}
                              lineHeight={24}
                              fontFamily={'Poppins'}
                              fontWeight={"500"}
                              color={"#313132"}
                              margin={"0 0 10px 0"}
                          />

                          <Title
                              text={`BDT ${numberWithCommas(parseFloat(ordersAmount?.due).toFixed(2))}`}
                              fontSize={12}
                              lineHeight={24}
                              fontFamily={'Poppins'}
                              fontWeight={"500"}
                              color={"#313132"}
                              margin={"0 0 10px 0"}
                          />
                        </> : null
                  }
                </> : null
          }
        </div>
      </div>
    </>
  );
};

export default OrderItems;
