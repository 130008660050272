import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '../assets/icons/search.svg'
import {useHistory} from "react-router-dom";
import {connect} from 'react-redux'
import {Form} from 'react-bootstrap'
import Autocomplete from '@material-ui/lab/Autocomplete';
import Typography from "@material-ui/core/Typography";
import TimerMixin from "react-timer-mixin";

const MobileSearchBar = (props) => {
  let {categories, allProducts} = props;

  let featured = categories
    ? categories.length
      ? categories.find((item) => item.slug === "featured-products")
      : null
    : null;

  let id = featured ? featured.term_id : null;

  let featuredProducts = allProducts ? allProducts.length
    ? allProducts.filter((item) => {
      let found = item.category_ids.find((itm) => itm === id);
      if (found) return item;
    })
    : [] : [];

  let options = featuredProducts.length ? featuredProducts.map(item => {
    return item.name
  }) : []

  const classes = useStyles();

  const history = useHistory();

  const [searchText, setSearchText] = useState('')

  const handleText = e => {
    setSearchText(e.target.value)
  }

  const searchSubmit = (event, value) => {
    event.preventDefault();
    TimerMixin.setTimeout(() => {
      history.push(`/search?text=${value}`)
    }, 300)
  }

  return (
    <Form onSubmit={(e) => searchSubmit(e, searchText)}>
      <Autocomplete
        id="free-solo-demo"
        freeSolo
        options={options}
        onChange={(event, newValue) => {
          if (newValue) {
            setSearchText(newValue)
            searchSubmit(event, newValue)
          }
        }}
        renderOption={option => <Typography className={classes.options}>{option}</Typography> }
        renderInput={(params) => {
          const {InputLabelProps, InputProps, ...rest} = params;
          return (
            <Paper component="form" className={classes.root}>
              <InputBase
                {...params.InputProps}
                {...rest}
                className={classes.input}
                placeholder="What Are You Looking For?"
                onChange={handleText}
              />
              <button
                type="submit"
                onClick={(e) => searchSubmit(e, searchText)}
                style={{
                  height: 0,
                  width: 0,
                  opacity: 0,
                  pointerEvents: "none"
                }}
              />
              <div
                className={classes.iconButton}
                onClick={(e) => searchSubmit(e, searchText)}
              >
                <img src={SearchIcon}/>
              </div>
            </Paper>
          )
        }}
      />
    </Form>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: 25,
    height: 40,
    marginTop: 15,
    marginBottom: 5,
    boxShadow: "none",
    backgroundColor: '#F5F5F5',
  },
  input: {
    marginLeft: theme.spacing(3),
    flex: 1,
    fontFamily: 'Poppins',
    fontSize: 12,
    padding: '6px 0'
  },
  iconButton: {
    padding: '0 15px 4px 0',
    cursor: "pointer"
  },
  divider: {
    height: 28,
    margin: 4,
  },
  options: {
    fontSize: 12,
    fontFamily: 'Poppins',
    color: '#313132'
  }
}));

function mapStateToProps(state) {
  return {
    categories: state.homeReducer.categories,
    allProducts: state.homeReducer.allProducts,
  };
}

export default connect(mapStateToProps)(MobileSearchBar);
