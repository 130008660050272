import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import moment from "moment";

const DatePicker = (props) => {
  const classes = useStyles();

  const { handleDateOfBirth, dateOfBirth } = props;

  const [value, setValue] = useState(
    dateOfBirth.length ? dateOfBirth : new Date()
  );

  const onDateChange = (e) => {
    let date = moment(e.target.value).format("YYYY-MM-DD");
    setValue(e.target.value);
    handleDateOfBirth(date);
  };

  return (
    <form className={classes.container} noValidate>
      <TextField
        id="date"
        type="date"
        value={moment(value).format("YYYY-MM-DD")}
        onChange={onDateChange}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{ max: `${moment(new Date()).format("YYYY-MM-DD")}` }}
      />
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    width: "100%",
    fontSize: 14,
    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#707070'
  },
}));

export default DatePicker;
