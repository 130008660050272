import { LEFT_DRAWER } from "../../constants/left-drawer";

let initState = {
  openLeft: "",
};

export default function leftDrawerReducer(state = initState, action) {
  switch (action.type) {
    case LEFT_DRAWER.OPEN_DRAWER:
      return {
        ...state,
        openLeft: action.data,
      };

    default:
      return state;
  }
}
