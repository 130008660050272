import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    DatePicker as CustomDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from "moment";
import styled from "styled-components";

const DatePicker = (props) => {
    const classes = useStyles();

    const {handleDate, searchSlot} = props;

    const tomorrow = new Date();

    tomorrow.setDate(tomorrow.getDate() + 1);

    const [value, setValue] = useState(tomorrow);

    useEffect(() => {
        let date = moment(tomorrow).format("YYYY-MM-DD");
        handleDate(date);
        let data = {
            appoint_date: date,
        };
        searchSlot(data);
    }, []);

    const onDateChange = (e) => {
        // //console.log('event', e)
        let date = moment(e.target.value).format("YYYY-MM-DD");
        setValue(e.target.value);
        handleDate(date);
        let data = {
            appoint_date: date,
        };
        searchSlot(data);
    };

    const onDateChangeUpdated = (value) => {
        let date = moment(value).format("YYYY-MM-DD");
        // //console.log('date',value)
        setValue(value);
        handleDate(date);
        let data = {
            appoint_date: date,
        };
        searchSlot(data);
    };

    function disableWeekends(date) {
        return date.getDay() === 0;
    }

    return (
        <form className={classes.container} noValidate>
            {/*<TextField*/}
            {/*    id="date"*/}
            {/*    label="Date"*/}
            {/*    type="date"*/}
            {/*    value={moment(value).format("YYYY-MM-DD")}*/}
            {/*    onChange={onDateChange}*/}
            {/*    className={classes.textField}*/}
            {/*    InputLabelProps={{*/}
            {/*        shrink: true,*/}
            {/*    }}*/}
            {/*    inputProps={{min: `${moment(tomorrow).format("YYYY-MM-DD")}`}}*/}
            {/*/>*/}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <CustomMuiKeyboardDatePicker
                    format="dd-MM-yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Date"
                    minDate={moment().toDate()}
                    className={classes.textField}
                    value={moment(value).format("YYYY-MM-DD")}
                    onChange={(date) => onDateChangeUpdated(date)}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    shouldDisableDate={disableWeekends}
                />
            </MuiPickersUtilsProvider>
        </form>
    );
};

const CustomMuiKeyboardDatePicker = styled(KeyboardDatePicker)`
  .MuiButtonBase-root{
    margin-right: -12px;
    color: unset;

    .MuiSvgIcon-root{
      width: 20px;
      height: 20px;
    }
  }
`
const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        fontSize: 14,
        fontFamily: 'Poppins',
        marginLeft: 0,
        marginRight: 0,
        width: "100%",

    },
}));

export default DatePicker;
