import axios from "axios";
import config from "../config";

let authToken = Buffer.from(config.CK + ":" + config.CS).toString("Base64");

function getIRequestProp() {
    let serverUrl = config.BASE_URL;
    return {
        serverUrl: serverUrl,
        requestHeader: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
        },
    };
}

function getWRequestProp() {
    let serverUrl = config.BASE_URL;
    return {
        serverUrl: serverUrl,
        requestHeader: {
            "Content-Type": "application/json",
            Authorization: `Basic ${authToken}`,
        },
    };
}

function getAuthRequestProp() {
    let serverUrl = config.BASE_URL;
    return {
        serverUrl: serverUrl,
        requestHeader: {
            "Content-Type": "application/json",
        },
    };
}

function getAuthFormRequestProp() {
    let serverUrl = config.BASE_URL;
    return {
        serverUrl: serverUrl,
        requestHeader: {
            'Content-Type': 'multipart/form-data;boundary=----WebKitFormBoundaryyrV7KO0BoCBuDbTL'
        },
    };
}

async function get(url, parameter, isAuthorizationRequired = true) {
    let {serverUrl, requestHeader} = getIRequestProp();
    /*
       1. isAuthorizationRequired is added, so authorization can be
       sent with only required api request and bypass preflight request
     */
    if (!isAuthorizationRequired) delete requestHeader.Authorization;
    return axios.get(serverUrl + url, {
        params: parameter,
        headers: requestHeader,
    });
}

async function getWc(url, parameter, isAuthorizationRequired = true) {
    let {serverUrl, requestHeader} = getWRequestProp();
    /*
       1. isAuthorizationRequired is added, so authorization can be
       sent with only required api request and bypass preflight request
     */
    if (!isAuthorizationRequired) delete requestHeader.Authorization;
    return axios.get(serverUrl + url, {
        params: parameter,
        headers: requestHeader,
    });
}

async function post(url, body) {
    let {serverUrl, requestHeader} = getIRequestProp();
    return await axios.post(serverUrl + url, body, {
        headers: requestHeader,
    });
}

async function postWc(url, body) {
    let {serverUrl, requestHeader} = getWRequestProp();
    return await axios.post(serverUrl + url, body, {
        headers: requestHeader,
    });
}

async function postAuthOne(url, body) {
    let {serverUrl, requestHeader} = getAuthRequestProp();
    return await axios.post(serverUrl + url + '?oauth_consumer_key=ck_ffd4fde9c7f9008eaf5171d56e31826b50d951af&oauth_signature_method=HMAC-SHA1&oauth_timestamp=1635672978&oauth_nonce=z3NMAWq7SnH&oauth_version=1.0&oauth_signature=GLxxEnYqJxI65xJGDJF0R%2BIpkss%3D',
        body, {
            headers: requestHeader,
        });
}

async function postAuthFormData(url, body) {
    let {serverUrl, requestHeader} = getAuthFormRequestProp();
    return await axios.post(serverUrl + url + '?oauth_consumer_key=ck_ffd4fde9c7f9008eaf5171d56e31826b50d951af&oauth_signature_method=HMAC-SHA1&oauth_timestamp=1635672978&oauth_nonce=z3NMAWq7SnH&oauth_version=1.0&oauth_signature=GLxxEnYqJxI65xJGDJF0R%2BIpkss%3D',
        body, {
            headers: requestHeader,
        });
}

async function put(url, body) {
    let {serverUrl, requestHeader} = getIRequestProp();
    return await axios.put(serverUrl + url, body, {
        headers: requestHeader,
    });
}

async function patch(url, body) {
    let {serverUrl, requestHeader} = getIRequestProp();
    return await axios.patch(serverUrl + url, body, {
        headers: requestHeader,
    });
}

async function remove(url, body) {
    let {serverUrl, requestHeader} = getIRequestProp();
    return await axios.delete(serverUrl + url, {
        data: body,
        headers: requestHeader,
    });
}

export const AxiosServices = {
    get,
    post,
    put,
    patch,
    remove,
    getWc,
    postWc,
    postAuthOne,
    postAuthFormData
};
