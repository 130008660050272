import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import SendIcon from "../assets/icons/sendIcon.svg";

export default function CustomizedInputBase(props) {
  const classes = styles();

  const { email, handleEmail, handleSubmit } = props;

  return (
    <Paper component="form" className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder="Your Email"
        value={email}
        onChange={handleEmail}
      />
      <div className={classes.iconButton} onClick={handleSubmit}>
        <img className={classes.iconImage} src={SendIcon} alt="" />
      </div>
    </Paper>
  );
}

const styles = makeStyles((theme) => ({
  root: {
    padding: "2px 0",
    display: "flex",
    alignItems: "center",
    width: "100%",
    borderRadius: 25,
    border: "1px solid #313132",
    height: 30,
    boxShadow: "none",
    backgroundColor: "#EFEFEF",
    [theme.breakpoints.down(767)]: {
      height: 40,
    },
  },
  input: {
    marginLeft: theme.spacing(2),
    flex: 1,
    fontFamily: "Poppins",
    fontSize: 12,
    padding: "6px 0",
  },
  iconButton: {
    padding: "2px 12px 4px 0",
    cursor: "pointer",
  },
  iconImage: {
    height: "13.01px !important",
    width: "13.01px !important",
    color: "#707070",
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));
