import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { PageAnimation } from "../../components/PageAnimation";
import StoryList from "./components/StoryList";
import { connect } from "react-redux";
import {fetchBrand, fetchHomeBannerSlider, fetchProductData, getAllPosts} from "../../redux/actions/home";

const Blog = (props) => {
  const { posts,getAllPosts } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllPosts();
  }, []);

  const stories = posts
    ? posts.length
      ? posts.filter((item, key) => item.category[key].slug === "blog")
      : []
    : [];
  // //console.log('blog post data',posts)
  return (
    <motion.div
      exit="exit"
      animate="show"
      variants={PageAnimation}
      initial="hidden"
    >
      <StoryList stories={stories} />
    </motion.div>
  );
};

function mapStateToProps(state) {
  return {
    posts: state.homeReducer.posts,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAllPosts: () => dispatch(getAllPosts()),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Blog);
