import React from "react";
import Text from "./Text";
import IconButton from '@material-ui/core/IconButton';
import styled from "styled-components";
import {Container, Row} from "react-bootstrap";
import close from '../assets/images/close-black.svg'

const TopBar = () =>{

    return(
        <StyledTopBar>
           <Container className="container">
               <Row>
                   <div className="text">Now offering all of our services virtually</div>
                   <IconButton
                       aria-label="menu"
                       style={{ marginLeft: "auto"}}
                   >
                       <img src={close} alt={'close'} style={{ height: 10, width: 10}}/>
                   </IconButton>
               </Row>
           </Container>
        </StyledTopBar>
    )

}

const StyledTopBar = styled.div`
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  align-items: center;
  display: flex;
  transition: background-color .3s ease;
  background-color: #fff;
  .container {
    max-width: 92%;
  }
  .row {
    height: 40px;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e2e2e2;
  }
  .text {
    color: rgb(0, 0, 0);
    font-weight: 300;
    font-size: 14px;
    width: 96%;
    text-align: center;
  }
`

export default TopBar
