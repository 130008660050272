import { PRE_BOOK } from "../../constants/prebooking";

export function searchSlot(data) {
  return {
    type: PRE_BOOK.SEARCH_SLOT.MAIN,
    data,
  };
}

export function submitSlot(data,appointment_type) {
  return {
    type: PRE_BOOK.SUBMIT_SLOT.MAIN,
    data,
    appointment_type
  };
}

export function submitSlotExtra() {
  return {
    type: PRE_BOOK.SUBMIT_SLOT.SUCCESS,
    result: null
  };
}

export function clearSubmit() {
  return {
    type: PRE_BOOK.CLEAR_SUBMIT,
  };
}

export function handlePreBook(open, id) {
  return {
    type: PRE_BOOK.HANDLE_PREBOOK,
    open,
    id,
  };
}

export function clearSlot() {
  return {
    type: PRE_BOOK.CLEAR_SLOT,
  };
}
