import React from 'react';
import Login from './components/signin';
import SignUp from './components/signup';
import ForgetPassword from "./components/forget-password";
import VerifyCode from './components/verfiy-code'
import ChangePassword from './components/change-password'
import AuthDrawer from "./components/AuthDrawer";
import SendCode from "./components/SendCode";

let AuthModal = props => {
    return (
        <>
            <Login/>
            <SendCode/>
            <SignUp/>
            <ForgetPassword/>
            <VerifyCode/>
            <ChangePassword/>
            <AuthDrawer/>
        </>
    )
}

export default AuthModal
