import React, {useEffect} from "react";
import styled from "styled-components";
import Title from "../../components/Title";
import {Link, useLocation, withRouter} from "react-router-dom";
import {clearCheckout, setTempOrder} from "../../redux/actions/rightDrawer";
import {initNewOrder, paymentFailed} from "../../redux/actions/orders";
import {connect} from "react-redux";

function useQuery() {
    const {search} = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

let PaymentFailure = (props) => {

    let query = useQuery();
    let order_id = query.get('order_id');

    useEffect(()=>{
        let data = {
            order_id: order_id
        }
        props.paymentFailed(data)
    },[order_id])

    return (
        <StyledSuccess>
            <div className="main">
                <Title
                    text={"Order Placed. Payment is pending."}
                    fontWeight={"bold"}
                    fontSize={24}
                    lineHeight={32}
                    margin={"30px 0 10px 0"}
                    textAlign={"center"}
                />
                <Link to={"/"}>
                    <Title
                        text={`Return to Home`}
                        fontWeight={400}
                        fontSize={18}
                        lineHeight={24}
                        margin={"0 0 10px 0"}
                        textAlign={"center"}
                        color={"#419AE8"}
                    />
                </Link>
            </div>
        </StyledSuccess>
    );
};

let StyledSuccess = styled.div`
  background-color: #f8f8f8;
  padding: 120px 40px;
  height: 120vh;

  .main {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      height: 50px;
      width: 50px;
    }
  }
`;


function mapStateToProps(state) {
    return {
        newOrder: state.orderReducer.newOrder,
        authData: state.authReducer.authData,
        tempOrder: state.rightDrawerReducer.tempOrder,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        clearCheckout: () => dispatch(clearCheckout()),
        initNewOrder: () => dispatch(initNewOrder()),
        setTempOrder: (data) => dispatch(setTempOrder(data)),
        paymentFailed: (data) => dispatch(paymentFailed(data))
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PaymentFailure)
);
