import React, {useEffect, useState} from "react";
import { motion } from "framer-motion";
import { PageAnimation } from "../../components/PageAnimation";
import StoryList from "./components/StoryList";
import { connect } from "react-redux";
import {getWebSeries, getWebSeriesCategory, getWebSeriesLoadMore} from "../../redux/actions/home";

const WebSeries = (props) => {
  const {getWebSeries,getWebSeriesLoadMore, webSeries,
    webSeriesCat, posts,loading,loadMoreLoading, getWebSeriesCategory } = props;
  const [number, setNumber] = useState(1)

  useEffect(() => {
    window.scrollTo(0, 0);
    let params = {
      "page": 1,
      "Limit": 6
    }
    setNumber(1)
    getWebSeries(params, []);
  }, []);

  let handlerClick = (slug) =>{
    window.scrollTo(0, 0);

    let params = {
      "page": 1,
      "Limit": 6,
      "cat": slug
    }
    setNumber(1)
    getWebSeriesLoadMore(params, []);

    let selectedVariant = []
    let variants = webSeriesCat;
    for (let i = 0; i < variants.length; i++) {
      let variant = variants[i];
      let data;
      if (variant.category_slug === slug) {
        data = {
          category_name: variant.category_name,
          category_slug: variant.category_slug,
          is_selected: true
        }
        selectedVariant.push(data)
      } else {
        data = {
          category_name: variant.category_name,
          category_slug: variant.category_slug,
          is_selected: false
        }
        selectedVariant.push(data)
      }
    }
    //console.log("selectedVariant",selectedVariant)
    getWebSeriesCategory(selectedVariant)
  }

  useEffect(()=>{
    const findSelectedCat = child_cat.find(element => element.is_selected);
    let params = {
      "page": number,
      "Limit": 6,
      "cat": findSelectedCat?.category_slug
    }
    if (number > 1) {
      getWebSeriesLoadMore(params, posts);
    }
  }, [number])

  const stories = posts
    ? posts.length
      ? posts
      : []
    : [];

  const child_cat = webSeriesCat
    ? webSeriesCat.length
          ? webSeriesCat
          : []
      : [];

   return (
    <motion.div
      exit="exit"
      animate="show"
      variants={PageAnimation}
      initial="hidden"
    >
      <StoryList stories={stories}
                 child_cat={child_cat}
                 total={webSeries?.total}
                 handlerClick={handlerClick}
                 number={number}
                 setNumber={setNumber}
                 loading={loading}
                 loadMoreLoading={loadMoreLoading}
      />
    </motion.div>
  );
};

function mapStateToProps(state) {
  return {
    webSeries: state.homeReducer.webSeries,
    posts: state.homeReducer.webSeriesPost,
    webSeriesCat: state.homeReducer.webSeriesCat,
    loading: state.homeReducer.webSeriesLoading,
    loadMoreLoading: state.homeReducer.loadMoreLoading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getWebSeries: (params,prev_data) => dispatch(getWebSeries(params,prev_data)),
    getWebSeriesLoadMore: (params,prev_data) => dispatch(getWebSeriesLoadMore(params,prev_data)),
    getWebSeriesCategory: (category) => dispatch(getWebSeriesCategory(category))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(WebSeries);
