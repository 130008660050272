import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import bannerProduct from "../../../assets/images/banner-all-product.png";
import aboutUsImage from "../../../assets/images/about-us.png";
import mission from "../../../assets/images/mission.png";
import Divider from "@material-ui/core/Divider";
import Title from "../../../components/Title";
import Text from "../../../components/Text";
import { fetchFeaturedProducts } from "../../../redux/actions/home";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Story from "./Story";
import { loadMore } from "../../../helpers/globalFunc";
import Product from "../../home/components/Product";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 2,
    width: 170,
    marginBottom: 13,
    marginTop: 8,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#989899",
  },
}))(LinearProgress);

const StoryList = (props) => {
  let { stories } = props;

  let [last, setLast] = useState(6);

  let [storyList, setStoryList] = useState([]);

  const handleLoad = () => {
    let retData = loadMore(stories, last, 6);

    setStoryList(retData.retArr);

    setLast(retData.len);
  };

  //console.log('Stories', stories)

  useEffect(() => {
    if (stories.length) {
      setStoryList(stories.slice(0, last));
    }
  }, [stories]);

  return (
    <StyledStoryList>
      <Container>
        <Row className="change-view">
          <Col>
            <div
              style={{ display: "flex", marginTop: 120, alignItems: "center" }}
            >
              <div className="text-breadcrumb">Product</div>
              <div className="text-breadcrumb-point" />
              <div className="text-breadcrumb">Blogs</div>
            </div>
            <Divider style={{ marginTop: 6, marginBottom: 30 }} />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="subTitle">News & Blogs</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 24,
              }}
            >
              <div className="top-title">Blogs</div>
            </div>
          </Col>
        </Row>
        <Row>
          {storyList.length
            ? storyList.map((item, key) => {
                return (
                  <Col key={key} lg={4} md={4} sm={6} className="column-cl">
                    <Story item={item} />
                  </Col>
                );
              })
            : null}
        </Row>
        <Row>
          {stories.length ? (
            <Col>
              <center>
                <Text
                  text={`Showing ${storyList.length} of ${stories.length}`}
                  fontWeight={"normal"}
                  fontFamily={"Renner, sans-serif"}
                  fontSize={10}
                />
                <BorderLinearProgress variant="determinate" value={50} />
                <div className="read-more" onClick={() => handleLoad()}>
                  <div className="load-button">Load more</div>
                </div>
              </center>
            </Col>
          ) : null}
        </Row>
      </Container>
    </StyledStoryList>
  );
};

const StyledStoryList = styled.div`
  padding-bottom: 64px;
  min-height: 60vh;

  .container {
    max-width: 95%;
    @media (max-width: 991px) {
      max-width: 100%;
      padding: 20px;
    }
  }
  .text-breadcrumb {
    font-weight: normal;
    font-size: 10px;
    color: #313132;
    font-family: Renner, sans-serif;
  }

  .text-breadcrumb-point {
    width: 3px;
    height: 3px;
    color: #313132;
    border-radius: 50%;
    background-color: #313132;
    margin-right: 8px;
    margin-left: 8px;
  }

  .change-view {
    display: block;
    @media (max-width: 991px) {
      display: none;
    }
  }

  .subTitle {
    font-family: Renner, sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom: 2px;
    margin-top: 10px;
    color: rgb(156, 148, 52);
    @media (max-width: 991px) {
      margin-top: 120px;
    }
  }

  .top-title {
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 0px;
    padding-bottom: 8px;
    color: #313132;
  }

  .subTitle-text {
    font-family: Renner, sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 0px;
    padding-bottom: 2px;
    margin-top: 55px;
    color: rgb(156, 148, 52);
  }

  .top-title-text {
    font-size: 24px;
    font-weight: 500;
    font-family: Renner,sans-serif;
    text-transform: uppercase;
    padding-left: 0px;
    padding-bottom: 8px;
    color: #313132;
  }

  .top-title-text-bold {
    font-size: 18px;
    font-weight: bold;
    font-family: Renner,sans-serif;
    padding-left: 0px;
    padding-bottom: 8px;
    color: #313132;
  }

  .top-title-text-body {
    font-size: 14px;
    font-weight: normal;
    font-family: Renner, sans-serif;
    padding-left: 0px;
    padding-bottom: 8px;
    color: #313132;
  }

  .column-cl {
    margin-bottom: 30px;

    .container {
      max-width: 100%;
    }
  }

  .read-more {
    background-color: #fff;
    border-radius: 50px;
    border: 1px solid #313132;
    cursor: pointer;
    text-align: center;
    width: 76px;

    .load-button {
      color: #313132;
      font-size: 10px;
      font-weight: normal;
      font-family: Renner, sans-serif;
      padding: 3px 8px 3px 8px;
    }
    &:hover {
      border: 1px solid #9e6c51;
      background-color: #9e6c51;
      .load-button {
        color: #ffffff;
      }
    }
  }
`;

export default StoryList;
