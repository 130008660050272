import { takeLatest, put, call } from "redux-saga/effects";
import Logger from "../../../helpers/logger";
import { AxiosServices } from "../../../network/AxiosServices";
import { ApiServices } from "../../../network/ApiServices";
import { RIGHT_DRAWER } from "../../constants/right-drawer";

function* addServerCart(actions) {
  try {
    const result = yield call(
      AxiosServices.post,
      ApiServices.ADD_CART,
      actions.data
    );

    yield put({
      type: RIGHT_DRAWER.ADD_CART_SERVER.SUCCESS,
      result: result.data,
      slug: actions.slug
    });
  } catch (err) {
    Logger(err);

    yield put({
      type: RIGHT_DRAWER.ADD_CART_SERVER.FAILURE,
    });
  }
}

function* updateCart(actions) {
  try {
    const result = yield call(
      AxiosServices.post,
      ApiServices.UPDATE_CART,
      actions.data
    );

    let clearReq = {
      product_id: actions.data.product,
      count: 0,
    };

    yield put({
      type: RIGHT_DRAWER.UPDATE_CART.SUCCESS,
      result: result.data,
      req: clearReq,
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: RIGHT_DRAWER.UPDATE_CART.FAILURE,
    });
  }
}

function* fetchAllCart(actions) {
  try {
    const result = yield call(
      AxiosServices.post,
      ApiServices.FETCH_ALL_CART,
      actions.data
    );

    yield put({
      type: RIGHT_DRAWER.FETCH_ALL_CART_ITEMS.SUCCESS,
      result: result.data,
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: RIGHT_DRAWER.FETCH_ALL_CART_ITEMS.FAILURE,
    });
  }
}

function* mergeCart(actions) {
  try {
    const result = yield call(
      AxiosServices.post,
      ApiServices.MERGE_CART,
      actions.data
    );
    //console.log(result.data, "================>>")
    yield put({
      type: RIGHT_DRAWER.MERGE_CART_ITEMS.SUCCESS,
      result: result.data,
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: RIGHT_DRAWER.MERGE_CART_ITEMS.FAILURE,
    });
  }
}

function* clearCart(actions) {
  try {
    const result = yield call(
      AxiosServices.post,
      ApiServices.CLEAR_CART,
      actions.data
    );

    yield put({
      type: RIGHT_DRAWER.CLEAR_CART_SERVER.SUCCESS,
      result: result.data,
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: RIGHT_DRAWER.CLEAR_CART_SERVER.FAILURE,
    });
  }
}

function* addWishlistServer(actions) {
  try {
    const result = yield call(
      AxiosServices.post,
      ApiServices.ADD_WISHLIST,
      actions.data
    );

    yield put({
      type: RIGHT_DRAWER.ADD_WISHLIST_SERVER.SUCCESS,
      result: result.data,
      slug: actions.slug
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: RIGHT_DRAWER.ADD_WISHLIST_SERVER.FAILURE,
    });
  }
}

function* removeWishlistServer(actions) {
  try {
    const result = yield call(
      AxiosServices.post,
      ApiServices.REMOVE_WISHLIST,
      actions.data
    );

    yield put({
      type: RIGHT_DRAWER.REMOVE_WISHLIST_SERVER.SUCCESS,
      result: actions.data,
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: RIGHT_DRAWER.REMOVE_WISHLIST_SERVER.FAILURE,
    });
  }
}

function* getAllWishlist(actions) {
  try {
    const result = yield call(
      AxiosServices.post,
      ApiServices.FETCH_WISHLIST,
      actions.data
    );

    yield put({
      type: RIGHT_DRAWER.FETCH_ALL_WISHLIST.SUCCESS,
      result: result.data,
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: RIGHT_DRAWER.FETCH_ALL_WISHLIST.FAILURE,
    });
  }
}

function* clearWishlistServer(actions) {
  try {
    const result = yield call(
      AxiosServices.post,
      ApiServices.CLEAR_WISHLIST,
      actions.data
    );

    yield put({
      type: RIGHT_DRAWER.CLEAR_ALL_WISHLIST.SUCCESS,
      result: result.data,
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: RIGHT_DRAWER.CLEAR_ALL_WISHLIST.FAILURE,
    });
  }
}

function* mergeWishlist(actions) {
  try {
    const result = yield call(
      AxiosServices.post,
      ApiServices.MERGE_WISHLIST,
      actions.data
    );

    //console.log(result.data, "-================>> merge")

    yield put({
      type: RIGHT_DRAWER.MERGE_WISHLIST.SUCCESS,
      result: result.data,
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: RIGHT_DRAWER.MERGE_WISHLIST.FAILURE,
    });
  }
}

export default function* rightDrawerSagas() {
  yield takeLatest(RIGHT_DRAWER.ADD_CART_SERVER.MAIN, addServerCart);
  yield takeLatest(RIGHT_DRAWER.UPDATE_CART.MAIN, updateCart);
  yield takeLatest(RIGHT_DRAWER.MERGE_CART_ITEMS.MAIN, mergeCart);
  yield takeLatest(RIGHT_DRAWER.FETCH_ALL_CART_ITEMS.MAIN, fetchAllCart);
  yield takeLatest(RIGHT_DRAWER.CLEAR_CART_SERVER.MAIN, clearCart);
  yield takeLatest(RIGHT_DRAWER.ADD_WISHLIST_SERVER.MAIN, addWishlistServer);
  yield takeLatest(
    RIGHT_DRAWER.REMOVE_WISHLIST_SERVER.MAIN,
    removeWishlistServer
  );
  yield takeLatest(RIGHT_DRAWER.FETCH_ALL_WISHLIST.MAIN, getAllWishlist);
  yield takeLatest(RIGHT_DRAWER.CLEAR_ALL_WISHLIST.MAIN, clearWishlistServer);
  yield takeLatest(RIGHT_DRAWER.MERGE_WISHLIST.MAIN, mergeWishlist);
}
