import React from "react";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { CounterBox, CounterButton, CounterValue } from "../counterStyle";
import { updateCart } from "../../../redux/actions/rightDrawer";
import { connect } from "react-redux";

const Counter = ({
  onDecrement,
  onIncrement,
  value,
  item,
  variant,
  className,
  authData,
  updateCart,
}) => {
  const userId = authData ? (authData.id ? authData.id : null) : null;

  let addItem = () => {
    if (userId) {
      const newData = {
        user_id: userId,
        product: item.id,
        count: 1,
        flag: true,
      };
      updateCart(newData);
    } else {
      onIncrement(item);
    }
  };

  let removeItem = () => {
    if (userId) {
      const newData = {
        user_id: userId,
        product: item.id,
        count: 1,
        flag: false,
      };
      updateCart(newData);
    } else {
      let data = {
        count: 1,
        id: item.id,
      };
      onDecrement(data);
    }
  };

  return (
    <CounterBox variant={variant} className={className}>
      <div onClick={removeItem} style={{ cursor: "pointer" }}>
        <CounterButton variant={variant}>
          <RemoveIcon style={{ width: 16 }} />
        </CounterButton>
      </div>
      <CounterValue>{value < 10 ? `0${value}` : value}</CounterValue>
      <div onClick={addItem} style={{ cursor: "pointer" }}>
        <CounterButton variant={variant}>
          <AddIcon style={{ width: 16 }} />
        </CounterButton>
      </div>
    </CounterBox>
  );
};

function mapStateToProps(state) {
  return {
    authData: state.authReducer.authData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateCart: (data) => dispatch(updateCart(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Counter);
