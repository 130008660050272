import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Title from './Title'
import {withStyles} from "@material-ui/core";

const bookingStyle = theme => ({
  iconText: {
    color: '#fff',
    fontSize: 14,
    marginLeft: 10
  },
  booking: {
    backgroundColor: '#313132',
    margin: '15px 20px',
    borderRadius: '25px',
    cursor: "pointer",
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: 2,
    height: 40
  },
  priceButton: {
    backgroundColor: '#ffffff',
    borderRadius: '25px',
    width: 100,
    height: '98%',
    textAlign: 'center'
  },
  buttonIcon: {
    position: 'absolute',
    right: 36,
    [theme.breakpoints.down("sm")]:{
      right: 24
    }
  },
  loader: {
    color: 'white',
    position: 'absolute',
    marginTop: '0.75%'
  }
})

let style = theme => bookingStyle(theme);

let NewButton = props => {
  let {classes, loading, handleSubmit, price} = props;
  return (
    <div className={classes.booking} onClick={handleSubmit}>
      <IconButton
        className={classes.iconText}
        aria-label="directions"
      >
        Checkout
      </IconButton>
      <div className={classes.priceButton}>
        <Title
          text={`BDT ${price}`}
          color={'#313132'}
          fontSize={14}
          lineHeight={20}
          fontFamily={'Poppins'}
          // fontWeight={'600'}
          textAlign={'center'}
          margin={'8px 0 0 0'} //margin={'8px 0 0 0'}
        />
      </div>
    </div>

  )
}

export default withStyles(style)(NewButton)

