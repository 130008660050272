import React from 'react';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';

const Title = (
    {
        text,
        textAlign,
        fontSize,
        fontWeight,
        fontFamily,
        color,
        letterSpacing,
        lineHeight,
        textTransform,
        margin
    }) => {

  return (
    <VisibilitySensor partialVisibility={{top: 0, bottom: 60}}>
      {({isVisible}) =>
        <StyledTitle
          className={`${isVisible ? 'anim-active' : ''} fade-up title`}
          fontSize={fontSize}
          fontWeight={fontWeight}
          color={color}
          fontFamily={fontFamily}
          lineHeight={lineHeight}
          letterSpacing={letterSpacing}
          textTransform={textTransform}
          margin={margin}
          textAlign={textAlign}
        >
          {text}
        </StyledTitle>
      }
    </VisibilitySensor>
  )
};


const StyledTitle = styled.h2`
  font-size: ${props => props.fontSize || 42}px;
  font-weight: ${props => props.fontWeight || '500'};
  font-family: ${props => props.fontFamily || 'Renner,sans-serif'};
  margin: ${props => props.margin || '0px'};;
  line-height: ${props => props.lineHeight || 42}px;
  text-align: ${props => props.textAlign || null};
  color: ${props => props.color || '#000000'};
  text-transform: ${props => props.textTransform || 'capitalize'};


  //@media(max-width:767px){
  //  font-size: 24px;
  //  line-height: 32px;
  //  margin-bottom: 30px;
  //}
`;


export default Title;
