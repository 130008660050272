import React, { useState, useEffect } from "react";
import Title from "../../../components/Title";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import moment from "moment";

import InformationField from "./information-field";
import ContactField from "./contact-field";
import UpdatePassword from "./update-password";

const UpdateAccount = (props) => {
  const classes = useStyles();
  const {
    profileData,
    updateUser,
    userId,
    userToken,
    profileDataLoading,
    updatePassword,
    passwordReset,
    passwordResetLoading,
    resetPassInit
  } = props;

  const [personal, setPersonal] = useState(false);
  const [contact, setContact] = useState(false);
  const [passwordChange, setPasswordChange] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");

  useEffect(() => {
    if (profileData) {
      if (profileData.first_name) setFirstName(profileData.first_name);
      if (profileData.last_name) setLastName(profileData.last_name);
      if (profileData.username) setPhone(profileData.username);
      if (profileData.date_of_birth) setDateOfBirth(profileData.date_of_birth);
      if (profileData.email) setEmail(profileData.email);
      if (profileData.gender) setGender(profileData.gender);
    }
  }, []);

  const handlePersonal = () => {
    setPersonal(!personal);
  };

  const handleContact = () => {
    setContact(!contact);
  };

  const handlePasswordChange = () => {
    setPasswordChange(!passwordChange);
  };

  const handleFirstName = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastName = (e) => {
    setLastName(e.target.value);
  };

  const handlePhone = (e) => {
    setPhone(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleDateOfBirth = (value) => {
    setDateOfBirth(value);
  };

  const handleGender = (value) => {
    setGender(value);
  };

  return (
    <div className={classes.main}>
      <Card className={classes.root}>
        <CardContent className={classes.cardContent}>
          <div className="edit-address">
            <Title
              text={"Personal Information"}
              color={"#313132"}
              fontSize={16}
              lineHeight={24}
              fontWeight={"500"}
              textTransform={"uppercase"}
              fontFamily={"Poppins"}
            />
            {personal ? (
              <div
                onClick={handlePersonal}
                style={{
                  marginLeft: "auto",
                  backgroundColor: "#313132",
                  padding: "5px 18px 5px 18px",
                  borderRadius: "50px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    color: "#fff",
                    fontSize: 12,
                    fontWeight: 400,
                    fontFamily: "Poppins",
                  }}
                >
                  Close
                </div>
              </div>
            ) : (
              <div
                onClick={handlePersonal}
                style={{
                  marginLeft: "auto",
                  backgroundColor: "#EFEFEF",
                  padding: "5px 18px 5px 18px",
                  borderRadius: "50px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    color: "#313132",
                    fontSize: 12,
                    fontWeight: 400,
                    fontFamily: "Poppins",
                  }}
                >
                  Edit
                </div>
              </div>
            )}
          </div>
          {personal ? (
            <InformationField
              firstName={firstName}
              lastName={lastName}
              gender={gender}
              dateOfBirth={dateOfBirth}
              handleFirstName={handleFirstName}
              handleLastName={handleLastName}
              handleDateOfBirth={handleDateOfBirth}
              handleGender={handleGender}
              handleCancel={handlePersonal}
              updateUser={updateUser}
              userId={userId}
              profileDataLoading={profileDataLoading}
            />
          ) : (
            <>
              <Title
                text={
                  firstName.length && lastName.length
                    ? firstName + " " + lastName
                    : "No First Name and Last Names Added"
                }
                color={"#313132"}
                fontSize={14}
                lineHeight={22}
                fontWeight={"400"}
                margin={"30px 0 10px 0"}
                fontFamily={"Poppins"}
              />
              <Title
                text={moment(dateOfBirth).format("DD-MM-YYYY")}
                color={"#313132"}
                fontSize={14}
                lineHeight={22}
                fontWeight={"400"}
                margin={"10px 0"}
                fontFamily={"Poppins"}
              />
              <Title
                text={gender}
                color={"#313132"}
                fontSize={14}
                lineHeight={22}
                fontWeight={"400"}
                margin={"10px 0 30px 0"}
                fontFamily={"Poppins"}
                textTransform={"capitalize"}
              />
            </>
          )}

          <div style={{backgroundColor: 'rgba(122,121,121,0.1)', height: 2, width: '100%', marginBottom: 20, marginTop: 20}}/>

          <div className="edit-address">
            <Title
              text={"Contact Information"}
              color={"#313132"}
              fontSize={16}
              lineHeight={24}
              fontWeight={"500"}
              textTransform={"uppercase"}
              fontFamily={"Poppins"}
            />
            {contact ? (
              <div
                onClick={handleContact}
                style={{
                  marginLeft: "auto",
                  backgroundColor: "#313132",
                  padding: "5px 18px 5px 18px",
                  borderRadius: "50px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    color: "#fff",
                    fontSize: 12,
                    fontWeight: 400,
                    fontFamily: "Poppins",
                  }}
                >
                  Close
                </div>
              </div>
            ) : (
              <div
                onClick={handleContact}
                style={{
                  marginLeft: "auto",
                  backgroundColor: "#EFEFEF",
                  padding: "5px 18px 5px 18px",
                  borderRadius: "50px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    color: "#313132",
                    fontSize: 12,
                    fontWeight: 400,
                    fontFamily: "Poppins",
                  }}
                >
                  Edit
                </div>
              </div>
            )}
          </div>
          {contact ? (
            <ContactField
              phone={phone}
              email={email}
              handlePhone={handlePhone}
              handleEmail={handleEmail}
              handleCancel={handleContact}
              updateUser={updateUser}
              userId={userId}
              profileData={profileData}
              profileDataLoading={profileDataLoading}
            />
          ) : (
            <>
              <Title
                text={"+88" + phone}
                color={"#313132"}
                fontSize={14}
                lineHeight={22}
                fontWeight={"400"}
                margin={"30px 0 10px 0"}
                fontFamily={"Poppins"}
              />
              <Title
                text={email.length ? email : "No Emails available"}
                color={"#313132"}
                fontSize={14}
                lineHeight={22}
                fontWeight={"400"}
                margin={"10px 0 30px 0"}
                fontFamily={"Poppins"}
                textTransform={"none"}
              />
            </>
          )}

          <div style={{backgroundColor: 'rgba(122,121,121,0.1)', height: 2, width: '100%', marginBottom: 20, marginTop: 20}}/>

          <div className="edit-address">
            <Title
              text={"Password"}
              color={"#313132"}
              fontSize={16}
              lineHeight={24}
              fontWeight={"500"}
              textTransform={"uppercase"}
              fontFamily={"Poppins"}
            />
            {passwordChange ? (
              <div
                onClick={handlePasswordChange}
                style={{
                  marginLeft: "auto",
                  backgroundColor: "#313132",
                  padding: "5px 18px 5px 18px",
                  borderRadius: "50px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    color: "#fff",
                    fontSize: 12,
                    fontWeight: 400,
                    fontFamily: "Poppins",
                  }}
                >
                  Close
                </div>
              </div>
            ) : (
              <div
                onClick={handlePasswordChange}
                style={{
                  marginLeft: "auto",
                  backgroundColor: "#EFEFEF",
                  padding: "5px 18px 5px 18px",
                  borderRadius: "50px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    color: "#313132",
                    fontSize: 12,
                    fontWeight: 400,
                    fontFamily: "Poppins",
                  }}
                >
                  Edit
                </div>
              </div>
            )}
          </div>
          {passwordChange ? (
            <UpdatePassword
              handleCancel={handlePasswordChange}
              userId={userId}
              userToken={userToken}
              updatePassword={updatePassword}
              passwordReset={passwordReset}
              loading={passwordResetLoading}
              resetPassInit={resetPassInit}
            />
          ) : (
            <>
              <Title
                text={"********"}
                color={"#313132"}
                fontSize={14}
                lineHeight={22}
                fontWeight={"400"}
                margin={"30px 0 10px 0"}
                fontFamily={"Poppins"}
              />
            </>
          )}
          <div style={{backgroundColor: 'rgba(122,121,121,0.1)', height: 2, width: '100%', marginBottom: 20, marginTop: 20}}/>
        </CardContent>
      </Card>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    margin: "10px 17px",
    [theme.breakpoints.down(992)]: {
      margin: "10px 0",
    },
  },
  root: {
    minWidth: 275,
    marginBottom: 17,
    boxShadow: "0px 0px 10px #0000000D",
  },
  cardContent: {
    padding: "30.5px",
    [theme.breakpoints.down(992)]: {
      padding: 10,
    },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default UpdateAccount;
