import { RIGHT_DRAWER } from "../../constants/right-drawer";

export function handleRightDrawer(data) {
  return {
    type: RIGHT_DRAWER.OPEN_DRAWER,
    data,
  };
}

export function initCartList() {
  return {
    type: RIGHT_DRAWER.INIT_CARTLIST,
  };
}

export function setTempOrder(data) {
  return {
    type: RIGHT_DRAWER.TEMP_ORDER,
    data,
  };
}

export function addItemToCart(data) {
  return {
    type: RIGHT_DRAWER.ADD_ITEM_TO_CART,
    data,
  };
}

export function addCartServer(data, slug) {
  return {
    type: RIGHT_DRAWER.ADD_CART_SERVER.MAIN,
    data,slug
  };
}

export function updateCart(data) {
  return {
    type: RIGHT_DRAWER.UPDATE_CART.MAIN,
    data,
  };
}

export function fetchAllCart(data) {
  return {
    type: RIGHT_DRAWER.FETCH_ALL_CART_ITEMS.MAIN,
    data,
  };
}

export function mergeCart(data) {
  return {
    type: RIGHT_DRAWER.MERGE_CART_ITEMS.MAIN,
    data,
  };
}

export function clearCartServer(data) {
  return {
    type: RIGHT_DRAWER.CLEAR_CART_SERVER.MAIN,
    data,
  };
}

export function removeItemFromCart(data) {
  return {
    type: RIGHT_DRAWER.REMOVE_ITEM_FROM_CART,
    data,
  };
}

export function clearCart() {
  return {
    type: RIGHT_DRAWER.CLEAR_CART,
  };
}

export function addItemToWishlist(data) {
  return {
    type: RIGHT_DRAWER.ADD_ITEM_TO_WISHLIST,
    data,
  };
}

export function removeItemFromWishlist(data) {
  return {
    type: RIGHT_DRAWER.REMOVE_ITEM_FROM_WISHLIST,
    data,
  };
}

export function clearWishlist() {
  return {
    type: RIGHT_DRAWER.CLEAR_WISHLIST,
  };
}

export function setCheckout(data) {
  return {
    type: RIGHT_DRAWER.SET_CHECKOUT,
    data,
  };
}

export function clearCheckout() {
  return {
    type: RIGHT_DRAWER.CLEAR_CHECKOUT,
  };
}

export function addWishlistServer(data, slug) {
  return {
    type: RIGHT_DRAWER.ADD_WISHLIST_SERVER.MAIN,
    data,slug
  };
}

export function removeWishlistServer(data) {
  return {
    type: RIGHT_DRAWER.REMOVE_WISHLIST_SERVER.MAIN,
    data,
  };
}

export function getAllWishlist(data) {
  return {
    type: RIGHT_DRAWER.FETCH_ALL_WISHLIST.MAIN,
    data,
  };
}

export function mergeWishlist(data) {
  return {
    type: RIGHT_DRAWER.MERGE_WISHLIST.MAIN,
    data,
  };
}

export function clearWishlistServer(data) {
  return {
    type: RIGHT_DRAWER.CLEAR_ALL_WISHLIST.MAIN,
    data,
  };
}
