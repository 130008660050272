import React, { useState, useEffect } from "react";
import Title from "../../../components/Title";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import AddressFiled from "./address-field";

const UpdateAddress = (props) => {
  const classes = useStyles();

  const { profileData, profileDataLoading, userId, updateUser } = props;

  const [addressField, setAddressField] = useState(false);
  const [shippingField, setShippingField] = useState(false);

  const [billingAddress1, setBillingAddress1] = useState("");
  const [billingAddress2, setBillingAddress2] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [billingCountry, setBillingCountry] = useState("");
  const [shippingAddress1, setShippingAddress1] = useState("");
  const [shippingAddress2, setShippingAddress2] = useState("");
  const [shippingCity, setShippingCity] = useState("");
  const [shippingCountry, setShippingCountry] = useState("");

  const [errB, setErrB] = useState("");
  const [errS, setErrS] = useState("");

  const handleBillingAddress1 = (e) => {
    setBillingAddress1(e.target.value);
  };

  const handleBillingAddress2 = (e) => {
    setBillingAddress2(e.target.value);
  };

  const handleBillingCity = (e) => {
    setBillingCity(e.target.value);
  };

  const handleBillingCountry = (e) => {
    setBillingCountry(e.target.value);
  };

  const handleShippingAddress1 = (e) => {
    setShippingAddress1(e.target.value);
  };

  const handleShippingAddress2 = (e) => {
    setShippingAddress2(e.target.value);
  };

  const handleShippingCity = (e) => {
    setShippingCity(e.target.value);
  };

  const handleShippingCountry = (e) => {
    setShippingCountry(e.target.value);
  };

  const handleBillingSubmit = () => {
    if (billingAddress1.length && billingCity.length && billingCountry.length) {
      const data = {
        user_id: userId,
        billing_address: {
          billing_address_1: billingAddress1,
          billing_address_2: billingAddress2,
          billing_city: billingCity,
          billing_country: billingCountry,
        },
      };
      updateUser(data);
    } else {
      setErrB("One or more input fields are empty");
    }
  };

  const handleShippingSubmit = () => {
    if (
      shippingAddress1.length &&
      shippingCity.length &&
      shippingCountry.length
    ) {
      const data = {
        user_id: userId,
        shipping_address: {
          shipping_address_1: shippingAddress1,
          shipping_address_2: shippingAddress2,
          shipping_city: shippingCity,
          shipping_country: shippingCountry,
        },
      };
      updateUser(data);
    } else {
      setErrS("One or more input fields are empty");
    }
  };

  const handleAddressField = () => {
    setAddressField(!addressField);
  };

  const handleShippingField = () => {
    setShippingField(!shippingField);
  };

  useEffect(() => {
    if (profileData) {
      setBillingAddress1(profileData.billing_address.address_1);
      setBillingAddress2(profileData.billing_address.address_2);
      setShippingAddress1(profileData.shipping_address.address_1);
      setShippingAddress2(profileData.shipping_address.address_2);
      setBillingCity(profileData.billing_address.city);
      setShippingCountry(profileData.shipping_address.city);
      setBillingCountry(profileData.billing_address.country);
      setShippingCountry(profileData.shipping_address.country);
    }
  }, []);

  return (
    <div className={classes.main}>
      <Card className={classes.root}>
        <CardContent className={classes.cardContent}>
          <div className="edit-address">
            <Title
              text={"Billing Address"}
              color={"#313132"}
              fontSize={16}
              lineHeight={20}
              fontWeight={"500"}
              textTransform={"uppercase"}
              fontFamily={"Poppins"}
            />
            {addressField ? (
              <div
                onClick={handleAddressField}
                style={{
                  marginLeft: "auto",
                  backgroundColor: "#313132",
                  padding: "5px 18px 5px 18px",
                  borderRadius: "50px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    color: "#fff",
                    fontSize: 12,
                    fontWeight: 400,
                    fontFamily: "Poppins",
                  }}
                >
                  Close
                </div>
              </div>
            ) : (
              <div
                onClick={handleAddressField}
                style={{
                  marginLeft: "auto",
                  backgroundColor: "#EFEFEF",
                  padding: "5px 18px 5px 18px",
                  borderRadius: "50px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    color: "#313132",
                    fontSize: 12,
                    fontWeight: 400,
                    fontFamily: "Poppins",
                  }}
                >
                  Edit
                </div>
              </div>
            )}
          </div>
          {addressField ? (
            <AddressFiled
              handleCancel={handleAddressField}
              address1={billingAddress1}
              address2={billingAddress2}
              handleAddress1={handleBillingAddress1}
              handleAddress2={handleBillingAddress2}
              err={errB}
              loading={profileDataLoading}
              handleSubmit={handleBillingSubmit}
              city={billingCity}
              handleCity={handleBillingCity}
              country={billingCountry}
              handleCountry={handleBillingCountry}
            />
          ) : (
            <>
              <Title
                text={
                  billingAddress1.length
                    ? billingAddress1
                    : "No addresses added"
                }
                color={"#313132"}
                fontSize={14}
                lineHeight={22}
                fontWeight={"400"}
                margin={"30px 0 10px 0"}
                fontFamily={"Poppins"}
              />
              <Title
                text={billingAddress2}
                color={"#313132"}
                fontSize={14}
                lineHeight={22}
                fontWeight={"400"}
                margin={"10px 0"}
                fontFamily={"Poppins"}
              />
              <Title
                text={billingCity}
                color={"#313132"}
                fontSize={14}
                lineHeight={22}
                fontWeight={"400"}
                margin={"10px 0"}
                fontFamily={"Poppins"}
              />
              <Title
                text={billingCountry}
                color={"#313132"}
                fontSize={14}
                lineHeight={22}
                fontWeight={"400"}
                margin={"10px 0 30px 0"}
                fontFamily={"Poppins"}
              />
            </>
          )}
          <div style={{backgroundColor: 'rgba(122,121,121,0.1)', height: 2, width: '100%', marginBottom: 20, marginTop: 20}}/>

          {/*part 2*/}
          <div className="edit-address">
            <Title
              text={"Shipping Address"}
              color={"#313132"}
              fontSize={16}
              lineHeight={20}
              fontWeight={"500"}
              textTransform={"uppercase"}
              fontFamily={"Poppins"}
            />
            {shippingField ? (
              <div
                onClick={handleShippingField}
                style={{
                  marginLeft: "auto",
                  backgroundColor: "#313132",
                  padding: "5px 18px 5px 18px",
                  borderRadius: "50px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    color: "#fff",
                    fontSize: 12,
                    fontWeight: 400,
                    fontFamily: "Poppins",
                  }}
                >
                  Close
                </div>
              </div>
            ) : (
              <div
                onClick={handleShippingField}
                style={{
                  marginLeft: "auto",
                  backgroundColor: "#EFEFEF",
                  padding: "5px 18px 5px 18px",
                  borderRadius: "50px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{
                    color: "#313132",
                    fontSize: 12,
                    fontWeight: 400,
                    fontFamily: "Poppins",
                  }}
                >
                  Edit
                </div>
              </div>
            )}
          </div>
          {shippingField ? (
            <AddressFiled
              handleCancel={handleShippingField}
              address1={shippingAddress1}
              address2={shippingAddress2}
              handleAddress1={handleShippingAddress1}
              handleAddress2={handleShippingAddress2}
              err={errS}
              loading={profileDataLoading}
              handleSubmit={handleShippingSubmit}
              city={shippingCity}
              handleCity={handleShippingCity}
              country={shippingCountry}
              handleCountry={handleShippingCountry}
            />
          ) : (
            <>
              <Title
                text={
                  shippingAddress1.length
                    ? shippingAddress1
                    : "No Addresses Added"
                }
                color={"#313132"}
                fontSize={14}
                lineHeight={22}
                fontWeight={"400"}
                margin={"30px 0 10px 0"}
                fontFamily={"Poppins"}
              />
              <Title
                text={shippingAddress2}
                color={"#313132"}
                fontSize={14}
                lineHeight={22}
                fontWeight={"400"}
                margin={"10px 0"}
                fontFamily={"Poppins"}
              />
              <Title
                text={shippingCity}
                color={"#313132"}
                fontSize={14}
                lineHeight={22}
                fontWeight={"400"}
                margin={"10px 0"}
                fontFamily={"Poppins"}
              />
              <Title
                text={shippingCountry}
                color={"#313132"}
                fontSize={14}
                lineHeight={22}
                fontWeight={"400"}
                margin={"10px 0 30px 0"}
                fontFamily={"Poppins"}
              />
            </>
          )}
          <div style={{backgroundColor: 'rgba(122,121,121,0.1)', height: 2, width: '100%', marginBottom: 20, marginTop: 20}}/>
        </CardContent>
      </Card>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  main: {
    margin: "10px 17px",
    [theme.breakpoints.down(992)]: {
      margin: "10px 0",
    },
  },
  root: {
    minWidth: 275,
    marginBottom: 17,
    boxShadow: "0px 0px 10px #0000000D",
  },
  cardContent: {
    paddingBottom: "0px !important",
    [theme.breakpoints.down(992)]: {
      padding: 10,
    },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default UpdateAddress;
