import React, {useState, useEffect} from 'react';
import Button from '../../../components/NewButtonSubmit';
import Title from "../../../components/Title";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Form, Row, Col} from 'react-bootstrap'
import {handleAuthModal, verifyInit, forgetPassUpdate, logout} from "../../../redux/actions/auth";
import {connect} from 'react-redux'
import {withStyles} from "@material-ui/core";
import TimerMixin from 'react-timer-mixin'

import {
  confirmPassValidator,
  passwordValidator
} from "../../../helpers/validators";

import {authStyle} from "../authStyle";
import CrossIcon from "../../../assets/icons/cross-icon.svg";

let styles = theme => authStyle(theme)

let Login = (props) => {
  let {
    openModal,
    handleAuthModal,
    classes,
    verifyInit,
    forgetPassUpdate,
    forgetPass,
    forgetPassLoading,
    code,
    phoneNum
  } = props

  let [password, setPassword] = useState('')
  let [confirm, setConfirm] = useState('')
  let [passwordErr, setPasswordErr] = useState('')
  let [confirmErr, setConfirmErr] = useState('')
  let [success, setSuccess] = useState('')
  let [error, setError] = useState('')

  let handlePassword = e => {
    setPassword(e.target.value)
  }

  let handleConfirm = (e) => {
    setConfirm(e.target.value)
  }

  let passwordBlur = () =>  {
    let valid = passwordValidator(password)

    if(valid) {
      setPasswordErr('')
    } else {
      setPasswordErr("Password must be of minimum 6 characters long without any space")
    }
  }

  let confirmPasswordBlur = () => {
    let valid = confirmPassValidator(password, confirm)

    if(valid) {
      setConfirmErr('')
    } else {
      setConfirmErr("Passwords do not match")
    }
  }

  const handleClose = () => {
    handleAuthModal('')
  };

  let handleSubmit = () => {
    passwordBlur()
    confirmPasswordBlur()

    if(passwordErr.length || confirmErr.length) {
      // do nothing
    } else  {
      let data = {
        user_phone: phoneNum,
        otp: code,
        new_password: password
      }

      forgetPassUpdate(data)
    }
  }

  useEffect(() => {
    if(forgetPass) {
      if(forgetPass.status) {
        setSuccess(forgetPass.message)
      } else {
        setError(forgetPass.message)
      }

      TimerMixin.setTimeout(() => {
        verifyInit()
        setSuccess('')
        setError('')
        props.logout()
        handleClose()
      }, 5000)
    }
  }, [forgetPass])

  return (
    <div>
      <Dialog
        open={openModal === 'change-pass'}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className={classes.main}>
          <Title
            text={'Change Password'}
            color={'#313132'}
            fontSize={24}
            lineHeight={40}
            fontWeight={'500'}
            textAlign={'left'}
            textTransform={'capitalize'}
            // margin={'5px 0px 15px 0px'}
          />
          <img className={classes.crossIcon} src={CrossIcon} onClick={handleClose} />
          <Form>
            <Form.Group>
              <Row>
                <Col className={classes.formField} md={12}>
                  <Form.Label className={classes.formLabel}>Password</Form.Label>
                  <Form.Control
                    type='password'
                    name="password"
                    value={password}
                    className={classes.formInput}
                    placeholder={'Your Password'}
                    onChange={handlePassword}
                  />
                  {passwordErr.length ? (
                    <Title
                      text={passwordErr}
                      fontWeight={200}
                      fontSize={14}
                      lineHeight={20}
                      color={'red'}
                    />
                  ) : null}
                </Col>
                <Col className={classes.formField} md={12}>
                  <Form.Label className={classes.formLabel}>Confirm Password</Form.Label>
                  <Form.Control
                    type='password'
                    name="password"
                    value={confirm}
                    className={classes.formInput}
                    placeholder={'Confirm Your Password'}
                    onChange={handleConfirm}
                    onBlur={passwordBlur}
                  />
                  {confirmErr.length ? (
                    <Title
                      text={confirmErr}
                      fontWeight={200}
                      fontSize={14}
                      lineHeight={20}
                      color={'red'}
                    />
                  ) : null}
                </Col>
              </Row>
            </Form.Group>
          </Form>
          <Button text={'Confirm'} handleSubmit={handleSubmit} loading={forgetPassLoading} />
        </DialogContent>
      </Dialog>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    openModal: state.authReducer.openModal,
    phoneNum: state.authReducer.phoneNum,
    forgetPass: state.authReducer.forgetPass,
    forgetPassLoading: state.authReducer.forgetPassLoading,
    code: state.authReducer.code
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleAuthModal: data => dispatch(handleAuthModal(data)),
    verifyInit: data => dispatch(verifyInit()),
    forgetPassUpdate: data => dispatch(forgetPassUpdate(data)),
    logout: () => dispatch(logout())
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Login))
