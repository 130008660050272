import * as React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {authStyle} from "../../auth/authStyle";

import styled from "styled-components";
import {withStyles} from "@material-ui/core";

const CheckOptions = (props) => {
  const { checked, setChecked, classes } = props;
  return (
    <StyledOption>
      <FormGroup className="form-group">
        <FormControlLabel
          onClick={() => setChecked("physical")}
          control={
            <Checkbox checked={checked === "physical"} color="primary" />
          }
          label="Visit Physically"
          className={classes.formControl}
        />
        <FormControlLabel
          onClick={() => setChecked("online")}
          control={<Checkbox checked={checked === "online"} color="primary" />}
          label="Online Schedule"
          className={classes.formControl}
        />
      </FormGroup>
    </StyledOption>
  );
};

let styles = (theme) => authStyle(theme);

const StyledOption = styled.div`
  .form-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
  }
`;

export default withStyles(styles)(CheckOptions);
