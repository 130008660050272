import { ORDERS } from "../../constants/orders";

export function setOrder(data) {
  return {
    type: ORDERS.SET_ORDER.MAIN,
    data,
  };
}

export function paymentInit() {
  return {
    type: ORDERS.PAYMENT_INIT,
  };
}

export function onlinePayment(data) {
  return {
    type: ORDERS.PAYMENT_GATEWAY.MAIN,
    data,
  };
}

export function getAllOrders(id) {
  return {
    type: ORDERS.FETCH_ALL_ORDERS.MAIN,
    id,
  };
}

export function setHome(data) {
  return {
    type: ORDERS.SET_HOME.MAIN,
    data,
  };
}

export function setAddress(data) {
  return {
    type: ORDERS.SET_ADDRESS.MAIN,
    data,
  };
}

export function setEmail(data) {
  return {
    type: ORDERS.SET_EMAIL.MAIN,
    data,
  };
}

export function setPhone(data) {
  return {
    type: ORDERS.SET_PHONE.MAIN,
    data,
  };
}

export function paymentDone(data) {
  return {
    type: ORDERS.PAYMENT_DONE.MAIN,
    data,
  };
}

export function ordersAmount(data) {
  return {
    type: ORDERS.ORDERS_AMOUNT.MAIN,
    data,
  };
}

export function orderAttachment(data) {
  return {
    type: ORDERS.ORDERS_FILE.MAIN,
    data,
  };
}

export function paymentFailed(data) {
  return {
    type: ORDERS.PAYMENT_FAILED.MAIN,
    data,
  };
}

export function setPayment(data) {
  return {
    type: ORDERS.SET_PAYMENT.MAIN,
    data,
  };
}

export function initNewOrder() {
  return {
    type: ORDERS.INIT_NEW_ORDER.MAIN,
  };
}
