import React, {useEffect} from "react";
import Drawer from "@material-ui/core/Drawer";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { openLeftDrawer } from "../../../redux/actions/left-drawer";
import CrossIcon from "../../../assets/icons/cross-icon.svg";
import LeftButton from "../../../assets/images/navLeftBlack.svg";
import { leftDrawerStyle } from "../leftDrawerStyle";
import LogoImage from "../../../assets/images/shanta-logo.png";
import Title from "../../../components/Title";
import TimerMixin from "react-timer-mixin";

import { useHistory, Link } from "react-router-dom";
import {fetchBrand} from "../../../redux/actions/home";
import ReactHtmlParser from "react-html-parser";

let BrandDrawer = (props) => {
  let { classes, openLeft, handleLeftDrawer, posts, brandData } = props;

  useEffect(()=>{
    props.fetchBrand()
  },[])

  let brands = brandData ? brandData.length ? brandData  : [] : [];
  // //console.log('brand data list',brands);
  brands?.length>0 && brands.sort((x, y) => x.sort_order - y.sort_order);
  // //console.log('sortBrand',brands)
  
  let history = useHistory();

  let handleClose = () => {
    handleLeftDrawer("");
  };

  const handleRoute = (route) => {
    handleClose();
    TimerMixin.setTimeout(() => {
      history.push(`/brand-products/${route}`);
    }, 500);
  };

  return (
    <div>
      <Drawer
        anchor={"left"}
        open={openLeft === "left-brand"}
        onClose={handleClose}
      >
        <div className={classes.drawerHeader}>
          <div
            onClick={() => handleLeftDrawer("")}
            className={classes.crossIcon}
          >
            <img alt="" src={CrossIcon} />
          </div>
          <div onClick={() => handleRoute('')}>
            <img alt="" src={LogoImage} />
          </div>
        </div>
        <div className={classes.secondaryHeader}>
          <div>
            <img
              src={LeftButton}
              alt=""
              onClick={() => handleLeftDrawer("left-main")}
              className={classes.leftIcon}
            />
          </div>
          <Title
            text={"Brands"}
            color={"#313132"}
            fontSize={14}
            fontFamily={'Poppins'}
            lineHeight={24}
            fontWeight={"600"}
            textAlign={"left"}
            margin={"0 0 0px 28px"}
          />
        </div>
        <div className={classes.drawerBody}>
          <div role="presentation">
            <div className={classes.itemDivMain}>
              {brands.length
                ? brands.map((item, key) => {
                  // //console.log('Brrand',brands)
                   
                    return (
                      
                      <div
                          key={key}
                        className={classes.itemDiv}
                        onClick={() => handleRoute(item.slug)}
                      >
                        <img
                          className={classes.brandImage}
                          src={item.logo}
                          alt={`${ReactHtmlParser(item.name)} image`}
                        />
                        <Title
                          text={ReactHtmlParser(item.name)}
                          color={"#313132"}
                          fontSize={14}
                          lineHeight={24}
                          fontFamily={'Poppins'}
                          fontWeight={"400"}
                          textAlign={"left"}
                          margin={"10px 0 0px 0px"}
                        />
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

let style = (theme) => leftDrawerStyle(theme);

function mapStateToProps(state) {
  return {
    openLeft: state.leftDrawerReducer.openLeft,
    posts: state.homeReducer.posts,
    brandData: state.homeReducer.brandData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleLeftDrawer: (data) => dispatch(openLeftDrawer(data)),
    fetchBrand: () => dispatch(fetchBrand()),
  };
}

export default withStyles(style)(
  connect(mapStateToProps, mapDispatchToProps)(BrandDrawer)
);
