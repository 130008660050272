import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Title from "../../components/Title";
import CheckoutDetails from "./components/CheckoutDetails";
import UserDetails from "./components/UserDetails";
import {
  handleRightDrawer,
  clearCartServer,
} from "../../redux/actions/rightDrawer";
import { connect } from "react-redux";
import Divider from "@material-ui/core/Divider";

let Checkout = (props) => {
  let { authData } = props;
  let userId = authData ? (authData.id ? authData.id : null) : null;
  useEffect(() => {
    window.scrollTo(0, 0);
    handleRightDrawer("");
  }, []);
  return (
    <StyledCheckout>
        <Row className="change-view">
            <Col>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <div className="text-breadcrumb">Home</div>
                    <div className="text-breadcrumb-point"/>
                    <div className="text-breadcrumb">Shopping Bag</div>
                    <div className="text-breadcrumb-point"/>
                    <div className="text-breadcrumb">Checkout</div>
                </div>
                <Divider style={{marginTop: 6}}/>
            </Col>
        </Row>
      <Title
        text={"Checkout"}
        color={"#9C9434"}
        fontSize={12}
        lineHeight={18}
        fontFamily={'Poppins'}
        textTransform={'uppercase'}
        fontWeight={"500"}
        textAlign={"left"}
        margin={'30px 0 0 0'}
      />
      <Title
        text={"Confirm Delivery Details"}
        color={"#313132"}
        fontSize={24}
        lineHeight={40}
        textTransform={'uppercase'}
        fontWeight={"500"}
        textAlign={"left"}
        margin={"0 0 30px 0"}
      />
      <Row>
        <Col md={7} sm={12}>
          <UserDetails />
        </Col>
        <Col md={5} sm={12}>
          <CheckoutDetails />
        </Col>
      </Row>
    </StyledCheckout>
  );
};

let StyledCheckout = styled.div`
  overflow-y: hidden;
  margin-top: 100px;
  padding: 0 55px;

  .text-breadcrumb {
    font-weight: normal;
    font-size: 10px;
    color: #313132;
    font-family: "Poppins", sans-serif;
  }

  .text-breadcrumb-point{
    width: 3px;
    height: 3px;
    color: #313132;
    border-radius: 50%;
    background-color: #313132;
    margin-right: 8px;
    margin-left: 8px;
  }
  .change-view {
    display: block;
    @media(max-width: 991px){
      display: none;
    }
  }
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

function mapStateToProps(state) {
  return {
    authData: state.authReducer.authData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleRightDrawer: (data) => dispatch(handleRightDrawer(data)),
    clearCartServer: (data) => dispatch(clearCartServer(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
