import React from 'react'
import {Row, Col} from 'react-bootstrap'
import Title from '../../../components/Title'
import styled from 'styled-components'
import {numberWithCommas} from "../../../helpers/globalFunc";
import Image from '../../../assets/images/dynamic/Image.png'

let CheckoutItem = props => {
  let {product} = props

  return (
    <StyledDiv>
      <div className="main-div">
        <div className="sub-div">
          <img
            src={product.cartItem.image_id}
            style={{ width: 90, height: 70 }}
          />
        </div>
        <div className="body-div">
          <div>
            <Title
              text={product.cartItem.name}
              fontSize={14}
              lineHeight={20}
              fontWeight={'600'}
              fontFamily={'Poppins'}
              color={'#313132'}
              textAlign={'left'}
            />
          </div>
          <div className="sku-div">
            <Title
              text={`SKU: ${product.cartItem.sku}`}
              fontSize={12}
              lineHeight={20}
              fontFamily={'Poppins'}
              fontWeight={'400'}
              color={'#707070'}
              textAlign={'left'}
              margin={'0 20px 0 0'}
            />
          </div>
          <div className="price-div">
            <Title
              text={`Total: ${product.count} * ${numberWithCommas(parseFloat(product.cartItem.price))}`}
              fontSize={12}
              lineHeight={20}
              fontWeight={'normal'}
              color={'#313132'}
              textAlign={'left'}
              margin={'10px 50px 0 0'}
            />
            <div className="price-text">
              <Title
                  text={`BDT `}
                  fontSize={10}
                  lineHeight={20}
                  fontWeight={'normal'}
                  color={'#313132'}
                  textAlign={'right'}
                  margin={'8px 0 0 0'}
              />
              <Title
                text={`${numberWithCommas(parseInt(product.count) * parseFloat(product.cartItem.price))}`}
                fontSize={14}
                lineHeight={20}
                fontWeight={'normal'}
                color={'#313132'}
                textAlign={'right'}
                margin={'10px 0 0 0'}
              />
            </div>
          </div>
        </div>
      </div>
    </StyledDiv>
  )
}

let StyledDiv = styled.div`
  margin: 10px 5px;
  .main-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .sub-div {
    margin-right: 10px;
  }
  .body-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 160px;
  }
  .sku-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .price-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .price-text {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: absolute;
    right: 36px;
  }
  .button-div {
    position: absolute;
    right: 16px;
  }
  .icon {
    width: 18px;
  }
  .icon-button {
    padding: 0 !important;
  }
  
  @media (max-width: 767px) {
    .price-text {
      right: 16px;
    }
  }
`

export default CheckoutItem
