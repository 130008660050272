import React, { useEffect } from "react";
import styled from "styled-components";
import Title from "../../components/Title";
import {withRouter, useHistory, Link, useLocation} from "react-router-dom";
import SuccessImage from "../../assets/images/success.png";
import {
  initNewOrder,
  setPayment,
  setEmail,
  setPhone,
  setHome,
  setAddress,
  setOrder, paymentDone, paymentFailed,
} from "../../redux/actions/orders";
import {updateUser} from "../../redux/actions/auth";
import { clearCheckout, setTempOrder } from "../../redux/actions/rightDrawer";
import { connect } from "react-redux";
import TimerMixin from "react-timer-mixin";

function useQuery() {
  const {search} = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

let PaymentProcess = (props) => {
  let history = useHistory();
  let {
    initNewOrder,
    setPayment,
    setEmail,
    setPhone,
    setHome,
    setAddress,
    setTempOrder,
    clearCheckout,
    tempOrder,
    newOrder,
    setOrder,
    updateUser,
    paymentDone
  } = props;

  // useEffect(() => {
  //   if (tempOrder) {
  //     tempOrder.set_paid = true;
  //     let userAddressUpdate = {
  //       user_id: tempOrder.customer_id,
  //       billing_address: {
  //         billing_address_1: tempOrder.billing.address_1,
  //         billing_address_2: tempOrder.billing.address_2,
  //         billing_city: tempOrder.billing.city,
  //       },
  //     }
  //     if(tempOrder.shipping) {
  //       userAddressUpdate.shipping_address = {
  //         shipping_address_1: tempOrder.shipping.address_1,
  //         shipping_address_2: tempOrder.shipping.address_2,
  //         shipping_city: tempOrder.shipping.city,
  //       }
  //     }
  //     updateUser(userAddressUpdate)
  //     setOrder(tempOrder);
  //   }
  // }, [tempOrder]);

  let query = useQuery();
  let key = query.get('key');

  useEffect(()=>{
    let data = {
      key: key
    }
    paymentDone(data)
  },[key])

  useEffect(() => {
    TimerMixin.setTimeout(() => {
      clearCheckout();
      setTempOrder(null);
      setPhone(null);
      setEmail(null);
      setPayment("cash");
      setHome(null);
      setAddress(null);
      initNewOrder();
      //history.push("/orders");
    }, 6000);
  }, []);

  return (
    <StyledSuccess>
      <div className="main">
        <img src={SuccessImage} alt="success" />
        <Title
          text={"Order Placed Successfully!"}
          fontWeight={"bold"}
          fontSize={24}
          lineHeight={32}
          margin={"30px 0 10px 0"}
          textAlign={"center"}
        />
        <Link to={"/orders"}>
          <Title
            text={`Go to order list`}
            fontWeight={400}
            fontSize={18}
            lineHeight={24}
            margin={"0 0 10px 0"}
            textAlign={"center"}
            color={"#419AE8"}
          />
        </Link>
      </div>
    </StyledSuccess>
  );
};

let StyledSuccess = styled.div`
  background-color: #f8f8f8;
  padding: 120px 40px;
  height: 120vh;

  .main {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      height: 50px;
      width: 50px;
    }
  }
`;

function mapStateToProps(state) {
  return {
    newOrder: state.orderReducer.newOrder,
    authData: state.authReducer.authData,
    tempOrder: state.rightDrawerReducer.tempOrder,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearCheckout: () => dispatch(clearCheckout()),
    initNewOrder: () => dispatch(initNewOrder()),
    setTempOrder: (data) => dispatch(setTempOrder(data)),
    setOrder: (data) => dispatch(setOrder(data)),
    setEmail: (data) => dispatch(setEmail(data)),
    setPhone: (data) => dispatch(setPhone(data)),
    setHome: (data) => dispatch(setHome(data)),
    setAddress: (data) => dispatch(setAddress(data)),
    setPayment: (data) => dispatch(setPayment(data)),
    updateUser: data => dispatch(updateUser(data)),
    paymentDone: (data) => dispatch(paymentDone(data))
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PaymentProcess)
);
