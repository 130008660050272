import React,{useState} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AddIcon from '@material-ui/icons/Add';
import Title from "../../../components/Title";
import Text from '../../../components/Text';
import Divider from "@material-ui/core/Divider";
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";

let Accordions = (props) => {
  const classes = useStyles();
  let {item} = props;

  return (
    <StyledExpand>
      {item.length ? item.map(expand => {
        return (
          <Accordion className={classes.root}>
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              expandIcon={<AddIcon />}
            >
              <Title
                text={expand.title}
                color={'#313132'}
                fontSize={16}
                lineHeight={24}
                fontFamily={'Poppins'}
                fontWeight={600}
              />
            </AccordionSummary>
            <AccordionDetails>
              <div>
                <div style={{backgroundColor: 'rgba(122,121,121,0.1)', height: 2, width: '100%', marginBottom: 20, marginTop:-5}}/>
                <Text
                    color={'#313132'}
                    text={expand.content}
                    fontFamily={'Poppins'}
                />
              </div>
            </AccordionDetails>
          </Accordion>
        )
      }) : null}
    </StyledExpand>
  );
}

const StyledExpand = styled.div`
  .column-cl-product{
    margin-bottom: 72px;
  }
  .MuiAccordion-root:before{
    opacity: 0;
  }
`

const ProductCardWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: #fcfcfc;
  border-bottom: 2px solid #efefef;
  position: relative;
  border-radius: 0;
  cursor: pointer;
  margin-right: 30px;

  &:nth-last-of-type(1) {
    margin-right: 0;
  }
`;


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: 20,
    padding: '10px 5px',
    boxShadow: '0px 0px 10px #0000000D !important'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default Accordions;
