import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Title from "../../../components/Title";
import styled from "styled-components";
import Divider from "@material-ui/core/Divider";
import { Form } from "react-bootstrap";
import Select from "react-select";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import AddressField from "./AddressField";
import PhoneField from "./PhoneField";
import EmailField from "./EmailField";
import { connect } from "react-redux";

import { validatePhone, validateEmail } from "../../../helpers/validators";
import {
  setPhone,
  setHome,
  setEmail,
  setAddress,
  setPayment,
} from "../../../redux/actions/orders";
import {AMOUNT} from "../../../constants/apiParams";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    color: state.isSelected ? "#FFF" : "#313132",
    backgroundColor: state.isSelected ? "#313132" : "#FFF",
    margin: 0,
  }),
  menu: (provided, state) => ({
    ...provided,
    color: "rgba(0,0,0,0.5)",
    backgroundColor: state.isSelected ? "#000" : "#FFF",
    margin: 0,
  }),
  menuList: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#000" : "#FFF",
    borderRadius: 0,
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#e8e8e8",
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none",
  }),
};

let calculatePrice = (carts) => {
  let price = 0;

  if (carts.length) {
    let len = carts.length;
    for (let i = 0; i < len; i++) {
      let calc = carts[i].count * parseFloat(carts[i].cartItem.price);

      price += calc;
    }
    return price;
  } else {
    return 0;
  }
};

let UserDetails = (props) => {
  const classes = useStyles();

  let {
    authData,
    profileData,
    setHomeAddr,
    setAddr,
    setPhoneNo,
    setEmailId,
    setPaymentMeth,
    checkout
  } = props;

  let [payment, setPayment] = useState("bacs");
  let [homeAddress, setHome] = useState("");
  let [homeAddress2, setHome2] = useState("");
  let [homeCity, setHomeCity] = useState("");
  let [homeErr, setHomeErr] = useState("");
  let [address1, setAddress] = useState("");
  let [address2, setAddress2] = useState("");
  let [addressCity, setAddressCity] = useState("");
  let [homeField, setHomeField] = useState(false);
  let [phone, setPhone] = useState(authData ? authData.user_phone : "");
  let [phoneField, setPhoneField] = useState(false);
  let [phoneErr, setPhoneErr] = useState("");
  let [email, setEmail] = useState("");
  let [emailField, setEmailField] = useState(false);
  let [emailErr, setEmailErr] = useState("");

  let [checkHome, setCheckHome] = useState(true);
  let [checkAddr, setCheckAddr] = useState(false)

  const handleCheckHome = () => {
    setCheckHome(!checkHome)
  }

  const handleCheckAddr = () => {
    setCheckAddr(!checkAddr)
  }

  useEffect(() => {
    if (authData) {
      if (authData.token) {
        setPhoneNo(authData.user_phone);
      }
    }
  }, [authData]);

  useEffect(() => {
    if (profileData) {
      setHome(profileData.billing_address.address_1);
      setHome2(profileData.billing_address.address_2);
      setHomeCity(profileData.billing_address.city);
      setAddress(profileData.shipping_address.address_1);
      setAddress2(profileData.shipping_address.address_2);
      setAddressCity(profileData.shipping_address.city);
      setEmail(profileData.email);
      setPhone(profileData.user_phone);

      if (profileData.email.length) setEmailId(profileData.email);

      let homeData = {
        address_1: profileData.billing_address.address_1,
        address_2: profileData.billing_address.address_2,
        city: profileData.billing_address.city,
        phone: profileData.user_phone,
        email: profileData.email
      };

      if (homeData.address_1.length && homeData.city.length) {
        setHomeAddr(homeData);
      }

      let addressData = {
        address_1: profileData.shipping_address.address_1,
        address_2: profileData.shipping_address.address_2,
        city: profileData.shipping_address.city,
      };

      if (addressData.address_1.length && addressData.city.length) {
        setAddr(addressData);
      }
    }
  }, [profileData]);

  let handleHomeAddress = (e) => {
    setHome(e.target.value);
  };

  let handleHome2 = (e) => {
    setHome2(e.target.value);
  };

  let handleHomeCity = (e) => {
    setHomeCity(e.target.value);
  };

  let handleAddress = (e) => {
    setAddress(e.target.value);
  };

  let handleAddress1 = (e) => {
    setAddress2(e.target.value);
  };

  let handleAddressCity = (e) => {
    setAddressCity(e.target.value);
  };

  let handleHomeField = () => {
    if (!homeField) {
      setHomeField(true);
    } else {
      let homeData = {
        address_1: homeAddress,
        address_2: homeAddress2,
        phone: phone,
        city: homeCity,
      };
      let addressData = {
        address_1: address1,
        address_2: address2,
        phone: phone,
        city: addressCity,
      };
      if (homeAddress.length && homeCity.length) {
        if(checkHome) {
          setHomeAddr(homeData);
          setHomeField(false);
          setHomeErr("");
        } else {
          setHomeAddr(null)
          if(!checkAddr) {
            setHomeErr("Minimum one address filed must be selected");
          } else {
            setAddr(homeData)
            setHomeField(false);
            setHomeErr("");
          }
        }
      }
      if (address1.length) {
        if(checkAddr) {
          setAddr(addressData);
          setHomeField(false);
          setHomeErr("");
        } else {
          setAddr(null)
        }
      }
    }
  };

  let handlePhoneBlur = () => {
    let valid = validatePhone(phone);

    if (!valid) {
      setPhoneErr("Phone number is not valid");
    }
  };

  let handlePhoneField = (e) => {
    e.preventDefault()
    let valid = validatePhone(phone);
    if (!valid) {
      if (!phoneField) {
        setPhoneField(true);
      } else {
        setPhoneErr("Phone number is not valid");
      }
    } else {
      if (!phoneField) {
        setPhoneField(true);
      } else {
        setPhoneNo(phone);
        setPhoneField(false);
      }
    }
  };

  let handleEmail = (e) => {
    e.preventDefault()
    setEmailErr("");
    setEmail(e.target.value);
  };

  let handleEmailField = (e) => {
    e.preventDefault()
    let valid = validateEmail.test(email);

    if (!valid) {
      if(!emailField) {
        setEmailField(true);
      } else {
        setEmailErr("Email is not valid");
      }
    } else {
      if (!emailField) {
        setEmailField(true);
      } else {
        setEmailId(email);
        setEmailField(false);
      }
    }
  };

  let handleEmailBlur = () => {
    let valid = validateEmail.test(email);
    if (!valid) {
      setEmailErr("Email is not valid");
    }
  };

  let handlePhone = (e) => {
    setPhoneErr("");
    setPhone(e.target.value);
  };

  let handleChange = (option) => {
    setPayment(option);
    setPaymentMeth(option);
  };

  let price = calculatePrice(checkout);
  let vat = price * 0.075;
  let total = (price + 600 + vat) - price * 0.05;
  let amountDue = total > 50000 ? total - 50000 : 0

  return (
    <StyledUser>
      <Card className={classes.root}>
        <CardContent>
          <div className="edit-address">
            <Title
              text={"DELIVERY ADDRESS"}
              color={"#313132"}
              fontSize={16}
              lineHeight={20}
              fontFamily={'Poppins'}
              fontWeight={"500"}
            />
            {homeField ? (
              <div
                onClick={handleHomeField}
                style={{
                  marginLeft: "auto",
                  backgroundColor: "#313132",
                  padding: "5px 18px 5px 18px",
                  borderRadius: "50px",
                  cursor: "pointer",
                }}
              >
                <div style={{ color: "#fff", fontSize: 10, fontWeight: 400, fontFamily: 'Poppins' }}>
                  Add Address
                </div>
              </div>
            ) : (
              <div
                onClick={handleHomeField}
                style={{
                  marginLeft: "auto",
                  backgroundColor: "#EFEFEF",
                  padding: "5px 18px 5px 18px",
                  borderRadius: "50px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{ color: "#313132", fontSize: 12, fontWeight: 400, fontFamily: 'Poppins' }}
                >
                  Edit Address
                </div>
              </div>
            )}
          </div>
          {homeField ? (
            <AddressField
              home={homeAddress}
              home2={homeAddress2}
              homeCity={homeCity}
              address1={address1}
              address2={address2}
              addressCity={addressCity}
              handleHomeAddress={handleHomeAddress}
              handleHomeAddress1={handleHome2}
              handleHomeCity={handleHomeCity}
              handleAddress={handleAddress}
              handleAddress1={handleAddress1}
              handleAddressCity={handleAddressCity}
              homeErr={homeErr}
              checkHome={checkHome}
              checkAddr={checkAddr}
              handleCheckHome={handleCheckHome}
              handleCheckAddr={handleCheckAddr}
            />
          ) : (
            <>
              {checkHome && homeAddress.length ? (
                <>
                  <Title
                    text={"Home:"}
                    color={"#313132"}
                    fontSize={14}
                    lineHeight={18}
                    fontFamily={'Poppins'}
                    fontWeight={"600"}
                    margin={"30px 0 10px 0"}
                  />
                  <Title
                    text={homeAddress.length ? homeAddress : "No Addresses Given"}
                    color={"#313132"}
                    fontSize={14}
                    lineHeight={18}
                    fontFamily={'Poppins'}
                    fontWeight={"500"}
                    margin={"0 0 5px 0"}
                  />
                  <Title
                    text={homeAddress2}
                    color={"#313132"}
                    fontSize={14}
                    lineHeight={18}
                    fontFamily={'Poppins'}
                    fontWeight={"500"}
                    margin={"0 0 5px 0"}
                  />
                  <Title
                    text={homeCity}
                    color={"#313132"}
                    fontSize={14}
                    lineHeight={18}
                    fontFamily={'Poppins'}
                    fontWeight={"500"}
                    margin={"0 0 30px 0"}
                  />
                </>
              ) : ''}
              {address1.length && checkAddr ? (
                <>
                  <Title
                    text={"Address Line 1:"}
                    color={"#313132"}
                    fontSize={14}
                    lineHeight={18}
                    fontFamily={'Poppins'}
                    fontWeight={"700"}
                    margin={"30px 0 10px 0"}
                  />
                  <Title
                    text={address1}
                    color={"#313132"}
                    fontSize={14}
                    lineHeight={18}
                    fontFamily={'Poppins'}
                    fontWeight={"500"}
                    margin={"0 0 5px 0"}
                  />
                  <Title
                    text={address2}
                    color={"#313132"}
                    fontSize={14}
                    lineHeight={18}
                    fontFamily={'Poppins'}
                    fontWeight={"500"}
                    margin={"0 0 5px 0"}
                  />
                  <Title
                    text={addressCity}
                    color={"#313132"}
                    fontSize={14}
                    fontFamily={'Poppins'}
                    lineHeight={18}
                    fontWeight={"500"}
                    margin={"0 0 30px 0"}
                  />
                </>
              ) : null}
            </>
          )}
          <Divider />
          {/* <Title
            text={'Delivery Schedule'}
            color={'#313132'}
            fontSize={16}
            lineHeight={20}
            fontWeight={'700'}
            margin={'20px 0 10px 0'}
          />
          <div className="select-div">
            <div style={{margin: 10}}>
              <Select
                styles={customStyles}
                className='select-here'
                placeholder='Select Date'
                options={options}
              />
            </div>
            <div style={{margin: 10}}>
              <Select
                styles={customStyles}
                className='select-here'
                placeholder='Select Time'
                options={timeOptions}
              />
            </div>
          </div>
          <Divider/> */}
          <div className="edit-address">
            <Title
              text={"Email Address"}
              color={"#313132"}
              fontSize={16}
              lineHeight={20}
              fontFamily={'Poppins'}
              fontWeight={"600"}
              margin={"30px 0 10px 0"}
            />
            {emailField ? (
              <div
                onClick={handleEmailField}
                style={{
                  marginLeft: "auto",
                  backgroundColor: "#313132",
                  padding: "5px 18px 5px 18px",
                  borderRadius: "50px",
                  cursor: "pointer",
                }}
              >
                <div style={{ color: "#fff", fontSize: 12, fontWeight: 400, fontFamily: 'Poppins' }}>
                  Add Email
                </div>
              </div>
            ) : (
              <div
                onClick={handleEmailField}
                style={{
                  marginLeft: "auto",
                  backgroundColor: "#EFEFEF",
                  padding: "5px 18px 5px 18px",
                  borderRadius: "50px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{ color: "#313132", fontSize: 12, fontWeight: 400, fontFamily: "Poppins" }}
                >
                  Edit Email
                </div>
              </div>
            )}
          </div>
          {emailField ? (
            <EmailField
              email={email}
              handleEmail={handleEmail}
              handleBlur={handleEmailBlur}
              submit={handleEmailField}
              emailErr={emailErr}
            />
          ) : (
            <>
              <Title
                text={email.length ? email : "No Email Address Given"}
                color={"#313132"}
                fontSize={14}
                lineHeight={18}
                fontFamily={'Poppins'}
                fontWeight={"500"}
                margin={"10px 5px 30px 5px"}
                textTransform={"none"}
              />
            </>
          )}
          <Divider />
          <div className="edit-address">
            <Title
              text={"Contact No"}
              color={"#313132"}
              fontSize={16}
              lineHeight={20}
              fontFamily={'Poppins'}
              fontWeight={"600"}
              margin={"30px 0 10px 0"}
            />
            {phoneField ? (
              <div
                onClick={handlePhoneField}
                style={{
                  marginLeft: "auto",
                  backgroundColor: "#313132",
                  padding: "5px 18px 5px 18px",
                  borderRadius: "50px",
                  cursor: "pointer",
                }}
              >
                <div style={{ color: "#fff", fontSize: 12, fontWeight: 400, fontFamily: 'Poppins' }}>
                  Add Phone
                </div>
              </div>
            ) : (
              <div
                onClick={handlePhoneField}
                style={{
                  marginLeft: "auto",
                  backgroundColor: "#EFEFEF",
                  padding: "5px 18px 5px 18px",
                  borderRadius: "50px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{ color: "#313132", fontSize: 12, fontWeight: 400, fontFamily: 'Poppins' }}
                >
                  Edit Phone
                </div>
              </div>
            )}
          </div>
          {phoneField ? (
            <PhoneField
              phone={phone}
              handlePhone={handlePhone}
              handleBlur={handlePhoneBlur}
              phoneErr={phoneErr}
              submit={handlePhoneField}
            />
          ) : (
            <>
              <Title
                text={phone.length ? "+88-" + phone : "No Phone Number Given"}
                color={"#313132"}
                fontSize={14}
                lineHeight={18}
                fontFamily={'Poppins'}
                fontWeight={"500"}
                margin={"10px 5px 30px 5px"}
              />
              <Divider />
            </>
          )}
          <Title
            text={"Payment Options"}
            color={"#313132"}
            fontSize={16}
            lineHeight={20}
            fontFamily={'Poppins'}
            fontWeight={"600"}
            margin={"30px 0 20px 0"}
          />
          <div className="payment-option">
            {/*{*/}
            {/*  total <= AMOUNT ? (*/}
            {/*      <div className="payment-div" onClick={() => handleChange("cash")}>*/}
            {/*        <FormControl>*/}
            {/*          <FormControlLabel*/}
            {/*              value="cash"*/}
            {/*              control={*/}
            {/*                <Radio color="primary" checked={payment === "cash"} />*/}
            {/*              }*/}
            {/*              label="Cash On Delivery"*/}
            {/*          />*/}
            {/*        </FormControl>*/}
            {/*      </div>*/}
            {/*  ) : null*/}
            {/*}*/}

            <div className="payment-div" onClick={() => handleChange("bacs")}>
              <FormControl>
                <FormControlLabel
                    value="bacs"
                    control={
                      <Radio color="primary" checked={payment === "bacs"} />
                    }
                    label="Bank Transfer"
                />
              </FormControl>
            </div>

            <div className="payment-div" onClick={() => handleChange("online")}>
              <FormControl>
                <FormControlLabel
                  value="card"
                  control={
                    <Radio color="primary" checked={payment === "online"} />
                  }
                  label="Online Payment"
                />
              </FormControl>
            </div>
          </div>
        </CardContent>
      </Card>
    </StyledUser>
  );
};

let StyledUser = styled.div`
  .MuiPaper-elevation1 {
    box-shadow: 0px 0px 1px -1px rgb(228 228 228 / 20%), 0px 0px 0px 0px rgb(228 228 228 / 14%), 0px 1px 15px 0px rgb(228 228 228 / 55%);
  }
  .edit-address {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .select-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
  }

  .payment-option {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    @media(max-width: 991px){
      display: block;
    }
  }

  .payment-div {
    background-color: #f5f5f5;
    width: 200px;
    height: 50px;
    text-align: center;
    font-size: 14px;
    padding-top: 5px;
    margin-right: 20px;
    cursor: pointer;
    @media(max-width: 991px){
      width: 100%;
      text-align: left;
      padding-left: 15px;
      margin-bottom: 15px;
    }
  }

  .select-here {
    width: 150px;
    @media(max-width: 991px){
      width: 100%;
    }
  }
`;

function mapStateToProps(state) {
  return {
    authData: state.authReducer.authData,
    profileData: state.authReducer.profileData,
    checkout: state.rightDrawerReducer.checkout,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setHomeAddr: (data) => dispatch(setHome(data)),
    setAddr: (data) => dispatch(setAddress(data)),
    setPhoneNo: (data) => dispatch(setPhone(data)),
    setEmailId: (data) => dispatch(setEmail(data)),
    setPaymentMeth: (data) => dispatch(setPayment(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
