import React, {useState, useEffect} from "react";
import {Form, Row, Col, Container} from "react-bootstrap";
import {
    addCustomerService
} from "../../redux/actions/customer-service";
import {withStyles} from "@material-ui/core";
import {authStyle} from "../auth/authStyle";
import {connect} from "react-redux";
import Button from "../../components/NewButtonSubmit";
import Divider from "@material-ui/core/Divider";
import {
    validatePhone,
    validateNameField,
    validateEmail,
} from "../../helpers/validators";
import TimerMixin from "react-timer-mixin";
import Title from "../../components/Title";
import styled from "styled-components";

const CustomerService = (props) => {
    const {
        classes,
        submitLoading,
        addCustomerService,
        customerServiceLoading,
        response_result
    } = props;

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [subject, setSubject] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const [submitMsg, setSubmitMsg] = useState("");
    const [submitErr, setSubmitErr] = useState("");

    useEffect(()=>{
        window.scrollTo ({top: 0,behavior: "smooth"});
    },[]);

    const handleSubmit = () => {
        const data = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            subject: subject,
            phone_no: phone,
            message: message,
        }
        // //console.log('customer service',addCustomerService)
        addCustomerService(data);
        setTimeout(()=>{
            resetFormData();
        },1500)
    };
    // //console.log('response result',response_result)
    const resetFormData = ()=>{
        setPhone("");
        setFirstName("");
        setLastName("");
        setEmail("");
        setMessage("");
        setSubject("");
    }
    const checkIfSubmitDisabled = ()=>{
        if(firstName === "" || email === "" || subject === "" || message === ""){
            return true;
        }
        return false;
    }
    return (
        <ProductMain>
            <ProductDesktop>
                <Container className="main">
                    <Row>
                        <Col md={12} lg={12} className="book-an-appointment-form">
                            <Title
                                text={"Customer Service"}
                                color={"#9C9434"}
                                fontSize={12}
                                lineHeight={18}
                                fontWeight={500}
                                fontFamily={'Renner, sans-serif'}
                                textAlign={"left"}
                                letterSpacing={60}
                                textTransform={"uppercase"}
                                // margin={'15px 0px 15px 0px'}
                            />
                            <Divider/>
                            <Form>
                                <Form.Group>
                                    <Row>
                                        <Col className={classes.formField} lg={6} md={12}>
                                            <Form.Label className={classes.formLabel}>First Name*</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                className={classes.formControl}
                                                value={firstName}
                                                onChange={(e)=> setFirstName(e.target.value)}
                                                placeholder={"First Name"}
                                            />
                                        </Col>
                                        <Col className={classes.formField} lg={6} md={12}>
                                            <Form.Label className={classes.formLabel}>Last Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                className={classes.formControl}
                                                value={lastName}
                                                onChange={(e)=> setLastName(e.target.value)}
                                                placeholder={"Last Name"}
                                            />
                                        </Col>
                                        <Col className={classes.formField} lg={12} md={12}>
                                            <Form.Label className={classes.formLabel}>Email*</Form.Label>
                                            <Form.Control
                                                type="email"
                                                name="name"
                                                className={classes.formControl}
                                                value={email}
                                                onChange={(e)=> setEmail(e.target.value)}
                                                placeholder={"Enter Email Address"}
                                            />
                                        </Col>
                                        <Col className={classes.formField} lg={12} md={12}>
                                            <Form.Label className={classes.formLabel}>Subject*</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                className={classes.formControl}
                                                value={subject}
                                                onChange={(e)=> setSubject(e.target.value)}
                                                placeholder={"Subject"}
                                            />
                                        </Col>
                                        <Col className={classes.formField} lg={12} md={12}>
                                            <Form.Label className={classes.formLabel}>Phone Number*</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                className={classes.formControl}
                                                value={phone}
                                                onChange={(e)=> setPhone(e.target.value)}
                                                placeholder={"Enter Your Phone Number"}
                                            />
                                        </Col>
                                        <Col className={classes.formField} lg={12} md={12}>
                                            <Form.Label className={classes.formLabel}>
                                                Message*
                                            </Form.Label>
                                            <Form.Control
                                                required
                                                rows={5}
                                                as={"textarea"}
                                                name="Message"
                                                value={message}
                                                className={classes.formControl}
                                                onChange={(e)=> setMessage(e.target.value)}
                                                placeholder={"Message"}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Form>
                            {!customerServiceLoading && response_result?.status !== undefined &&
                            <Title
                                text={"You message has been sent successfully"}
                                fontWeight={200}
                                fontSize={14}
                                lineHeight={20}
                                color={"green"}
                                fontFamily={'Renner, sans-serif'}
                                textAlign="center"
                                />}
                            <Button
                                disabled={checkIfSubmitDisabled()}
                                text={"Submit"}
                                handleSubmit={handleSubmit}
                                loading={customerServiceLoading}
                            />
                        </Col>
                    </Row>
                </Container>
            </ProductDesktop>
        </ProductMain>
    );
};

let styles = (theme) => authStyle(theme);
let ProductMain = styled.div`
  margin-top: 100px;
  padding: 0 15px;
  margin-bottom: 100px;
  //height: ${(props) => (props.product ? "auto" : "120vh")};

  .loader {
    margin-top: 25%;
    text-align: center;
  }

  @media (max-width: 991px) {
    padding: 0;
    margin-top: 120px;
  }
`;

let ProductDesktop = styled.div`
  .container {
    max-width: 95%;
    .row{
      justify-content: center;
      align-items: center;
    }
  }
  
  .main {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .left-div {
    padding-left: 0px;
    padding-right: 0px;
  }

  .right-sticky {
    height: auto;
    padding: 0 15px 15px 30px;
  }

  .right-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .price-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .variation-div {
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .color-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
  }

  .color-desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
  }

  .button-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
  }

  .button-inner {
    width: 82%;
    margin-right: 20px;
  }

  @media (max-width: 991px) {
    .right-sticky {
      display: none;
    }
  }
`;
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        color: state.isSelected ? "#FFF" : "#313132",
        backgroundColor: state.isSelected ? "#313132" : "#FFF",
        margin: 0,
    }),
    menu: (provided, state) => ({
        ...provided,
        color: "rgba(0,0,0,0.5)",
        backgroundColor: state.isSelected ? "#000" : "#FFF",
        margin: 0,
        maxHeight: 100,
        overflowY: "auto",
    }),
    menuList: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#000" : "#FFF",
        borderRadius: 0,
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: "none",
    }),
};

function mapStateToProps(state) {
    return {
        customerServiceLoading: state.customerServiceReducer.customer_service_loading,
        response_result: state.customerServiceReducer.response_result
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addCustomerService: (data) => dispatch(addCustomerService(data)),
    };
}

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(CustomerService)
);
