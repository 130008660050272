import React from "react";
import { Row, Col } from "react-bootstrap";
import Title from "../../../components/Title";
import styled from "styled-components";
import Counter from "./counter";
import {
  addItemToCart,
  removeItemFromCart,
  updateCart,
  handleRightDrawer
} from "../../../redux/actions/rightDrawer";
import { connect } from "react-redux";
import DeleteIcon from "@material-ui/icons/HighlightOffOutlined";
import IconButton from "@material-ui/core/IconButton";
import TimerMixin from 'react-timer-mixin';
import { useHistory, Link } from "react-router-dom";
import { numberWithCommas } from "../../../helpers/globalFunc";
import CrossBlack from "../../../assets/icons/cross-icon.svg";

function isObjectEmpty(obj) {
  if (typeof obj === 'object' && obj !== null) {
    return true
  } else return false
}

let CartItem = (props) => {
  let {
    product,
    addItemToCart,
    removeItemFromCart,
    getCurrent,
    cart,
    updateCart,
    authData,
    handleRightDrawer
  } = props;

  let { cartItem } = product;

  const userId = authData ? (authData.id ? authData.id : null) : null;

  let history = useHistory();

  let currentItem = getCurrent(cart, cartItem.id);

  let handleRemove = () => {
    if (userId) {
      const newData = {
        user_id: userId,
        product: currentItem.cartItem.id,
        count: currentItem.count,
        flag: false,
      };
      updateCart(newData);
    } else {
      let data = {
        count: currentItem.count,
        id: currentItem.cartItem.id,
      };
      removeItemFromCart(data);
    }
  };

  let onCartClick = (slug, parent) => {
    handleRightDrawer('')
    TimerMixin.setTimeout(() => {
      // if(parent) {
      //   history.push(`/product/${parent}?index=${slug}`)
      // } else {
      //   history.push(`/product/${slug}`)
      // }
      history.push(`/product/${slug}`)

    }, 500)
  }

  return (
    <StyledDiv>
      <div className="main-div">
        <div className="sub-div">
          <Counter
            item={cartItem}
            variant="lightVertical"
            value={currentItem.count}
            onIncrement={addItemToCart}
            onDecrement={removeItemFromCart}
          />
        </div>
        <div className="sub-div" onClick={() => onCartClick(cartItem.slug, cartItem.parent_id)}>
          <img
            src={cartItem.image ? cartItem.image : isObjectEmpty(cartItem.image_id) ? cartItem.image_id?.url : cartItem.image_id}
            alt=""
            style={{ width: 90, height: 70 }}
          />
        </div>
        <div className="body-div">
          <Link onClick={() => onCartClick(cartItem.slug, cartItem.parent_id)}>
            <div>
              <Title
                text={cartItem.name}
                fontSize={14}
                lineHeight={20}
                fontFamily={'Poppins'}
                fontWeight={"600"}
                color={"#313132"}
                textAlign={"left"}
              />
            </div>
          </Link>
          <div className="sku-div">
            <Title
              text={`SKU: ${cartItem.sku}`}
              fontSize={12}
              lineHeight={20}
              fontFamily={'Poppins'}
              fontWeight={"400"}
              color={"#707070"}
              textAlign={"left"}
              margin={"0 20px 0 0"}
            />
            <div className="button-div">
              <IconButton className="icon-button" onClick={handleRemove}>
                <img src={CrossBlack} alt="" style={{width: 12}}/>
              </IconButton>
            </div>
          </div>
          {
            cartItem?.stock === "instock" ?
                <div className="price-div">
                  <Title
                      text={`Total: ${currentItem.count} * ${numberWithCommas(
                          parseInt(product.cartItem.price)
                      )}`}
                      fontSize={12}
                      lineHeight={20}
                      fontFamily={'Poppins'}
                      fontWeight={"400"}
                      color={"#313132"}
                      textAlign={"left"}
                      margin={"10px 50px 0 0"}
                  />
                  <div className="price-text">
                    <Title
                        text={'BDT'}
                        fontSize={8}
                        lineHeight={20}
                        fontFamily={'Poppins'}
                        fontWeight={"600"}
                        color={"#313132"}
                        textAlign={"right"}
                        margin={"9px 3px 0 0"}
                    />
                    <Title
                        text={`${numberWithCommas(
                            parseFloat(currentItem.count * parseFloat(cartItem.price))
                        )}`}
                        fontSize={12}
                        lineHeight={20}
                        fontFamily={'Poppins'}
                        fontWeight={"600"}
                        color={"#313132"}
                        textAlign={"right"}
                        margin={"10px 0 0 0"}
                    />
                  </div>
                </div>
                :
                <div className="price-div">
                  <Title
                      text={'Out of Stock'}
                      fontSize={12}
                      lineHeight={20}
                      fontFamily={'Poppins'}
                      fontWeight={"400"}
                      color={"#313132"}
                      textAlign={"left"}
                      margin={"10px 50px 0 0"}
                  />
                </div>
          }

        </div>
      </div>
    </StyledDiv>
  );
};

let StyledDiv = styled.div`
  .main-div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .sub-div {
    margin-right: 10px;
    cursor: pointer;
  }
  .body-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 200px;
  }
  .sku-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .price-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .price-text {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: absolute;
    right: 16px;
  }
  .button-div {
    position: absolute;
    right: 16px;
    top: 6px;
  }
  .icon {
    width: 18px;
  }
  .icon-button {
    padding: 0 !important;
  }
`;

function mapStateToProps(state) {
  return {
    authData: state.authReducer.authData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addItemToCart: (data) => dispatch(addItemToCart(data)),
    removeItemFromCart: (data) => dispatch(removeItemFromCart(data)),
    updateCart: (data) => dispatch(updateCart(data)),
    handleRightDrawer: data => dispatch(handleRightDrawer(data))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CartItem);
