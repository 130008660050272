import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Product from "../../home/components/Product";
import Text from "../../../components/Text";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { loadMore } from "../../../helpers/globalFunc";
import { connect } from "react-redux";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 2,
    width: 170,
    marginBottom: 13,
    marginTop: 8,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#989899",
  },
}))(LinearProgress);

const AvailableProduct = (props) => {
  let { featuredProducts, title, subTitle, loadNum,
    loadMoreLoading,changePageNumber,catId, pageNumber,categoryProducts
    ,fetchCategoryProductsWithPost, dataSize, isSearch, data, setShowPopup } = props;

  const isPreBook = subTitle === "Pre Book Now";

  let [last, setLast] = useState(loadNum ? loadNum : 20);

  let [productList, setProductList] = useState([]);

  const handleLoad = () => {
    if (!isSearch) {
      fetchCategoryProductsWithPost({
        id: catId,
        pageNum: `${Number(pageNumber) + 1}`,
        post_per_page: "20"
      }, categoryProducts)
    } else {
      data.pageNum = Number(pageNumber) + 1;
      fetchCategoryProductsWithPost({
        id: catId,
        pageNum: `${Number(pageNumber) + 1}`,
        post_per_page: "20"
      }, categoryProducts, true, data)
    }

    changePageNumber(`${Number(props.pageNumber) + 1}`);

  };

  useEffect(() => {
    if (featuredProducts.length) {
      // setProductList(featuredProducts.slice(0, last));
      setProductList(featuredProducts);
    }
  }, [featuredProducts]);

  return (
    <div>
      <Row>
        <Col>
          <div className="subTitle-text">{title}</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 24 }}
          >
            <div className="top-title-text">{subTitle}</div>
          </div>
        </Col>
      </Row>
      <Row>
        {productList.length
          ? productList.map((item, key) => {
              return (
                <Col key={key} lg={4} md={4} sm={6} className="column-cl">
                  <Product item={item} setShowPopup={setShowPopup} isPreBook={isPreBook} />
                </Col>
              );
            })
          : null}


      </Row>
      {subTitle !== "Pre Book Now" && featuredProducts[0]?.total > 20 && productList.length < featuredProducts[0]?.total ?
      <center>
        <Text
            text={`Showing ${dataSize} of ${featuredProducts[0]?.total}`}
            fontWeight={"normal"}
            fontFamily={"Poppins"}
            fontSize={10}
        />
        {loadMoreLoading ? <p style={{fontSize: "12px",fontFamily: "Poppins,sans-serif"}}>Loading more data...</p> : (
            <>
              <BorderLinearProgress
                  variant="determinate"
                  value={
                    parseFloat(dataSize / featuredProducts[0].total) * 100
                  }
              />
              <div className="read-more" onClick={() => handleLoad()}>
                <div className="load-button">Load more</div>
              </div>
            </>
        )}

      </center> : null }
      {/*{featuredProducts.length >= last ? (*/}
      {/*  <center>*/}
      {/*    <Text*/}
      {/*      text={`Showing ${productList.length} of ${featuredProducts.length}`}*/}
      {/*      fontWeight={"normal"}*/}
      {/*      fontFamily={"Poppins"}*/}
      {/*      fontSize={10}*/}
      {/*    />*/}
      {/*    <BorderLinearProgress*/}
      {/*      variant="determinate"*/}
      {/*      value={*/}
      {/*        parseFloat(productList.length / featuredProducts.length) * 100*/}
      {/*      }*/}
      {/*    />*/}
      {/*    <div className="read-more" onClick={() => handleLoad()}>*/}
      {/*      <div className="load-button">Load more</div>*/}
      {/*    </div>*/}
      {/*  </center>*/}
      {/*) : null}*/}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    loadNum: state.homeReducer.loadNum,
    loadMoreLoading: state.homeReducer.loadMoreLoading,
  };
}

export default connect(mapStateToProps)(AvailableProduct);
