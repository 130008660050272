import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import FooterLogo from "../assets/images/logo.png";
import { Link, useLocation, withRouter } from "react-router-dom";
import Text from "./Text";
import Title from "./Title";
import facebookIcon from "../assets/icons/facebook.svg";
import instagramIcon from "../assets/icons/instagram.svg";
import twitterIcon from "../assets/icons/twitter.svg";
import Newsletter from "./NewsletterText";
import FooterCollapsible from "./FooterCollapsible";
import { validateEmail } from "../helpers/validators";
import TimerMixin from "react-timer-mixin";
import {connect} from "react-redux";
import prebook from "../container/prebook";
import paymentlogo from "../assets/images/paymentLogo2.png"
import paymentlogoUpdated from "../assets/images/shanta_ssl_footer.png"
import moment from "moment";
const Footer = (props) => {
  let { pathname } = useLocation();

  let { submitNewsletter, initNewsLetter, newsletter, authData } = props;

  let [email, setEmail] = useState("");
  let [emailErr, setEmailErr] = useState("");
  let [success, setSuccess] = useState("");
  let [error, setError] = useState("");

  let handleEmail = (e) => {
    setEmailErr("");
    setEmail(e.target.value);
  };

  let handleSubmit = () => {
    let isValid = validateEmail.test(email);

    if (!isValid) {
      setEmailErr("Email is not valid");
    } else {
      let data = {
        email: email,
      };

      submitNewsletter(data);
    }
  };

  useEffect(() => {
    if (newsletter) {
      if (newsletter.status) {
        setError(newsletter.message);
        setEmail("");
        TimerMixin.setTimeout(() => {
          initNewsLetter();
        }, 4000);
      } else {
        setSuccess(newsletter.message);
        setEmail("");
        TimerMixin.setTimeout(() => {
          initNewsLetter();
        }, 4000);
      }
    }
  }, [newsletter]);

  return (
    <StyledFooter id="footer" className="footer">
      <Container>
        <Row>
          <Col md={3}>
            <div className="footer__top">
              <Title
                text={"Join Shanta Lifestyle"}
                fontSize={16}
                fontWeight={700}
                lineHeight={24}
                color={"#313132"}
                fontFamily={"Renner,sans-serif"}
              />
              <Text
                text={
                  "Bring your ideas to life with inspiration, seasonal offers, exclusive events, and lots of good things in store."
                }
                fontSize={14}
                margin={"10px 0"}
                lineHeight={20}
                color={"#313132"}
                fontWeight={400}
                fontFamily={"Renner,sans-serif"}
              />
              <Title
                text={"Subscribe to our newsletter"}
                fontSize={12}
                fontWeight={400}
                lineHeight={15}
                color={"#5D5D5D"}
                fontFamily={"Renner,sans-serif"}
                margin={"30px 0 10px 0"}
              />
              <Newsletter
                handleEmail={handleEmail}
                handleSubmit={handleSubmit}
                email={email}
              />
              {success.length ? (
                <Title
                  text={success}
                  fontWeight={200}
                  fontFamily={"Renner,sans-serif"}
                  fontSize={14}
                  lineHeight={20}
                  color={"green"}
                />
              ) : null}
              {error.length ? (
                <Title
                  text={error}
                  fontWeight={200}
                  fontFamily={"Renner,sans-serif"}
                  fontSize={14}
                  lineHeight={20}
                  color={"red"}
                />
              ) : null}
              {emailErr.length ? (
                <Title
                  text={emailErr}
                  fontWeight={200}
                  fontFamily={"Renner,sans-serif"}
                  fontSize={14}
                  lineHeight={20}
                  color={"red"}
                />
              ) : null}
              <div className="social">
                <div className="social-button">
                  <a href={'https://www.facebook.com/shantalifestyle'} target="_blank">
                    <img src={facebookIcon} alt="" />
                  </a>
                </div>
                <div className="social-button">
                  <a href={'https://instagram.com/shantalifestyle?igshid=YmMyMTA2M2Y='} target="_blank">
                    <img src={instagramIcon} alt="" />
                  </a>
                </div>
              </div>
            </div>
          </Col>
          <Col md={9} className="desktop-menu footer__menu">
            <div className="footer__menu__single">
              <h6>Support</h6>
              <ul>
              <li>
                  <Link to={"/faq"}>FAQ</Link>
                </li>
                {/*<li>*/}
                {/*  <Link to={"/contact-us"}>Contact Us</Link>*/}
                {/*</li>*/}
                <li>
                  <Link to={"/book-appointment"}>Book an Appointment</Link>
                </li>
                {
                  authData &&
                  <li>
                    <Link to={"/orders"}></Link>
                  </li>
                }

                <li>
                  <Link to={"/customer-service"}>Customer Service</Link>
                </li>
                {/*<li>*/}
                {/*  <Link to={"/privacy-policy"}>Customer Services</Link>*/}
                {/*</li>*/}
              </ul>
            </div>
            <div className="footer__menu__single">
              <h6>View & Explore</h6>
              <ul>
                {/*<li>*/}
                {/*  <Link to={"/"}>Products</Link>*/}
                {/*</li>*/}
                <li>
                  <Link to={"/featured"}>View by Product</Link>
                </li>
                <li>
                  <Link to={"/room"}>View by Room</Link>
                </li>
                <li>
                  <Link to={"/brand"}>View by Brand</Link>
                </li>
                {/* <li>
                  <Link to={"/stories"}>Stories</Link>
                </li> */}
                {/*<li>*/}
                {/*  <Link to={"/"}>Offers</Link>*/}
                {/*</li>*/}
              </ul>
            </div>
            <div className="footer__menu__single">
              <h6>About Us</h6>
              <ul>
                <li>
                  <Link to={"/about-us"}>Shanta Lifetyle</Link>
                </li>
                <li>
                  <Link to={"/blog-and-news"}>News & Blog</Link>
                </li>
                <li>
                  <Link to={"/webseries"}>Webseries</Link>
                </li>
                {/*<li>*/}
                {/*  <Link to={"/"}>Our Belief</Link>*/}
                {/*</li>*/}
                {/*<li>*/}
                {/*  <Link to={"/"}>Our Team</Link>*/}
                {/*</li>*/}
              </ul>
            </div>
            <div className="footer__menu__single">
              <h6>Legal</h6>
              <ul>
                {/*<li>*/}
                {/*  <Link to={"/"}>Privacy & Security</Link>*/}
                {/*</li>*/}
                <li>
                  <Link to={"/terms-conditions"}>Terms and conditions</Link>
                </li>
                <li>
                  <Link to={"/privacy-policy"}>Privacy Policy</Link>
                </li>
               
                <li>
                  <Link to={"/warranty-policy"}>Warranty Policy</Link>
                </li>
                <li>
                  <Link to={"/refund-returns"}>Refund Policy</Link>
                </li>
              </ul>
            </div>
          </Col>
        </Row>

        <Row className="footer__menu-mobile">
          <FooterCollapsible />

          <div className="social-mobile">
           <a href={'https://www.facebook.com/shantalifestyle'} target="_blank">
             <img src={facebookIcon} alt="" />
           </a>
            <a href={'https://instagram.com/shantalifestyle?igshid=YmMyMTA2M2Y='} target="_blank">
              <img src={instagramIcon} alt="" />
            </a>
          </div>

        </Row>
        {/*<div className="payment_logo">*/}
        {/*    <img src={paymentlogoUpdated}/>*/}
        {/*</div>*/}
        <Row style={{borderTop:'1px solid rgba(0,0,0,.25)', paddingTop:'5px', paddingBottom:'5px'}} >

          <Col md={7}>
            <p style={{paddingTop:'20px'}}>
              © {moment(new Date()).format('YYYY')} All rights reserved by Shanta Lifestyle Designed & Developed by{" "}
              <a target="_blank" href="http://dcastalia.com/">
                Dcastalia
              </a>
            </p>
          </Col>

          <Col md={5}>
            <img style={{width:'100%'}} src={paymentlogoUpdated}/>
          </Col>

          <div className="">
          </div>

        </Row>
        {/*/!*<div className="footer__bottom">*!/ payment_logo*/}

        {/*</div>*/}
      </Container>
    </StyledFooter>
  );
};

const StyledFooter = styled.div`
  background-color: #efefef;
  position: relative;
  padding-top: 60px;

  .container {
    max-width: 95%;
    @media (max-width: 991px) {
      max-width: 100%;
      padding: 20px;
    }
  }

  //&:after {
  //  content: "";
  //  text-align: center;
  //  position: absolute;
  //  bottom: 73px;
  //  height: 1px;
  //  left: 3.5%;
  //  width: 93%;
  //  background-color: rgba(0, 0, 0, 0.25);
  //}

  .footer__top {
    margin-bottom: 58px;
    width: 80%;

    img {
      height: 40px;
      width: 114px;
    }
    @media (max-width: 1030px) {
      width: 100%;
    }
  }

  .booking {
    background-color: #419ae8;
    margin-top: 30px;
    border-radius: 3px;
    max-width: 120px;
    text-align: center;
  }

  .icon-text {
    color: #fff;
    font-size: 15px;
  }

  .footer__menu {
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
    width: 100%;
    margin-bottom: 80px;

    &__single {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      flex-direction: column;
      margin: 0 30px;
      max-width: 200px;

      h6 {
        margin: 0 0 20px 0;
        font-family: Renner,sans-serif;
        font-size: 14px;
        color: #313132;
        font-weight: bold;
        line-height: 24px;
        text-transform: capitalize;
      }

      ul {
        li {
          a {
            font-size: 12px;
            font-family: Renner,sans-serif;
            font-weight: 400;
            line-height: 15px;
            color: #5d5d5d;
            display: block;
            margin-bottom: 10px;
            text-transform: capitalize;

            &:hover {
              text-decoration: underline;
            }
          }

          &:nth-last-of-type(1) a {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .social {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    bottom: 20px;
  }

  .social-button {
    cursor: pointer;
    img {
      color: #313132;
      width: 12px;
      height: 12px;
      margin-right: 20px;
    }
  }

  .social-mobile {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 20px;

    img {
      color: #313132;
      width: 18px;
      height: 18px;
      margin-right: 30px;
    }
  }
  .payment_logo{

    padding-bottom: 30px;
    max-width: 30px;
    margin: 0 auto;
    img{
      position: absolute;
      top: 130px;
      right: 55px;
      bottom: 0;
      margin: auto;
      max-width: 30%;
      max-height: 30%;

    }
  }

  .footer__bottom {
    padding: 20px 0;
    display: flex;

    p {
      font-weight: 400;
      font-size: 12px;
      margin-bottom: 3px;
      color: #313132;

      &:nth-last-of-type(1) {
        margin-bottom: 0;
      }

      a {
        &:hover {
          text-decoration: underline !important;
        }
      }
    }
    
    div {
      
    }
  }

  .footer__menu-mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 60px;

    .collapsible {
      width: 100%;
      margin: 0 15px 15px;
    }
    
  }

  .footer__menu-mobile__single {
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    flex: 1;
   
    h6 {
      margin: 0 0 20px 0px;
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      text-transform: capitalize;
    }

    ul {
      li {
        a {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          display: block;
          margin-bottom: 20px;
          text-transform: capitalize;
        }
      }
    }
  }

  .footer__menu-mobile {
    width: 100%;
  }

  @media (max-width: 1030px) {
    .payment_logo{
      img{
        top: 300px;
      }
    }
  }

  @media (min-width: 767px) {
    .footer__menu-mobile {
      display: none;
    }
    
  }
  @media (max-width: 767px) {
    .desktop-menu {
      display: none;
    }
    .footer__bottom{
      padding: 0;
    }
    
    .social {
      display: none;
    }

    &:after {
      //content: "";
      //text-align: center;
      //position: absolute;
      //bottom: 80px;
      //height: 1px;
      //left: 3%;
      //width: 94%;
      //background-color: rgba(0, 0, 0, 0.25);
    }

    .social {
      margin-left: -18px;
    }
  }

  @media (max-width: 1700px) {
    .footer__menu__single {
      max-width: 150px;
    }
  }
  @media (max-width: 1030px) {
    .footer__menu {
      flex-wrap: wrap;
      width: 100%;
    }

    .footer__menu__single {
      min-width: 25%;
      max-width: 25%;
      margin-bottom: 40px;
    }
  }
`;



function mapStateToProps(state) {
  return {
    authData: state.authReducer.authData,
  }
}

export default withRouter(connect(mapStateToProps)(Footer));
