import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {connect} from 'react-redux'
import Title from "../../../components/Title";
import {numberWithCommas} from "../../../helpers/globalFunc";
import AddReview from "../../product-details/components/AddReview";
import {addReview, initReview} from "../../../redux/actions/auth";

let createData = (row, all) => {
  let found = all.find (item => item.id === row.product_id)

  if (found) return found
}

let BasicTable = (props) => {
  let {
    active,
    allProducts,
    mobile,
    authData,
    initReview,
    addReview,
    newReview,
    newReviewErr,
    newReviewLoading
  } = props;

  let [open, setOpen] = useState(false)
  let [productId, setId] = useState('')

  let lineItems = active.line_items;
  const classes = useStyles ();

  let handleOpen = id => {
    setOpen(true)
    setId(id)
  }

  return (
    <>
      <AddReview
        open={open}
        handleClickOpen={setOpen}
        authData={authData}
        productId={productId}
        submitReview={addReview}
        initReview={initReview}
        newReview={newReview}
        newReviewErr={newReviewErr}
        newReviewLoading={newReviewLoading}
      />
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{fontFamily: 'Poppins', fontWeight: 500, fontSize: 16, color: '#313132'}}><b>Product Description</b></TableCell>
            <TableCell align={mobile ? "left" : "center"} className={classes.review}>{''}</TableCell>
            <TableCell align={mobile ? "left" : "center"} style={{fontFamily: 'Poppins', fontWeight: 500, fontSize: 14, color: '#000'}}>Quantity</TableCell>
            <TableCell align={mobile ? "left" : "right"} style={{fontFamily: 'Poppins', fontWeight: 500, fontSize: 14, color: '#000'}}>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lineItems.map ((row) => {
            let getItem = createData(row, allProducts)
            let quantity = getItem ? `${numberWithCommas(parseFloat(getItem.price))}  * ${row.quantity}` : row.quantity
            let productImg = getItem ? getItem.variation_image : ''
            let sku = getItem ? `SKU: ${getItem.sku}` : ''
            //console.log("row", row)
            return (
              <TableRow key={row.name}>
                <TableCell className={classes.firstCol}>
                  <img
                    className={classes.productImg}
                    src={row.variation_image}
                  />
                  <div>
                    <Title
                      text={row.name}
                      fontSize={14}
                      lineHeight={24}
                      fontFamily={'Poppins'}
                      fontWeight={"600"}
                      color={'#313132'}
                      margin={'0 0 0 0'}
                    />
                    <Title
                      text={sku}
                      fontSize={12}
                      lineHeight={18}
                      fontFamily={'Poppins'}
                      fontWeight={'400'}
                      color={'#707070'}
                      margin={'0 0 10px 0'}
                    />
                  </div>
                </TableCell>
                <TableCell align={mobile ? "left" : "center"} className={classes.review}>
                  {active.status === 'delivered' ? (
                    <div onClick={() => handleOpen(row.id)}>
                      <Title
                        text={'Add Review'}
                        fontSize={12}
                        fontFamily={'Poppins'}
                        color={'#707070'}
                        lineHeight={18}
                        fontWeight={'400'}
                      />
                    </div>
                  ) : null}
                </TableCell>
                <TableCell align={mobile ? "left" : "center"}>
                  <Title
                      text={quantity}
                      fontSize={14}
                      lineHeight={20}
                      fontWeight={'400'}
                      color={'#5d5d5d'}
                      margin={'0 0 0px 0'}
                  />
                </TableCell>
                <TableCell align={mobile ? "left" : "right"}>
                  <Title
                    text={`BDT ${numberWithCommas(parseFloat(row.total))}`}
                    fontSize={14}
                    lineHeight={20}
                    fontWeight={'400'}
                    color={'#5d5d5d'}
                    margin={'0 0 0px 0'}
                  />
                  {active.status === 'delivered' ? (
                    <div className={classes.mobileReview} onClick={() => handleOpen(row.id)}>
                      <Title
                        text={'Add Review'}
                        fontSize={14}
                        color={'#707070'}
                        lineHeight={18}
                        fontWeight={'400'}
                        margin={"10px 20px 0 0"}
                        textAlign={"center"}
                      />
                    </div>
                  ) : null}
                </TableCell>
              </TableRow>
            )
          })
          }
        </TableBody>
      </Table>
    </>
  );
}

const useStyles = makeStyles (theme =>({
  table: {
    minWidth: 350,
    overflowX: "auto",
  },
  firstCol: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    [theme.breakpoints.down(767)]: {
      flexDirection: "column",
      alignItems: "flex-start"
    }
  },
  review: {
    [theme.breakpoints.down(767)]: {
      display: "none"
    }
  },
  lastCol: {
    display: "flex",
    flexDirection: "column",
    alignItems: 'center'
  },
  mobileReview: {
    display: "none",
    [theme.breakpoints.down(767)]: {
      display: "block"
    }
  },
  productImg: {
    width: 60,
    height: 50,
    marginRight: 16,
    [theme.breakpoints.down(767)]: {
      marginRight: 0,
    }
  }
}));

function mapStateToProps (state) {
  return {
    allProducts: state.homeReducer.allProducts,
    authData: state.authReducer.authData,
    newReview: state.authReducer.newReview,
    newReviewErr: state.authReducer.newReviewErr,
    newReviewLoading: state.authReducer.newReviewLoading,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addReview: data => dispatch(addReview(data)),
    initReview: () => dispatch(initReview()),
  }
}

export default connect (mapStateToProps, mapDispatchToProps) (BasicTable)
