import React from "react";
import Drawer from "@material-ui/core/Drawer";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  clearWishlist,
  handleRightDrawer,
  clearWishlistServer,
} from "../../../redux/actions/rightDrawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import HeartIcon from "../../../assets/icons/heart-icon-white.svg";
import WishlistButton from "./WishlistButton";
import CrossIcon from "../../../assets/icons/cross-button-white.svg";
import { rightDrawerStyle } from "../rightDrawerStyle";
import Title from "../../../components/Title";

import WishlistItem from "./wishListItem";
import styled from "styled-components";

let style = (theme) => rightDrawerStyle(theme);

let CartDrawer = (props) => {
  let {
    classes,
    openRight,
    handleRightDrawer,
    wishlist,
    clearWishlist,
    allWishlist,
    authData,
    clearWishlistServer,

  } = props;

  const userId = authData ? (authData.id ? authData.id : null) : null;

  let activeWishList = userId ? allWishlist : wishlist;

  let handleClose = () => {
    handleRightDrawer("");
  };

  let handleClear = () => {
    if (userId) {
      let data = {
        user_id: userId,
      };

      clearWishlistServer(data);
    } else {
      clearWishlist();
    }
  };

  const list = () => (
    <StyledDiv role="presentation">
      {activeWishList ? (
        activeWishList.length ? (
          activeWishList.map((item, key) => (
            <>
              <List key={key}>
                <ListItem>
                  <WishlistItem product={item} />
                </ListItem>
              </List>
              <Divider />
            </>
          ))
        ) : (
          <>
            <List>
              <ListItem>
                <Title
                  text={`No items in wishlist`}
                  color={"#313132"}
                  fontSize={14}
                  lineHeight={24}
                  fontWeight={"400"}
                  margin={"0 0 0 12px"}
                  textTransform={"capitalize"}
                />
              </ListItem>
            </List>
            <Divider />
          </>
        )
      ) : null}
    </StyledDiv>
  );

  return (
    <div>
      <Drawer
        anchor={"right"}
        open={openRight === "wishlist"}
        onClose={handleClose}
      >
        <div className={classes.drawerHeader}>
          <div className={classes.drawerHeaderText}>
            <img src={HeartIcon} alt="" />
            <Title
              text={
                activeWishList.length
                  ? `${activeWishList.length} items in wishlist`
                  : "No Items in wishlist"
              }
              color={"#fff"}
              fontSize={14}
              lineHeight={24}
              fontWeight={"400"}
              margin={"0 0 0 12px"}
              textTransform={"capitalize"}
            />
          </div>
          <div
            onClick={() => handleRightDrawer("")}
            className={classes.crossIcon}
          >
            <img src={CrossIcon} alt="" />
          </div>
        </div>
        <div className={classes.drawerBody}>
          {list()}
        </div>
        {activeWishList.length ? (
           <StyledButton>
             <WishlistButton
                 text={"Clear Wishlist"}
                 handleSubmit={handleClear}
             />
           </StyledButton>
        ) : null}
      </Drawer>
    </div>
  );
};

const StyledButton = styled.div`
  position: absolute;
  bottom: 0px;
  width: 90%;
  @media(max-width: 767px){
    width: 95%;
  }
  @media(max-width: 580px){
    width: 94%;
  }
  @media(max-width: 450px){
    width: 92%;
  }
`

let StyledDiv = styled.div`
  .MuiListItem-gutters {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`

function mapStateToProps(state) {
  return {
    openRight: state.rightDrawerReducer.openDrawer,
    wishlist: state.rightDrawerReducer.wishlist,
    authData: state.authReducer.authData,
    allWishlist: state.rightDrawerReducer.allWishlist,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleRightDrawer: (data) => dispatch(handleRightDrawer(data)),
    clearWishlist: () => dispatch(clearWishlist()),
    clearWishlistServer: (data) => dispatch(clearWishlistServer(data)),
  };
}

export default withStyles(style)(
  connect(mapStateToProps, mapDispatchToProps)(CartDrawer)
);
