import React from 'react';
import ReactHtmlParser from "react-html-parser";
import styled from "styled-components";
import {useLocation} from "react-router-dom";

const Datas = (props) => {
    let location = useLocation()
    let {pathname} = location

    return (
        <StyledBody>
            {
                pathname === '/terms-conditions' ?
                    <div>
                        {ReactHtmlParser(ReactHtmlParser(props.data))}
                    </div> : null
            }
            {
                pathname === '/privacy-policy' ?
                    <div>
                        {ReactHtmlParser(props.data)}
                    </div> : null
            }
        </StyledBody>
    );
};

let StyledBody = styled.div`
  min-height: 80vh;
  padding: 36px;
  margin-top: 100px;
  margin-bottom: 60px;
`

export default Datas;