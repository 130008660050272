import React, { useState } from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import RightIcon from "../../../assets/images/rightIconArrow.svg";
import LeftIcon from "../../../assets/images/navLeftBlack.svg";
import room from "../../../assets/images/room.png";
import innerSlider from "../../../assets/images/innerSlider.png";
import { featureData } from "../../../assets/data/products";
import Title from "../../../components/Title";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import rightArrowColored from "../../../assets/images/rightIconArrowColored.svg";

const ShopByRoom = (props) => {
  let { categories } = props;

  let history = useHistory();

  let [progress, setProgress] = useState(20);

  let handleProgress = (value) => {
    if (value > 100) setProgress(100);
    else if (value < 20) setProgress(20);
    else setProgress(value);
  };

  let room = categories
    ? categories.length
      ? categories.find((item) => item.slug === "room")
      : null
    : null;

  let roomId = room ? room.term_id : null;

  let roomChild = roomId
    ? categories.length
      ? categories.filter((item) => item.parent === roomId)
      : []
    : [];
  roomChild?.length>0 && roomChild.sort((x, y) => x.sort_order - y.sort_order);
  let allChild = roomChild.length
    ? roomChild.map((item) => {
        let subCategory = categories.filter(
          (sub) => sub.parent === item.term_id
        );
        let newObj = {
          ...item,
          child: subCategory,
        };

        return newObj;
      })
    : [];

  let handleRoute = (slug) => {
    history.push(`/room-products/${slug}`);
  };

  let handleProductRoute = (id) => {
    history.push(`/product-list/${id}`);
  };

  let len = roomChild.length;

  const params = {
    on: {
      progress: (swiper, progress) => handleProgress(Math.floor(swiper * 100)),
    },
    slidesPerView: 1,
    spaceBetween: 20,
    speed: 1000,
    // pagination: {
    //     el: '.swiper-pagination',
    //     clickable: true
    // },
    breakpoints: {
      575: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      576: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
    navigation: {
      nextEl: '.swiper-button-next-room',
      prevEl: '.swiper-button-prev-room'
    },
  };

  const triggerSwiperArrowClick = (clickNext) => {
    const swiper_next_item = document.getElementsByClassName(clickNext === true ? 'swiper-button-next-room' : 'swiper-button-prev-room');
    //console.log('shop by room',swiper_next_item);
    swiper_next_item[0]?.click();
  }

  const paramsInner = {
    slidesPerView: 3,
    spaceBetween: 15,
    speed: 1000,
    // pagination: {
    //     el: '.swiper-pagination',
    //     clickable: true
    // },
    // autoplay: {
    //   delay: 4500,
    //   disableOnInteraction: false,
    // },
    breakpoints: {
      575: {
        slidesPerView: 3,
        spaceBetween: 15,
      },
      1080: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
      1081: {
        slidesPerView: 3,
        spaceBetween: 15,
      },
      1500: {
        slidesPerView: 3,
        spaceBetween: 15,
      },
      1501: {
        slidesPerView: 4,
        spaceBetween: 15,
      },
    },
    navigation: {
      nextEl: ".swiper-button-next",
      //prevEl: '.swiper-button-prev'
    },
  };

  return (
    <StyledShopByRoom>
      <Container className="news-events-slider">
        <Row>
          <Col sm={12}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 32,
                marginBottom: 24,
              }}
            >
              <div className="top-title">View by Room</div>
              {roomChild.length> 3 &&
              <div className="custom-slider-arrow">
                <button onClick={() => triggerSwiperArrowClick(false)}
                        className="custom-prev-btn">
                  <img src={rightArrowColored} alt=""/>
                </button>
                <button onClick={() => triggerSwiperArrowClick(true)}
                        className="custom-next-btn">
                  <img src={rightArrowColored} alt=""/>
                </button>
              </div>}
              <div
                style={{
                  marginLeft: "auto",
                  backgroundColor: "#EFEFEF",
                  padding: "5px 18px 5px 18px",
                  borderRadius: "50px",
                  cursor: "pointer",
                }}
              >
                <div
                  style={{ color: "#313132", fontSize: 12, fontWeight: 400 }}
                >
                  <li>
                    <Link to="/room">See all</Link>
                  </li>
                </div>
              </div>
            </div>
            {roomChild.length && len === roomChild.length ?  (
              <>
                <Swiper {...params}>
                  {allChild.map((data, key) => {
                    return (

                    <div key={key} className="news-events-slider__single">


                      <div className="news-events-slider__single__img"
                          onClick={() => handleRoute(data.slug)}
                        >
                          <img src={data.image_url} alt={`${data.name} image`} />
                        </div>
                        <div className="news-events-slider__single__content">
                          <Title
                            text={data.name}
                            fontSize={24}
                            color="#ffffff"
                            fontWeight={500}
                            lineHeight={"32"}
                            textTransform={"uppercase"}
                          />
                          {/*<Title*/}
                          {/*  text={`Everything you need in your ${data.name}`}*/}
                          {/*  fontSize={14}*/}
                          {/*  color="#EFEFEF"*/}
                          {/*  fontWeight={300}*/}
                          {/*  fontFamily={"Poppins"}*/}
                          {/*  lineHeight={"16"}*/}
                          {/*  textTransform={'normal'}*/}
                          {/*  letterSpacing={1}*/}
                          {/*  margin={"5px 0 0 0"}*/}
                          {/*/>*/}
                          <div style={{ marginTop: 30 }}>
                            {data.child.length ? (
                              <Swiper {...paramsInner}>
                                {data.child.map((childData, key) => {
                                  return (
                                    <div
                                        key={key}
                                      className="inner-slider"
                                      onClick={() =>
                                        handleProductRoute(childData.term_id)
                                      }
                                    >
                                      <div
                                        style={{
                                          display: "table-row",
                                          alignItems: "center",
                                          textAlign: "center",
                                          marginRight: 28,
                                        }}
                                      >
                                        <img src={childData.image_url} alt="" />

                                        <div
                                          style={{
                                            fontSize: 10,
                                            fontWeight: 400,
                                            fontFamily: "Poppins",
                                            color: "#fff",
                                            paddingTop: 10,
                                            height: 40
                                          }}
                                        >
                                          {childData.name}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </Swiper>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Swiper>
                <LinearProgress
                  style={{ marginTop: 30, height: 2 }}
                  variant="determinate"
                  value={progress}
                />
              </>
            ) : null}
          </Col>
        </Row>
      </Container>
    </StyledShopByRoom>
  );
};

const StyledShopByRoom = styled.div`
  background-color: #fff;
  position: relative;
  padding-bottom: 32px;

  .container {
    max-width: 95%;
  }

  .subTitle {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 8px;
    padding-bottom: 2px;
    color: rgb(156, 148, 52);
    padding-top: 32px;
  }

  .top-title {
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
    padding-left: 8px;
    color: #313132;
  }

  .news-events-slider {
    &__single {
      &__content {
        background-color: #43484c;
        padding: 20px;

        h4 {
          font-size: 14px;
          text-transform: uppercase;
          font-weight: 500;
          line-height: 21px;
          margin: 0 0 20px 0;
          color: rgba(9, 32, 59, 0.5);
        }

        p {
          font-size: 20px;
          font-weight: bold;
          line-height: 30px;
          margin-bottom: 40px;
        }

        h2 {
          width: 80%;
          height: 42px;
        }

        .line {
          margin-top: 4px;
          border-top: 1px solid rgba(255, 255, 255, 0.5);
        }

        .inner-slider {
          width: 72px !important;
          margin-right: auto;
          display: flex;
          overflow: hidden;
          cursor: pointer;

          img {
            height: 60px;
            width: 60px;
          }
        }

        @media (max-width: 1280px) {
          .inner-slider {
            width: 64px !important;
            margin-right: auto;
            display: flex;
            overflow: hidden;

            img {
              height: 50px;
              width: 50px;
              margin-right: 15px;

              &:nth-last-of-type(1) {
                margin-right: 0;
              }
            }
          }
        }
      }

      &__img {
        padding-top: 105.41%;
        position: relative;
        overflow: hidden;
        z-index: 3;
        cursor: pointer;

        a {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          z-index: 3;
        }

        img {
          position: absolute;
          height: 100%;
          width: 100%;
          object-fit: cover;
          transform: scale(1.01);
          top: 0;
          left: 0;
          transition: all 2s ease;
        }

        &:hover {
          img {
            transform: scale(1.05);
            filter: contrast(140%);
          }
        }
      }
    }

    &__bottom {
      margin-top: -50px;
    }
  }

  .NewsEvents-slider__bottom {
    position: relative;
    z-index: 3;
  }

  .swiper-container {
    //margin-left: -50px;
    //margin-right: -50px;
    //padding-right: 50px;
    //padding-left: 50px;
    position: relative;

    //&:before {
    //  content: "";
    //  position: absolute;
    //  height: 100%;
    //  width: 50px;
    //  background-color: rgba(99, 110, 127, 0);
    //  left: 0;
    //  top: 0;
    //  z-index: 2;
    //}
    //
    //&:after {
    //  content: "";
    //  position: absolute;
    //  height: 100%;
    //  width: 50px;
    //  background-color: rgba(99, 110, 127, 0);
    //  right: 0;
    //  top: 0;
    //  z-index: 2;
    //}
  }

  .swiper-button-next {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 3;
    height: 25px;
    width: 25px;
    background-image: url(${RightIcon});
    background-position: center;
    background-size: contain;
    top: -22px;
    left: auto;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }
  .swiper-button-disabled {
    opacity: 1 !important;
  }

  .swiper-button-prev {
    position: absolute;
    right: auto;
    bottom: 0px;
    margin: auto;
    z-index: 3;
    height: 50px;
    width: 50px;
    top: 0;
    left: -14px;
    cursor: pointer;
    background-image: url(${LeftIcon});
    background-size: contain;

    &:focus {
      outline: none;
    }
  }

  .swiper-wrapper {
    margin-bottom: 0px;
  }

  .custom-slider-arrow{
    margin-left: 8px;
    display: flex;
    column-gap: 8px;
    .custom-next-btn,.custom-prev-btn{
      background: rgb(239, 239, 239);
      border-radius: 50%;
      border: unset;
      padding: 0;
    }
    .custom-prev-btn{
      transform: rotate(180deg);
    }
  }
  
  @media (max-width: 1024px) {
    .swiper-button-next {
      position: absolute;
      right: 0;
      bottom: 0;
      margin: auto;
      z-index: 3;
      height: 25px;
      width: 25px;
      background-image: url(${RightIcon});
      background-position: center;
      background-size: contain;
      top: -22px;
      left: auto;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }

    .swiper-button-prev {
      position: absolute;
      right: auto;
      bottom: 0px;
      margin: auto;
      z-index: 3;
      height: 50px;
      width: 50px;
      top: 0;
      left: 12px;
      cursor: pointer;
      background-image: url(${LeftIcon});
      background-size: contain;

      &:focus {
        outline: none;
      }
    }
  }

  @media (max-width: 767px) {
    .sub-title:nth-of-type(1) {
      margin-bottom: 58px;
    }

    .title {
      width: 100% !important;
    }

    .swiper-wrapper {
      margin-bottom: 0px;
    }
  }
`;

function mapStateToProps(state) {
  return {
    categories: state.homeReducer.categories,
  };
}

export default connect(mapStateToProps)(ShopByRoom);
