import React, {useState, useEffect} from "react";
import {Form, Row, Col, Container} from "react-bootstrap";
import {
    handlePreBook,
    searchSlot,
    submitSlot,
    clearSubmit,
    clearSlot,
} from "../../redux/actions/prebooking";
import {withStyles} from "@material-ui/core";
import {authStyle} from "../auth/authStyle";
import {connect} from "react-redux";
import Button from "../../components/NewButtonSubmit";
import CrossIcon from "../../assets/icons/cross-icon.svg";
import Divider from "@material-ui/core/Divider";
import Options from "./components/options";
import DatePicker from "./components/date-select";
import {
    validatePhone,
    validateNameField,
    validateEmail,
} from "../../helpers/validators";
import Select from "react-select";
import TimerMixin from "react-timer-mixin";
import Title from "../../components/Title";
import LeftSection from "../product-details/components/LeftSection";
import RightSection from "../product-details/components/RightSection";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";
import router from "react-router-dom/es/Router";
import {useHistory} from "react-router-dom";
import moment from "moment";

const BookAppointment = (props) => {
    const {
        openPreBook,
        handlePreBook,
        classes,
        authData,
        searchSlot,
        submitSlot,
        slots,
        submit,
        productId,
        submitLoading,
    } = props;

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [type, setType] = useState("physical");
    const [date, setDate] = useState("");
    const [slot, setSlot] = useState(null);
    const [submitMsg, setSubmitMsg] = useState("");
    const [submitErr, setSubmitErr] = useState("");
    const history = useHistory();
    const [nameError, setNameError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [slotError, setSlotError] = useState('');



    useEffect(() => {
        window.scrollTo ({top: 0,behavior: "smooth"});
        if (authData) {
            if (authData.token) {
                setName(authData.display_name);
                setPhone(authData.user_phone);
                setEmail(authData.user_email);
            }
        }
    }, []);

    useEffect(() => {
        if (submit) {
            if (submit.status === 0) {
                setSubmitErr(submit.message);
                TimerMixin.setTimeout(() => {
                    setSlot(null);
                    clearSubmit();
                    setType("physical");
                    setSubmitErr("");
                }, 4000);
            } else {
                setSubmitMsg(
                    "Slot booked successfully. An email/text will be sent to your Email Address/Phone Number"
                );
                TimerMixin.setTimeout(() => {
                    handlePreBook(false, null);
                    setSubmitMsg("");
                    clearSubmit();
                    setSlot(null);
                    setDate("");
                    setType("physical");
                    clearSlot();
                }, 4000);
            }
        }
    }, [submit]);

    let slotOptions = slots.length
        ? slots.map((item) => {
            let obj = {
                value: item,
                label: item.slot_time,
            };
            return obj;
        })
        : [];

    const handleName = (e) => {
        setName(e.target.value);
    };

    const handlePhone = (e) => {
        setPhone(e.target.value);
    };

    const handleEmail = (e) => {
        setEmail(e.target.value);
    };

    const setPreBook = () => {
        handlePreBook(false, null);
    };
    let isName = validateNameField(name);

    let isPhone = validatePhone(phone);
    // console.log(isName, isPhone)
    const handleSubmitCheck=()=>{
        if(!isName){
            setNameError('please fill up name field')
        }
        if(!isPhone){
            setPhoneError('please fill up phone field')
        }
        if(!slot){
            setSlotError('please fill up the slot')
        }
        if(isName && isPhone && slot ){
           handleSubmit();
        }

    }
    const handleSubmit = () => {
        let isName = validateNameField(name);
        let isPhone = validatePhone(phone);
        let isEmail = email.length ? validateEmail.test(email) : true;

            // console.log(isName,isPhone,slot);
            if (isName && isPhone && isEmail && slot && date.length) {
                const data = {
                    appoint_name: name,
                    apoint_email: email,
                    appoint_phone: phone,
                    appoint_visit_type: type,
                    appoint_slot_id: slot.id,
                    appoint_slot_time: slot.slot_time,
                    appoint_slot_start: slot.slot_start,
                    appoint_slot_end: slot.slot_end,
                    appoint_product: productId,
                    appoint_status: "2",
                    appoint_date: date,
                };
                // console.log(isName,isPhone,slot.slot_time);
                // console.log(data)
                if(authData) {
                    if (authData.id)
                        data.user_id = authData.id;
                }
                submitSlot(data,"appointment");

                let appoint = {
                    appoint_date: date,
                };
                setTimeout(()=>{
                    searchSlot(appoint);
                },1500);

                setTimeout(()=>{
                    resetAppointmentFormData();
                },3000);
            }


    };

    const resetAppointmentFormData = ()=>{
        setName("");
        setEmail("");
        setPhone("");
        setType("");
        setSlot(null);
        setDate(null);
        //history.push('/')
        window.location.reload()
    }

    return (
        <ProductMain>
            <ProductDesktop>
                <Container className="main">
                    <Row>
                        <Col md={12} lg={4} className="book-an-appointment-form">
                            <Title
                                text={"Book an appointment"}
                                color={"#9C9434"}
                                fontSize={12}
                                lineHeight={18}
                                fontWeight={500}
                                fontFamily={'Renner, sans-serif'}
                                textAlign={"left"}
                                letterSpacing={60}
                                textTransform={"uppercase"}
                                // margin={'15px 0px 15px 0px'}
                            />
                            <Divider/>
                            <Form>
                                <Form.Group>
                                    <Row>
                                        <Col className={classes.formField} md={12}>
                                            <Form.Label className={classes.formLabel}>Name*</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                className={classes.formControl}
                                                value={name}
                                                onChange={handleName}
                                                placeholder={"Your Name"}
                                            />
                                            <span className="error"><small>{nameError}</small></span>

                                        </Col>
                                        <Col className={classes.formField} md={12}>
                                            <Form.Label className={classes.formLabel}>
                                                Mobile Number*
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="Phone"
                                                value={phone}
                                                className={classes.formControl}
                                                onChange={handlePhone}
                                                placeholder={"Your Phone No"}
                                            />
                                            <span className="error"><small>{phoneError}</small></span>
                                        </Col>
                                        <Col className={classes.formField} md={12}>
                                            <Form.Label className={classes.formLabel}>Email</Form.Label>
                                            <Form.Control
                                                value={email}
                                                className={classes.formControl}
                                                placeholder={"Your Email"}
                                                onChange={handleEmail}
                                            />
                                        </Col>
                                        <Col className={classes.formField} md={12}>
                                            <Options checked={type} setChecked={setType}/>
                                        </Col>
                                        <Col md={12}>
                                            <DatePicker handleDate={setDate} searchSlot={searchSlot}/>
                                        </Col>
                                        <Col className={classes.formField} md={12}>
                                            <Select
                                                styles={customStyles}
                                                placeholder="Select Time"
                                                onFocus={false}
                                                className={classes.formControl}
                                                options={slotOptions}
                                                onChange={(e) => setSlot(e.value)}
                                            />
                                            <span className="error"><small>{slotError}</small></span>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Form>
                            <Button
                                text={"Book An Appointment"}
                                handleSubmit={handleSubmitCheck}
                                loading={submitLoading}
                            />
                            {submitErr.length ? (
                                <Title
                                    text={submitErr}
                                    fontWeight={200}
                                    fontSize={14}
                                    lineHeight={20}
                                    color={"red"}
                                    fontFamily={'Renner, sans-serif'}
                                    textAlign="center"
                                />
                            ) : null}
                            {submitMsg.length ? (
                                <Title
                                    text={submitMsg}
                                    fontWeight={200}
                                    fontSize={14}
                                    lineHeight={20}
                                    color={"green"}
                                    fontFamily={'Renner, sans-serif'}
                                    textAlign="center"
                                />
                            ) : null}
                        </Col>
                    </Row>
                </Container>
            </ProductDesktop>
        </ProductMain>
    );
};

let styles = (theme) => authStyle(theme);
let ProductMain = styled.div`
  margin-top: 100px;
  padding: 0 15px;
  margin-bottom: 100px;
  //height: ${(props) => (props.product ? "auto" : "120vh")};
  
  .loader {
    margin-top: 25%;
    text-align: center;
  }

  @media (max-width: 991px) {
    padding: 0;
    margin-top: 120px;
  }
`;

let ProductDesktop = styled.div`
  .container {
    max-width: 95%;
    .row{
      justify-content: center;
      align-items: center;
    }
  }
  .error{
    color: darkred;
  }
  
  .main {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  
  .book-an-appointment-form {
    @media(max-width: 768px){
      padding-bottom: 300px;
    }
  }

  .left-div {
    padding-left: 0px;
    padding-right: 0px;
  }

  .right-sticky {
    height: auto;
    padding: 0 15px 15px 30px;
  }

  .right-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .price-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .variation-div {
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .color-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
  }

  .color-desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
  }

  .button-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
  }

  .button-inner {
    width: 82%;
    margin-right: 20px;
  }

  @media (max-width: 991px) {
    .right-sticky {
      display: none;
    }
  }
`;
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        color: state.isSelected ? "#FFF" : "#313132",
        backgroundColor: state.isSelected ? "#313132" : "#FFF",
        margin: 0,
    }),
    menu: (provided, state) => ({
        ...provided,
        color: "rgba(0,0,0,0.5)",
        backgroundColor: state.isSelected ? "#000" : "#FFF",
        margin: 0
    }),
    menuList: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#000" : "#FFF",
        borderRadius: 0,
        height: 300
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: "none",
    }),
};

function mapStateToProps(state) {
    return {
        openPreBook: state.prebookReducer.openPreBook,
        productId: state.prebookReducer.productId,
        authData: state.authReducer.authData,
        slots: state.prebookReducer.slots,
        submit: state.prebookReducer.submit,
        submitLoading: state.prebookReducer.submitLoading,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        handlePreBook: (data, id) => dispatch(handlePreBook(data, id)),
        searchSlot: (data) => dispatch(searchSlot(data)),
        submitSlot: (data,appointment_type) => dispatch(submitSlot(data,appointment_type)),
        clearSubmit: () => dispatch(clearSubmit()),
        clearSlot: () => dispatch(clearSlot()),
    };
}

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(BookAppointment)
);
