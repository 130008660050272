import { PRE_BOOK } from "../../constants/prebooking";
import { takeLatest, put, call } from "redux-saga/effects";
import Logger from "../../../helpers/logger";
import { AxiosServices } from "../../../network/AxiosServices";
import { ApiServices } from "../../../network/ApiServices";

function* searchSlot(actions) {
  try {
    const result = yield call(
      AxiosServices.post,
      ApiServices.SEARCH_SLOT,
      actions.data
    );

    yield put({
      type: PRE_BOOK.SEARCH_SLOT.SUCCESS,
      result: result.data,
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: PRE_BOOK.SEARCH_SLOT.FAILURE,
    });
  }
}

function* submitSlot(actions) {
  try {
    const result = yield call(
      AxiosServices.post,
      actions.appointment_type === "prebook" ? ApiServices.SUBMIT_PREBOOK_SLOT : ApiServices.SUBMIT_SLOT,
      actions.data
    );

    yield put({
      type: PRE_BOOK.SUBMIT_SLOT.SUCCESS,
      result: result.data,
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: PRE_BOOK.SUBMIT_SLOT.FAILURE,
    });
  }
}

export default function* preebookSaga() {
  yield takeLatest(PRE_BOOK.SEARCH_SLOT.MAIN, searchSlot);
  yield takeLatest(PRE_BOOK.SUBMIT_SLOT.MAIN, submitSlot);
}
