import React, { useRef, useState } from "react";
import {
    FileUploadContainer,
    FormField,
    UploadFileBtn,
    FilePreviewContainer,
    PreviewContainer,
    PreviewList,
} from "./file-upload.styles";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import { Avatar, Button, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    paper: {
        "& .MuiTypography-body1": {
            fontFamily: `"Roboto", "Helvetica", "Arial"`,
        },
        // padding: "0 5%",
    },
    browseBtn: {
        backgroundColor: "#1ec3aa",
        borderRadius: 0,
        padding: "0 20px",
        textTransform: "none",
        color: "#fff",
        fontSize: "14px",
    },
}));

const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 10000000;

const convertNestedObjectToArray = (nestedObj) =>
    Object.keys(nestedObj).map((key) => nestedObj[key]);

const convertBytesToKB = (bytes) => Math.round(bytes / KILO_BYTES_PER_BYTE);

const FileUpload = ({
                        updateFilesCb,
                        maxFileSizeInBytes = DEFAULT_MAX_FILE_SIZE_IN_BYTES,
                        ...otherProps
                    }) => {
    const classes = useStyles();
    const fileInputField = useRef(null);
    const [files, setFiles] = useState({});

    const handleUploadBtnClick = () => {
        fileInputField.current.click();
    };

    const addNewFiles = (newFiles) => {
        for (let file of newFiles) {
            if (file.size < maxFileSizeInBytes) {
                if (!otherProps.multiple) {
                    return { file };
                }
                files[file.name] = file;
            }
        }
        return { ...files };
    };

    const callUpdateFilesCb = (files) => {
        const filesAsArray = convertNestedObjectToArray(files);
        updateFilesCb(filesAsArray);
    };

    const handleNewFileUpload = (e) => {
        const { files: newFiles } = e.target;
        if (newFiles.length) {
            let updatedFiles = addNewFiles(newFiles);
            setFiles(updatedFiles);
            callUpdateFilesCb(updatedFiles);
        }
    };

    const removeFile = (fileName) => {
        delete files[fileName];
        setFiles({ ...files });
        callUpdateFilesCb({ ...files });
    };

    return (
        <Grid container spacing={2} className={classes.paper}>
            <Grid item xs={12}>
                <FileUploadContainer>
                    <UploadFileBtn type="button" onClick={handleUploadBtnClick}>
                        Choose Files
                    </UploadFileBtn>

                    <FormField
                        type="file"
                        ref={fileInputField}
                        onChange={handleNewFileUpload}
                        title=""
                        value=""
                        {...otherProps}
                    />
                </FileUploadContainer>
            </Grid>

            {
                Object.keys(files).length ? (
                    <Grid item xs={12}>
                        <FilePreviewContainer>
                            <PreviewList>
                                {Object.keys(files).map((fileName, index) => {
                                    let file = files[fileName];
                                    let isImageFile = file.type.split("/")[0] === "image";
                                    let fileType = file.type.split("/")[1];
                                    return (
                                        <PreviewContainer key={fileName}>
                                            <div style={{ display: "flex", flexDirection: "row" }}>
                                                <div>
                                                    <Avatar
                                                        src={URL.createObjectURL(file)}
                                                        style={{ width: "48px", height: "48px", border: "1px solid #EEEEEE" }}
                                                    >
                                                        {fileType}
                                                    </Avatar>
                                                </div>
                                                <div
                                                    style={{
                                                        padding: "0 5px",
                                                        minWidth: "100px",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            color: "#333333",
                                                            overflow: "hidden"
                                                        }}
                                                    >
                                                        {file.name}
                                                    </div>{" "}
                                                    <div style={{ fontSize: "10px", color: '#333333' }}>
                                                        {convertBytesToKB(file.size)} kb/125Kb
                                                    </div>
                                                </div>
                                                <div>
                                                    <Button onClick={() => removeFile(fileName)}>
                                                        <CloseIcon style={{color: '#333333'}}/>
                                                    </Button>
                                                </div>
                                            </div>
                                        </PreviewContainer>
                                    );
                                })}
                            </PreviewList>
                        </FilePreviewContainer>
                    </Grid>
                ) : null
            }
        </Grid>
    );
};

export default FileUpload;
