import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Title from "../../../components/Title";
import WishlistButton from "../../../components/WishlistButton";
import NewButtonSubmit from "../../../components/NewButtonSubmit";
import { authStyle } from "../../auth/authStyle";
import { withStyles } from "@material-ui/styles";
import {
  passwordValidator,
  confirmPassValidator,
} from "../../../helpers/validators";
import TimerMixin from "react-timer-mixin";

const PasswordField = (props) => {
  const {
    classes,
    handleCancel,
    updatePassword,
    passwordReset,
    loading,
    userId,
    userToken,
    resetPassInit,
  } = props;

  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  const [newPassErr, setNewErr] = useState("");
  const [confirmErr, setConfirmErr] = useState("");
  const [success, setSuccess] = useState("");
  const [err, setErr] = useState("");

  const handleNewPassBlur = () => {
    const isValid = passwordValidator(newPass);
    if (!isValid) {
      setNewErr(
        "Password is not valid, must be of minimum 6 charecters and no allowed"
      );
    } else {
      setNewErr("");
    }
  };

  const handleConfirmPassBlur = () => {
    const isValid = confirmPassValidator(newPass, confirmPass);
    if (!isValid) {
      setConfirmErr("Passwords do not match");
    } else {
      setConfirmErr("");
    }
  };

  const handleOldPass = (e) => {
    setOldPass(e.target.value);
  };

  const handleNewPass = (e) => {
    setNewPass(e.target.value);
  };

  const handleConfirmPass = (e) => {
    setConfirmPass(e.target.value);
  };

  const handleSubmit = () => {
    handleConfirmPassBlur();
    handleNewPassBlur();

    if (!newPassErr.length && !confirmErr.length && userId && userToken) {
      let data = {
        user_id: userId,
        token: userToken,
        old_password: oldPass,
        new_password: newPass,
      };
      updatePassword(data);
    }
  };

  useEffect(() => {
    if (passwordReset) {
      if (passwordReset.status) {
        setSuccess(passwordReset.message);
      } else {
        setErr(passwordReset.message);
      }

      TimerMixin.setTimeout(() => {
        resetPassInit();
        setSuccess("");
        setErr("");
      }, 5000);
    }
  }, [passwordReset]);

  return (
    <Form>
      <Form.Group>
        <Row>
          <Col className={classes.formField} md={6} sm={12}>
            <Form.Label className={classes.formLabel}>Old Password</Form.Label>
            <Form.Control
              type="password"
              value={oldPass}
              className={classes.formControl}
              onChange={handleOldPass}
              placeholder={"Old Password"}
            />
          </Col>
        </Row>
        <Row>
          <Col className={classes.formField} md={6} sm={12}>
            <Form.Label className={classes.formLabel}>New Password</Form.Label>
            <Form.Control
              type="password"
              className={classes.formControl}
              value={newPass}
              placeholder={"New Password(Min 6 charecters)"}
              onChange={handleNewPass}
              onBlur={handleNewPassBlur}
            />
            {newPassErr.length ? (
              <Title
                text={newPassErr}
                color={"red"}
                margin={"10px 0 0 0"}
                fontSize={14}
                lineHeight={20}
              />
            ) : null}
          </Col>
          <Col className={classes.formField} md={6} sm={12}>
            <Form.Label className={classes.formLabel}>
              Confirm New Password
            </Form.Label>
            <Form.Control
              className={classes.formControl}
              type="password"
              value={confirmPass}
              placeholder={"Confirm New Password"}
              onChange={handleConfirmPass}
              onBlur={handleConfirmPassBlur}
            />
            {confirmErr.length ? (
              <Title
                text={confirmErr}
                color={"red"}
                margin={"10px 0 0 0"}
                fontSize={14}
                lineHeight={20}
              />
            ) : null}
          </Col>
          <Col className="button-field" md={12} sm={12}>
            <Row>
              <Col md={6} sm={12}>
                <NewButtonSubmit
                  text={"Save Changes"}
                  loading={loading}
                  handleSubmit={handleSubmit}
                />
              </Col>
              <Col md={6} sm={12}>
                <WishlistButton handleSubmit={handleCancel} text={"Cancel"} />
              </Col>
              <Col md={12} sm={12}>
                {success.length ? (
                  <Title
                    text={success}
                    color={"green"}
                    margin={"10px 0 0 0"}
                    fontSize={14}
                    lineHeight={20}
                  />
                ) : null}
                {err.length ? (
                  <Title
                    text={err}
                    color={"red"}
                    margin={"10px 0 0 0"}
                    fontSize={14}
                    lineHeight={20}
                  />
                ) : null}
              </Col>
            </Row>
          </Col>
        </Row>
      </Form.Group>
    </Form>
  );
};

let styles = (theme) => authStyle(theme);

export default withStyles(styles)(PasswordField);
