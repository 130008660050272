import React, {useEffect} from 'react'
import {motion} from "framer-motion";
import {PageAnimation} from "../../../components/PageAnimation";
import {connect} from 'react-redux'
import Details from "./Details";
import {getAllPosts} from "../../../redux/actions/home";

const StoriesDetails = (props) => {
    const {posts} = props
    let { post_name } = props.match.params;

    useEffect(() => {
        window.scrollTo(0, 0);
        props.getAllPosts();
    }, []);

    const stories = posts ?
      posts.length ? posts.filter((item, key) => item.category[key].slug === 'blog') : []
      : []
    const post = stories.length ? stories.find((item) => item.post_name === post_name) : ''

    return (
        <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
            <Details post={post}/>
        </motion.div>
    )
}

function mapStateToProps(state) {
    return {
        posts: state.homeReducer.posts,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getAllPosts: () => dispatch(getAllPosts()),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(StoriesDetails);
