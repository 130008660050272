import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import Title from "../../../components/Title";
import WishlistButton from "../../../components/WishlistButton";
import NewButtonSubmit from "../../../components/NewButtonSubmit";
import { authStyle } from "../../auth/authStyle";
import { withStyles } from "@material-ui/styles";
import Select from "react-select";
import DateField from "./date-field";

const slotOptions = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];

let InformationField = (props) => {
  let {
    classes,
    firstName,
    lastName,
    handleFirstName,
    handleLastName,
    handleCancel,
    updateUser,
    gender,
    dateOfBirth,
    handleGender,
    handleDateOfBirth,
    userId,
    profileDataLoading,
  } = props;

  const handleSubmit = () => {
    let updateData = {
      user_id: userId,
      first_name: firstName,
      last_name: lastName,
      gender: gender,
      date_of_birth: dateOfBirth,
    };

    updateUser(updateData);
  };

  return (
    <Form>
      <Form.Group>
        <Row>
          <Col className={classes.formField} md={6} sm={12}>
            <Form.Label className={classes.formLabel}>First Name</Form.Label>
            <Form.Control
              type="text"
              className={classes.formControl}
              value={firstName}
              onChange={handleFirstName}
              placeholder={"Your First Name"}
            />
          </Col>
          <Col className={classes.formField} md={6} sm={12}>
            <Form.Label className={classes.formLabel}>Last Name</Form.Label>
            <Form.Control
              type="text"
              className={classes.formControl}
              value={lastName}
              placeholder={"Your Last Name"}
              onChange={handleLastName}
            />
          </Col>
          <Col className={classes.formField} md={6} sm={12}>
            <Form.Label className={classes.formLabel}>Date Of Birth</Form.Label>
            <DateField
              handleDateOfBirth={handleDateOfBirth}
              dateOfBirth={dateOfBirth}
            />
          </Col>
          <Col className={classes.formField} md={6} sm={12}>
            <Form.Label className={classes.formLabel}>Gender</Form.Label>
            <Select
              styles={customStyles}
              className={classes.formControl}
              placeholder="Select Gender"
              onFocus={false}
              options={slotOptions}
              defaultValue={gender}
              onChange={(e) => handleGender(e.value)}
            />
          </Col>
          <Col style={{ marginTop: 20, marginBottom: 20 }} md={12} sm={12}>
            <Row>
              <Col md={6} sm={12}>
                <NewButtonSubmit
                  text={"Save Changes"}
                  handleSubmit={handleSubmit}
                  loading={profileDataLoading}
                />
              </Col>
              <Col md={6} sm={12}>
                <WishlistButton handleSubmit={handleCancel} text={"Cancel"} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form.Group>
    </Form>
  );
};

let styles = (theme) => authStyle(theme);

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderRadius: 0,
    color: state.isSelected ? "#FFF" : "#313132",
    backgroundColor: state.isSelected ? "#313132" : "#FFF",
    margin: 0,
  }),
  menu: (provided, state) => ({
    ...provided,
    color: "rgba(0,0,0,0.5)",
    backgroundColor: state.isSelected ? "#000" : "#FFF",
    margin: 0
  }),
  menuList: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#000" : "#FFF",
    borderRadius: 0,
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: "none",
  }),
  control: (provided, state) => ({
    ...provided,
    height: 30,
    fontSize: 14,
  }),
};

export default withStyles(styles)(InformationField);
