import React, { Component, useState } from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import { hover } from "../../../globalStyleVars";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import Slick from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bannerSliderImage from "../../../assets/images/bannerSlider1.png";
import { featureData } from "../../../assets/data/products";
import Title from "../../../components/Title";
import Text from "../../../components/Text";
import ReactHtmlParser from "react-html-parser";
import Modal from "../../../components/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";
import Image from "../../../components/Image";

const BannerSlider = (props) => {
  let { bannerData } = props;
  let [show, setShow] = useState(false);
  let [title, setTitle] = useState("");
  let [post, setPost] = useState("");

  let handleClose = () => {
    setShow(false);
  };

  let handleOpen = (title, post) => {
    setShow(!show);
    setTitle(title);
    setPost(post);
  };

  const images = [bannerSliderImage, bannerSliderImage, bannerSliderImage];

  const fadeProperties = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 5500,
    autoplay: true,
    pauseOnHover: true,
    prevArrow: "",
    nextArrow: "",
  };

  bannerData = bannerData && bannerData.length > 0 ? bannerData : [];
  // console.log(bannerData)

  return (
    <StyledSlide data={bannerData}>
      <div>
        <Container>
          <div className="slide-container">
            <Slick {...fadeProperties}>
              {bannerData && bannerData.length && bannerData.length > 0
                  ? bannerData.map((item, key) => {

                    return (
                        <div key={key} className="each-fade">
                          <Row>
                            <Col sm={12} xs={12} md={7} className="fade-img-top">
                              <div className="each-fade-img">
                                {/*<img src={item.banner} alt="" />*/}
                                <Image
                                    url={item.banner}
                                    alt={"Product"}
                                />
                              </div>
                            </Col>
                            <Col md={5}>
                              <div className="each-fade-div">
                                <div className="title-view-desktop">
                                  <Title
                                      text={item?.post_title}
                                      fontSize={36}
                                      color={item?.meta?.color[0]}
                                      fontWeight={300}
                                      fontFamily={"Renner,sans-serif"}
                                      textTransform={"uppercase"}
                                      lineHeight={"40"}
                                      letterSpacing={1}
                                      margin={"10px 0 0 0"}
                                  />
                                  <Title
                                      text={item?.meta?.title_two}
                                      fontSize={36}
                                      color={item?.meta?.color[0]}
                                      fontWeight={300}
                                      fontFamily={"Renner,sans-serif"}
                                      textTransform={"uppercase"}
                                      lineHeight={"40"}
                                      letterSpacing={1}
                                  />
                                  <Title
                                      text={item?.meta?.title_three}
                                      fontSize={36}
                                      color={item?.meta?.color[0]}
                                      fontWeight={600}
                                      fontFamily={"Renner,sans-serif"}
                                      textTransform={"uppercase"}
                                      lineHeight={"40"}
                                      letterSpacing={1}
                                  />
                                </div>
                                <div className="title-view-mobile">
                                  <Title
                                      text={item?.post_title}
                                      fontSize={36}
                                      color={item?.meta?.color[0]}
                                      fontWeight={300}
                                      fontFamily={"Renner,sans-serif"}
                                      textTransform={"uppercase"}
                                      lineHeight={"40"}
                                      letterSpacing={1}
                                      margin={"10px 0 0 0"}
                                  />
                                  <div style={{ display: "flex" }}>
                                    <Title
                                        text={item?.meta?.title_two}
                                        fontSize={36}
                                        color={item?.meta?.color[0]}
                                        fontWeight={300}
                                        fontFamily={"Renner,sans-serif"}
                                        textTransform={"uppercase"}
                                        lineHeight={"40"}
                                        letterSpacing={1}
                                        // margin={"0 0px 0 0"}
                                    />
                                    <Title
                                        text={item?.meta?.title_three}
                                        fontSize={36}
                                        color={item?.meta?.color[0]}
                                        fontWeight={600}
                                        fontFamily={"Renner,sans-serif"}
                                        textTransform={"uppercase"}
                                        lineHeight={"40"}
                                        letterSpacing={1}
                                    />
                                  </div>
                                </div>

                                <Text
                                    text={ReactHtmlParser(item?.post_content).length> 0 && ReactHtmlParser(item?.post_content)[0]}
                                    fontSize={14}
                                    color="#313132"
                                    fontWeight={400}
                                    fontFamily={"Poppins"}
                                    lineHeight={"24"}
                                    margin={"12px 0 4px 0"}
                                />
                                <Text
                                    text={item?.meta?.para_two}
                                    fontSize={14}
                                    color="#313132"
                                    fontWeight={400}
                                    fontFamily={"Poppins"}
                                    lineHeight={"24"}
                                    margin={"20px 0 4px 0"}
                                />

                                <div
                                    className="button-load-more"
                                    onClick={() =>
                                        handleOpen(
                                            item?.post_title +
                                            " " +
                                            item?.meta?.title_two +
                                            " " +
                                            item?.meta?.title_three,
                                            ReactHtmlParser(item?.post_content).length> 0 && ReactHtmlParser(item?.post_content)[0]
                                        )
                                    }
                                >
                                  {/* <div>
                                    <span>Read more</span>
                                  </div> */}
                                </div>
                              </div>
                            </Col>
                            <Col md={7} className="fade-img">
                              <div className="each-fade-img">
                                {/*<img src={item.banner} alt="" />*/}
                                <Image
                                    url={item?.banner}
                                    alt={"Product"}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                    );
                  })
                  : null}
            </Slick>
            <Modal
                show={show}
                handleClose={handleClose}
                title={title}
                post={post}
            />
          </div>
        </Container>
      </div>
    </StyledSlide>
  );
};

const StyledSlide = styled.div`
  margin-top: 120px;
  margin-bottom: 32px;
  min-height: ${(props) => (props.data ? "auto" : "100vh")};

  .container {
    max-width: 95%;
  }
  .title-view-desktop {
    display: block;
  }
  .title-view-mobile {
    display: none;
  }
  .fade-img {
    display: block;
  }
  .fade-img-top {
    display: none;
  }

  .slick-dots {
    position: absolute;
    bottom: 0px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;

    li {
      button {
        &:before {
          background-color: #707070;
          width: 30px;
          height: 3px;
          opacity: 0.5;
          color: #00000000;
        }
      }
    }

    li.slick-active button:before {
      background-color: #ffffff;
      width: 30px;
      height: 3px;
      opacity: 1;
      border-radius: 10px;
      color: #00000000;
    }
  }

  .each-fade {
    display: flex;
    width: 100%;
  }

  .each-fade-img {
    background-color: #fff;

    img {
      width: 100%;
      height: 80vh;
      object-fit: cover;
      padding-top: 30px;
    }
  }

  .each-fade-div {
    text-align: left;
    padding: 30px 52px 30px 0px;
    background: #fff;
  }

  .button-load-more {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 8px;

    div {
      border: 1px solid rgb(156, 148, 52);
      padding: 6.5px 0 8px 0;
      border-radius: 50px;
      cursor: pointer;
      text-align: center;
      width: 120px;
      height: 40px;

      span {
        color: rgb(156, 148, 52);
        font-size: 14px;
        font-weight: normal;
        font-family: Renner,sans-serif;
      }

      &:hover {
        background-color: rgb(156, 148, 52);

        span {
          color: #ffffff;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .container {
      max-width: 100%;
    }
    .fade-img {
      display: none;
    }
    .fade-img-top {
      display: block;
    }
    .title-view-desktop {
      display: none;
    }
    .title-view-mobile {
      display: block;
    }
    .each-fade-img {
      background-color: #fff;

      img {
        width: 100%;
        height: 50vh;
        object-fit: cover;
      }
    }
    .slick-dots {
      top: 47.8vh;
    }
    .each-fade-div {
      padding: 30px 2px 30px 2px;

      h2 {
        font-size: 24px;
      }
    }

    .button-load-more {
      display: flex;
      align-items: center;
      position: relative;
      margin-top: 64px;

      div {
        border: 1px solid rgb(156, 148, 52);
        background-color: rgb(156, 148, 52);
        padding: 6px 0 8px 0;
        border-radius: 50px;
        cursor: pointer;
        text-align: center;
        width: 100%;
        height: 40px;

        span {
          color: #ffffff;
          font-size: 14px;
          font-weight: normal;
          font-family: Renner,sans-serif;
        }
      }
    }
  }

  .react-slideshow-container .default-nav:first-of-type {
    display: none;
  }

  .react-slideshow-container .default-nav:last-of-type {
    display: none;
  }
`;

export default BannerSlider;
