import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { CounterBox, CounterButton, CounterValue } from '../../right-drawer/counterStyle';

const Counter = (
  {
    onDecrement,
    onIncrement,
    value,
    variant,
    className,
  }
) => {
  let addItem = () => {
    onIncrement()
  }

  let removeItem = () => {
    onDecrement()
  }

  return (
    <CounterBox variant={variant} className={className}>
      <div onClick={removeItem} style={{ cursor: 'pointer' }}>
        <CounterButton variant={variant}>
          <RemoveIcon style={{ width: 16 }} />
        </CounterButton>
      </div>
      <CounterValue>{value < 10 ? `0${value}` : value}</CounterValue>
      <div onClick={addItem} style={{ cursor: 'pointer' }}>
        <CounterButton variant={variant}>
          <AddIcon style={{ width: 16 }} />
        </CounterButton>
      </div>
    </CounterBox>
  );
};

export default Counter
