export const RIGHT_DRAWER = {
  OPEN_DRAWER: "OPEN_DRAWER",
  ADD_ITEM_TO_CART: "ADD_ITEM_TO_CART",
  ADD_CART_SERVER: {
    MAIN: "ADD_CART_SERVER",
    SUCCESS: "ADD_CART_SERVER_SUCCESS",
    FAILURE: "ADD_CART_SERVER_FAILURE",
  },
  REMOVE_ITEM_FROM_CART: "REMOVE_ITEM_FROM_CART",
  UPDATE_CART: {
    MAIN: "UPDATE_CART",
    SUCCESS: "UPDATE_CART_SUCCESS",
    FAILURE: "UPDATE_CART_FAILURE",
  },
  CLEAR_CART: "CLEAR_CART",
  CLEAR_CART_SERVER: {
    MAIN: "CLEAR_CART_SERVER",
    SUCCESS: "CLEAR_CART_SERVER_SUCCESS",
    FAILURE: "CLEAR_CART_SERVER_FAILURE",
  },
  FETCH_ALL_CART_ITEMS: {
    MAIN: "FETCH_ALL_CART_ITEMS",
    SUCCESS: "FETCH_ALL_CART_ITEMS_SUCCESS",
    FAILURE: "FETCH_ALL_CART_ITEMS_FAILURE",
  },
  MERGE_CART_ITEMS: {
    MAIN: "MERGE_CART_ITEMS",
    SUCCESS: "MERGE_CART_ITEMS_SUCCESS",
    FAILURE: "MERGE_CART_ITEMS_FAILURE",
  },
  ADD_ITEM_TO_WISHLIST: "ADD_ITEM_TO_WISHLIST",
  REMOVE_ITEM_FROM_WISHLIST: "REMOVE_ITEM_FROM_WISHLIST",
  CLEAR_WISHLIST: "CLEAR_WISHLIST",
  GET_COUNT: "GET_COUNT",
  GET_CART_ITEM: {
    MAIN: "GET_CART_ITEM",
    SUCCESS: "GET_CART_ITEM_SUCCESS",
    FAILURE: "GET_CART_ITEM_FAILURE",
  },
  ADD_WISHLIST_SERVER: {
    MAIN: "ADD_WISHLIST_SERVER",
    SUCCESS: "ADD_WISHLIST_SERVER_SUCCESS",
    FAILURE: "ADD_WISHLIST_SERVER_FAILURE",
  },
  REMOVE_WISHLIST_SERVER: {
    MAIN: "REMOVE_WISHLIST_SERVER",
    SUCCESS: "REMOVE_WISHLIST_SERVER_SUCCESS",
    FAILURE: "REMOVE_WISHLIST_SERVER_FAILURE",
  },
  FETCH_ALL_WISHLIST: {
    MAIN: "FETCH_ALL_WISHLIST",
    SUCCESS: "FETCH_ALL_WISHLIST_SUCCESS",
    FAILURE: "FETCH_ALL_WISHLIST_FAILURE",
  },
  CLEAR_ALL_WISHLIST: {
    MAIN: "CLEAR_ALL_WISHLIST",
    SUCCESS: "CLEAR_ALL_WISHLIST_SUCCESS",
    FAILURE: "CLEAR_ALL_WISHLIST_FAILURE",
  },
  MERGE_WISHLIST: {
    MAIN: "MERGE_WISHLIST",
    SUCCESS: "MERGE_WISHLIST_SUCCESS",
    FAILURE: "MERGE_WISHLIST_FAILURE",
  },
  TEMP_ORDER: "TEMP_ORDER",
  SET_CHECKOUT: "SET_CHECKOUT",
  INIT_CARTLIST: "INIT_CARTLIST",
  CLEAR_CHECKOUT: "CLEAR_CHECKOUT",
};
