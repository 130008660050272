import { ORDERS } from "../../constants/orders";
import { takeLatest, put, call } from "redux-saga/effects";
import Logger from "../../../helpers/logger";
import { AxiosServices } from "../../../network/AxiosServices";
import { ApiServices } from "../../../network/ApiServices";

function* setOrder(actions) {
  try {
    let result = yield call(
      AxiosServices.postWc,
      ApiServices.POST_ORDER,
      actions.data
    );

    yield put({
      type: ORDERS.SET_ORDER.SUCCESS,
      result: result.data,
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: ORDERS.SET_ORDER.FAILURE,
      result: err.response.data,
    });
  }
}

function* postOrderFile(actions) {
  try {
    let result = yield call(
        AxiosServices.postAuthFormData,
        ApiServices.ORDER_FILE,
        actions.data
    );

    yield put({
      type: ORDERS.ORDERS_FILE.SUCCESS,
      result: result.data,
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: ORDERS.ORDERS_FILE.FAILURE,
      result: err.response.data,
    });
  }
}

function* getOrdersAmount(actions) {
  try {
    let result = yield call(
        AxiosServices.postAuthOne,
        ApiServices.GET_ORDERS_AMOUNT,
        actions.data
    );

    yield put({
      type: ORDERS.ORDERS_AMOUNT.SUCCESS,
      result: result.data,
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: ORDERS.ORDERS_AMOUNT.FAILURE,
      result: err.response.data,
    });
  }
}

function* paymentGateway(actions) {
  try {
    const result = yield call(
      AxiosServices.postAuthOne,
      ApiServices.ONLINE_PAYMENT,
      actions.data
    );

    yield put({
      type: ORDERS.PAYMENT_GATEWAY.SUCCESS,
      result: result.data,
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: ORDERS.PAYMENT_GATEWAY.FAILURE,
    });
  }
}

function* paymentGatewayDone(actions) {
  try {
    const result = yield call(
      AxiosServices.postAuthOne,
      ApiServices.ONLINE_PAYMENT_SUCCESS,
      actions.data
    );

    yield put({
      type: ORDERS.PAYMENT_DONE.SUCCESS,
      result: result.data,
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: ORDERS.PAYMENT_DONE.FAILURE,
    });
  }
}

function* paymentGatewayFailed(actions) {
  try {
    const result = yield call(
      AxiosServices.postAuthOne,
      ApiServices.ONLINE_PAYMENT_FAILED,
      actions.data
    );

    yield put({
      type: ORDERS.PAYMENT_FAILED.SUCCESS,
      result: result.data,
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: ORDERS.PAYMENT_FAILED.FAILURE,
    });
  }
}

function* getAllOrders(actions) {
  try {
    let result = yield call(
      AxiosServices.get,
      ApiServices.GET_ORDERS+actions.id.customer
    );

    yield put({
      type: ORDERS.FETCH_ALL_ORDERS.SUCCESS,
      result: result.data,
    });
  } catch (err) {
    Logger(err);
    yield put({
      type: ORDERS.FETCH_ALL_ORDERS.FAILURE,
      result: err.result,
    });
  }
}

function* setHome(actions) {
  yield put({
    type: ORDERS.SET_HOME.SUCCESS,
    result: actions.data,
  });
}

function* setAddress(actions) {
  yield put({
    type: ORDERS.SET_ADDRESS.SUCCESS,
    result: actions.data,
  });
}

function* setEmail(actions) {
  yield put({
    type: ORDERS.SET_EMAIL.SUCCESS,
    result: actions.data,
  });
}

function* setPhone(actions) {
  yield put({
    type: ORDERS.SET_PHONE.SUCCESS,
    result: actions.data,
  });
}

function* setPayment(actions) {
  yield put({
    type: ORDERS.SET_PAYMENT.SUCCESS,
    result: actions.data,
  });
}

function* initNewOrder() {
  yield put({
    type: ORDERS.INIT_NEW_ORDER.SUCCESS,
  });
}

export default function* orderSagas() {
  yield takeLatest(ORDERS.SET_ORDER.MAIN, setOrder);
  yield takeLatest(ORDERS.FETCH_ALL_ORDERS.MAIN, getAllOrders);
  yield takeLatest(ORDERS.SET_HOME.MAIN, setHome);
  yield takeLatest(ORDERS.SET_ADDRESS.MAIN, setAddress);
  yield takeLatest(ORDERS.SET_PHONE.MAIN, setPhone);
  yield takeLatest(ORDERS.SET_EMAIL.MAIN, setEmail);
  yield takeLatest(ORDERS.SET_PAYMENT.MAIN, setPayment);
  yield takeLatest(ORDERS.INIT_NEW_ORDER.MAIN, initNewOrder);
  yield takeLatest(ORDERS.PAYMENT_GATEWAY.MAIN, paymentGateway);
  yield takeLatest(ORDERS.PAYMENT_DONE.MAIN, paymentGatewayDone);
  yield takeLatest(ORDERS.PAYMENT_FAILED.MAIN, paymentGatewayFailed);
  yield takeLatest(ORDERS.ORDERS_AMOUNT.MAIN, getOrdersAmount);
  yield takeLatest(ORDERS.ORDERS_FILE.MAIN, postOrderFile);
}
