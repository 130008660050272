export const ApiServices = {
  GET_HOME_BANNER: "wp-json/home/slider",
  USER_SIGN_UP: "wp-json/customer/add",
  SEND_SMS: "wp-json/customer/sendsms",
  USER_LOGIN: "wp-json/customer/login",
  PASSWORD_REQ: "wp-json/customer/forget",
  CODE_VERIFY: "wp-json/customer/forget/verification",
  UPDATE_FORGET_PASSWORD: "wp-json/customer/forget/password",
  FETCH_CATEGORIES: "wp-json/categories/list",
  CATEGORIES_BRAND: "wp-json/categories/parent/32",
  SCOPE_OF_WORK: "wp-json/scope/all",
  FETCH_PRODUCTS: "wp-json/products/category/",
  FETCH_PRODUCTS_SINGLE: "wp-json/products/category/single",
  FETCH_ALL_PRODUCTS: "wp-json/get_product/all",
  FETCH_POSTS: "wp-json/get_post/all",
  FETCH_BOD_LIST: "wp-json/get_post_bod/all",
  POST_ORDER: "wp-json/wc/v3/orders",
  //GET_ORDERS: "wp-json/wc/v3/orders",
  GET_ORDERS: "wp-json/orderinfo/id/",
  GET_ORDERS_AMOUNT: "wp-json/orders/id",
  ADD_CART: "wp-json/carts/add",
  UPDATE_CART: "wp-json/carts/update",
  FETCH_ALL_CART: "wp-json/carts/all",
  MERGE_CART: "wp-json/carts/merge",
  CLEAR_CART: "wp-json/carts/clear",
  GET_PROFILE: "wp-json/customer/get_all",
  SEARCH_SLOT: "wp-json/remain/slot",
  SUBMIT_SLOT: "wp-json/appointment/submit",
  SUBMIT_PREBOOK_SLOT: "wp-json/prebook/submit",
  ONLINE_PAYMENT: "wp-json/payment/payment_gateway",
  ONLINE_PAYMENT_SUCCESS: "wp-json/payment/payment-return",
  ONLINE_PAYMENT_FAILED: "wp-json/payment/payment-failed",
  UPDATE_PROFILE: "wp-json/customer/update",
  ADD_WISHLIST: "wp-json/wishlist/add",
  CLEAR_WISHLIST: "wp-json/wishlist/clear",
  REMOVE_WISHLIST: "wp-json/wishlist/remove",
  MERGE_WISHLIST: "wp-json/wishlist/merge",
  FETCH_WISHLIST: "wp-json/wishlist/all",
  FETCH_PAGES: "wp-json/page/id/",
  ORDER_FILE: "/wp-json/order/file",
  POST_REVIEW: "wp-json/wc/v3/products/reviews",
  PRODUCT_DETAIL: "wp-json/products/id/",
  PRODUCT_DETAILS_SLUG: "wp-json/products/slug/",
  PRODUCT_DETAILS_SLUG_3D: "wp-json/wc/v3/products/?slug=",
  CHANGE_PASSWORD: "wp-json/password/change",
  FETCH_FILTERED: "wp-json/filter/product",
  FETCH_FILTERED_TEXT: "wp-json/filter/search",
  NEWSLETTER_SUBSCRIBE: "wp-json/newsletter/add",
  ADD_CUSTOMER_SERVICE: "wp-json/ticket/submit",
  WEB_SERIES: "wp-json/web/series",
};
