import React from 'react'
import Cart from './components/cart'
import Wishlist from './components/wishlist'
import styled from "styled-components";

let RightDrawer = props => {
    return (
        <StyledDiv>
            <Cart/>
            <Wishlist/>
        </StyledDiv>
    )
}

let StyledDiv = styled.div`
  .MuiListItem-gutters {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`

export default RightDrawer
