import React, {useEffect} from "react";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import styled from "styled-components";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Title from "../../../components/Title";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import OrderDetailsMobile from "../../orders/components/OderDetailsMobile";
import {ordersAmount} from "../../../redux/actions/orders";
import {connect} from "react-redux";

const MobileOrderHistory = props => {
    const classes = useStylesCard();
    let {orders, active, setActive, statusCheck, ordersAmounts} = props

    let handleActive = (id, key) => {
        setActive(key)
        let data = {
            order_id: id
        }
        props.ordersAmount(data)
    }


    useEffect(() => {
      let first = orders ? orders.length > 0 ? orders[0] : '' : ''
        if (first) {
            let data = {
                order_id: first.id
            }
            props.ordersAmount(data)
        }

    }, [])

    return (
        <StyledList>
            <Card className={classes.root}>
                <CardContent className={classes.cardContent}>
                    <List className="list" component="nav" aria-label="main mailbox folders">
                        {orders ? orders.length ? orders.map((item, key) => {
                            let status = statusCheck(item.status)
                            return (
                                <Accordion>
                                    <AccordionSummary
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <ListItem
                                            button
                                            onClick={() => handleActive(item.id, key)}
                                            className="list-text"
                                        >
                                            <Title
                                                text={`Order ID #${item.id}`}
                                                color={'#313132'}
                                                fontSize={16}
                                                lineHeight={24}
                                                fontFamily={'Poppins'}
                                                fontWeight={'500'}
                                                textAlign={'left'}
                                            />
                                            <Title
                                                text={status.name}
                                                color={'#313132'}
                                                fontSize={10}
                                                lineHeight={14}
                                                fontFamily={'Poppins'}
                                                fontWeight={'400'}
                                                textAlign={'left'}
                                            />
                                        </ListItem>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <OrderDetailsMobile
                                            active={orders[active]}
                                            statusCheck={statusCheck}
                                            ordersAmounts={ordersAmounts}
                                            mobile={true}
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            )
                        }) : null : null}
                    </List>
                </CardContent>
            </Card>
        </StyledList>
    )
}

let useStylesCard = makeStyles((theme) => ({
    root: {
        minWidth: 200,
        marginBottom: 30,
        boxShadow: "0px 0px 10px #0000000D",
    },
    cardContent: {
        padding: "0px",
    }
}));

let StyledList = styled.div`
  .MuiCardContent-root:last-child {
    padding: 0px !important;
  }

  .MuiList-padding {
    padding: 0px !important;
  }

  .list-text {
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;
    height: 63px;
  }

  .list {
    max-width: 100%;
  }

  @media (max-width: 767px) {
    .list {
      max-width: 100%;
    }
  }
`

const Accordion = withStyles({
    root: {
        border: "1px solid rgba(0, 0, 0, .03)",
        boxShadow: "none",
        "&:not(:last-child)": {
            borderBottom: 0,
        },
        "&:before": {
            display: "none",
        },
        "&$expanded": {
            margin: "auto",
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        backgroundColor: "#fff",
        borderBottom: "1px solid rgba(0, 0, 0, .03)",
        marginBottom: -1,
        minHeight: 40,
        "&$expanded": {
            minHeight: 40,
            borderBottom: 0,
            backgroundColor: "#EBEBEB"
        },
    },
    content: {
        "&$expanded": {
            margin: "12px 0",
            borderBottom: 0,
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

const AccordionDetailsColor = withStyles((theme) => ({
    root: {
        padding: '30px 0 30px 0',
    },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        right: 12,
    },
    heading: {
        fontFamily: "Poppins",
        fontSize: "12px",
        fontWeight: "600",
        color: "#000000",
    },
    apply: {
        fontFamily: "Poppins",
        fontSize: "8px",
        marginLeft: "64px",
        backgroundColor: "#D8D8D8",
        padding: "5px 14px 4px 14px",
        border: "1px solid #D8D8D8",
        borderRadius: 15,
        height: 22,
        width: 50,
        cursor: "pointer",
        color: "#313132",
    },
    clearAll: {
        fontFamily: "Poppins",
        fontSize: "10px",
        marginLeft: "18px",
        backgroundColor: "#D8D8D8",
        padding: "7px 20px 7px 20px",
        border: "1px solid #D8D8D8",
        borderRadius: 15,
        height: 30,
        width: 120,
        cursor: "pointer",
        color: "#313132",
    },
    viewAll: {
        fontFamily: "Poppins",
        fontSize: "10px",
        marginLeft: "18px",
        backgroundColor: "#313132",
        padding: "7px 20px 7px 20px",
        border: "1px solid #313132",
        borderRadius: 15,
        height: 30,
        width: 120,
        cursor: "pointer",
        color: "#fff",
    },
    headingValue: {
        marginTop: 10,
        fontFamily: "Poppins",
        fontSize: "10px",
        fontWeight: "400",
        cursor: "pointer",
        color: "#313132",
    },
    countValue: {
        position: "absolute",
        right: 48,
        marginTop: 10,
        fontFamily: "Poppins",
        fontSize: "10px",
        fontWeight: "400",
        cursor: "pointer",
        color: "#313132",
    },
    headingSub: {
        marginTop: 34,
        marginBottom: 28,
        fontFamily: "Poppins",
        fontSize: "10px",
        fontWeight: "500",
        cursor: "pointer",
        color: "#313132",
    },
    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow:
            "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
        backgroundColor: "#f5f8fa",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
        "$root.Mui-focusVisible &": {
            outline: "2px auto rgba(19,124,189,.6)",
            outlineOffset: 2,
        },
        "input:hover ~ &": {
            backgroundColor: "#ebf1f5",
        },
        "input:disabled ~ &": {
            boxShadow: "none",
            background: "rgba(206,217,224,.5)",
        },
    },
    checkedIcon: {
        backgroundColor: "#313132",
        backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
        "&:before": {
            display: "block",
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        "input:hover ~ &": {
            backgroundColor: "#313132",
        },
    },
}));

function mapStateToProps(state) {
    return {
        orders: state.orderReducer.orders,
        ordersAmount: state.orderReducer.ordersAmount
    }
}

function mapDispatchToProps(dispatch) {
    return {
        ordersAmount: (data) => dispatch(ordersAmount(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileOrderHistory)
