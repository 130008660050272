export const leftDrawerStyle = (theme) => ({
  paper: {
    width: "355px !important",
    [theme.breakpoints.down("md")]: {
      width: "100% !important",
    },
  },
  fullList: {
    width: "auto",
  },
  drawerHeader: {
    height: "auto",
    padding: 30,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  secondaryHeader: {
    height: "auto",
    padding: "0 20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  drawerHeaderText: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  innerDrawerDiv: {
    padding: "20px 0px",
  },
  innerDiv: {
    cursor: "pointer",
  },
  crossIcon: {
    cursor: "pointer",
    margin: "0px 40px 10px 0px",
  },
  leftIcon: {
    cursor: "pointer",
    width: 34,
  },
  drawerBody: {
    padding: "15px 30px 30px 40px",
  },
  itemDivMain: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
    paddingLeft: 30,
  },
  itemDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // alignSelf:'flex-start',
    margin: "10px 0",
    cursor: "pointer",

    // flex: "2 3 100%"
  },
  itemImage: {
    width: 110,
    height: 60,
    objectFit: "cover"
  },
  brandImage: {
    width: 110,
    height: 60,
    border: "1px solid #d8d8d8",
  }
});
