import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {connect} from "react-redux";
import {useParams, useLocation} from "react-router-dom";
import {
    addItemToCart,
    addCartServer,
    addItemToWishlist,
    addWishlistServer,
} from "../../redux/actions/rightDrawer";
import {handlePreBook, submitSlot, submitSlotExtra} from "../../redux/actions/prebooking";
import RightSection from "./components/RightSection";
import LeftSection from "./components/LeftSection";
import CircularProgress from "@material-ui/core/CircularProgress";
import {addReview, handleAuthModal, initReview} from "../../redux/actions/auth";
import {fetchProductDetails, fetchProductDetails3D, filterInit} from "../../redux/actions/home";
import RightIcon from "../../assets/images/navRightBlack.svg";
import LeftIcon from "../../assets/images/navLeftBlack.svg";
import {Col, Container, Row} from "react-bootstrap";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

let ProductDetails = (props) => {
    let {
        allProducts,
        addItemToCart,
        addItemToWishlist,
        addCartServer,
        authData,
        handlePreBook,
        handleAuthModal,
        addReview,
        initReview,
        newReview,
        newReviewLoading,
        newReviewErr,
        fetchProductDetails,
        fetchProductDetails3D,
        addWishlistServer,
        productDetail,
        productDetail3D,
        submitSlotExtra,
        productDetailErr,
        productDetailLoading,
        filterInit,
        submitSlot,
        submit
    } = props;
    let {slug} = useParams();

    let [color, setColor] = useState('')
    let [material, setMaterial] = useState('')

    useEffect(() => {
        filterInit()
        submitSlotExtra()
        Promise.all([fetchProductDetails(slug),
            fetchProductDetails3D(slug)])
    }, [slug]);

    let query = useQuery();

    let index = query.get("index");


    let product = productDetail
        ? productDetail.length
            ? productDetail[0]
            : null
        : null;


    // useEffect(() => {
    //   if (slug && color && material) {
    //     fetchProductDetails3D(slug);
    //   }
    // }, [slug,color, material]);

    //console.log("product", product)

    let variation = product ? product?.variation : [];

    let category_ids = product ? product?.category_ids : []

    let prebook = category_ids.find((p)=>p.slug === 'pre-book-now')

    const isPrebook = prebook ? true : false;

    const userId = authData ? (authData.id ? authData.id : null) : null;

    variation = variation.length
        ? variation.sort((a, b) => (a.display_price < b.display_price ? -1 : 1))
        : [];

    let [active, setActive] = useState(null);
    let [idx, setId] = useState(typeof index === "string" ? parseInt(index) : 0);

    useEffect(() => {
        window.scrollTo(0, 0);
        // variation = variation.length
        //   ? variation.sort((a, b) => (a.display_price < b.display_price ? -1 : 1))
        //   : [];
        if (variation.length) {
            if (idx) {
                let indx = variation.find((item) => item.variation_id === idx);
                if (indx) {
                    setActive(indx);
                } else {
                    setActive(variation[0]);
                }
            } else {
                setActive(variation[idx]);
            }
        }
    }, [variation]);

    let handleVariable = (data) => {
        setActive(data);
    };

    let material_section = productDetail3D ? productDetail3D[0]?.acf ? productDetail3D[0]?.acf?.material_section ? productDetail3D[0]?.acf?.material_section : [] : [] : []
    let textures_section = []
    for (let i = 0; i < material_section.length; i++) {
        let section = material_section[i]
        let textures = section.textures_section
        textures.forEach(function(texture) {
            let data = {
                'material_name': texture.material_name,
                'color': texture.color
            }
            textures_section.push(data)
        });
    }

    return (
        <ProductMain product={product}>
            {product && (
                <ProductDesktop>
                    <Container className="main">
                        <Row style={{width: '100%'}}>
                            <Col md={12} lg={8} className="left-div">
                                <LeftSection
                                    textures_section={textures_section}
                                    productDetail3D={productDetail3D}
                                    product={product}
                                    active={active}
                                    color={color}
                                    material={material}
                                    setColors={setColor}
                                    setMaterials={setMaterial}
                                    handleVariable={handleVariable}
                                    variation={variation}
                                    preBook={isPrebook}
                                    authData={authData}
                                    addToCart={addItemToCart}
                                    handlePreBook={handlePreBook}
                                    handleAuthModal={handleAuthModal}
                                    addToWishlist={addItemToWishlist}
                                    addWishlistServer={addWishlistServer}
                                    addReview={addReview}
                                    initReview={initReview}
                                    newReview={newReview}
                                    newReviewErr={newReviewErr}
                                    newReviewLoading={newReviewLoading}
                                    addCartServer={addCartServer}
                                    userId={userId}
                                    idx={idx}
                                    setId={setId}
                                />
                            </Col>
                            <Col md={12} lg={4} className="right-sticky">
                                <RightSection
                                    textures_section={textures_section}
                                    authData={authData}
                                    product={product}
                                    addToCart={addItemToCart}
                                    addCartServer={addCartServer}
                                    setColors={setColor}
                                    color={color}
                                    material={material}
                                    setMaterials={setMaterial}
                                    addWishlistServer={addWishlistServer}
                                    addToWishlist={addItemToWishlist}
                                    userId={userId}
                                    preBook={isPrebook}
                                    active={active}
                                    handleVariable={handleVariable}
                                    variation={variation}
                                    handlePreBook={handlePreBook}
                                    submitSlot={submitSlot}
                                    submitSlotExtra={submitSlotExtra}
                                    idx={idx}
                                    setId={setId}
                                    submit_pre_book_loading={props.submitLoading}
                                    prebook_submit={submit}
                                />
                            </Col>
                        </Row>
                    </Container>
                </ProductDesktop>
            )}
            {
                productDetailLoading ?
                    <div className="loader">
                        <CircularProgress/>
                    </div> : null
            }
        </ProductMain>
    );
};

let ProductMain = styled.div`
  margin-top: 100px;
  padding: 0 15px;
  height: ${(props) => (props.product ? "auto" : "120vh")};

  .loader {
    margin-top: 25%;
    text-align: center;
  }

  @media (max-width: 991px) {
    padding: 0;
  }
`;

let ProductDesktop = styled.div`
  .container {
    max-width: 95%;
  }

  .main {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .left-div {
    padding-left: 0px;
    padding-right: 0px;
  }

  .right-sticky {
    height: auto;
    padding: 0 15px 15px 30px;
  }

  .right-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .price-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .variation-div {
    margin: 15px 0;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    &.firstchildBlock{
      display: block !important;
    }
  }

  .color-div {
    //display: flex;
    //flex-direction: row;
    //justify-content: space-between;
    //align-items: center;
    margin-top: 20px;
    //flex-wrap: wrap;
  }

  .swiper-button-next {
    position: absolute;
    right: -14px;
    bottom: 0px;
    margin: auto;
    z-index: 3;
    height: 50px;
    width: 50px;
    background-image: url(${RightIcon});
    background-position: center;
    background-size: contain;
    top: 0;
    left: auto;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  .swiper-button-prev {
    position: absolute;
    right: auto;
    bottom: 0px;
    margin: auto;
    z-index: 3;
    height: 50px;
    width: 50px;
    top: 0;
    left: -14px;
    cursor: pointer;
    background-image: url(${LeftIcon});
    background-size: contain;

    &:focus {
      outline: none;
    }
  }

  .color-desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 20%;
    width: auto !important;
  }

  .button-div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
  }

  .button-inner {
    width: 82%;
    margin-right: 20px;
  }

  @media (max-width: 991px) {
    .right-sticky {
      display: none;
    }
  }
`;

function mapStateToProps(state) {
    return {
        allProducts: state.homeReducer.allProducts,
        authData: state.authReducer.authData,
        newReview: state.authReducer.newReview,
        newReviewErr: state.authReducer.newReviewErr,
        newReviewLoading: state.authReducer.newReviewLoading,
        productDetail: state.homeReducer.productDetail,
        productDetail3D: state.homeReducer.productDetail3D,
        productDetailErr: state.homeReducer.productDetailErr,
        productDetailLoading: state.homeReducer.productDetailErr,
        submitLoading: state.prebookReducer.submitLoading,
        submit: state.prebookReducer.submit,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        handleAuthModal: (data) => dispatch(handleAuthModal(data)),
        addItemToCart: (data) => dispatch(addItemToCart(data)),
        addItemToWishlist: (data) => dispatch(addItemToWishlist(data)),
        addCartServer: (data, slug) => dispatch(addCartServer(data, slug)),
        handlePreBook: (data, id) => dispatch(handlePreBook(data, id)),
        addReview: (data) => dispatch(addReview(data)),
        initReview: () => dispatch(initReview()),
        fetchProductDetails: (data) => dispatch(fetchProductDetails(data)),
        fetchProductDetails3D: (data) => dispatch(fetchProductDetails3D(data)),
        addWishlistServer: (data, slug) => dispatch(addWishlistServer(data, slug)),
        submitSlot: (data,appointment_type) => dispatch(submitSlot(data,appointment_type)),
        submitSlotExtra: () => dispatch(submitSlotExtra()),
        filterInit: () => dispatch(filterInit()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);