import { all } from "redux-saga/effects";
import homeSagas from "./home";
import authSagas from "./auth";
import rightDrawerSagas from "./right-drawer";
import leftDrawerSagas from "./left-drawer";
import orderSagas from "./orders";
import preebookSaga from "./prebooking";
import customerServiceSagas from "./customer-service";

function* rootSaga() {
  yield all([
    homeSagas(),
    authSagas(),
    rightDrawerSagas(),
    leftDrawerSagas(),
    orderSagas(),
    preebookSaga(),
    customerServiceSagas(),
  ]);
}

export default rootSaga;
