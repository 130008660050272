import React from 'react'
import {Col, Form, Row} from 'react-bootstrap'
import Title from '../../../components/Title'
import {authStyle} from "../../auth/authStyle";
import {withStyles} from "@material-ui/styles";

let styles = theme => authStyle(theme)

let PhoneField = props => {
  let {classes, phone, handlePhone, handleBlur, phoneErr} = props
  return (
    <Form>
      <Form.Group>
        <Row>
          <Col className={classes.formField} md={12}>
            <Form.Label className={classes.formLabel}>Phone:</Form.Label>
            <Row>
              <Col md={2}>
                <Form.Control
                  className={classes.formInput}
                  type='text'
                  value={'+88'}
                  disabled={true}
                />
              </Col>
              <Col md={7}>
                <Form.Control
                  type='text'
                  name="phone"
                  value={phone}
                  onChange={handlePhone}
                  className={classes.formInput}
                  placeholder={'Your Phone Number'}
                  onBlur={handleBlur}
                  autoComplete="off"
                />
              </Col>
            </Row>
            {phoneErr.length ? (
              <Title
                text={phoneErr}
                fontWeight={200}
                fontSize={14}
                fontFamily={'Poppins'}
                lineHeight={20}
                color={'red'}
              />
            ) : null}
          </Col>
        </Row>
      </Form.Group>
    </Form>
  )
}

export default withStyles(styles)(PhoneField)
