import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import Title from "../../../components/Title";
import bannerProduct from "../../../assets/images/banner-all-product.png";

import { connect } from "react-redux";
import Divider from "@material-ui/core/Divider";
import Text from "../../../components/Text";
import {
  fetchFeaturedProducts,
  fetchFiltered,
  filterInit,
} from "../../../redux/actions/home";
import AvailableProduct from "./AvailableProduct";
import AdvanceFilter from "../../../components/AdvancedFilter";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactHtmlParser from "react-html-parser";
import MobileFilter from "../../../components/MobileFilter";

import { sortFilter, onlyUnique } from "../../../helpers/globalFunc";

const AllProduct = (props) => {
  let {
    categories,
    allProducts,
    fetchFiltered,
    filteredProduct,
    featuredProducts,
    filterInit,
    fetchFeatured,
  } = props;

  let [sort, setSort] = useState([]);

  let featured = categories
    ? categories.length
      ? categories.find((item) => item.slug === "featured-products")
      : null
    : null;

  let id = featured ? featured.term_id : null;

  let [isSearch, setSearch] = useState(false)
  let [pageNumber, changePageNumber] = useState(1)
  let [data, setData] = useState(
      {
        category_id: null,
        colors: [],
        height: [],
        material: [],
        pageNum: 1,
        post_per_page: '20',
        price: [],
        sort: [],
        width: []
      }
  )

  useEffect(() => {
    if (id) {
      data.pageNum = 1
      data.category_id = id
      if (!isSearch){
        fetchFeatured(id, pageNumber, [], false, data);
      } else {
        fetchFeatured(id, pageNumber, [], true, data);
      }
    }
  }, [id]);

  const handleSubmit = (data) => {
    window.scrollTo(0, 0);
    setSearch(true)
    data.pageNum = 1;
    data.post_per_page = "20";

    //console.log(data)
    setData(data)
    setSort(data.sort);
    changePageNumber(1)
    fetchFeatured(id, 1, [], true, data);
  };

  const handleClear = () => {
    window.scrollTo(0, 0);
    setSearch(false)
    filterInit();
    setSort([]);
    changePageNumber(1)
    setData({
      category_id: null,
      colors: [],
      height: [],
      material: [],
      pageNum: 1,
      post_per_page: '20',
      price: [],
      sort: [],
      width: []
    })
    fetchFeatured(id, 1, [], false, data);
  };

  filteredProduct = sort.length
    ? filteredProduct.length
      ? sortFilter(filteredProduct, sort)
      : sortFilter(featuredProducts, sort)
    : filteredProduct;

  let getVariations = featuredProducts
    ? featuredProducts.length
      ? featuredProducts.map((item) => {
          return item.variations;
        })
      : []
    : [];

  let variations = getVariations.flat();

  let availableColors = variations.map((item) => {
    let key = item.attributes.attribute_pa_color;
    return key;
  });

  let filteredColors = availableColors.filter(onlyUnique);

  let getColors = featuredProducts
    ? featuredProducts.map((item) => {
        if (item.attributes[0]) {
          if (item.attributes[0].pa_color) {
            let colAttr = item.attributes[0].pa_color;

            if (filteredColors.length) {
              let retCol = filteredColors.map((col) => {
                if (colAttr[col]) {
                  let ret = {
                    name: colAttr[col].name,
                    value: colAttr[col].description,
                    slug: colAttr[col].slug,
                    id: colAttr[col].term_id,
                  };

                  return ret;
                }
              });
              return retCol;
            }
          }
        }
      })
    : [];

  let flatColors = getColors.flat();

  let trueColors = flatColors.filter(Boolean);

  let colors = [];

  for (let i = 0; i < trueColors.length; i++) {
    let found = colors.length
      ? colors.find((item) => item.id === trueColors[i].id)
      : null;
    if (!found) {
      colors.push(trueColors[i]);
    }
  }

  let allMaterials = variations
    ? variations.map((item) => {
        if (item.attributes) {
          if (item.attributes.attribute_pa_materials) {
            let obj = {
              name: item.attributes.attribute_pa_materials,
            };

            return obj;
          }
        }
      })
    : [];

  allMaterials = allMaterials.filter(Boolean);

  let materials = [];

  for (let i = 0; i < allMaterials.length; i++) {
    let found = materials.length
      ? materials.find((item) => item.name === allMaterials[i].name)
      : null;
    if (!found) {
      materials.push(allMaterials[i]);
    }
  }

  let getMaterials = featuredProducts
      ? featuredProducts.map((item) => {
        if (item.attributes[0]) {
          if (item.attributes[0].pa_materials) {
            let colAttr = item.attributes[0].pa_materials;
            if (materials.length) {
              let retCol = materials.map((col) => {
                if (colAttr[col.name]) {
                  let ret = {
                    name: colAttr[col.name].name,
                    value: colAttr[col.name].description,
                    slug: colAttr[col.name].slug,
                    id: colAttr[col.name].term_id,
                  };

                  return ret;
                }
              });
              return retCol;
            }
          }
        }
      })
      : [];

  let flatMaterials = getMaterials.flat();

  let trueMaterials = flatMaterials.filter(Boolean);

  let finalMaterials = []

  for (let i = 0; i < trueMaterials.length; i++) {
    let found = finalMaterials.length
        ? finalMaterials.find((item) => item.id === trueMaterials[i].id)
        : null;
    if (!found) {
      finalMaterials.push(trueMaterials[i]);
    }
  }

  return (
    <StyledAllProduct id={id}>
      {id ? (
        <>
          <MobileFilter
            subName={"Featured Products"}
            id={id}
            handleFilters={handleSubmit}
            handleClear={handleClear}
            materials={finalMaterials}
            colors={colors}
          />
          <Container className="body-view">
            <Divider style={{ marginTop: 30 }} />
            <Row>
              <Col xs={3} className="filter-view">
                <AdvanceFilter
                  id={id}
                  handleFilters={handleSubmit}
                  handleClear={handleClear}
                  materials={finalMaterials}
                  colors={colors}
                />
              </Col>
              <Col xs={9} className="column-cl">
                {
                  featuredProducts && featuredProducts.length > 0 ?
                      <Container>
                        {filteredProduct.length ? (
                            <AvailableProduct
                                catId={id}
                                featuredProducts={filteredProduct}
                                title={"Featured Products"}
                                subTitle={"Filtered Results"}
                                pageNumber={pageNumber}
                                isSearch={isSearch}
                                data={data}
                                changePageNumber={changePageNumber}
                                fetchFeatured={fetchFeatured}
                            />
                        ) : (
                            <>
                              <AvailableProduct
                                  catId={id}
                                  featuredProducts={featuredProducts}
                                  title={"Featured Products"}
                                  // subTitle={"Best Sellers"}
                                  pageNumber={pageNumber}
                                  isSearch={isSearch}
                                  data={data}
                                  changePageNumber={changePageNumber}
                                  fetchFeatured={fetchFeatured}
                              />
                            </>
                        )}
                      </Container>
                      :
                      <h5 style={{marginTop: 20}}>
                        No product found.
                      </h5>
                }

              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <div className="loader">
          <CircularProgress />
        </div>
      )}
    </StyledAllProduct>
  );
};

const StyledAllProduct = styled.div`
  padding-bottom: 64px;
  height: ${(props) => (props.id ? "auto" : "120vh")};

  .loader {
    margin-top: 25%;
    text-align: center;

    @media (max-width: 991px) {
      margin-top: 40%;
    }
  }
  .container {
    max-width: 95%;
  }

  .Banner-view {
    display: block;
  }

  .text-filter {
    font-weight: 600;
    font-size: 14px;
    color: #313132;
    text-decoration: underline;
    font-family: "Poppins", sans-serif;
  }

  .text-filter-menu {
    font-family: Poppins, sans-serif;
    font-size: 10px;
    font-weight: 400;
    padding: 8px 16px 8px 16px;
    background-color: #d8d8d8;
    border-radius: 25px;
    cursor: pointer;
    white-space: nowrap;
    margin-right: 12px;
  }

  .filter-mobile-view {
    display: none;
  }

  .text-breadcrumb {
    font-weight: normal;
    font-size: 10px;
    color: #313132;
    font-family: "Poppins", sans-serif;
  }

  .text-breadcrumb-point {
    width: 3px;
    height: 3px;
    color: #313132;
    border-radius: 50%;
    background-color: #313132;
    margin-right: 8px;
    margin-left: 8px;
  }

  .body-view {
    display: block;
  }

  .subTitle {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 0px;
    padding-bottom: 2px;
    margin-top: 24px;
    color: rgb(156, 148, 52);
  }

  .top-title {
    font-size: 36px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 0px;
    padding-bottom: 8px;
    color: #313132;
  }

  .subTitle-text {
    font-family: Renner, sans-serif;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 0px;
    padding-bottom: 2px;
    margin-top: 30px;
    color: rgb(156, 148, 52);
  }

  .top-title-text {
    font-size: 18px;
    font-weight: bold;
    font-family: Renner,sans-serif;
    text-transform: uppercase;
    padding-left: 0px;
    padding-bottom: 8px;
    color: #313132;
  }

  .filter-view {
    display: block;
  }

  .column-cl {
    margin-bottom: 30px;

    .container {
      max-width: 100%;
    }
  }

  .read-more {
    background-color: #fff;
    border-radius: 50px;
    border: 1px solid #313132;
    cursor: pointer;
    text-align: center;
    width: 76px;

    .load-button {
      color: #313132;
      font-size: 10px;
      font-weight: normal;
      font-family: "Poppins", sans-serif;
      padding: 3px 8px 3px 8px;
    }

    &:hover {
      border: 1px solid #9e6c51;
      background-color: #9e6c51;

      .load-button {
        color: #ffffff;
      }
    }
  }

  .client-single {
    &__inner {
      padding-top: 42.74%;
      border: 0 solid #707070;

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 0;
        object-fit: cover;
        width: 98.3%;
      }
    }
  }

  @media (max-width: 991px) {
    .loader {
      margin-top: 30%;
    }

    .col-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .Banner-view {
      display: none;
    }

    .filter-mobile-view {
      display: block;
    }

    .filter-view {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .col-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .filter-mobile-view {
      display: block;
    }

    .body-view {
      display: block;
    }

    .filter-view {
      display: none;
    }

    .MuiLinearProgress-root {
      display: none;
    }

    .texts {
      display: none;

      p {
        font-size: 10px;
      }
    }

    .loader {
      margin-top: 45%;
    }

    .read-more {
      background-color: #fff;
      border-radius: 50px;
      border: 1px solid #313132;
      cursor: pointer;
      text-align: center;
      width: 100%;

      .load-button {
        color: #313132;
        font-size: 10px;
        font-weight: normal;
        font-family: "Poppins", sans-serif;
        padding: 8px 16px 8px 16px;
      }
    }
  }
`;

function mapStateToProps(state) {
  return {
    categories: state.homeReducer.categories,
    featuredProducts: state.homeReducer.featuredProducts,
    allProducts: state.homeReducer.allProducts,
    filteredProduct: state.homeReducer.filteredProduct,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchFeatured: (data, pageNum, prev_data_list, isSearch, search) => dispatch(fetchFeaturedProducts(data, pageNum, prev_data_list, isSearch, search)),
    fetchFiltered: (data) => dispatch(fetchFiltered(data)),
    filterInit: () => dispatch(filterInit()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AllProduct);
