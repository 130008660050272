import React, {useEffect} from 'react'
import {motion} from "framer-motion";
import styled from 'styled-components'
import {PageAnimation} from "../../components/PageAnimation";
import AboutUs from "./components/AboutUs";
import CircularProgress from "@material-ui/core/CircularProgress";

import {connect} from "react-redux";
import {fetchBrand, fetchHomeBannerSlider, fetchProductData, getAllPosts} from "../../redux/actions/home";

const About = (props) => {
  const { allPosts} = props

  useEffect(() => {
    window.scrollTo(0, 0)
    if ( allPosts) {
      if (!allPosts.length) {
        props.getAllPosts();
      }
    }
  }, [])

  // const aboutPost = allPosts ?
  //   allPosts.length ? allPosts.filter((item, key) => item.category[key].slug === 'about-us') : []
  //   : [] --> previous about post filtering ->
  const aboutPost = allPosts ?
    allPosts.length ? allPosts.filter((item, key) => item.post_name === 'our-story' || item.post_name === 'who-we-are' || item.post_name === 'mission-and-vision') : []
    : []

  return (
    <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
      <StyledAbout len={aboutPost.length}>
        {aboutPost.length ? (<AboutUs posts={aboutPost} />) : (
          <div className="loader">
            <CircularProgress />
          </div>
        )}
      </StyledAbout>
    </motion.div>
  )
}

const StyledAbout = styled.div`
  height: ${(props) => (props.len ? "auto" : "120vh")};
  
  .loader {
    margin-top: 25%;
    text-align: center;
  }
`

function mapStateToProps(state) {
  return {
    allPosts: state.homeReducer.posts
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getAllPosts: () => dispatch(getAllPosts()),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(About)
