import React from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import { products } from "../../../assets/data/products";
import room from "../../../assets/images/room.png";
import Title from "../../../components/Title";
import innerSlider from "../../../assets/images/innerSlider.png";
import RightIcon from "../../../assets/images/rightIconArrow.svg";

import { connect } from "react-redux";
import Divider from "@material-ui/core/Divider";
import Swiper from "react-id-swiper";
import { useHistory } from "react-router-dom";

const AllRoom = (props) => {
  let { categories } = props;

  let history = useHistory();

  let room = categories
    ? categories.length
      ? categories.find((item) => item.slug === "room")
      : null
    : null;

  let roomId = room ? room.term_id : null;

  let roomChild = roomId
    ? categories.length
      ? categories.filter((item) => item.parent === roomId)
      : []
    : [];

  let allChild = roomChild.length
    ? roomChild.map((item) => {
        let subCategory = categories.filter(
          (sub) => sub.parent === item.term_id
        );
        let newObj = {
          ...item,
          child: subCategory,
        };

        return newObj;
      })
    : [];

  //console.log('All Child', allChild)

  const paramsInner = {
    slidesPerView: 3,
    spaceBetween: 15,
    speed: 1000,
    // pagination: {
    //     el: '.swiper-pagination',
    //     clickable: true
    // },
    // autoplay: {
    //   delay: 4500,
    //   disableOnInteraction: false,
    // },
    breakpoints: {
      575: {
        slidesPerView: 3,
        spaceBetween: 15,
      },
      1080: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
      1081: {
        slidesPerView: 3,
        spaceBetween: 15,
      },
      1500: {
        slidesPerView: 3,
        spaceBetween: 15,
      },
      1501: {
        slidesPerView: 4,
        spaceBetween: 15,
      },
    },
    navigation: {
      nextEl: ".swiper-button-next",
      //prevEl: '.swiper-button-prev'
    },
  };

  let handleRoute = (slug) => {
    history.push(`/room-products/${slug}`);
  };

  let handleProductRoute = (id) => {
    history.push(`/product-list/${id}`);
  };

  return (
    <StyledAllBrand>
      <Container>
        <Row className="change-view">
          <Col>
            <div
              style={{ display: "flex", marginTop: 90, alignItems: "center" }}
            >
              <div className="text-breadcrumb">Category</div>
              <div className="text-breadcrumb-point" />
              <div className="text-breadcrumb">Room</div>
              {/*<div className="text-breadcrumb-point"/>*/}
              {/*<div className="text-breadcrumb">Bedroom</div>*/}
            </div>
            <Divider style={{ marginTop: 6, marginBottom: 30 }} />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="subTitle">Products</div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 24,
              }}
            >
              <div className="top-title">Shop by Room</div>
            </div>
          </Col>
        </Row>

        <Row>
          {allChild.length
            ? allChild.map((item, key) => {
                return (
                  <Col key={key} lg={4} md={6} sm={6} className="column-cl">
                    <div className="news-events-slider__single">
                      <div
                        className="news-events-slider__single__img"
                        onClick={() => handleRoute(item.slug)}
                      >
                        <img src={item.image_url} alt="" />
                      </div>
                      <div className="news-events-slider__single__content">
                        <Title
                          text={item.name}
                          fontSize={24}
                          color="#ffffff"
                          fontWeight={500}
                          lineHeight={"32"}
                          textTransform={"uppercase"}
                          height={"125"}
                        />
                        {/*<Title*/}
                        {/*  text={`Everything you need in your ${item.name}`}*/}
                        {/*  fontSize={12}*/}
                        {/*  color="#EFEFEF"*/}
                        {/*  fontWeight={300}*/}
                        {/*  fontFamily={"Poppins"}*/}
                        {/*  textTransform={"capitalize"}*/}
                        {/*  lineHeight={"13"}*/}
                        {/*  letterSpacing={1}*/}
                        {/*/>*/}
                        <div style={{ marginTop: 48 }}>
                          {item.child ? (
                            item.child.length ? (
                              <Swiper {...paramsInner}>
                                {item.child.map((data, key) => {
                                  return (
                                    <div
                                        key={key}
                                      className="inner-slider"
                                      onClick={() =>
                                        handleProductRoute(data.term_id)
                                      }
                                    >
                                      <div
                                        style={{
                                          display: "table-row",
                                          alignItems: "center",
                                          textAlign: "center",
                                          marginRight: 28,

                                        }}
                                      >
                                        <img src={data.image_url} alt="" />
                                        <div
                                          style={{
                                            fontSize: 10,
                                            fontWeight: 400,
                                            fontFamily: "Poppins",
                                            color: "#fff",
                                            paddingTop: 10,
                                            height:40

                                          }}
                                        >
                                          {data.name}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </Swiper>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })
            : null}
        </Row>
      </Container>
    </StyledAllBrand>
  );
};

const StyledBreadcrumb = styled.div`
  display: flex;
  margin-top: 90px;
  align-items: center;
`;

const StyledAllBrand = styled.div`
  padding-bottom: 64px;

  .container {
    max-width: 95%;
    @media(max-width: 991px){
      max-width: 100%;
      padding: 20px;
    }
  }

  .text-breadcrumb {
    font-weight: normal;
    font-size: 10px;
    color: #313132;
    font-family: "Poppins", sans-serif;
  }

  .text-breadcrumb-point {
    width: 3px;
    height: 3px;
    color: #313132;
    border-radius: 50%;
    background-color: #313132;
    margin-right: 8px;
    margin-left: 8px;
  }

  .change-view {
    display: block;
    @media(max-width: 991px){
      display: none;
    }
  }

  .subTitle {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom: 2px;
    margin-top: 10px;
    color: rgb(156, 148, 52);
    @media(max-width: 991px){
      margin-top: 120px;
    }
  }

  .top-title {
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    color: #313132;
    margin-bottom: 24px;
    @media(max-width: 991px){
      margin-bottom: 15px;
    }
  }

  .column-cl {
    margin-bottom: 30px;
  }

  .news-events-slider {
    &__single {
      &__content {
        background-color: #43484c;
        padding: 20px;

        h4 {
          font-size: 14px;
          text-transform: uppercase;
          font-weight: 500;
          line-height: 21px;
          margin: 0 0 20px 0;
          color: rgba(9, 32, 59, 0.5);
        }

        p {
          font-size: 20px;
          font-weight: bold;
          line-height: 30px;
          margin-bottom: 40px;
        }

        h2 {
          width: 80%;
          height: 42px;
        }

        .line {
          margin-top: 4px;
          border-top: 1px solid rgba(255, 255, 255, 0.5);
        }

        .inner-slider {
          width: 72px !important;
          margin-right: auto;
          display: flex;
          overflow: hidden;
          cursor: pointer;

          img {
            height: 70px;
            width: 70px;
          }
        }

        @media (max-width: 1280px) {
          .inner-slider {
            width: 64px !important;
            margin-right: auto;
            display: flex;
            overflow: hidden;
            cursor: pointer;

            img {
              height: 60px;
              width: 60px;
              margin-right: 15px;

              &:nth-last-of-type(1) {
                margin-right: 0;
              }
            }
          }
        }
      }

      &__img {
        padding-top: 105.41%;
        position: relative;
        overflow: hidden;
        z-index: 3;
        cursor: pointer;

        a {
          position: absolute;
          height: 100%;
          width: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          z-index: 3;
        }

        img {
          position: absolute;
          height: 100%;
          width: 100%;
          object-fit: cover;
          transform: scale(1.01);
          top: 0;
          left: 0;
          transition: all 2s ease;
        }

        &:hover {
          img {
            transform: scale(1.05);
            filter: contrast(140%);
          }
        }
      }
    }

    &__bottom {
      margin-top: -50px;
    }
  }

  .NewsEvents-slider__bottom {
    position: relative;
    z-index: 3;
  }

  .swiper-container {
    //margin-left: -50px;
    //margin-right: -50px;
    //padding-right: 50px;
    //padding-left: 50px;
    position: relative;

    //&:before {
    //  content: "";
    //  position: absolute;
    //  height: 100%;
    //  width: 50px;
    //  background-color: rgba(99, 110, 127, 0);
    //  left: 0;
    //  top: 0;
    //  z-index: 2;
    //}
    //
    //&:after {
    //  content: "";
    //  position: absolute;
    //  height: 100%;
    //  width: 50px;
    //  background-color: rgba(99, 110, 127, 0);
    //  right: 0;
    //  top: 0;
    //  z-index: 2;
    //}
  }

  .swiper-button-next {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: auto;
    z-index: 3;
    height: 25px;
    width: 25px;
    background-image: url(${RightIcon});
    background-position: center;
    background-size: contain;
    top: -22px;
    left: auto;
    cursor: pointer;

    &:focus {
      outline: none;
    }
  }

  .swiper-button-disabled {
    opacity: 1 !important;
  }

  .swiper-wrapper {
    margin-bottom: 0px;
  }
`;

function mapStateToProps(state) {
  return {
    categories: state.homeReducer.categories,
  };
}

export default connect(mapStateToProps)(AllRoom);
