import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import {withStyles} from "@material-ui/core/styles";
import {connect} from 'react-redux'
import {openLeftDrawer} from "../../../redux/actions/left-drawer";
import CrossIcon from '../../../assets/icons/cross-icon.svg'
import {leftDrawerStyle} from "../leftDrawerStyle";
import LogoImage from '../../../assets/images/shanta-logo.png'
import Title from "../../../components/Title";
import TimerMixin from 'react-timer-mixin'

import {useHistory, Link} from "react-router-dom";

let style = theme => leftDrawerStyle(theme)

let MainLeft = (props) => {
  let {classes, openLeft, handleLeftDrawer, authData} = props;

  let history = useHistory();

  let handleClose = () => {
    handleLeftDrawer('')

  }

  let handleRoute = (path) => {
    handleClose()
    TimerMixin.setTimeout(() => {
      history.push(`/${path}`)
    }, 500)
  }

  return (
    <div>
      <Drawer
        anchor={'left'}
        open={openLeft === 'left-main'}
        onClose={handleClose}
      >
        <div className={classes.drawerHeader}>
          <div
            onClick={() => handleLeftDrawer('')}
            className={classes.crossIcon}
          >
            <img src={CrossIcon} />
          </div>
          <div onClick={() => handleRoute('')}>
            <img src={LogoImage}/>
          </div>
        </div>
        <div className={classes.drawerBody}>
          <div
            role="presentation"
          >
            <Link onClick={() => handleRoute('about-us')}>
              <Title
                text={'Shanta Lifestyle'}
                color={'#313132'}
                fontSize={14}
                lineHeight={24}
                fontFamily={'Poppins'}
                fontWeight={'600'}
                textAlign={'left'}
                margin={'0 0 10px 40px'}
              />
            </Link>
            <Link onClick={() => handleRoute('scope-of-work')}>
              <Title
                  text={'Scope of Work'}
                  color={'#313132'}
                  fontSize={14}
                  lineHeight={24}
                  fontFamily={'Poppins'}
                  fontWeight={'600'}
                  textAlign={'left'}
                  margin={'0 0 10px 40px'}
              />
            </Link>
            <Link onClick={() => handleRoute('new-arrival')}>
              <Title
                text={'New Arrivals'}
                color={'#313132'}
                fontSize={14}
                lineHeight={24}
                fontFamily={'Poppins'}
                fontWeight={'600'}
                textAlign={'left'}
                margin={'0 0 10px 40px'}
              />
            </Link>
            <Link onClick={() => handleRoute('featured')}>
              <Title
                text={'Featured products'}
                color={'#313132'}
                fontSize={14}
                lineHeight={24}
                fontFamily={'Poppins'}
                fontWeight={'600'}
                textAlign={'left'}
                margin={'0 0 10px 40px'}
              />
            </Link>
            <div className={classes.innerDrawerDiv}>
              <div className={classes.innerDiv} onClick={() => handleLeftDrawer('left-products')}>
                <Title
                  text={'Products'}
                  color={'#313132'}
                  fontSize={14}
                  lineHeight={24}
                  fontFamily={'Poppins'}
                  fontWeight={'600'}
                  textAlign={'left'}
                  margin={'0 0 10px 40px'}
                />
              </div>
              <div className={classes.innerDiv} onClick={() => handleLeftDrawer('left-room')}>
                <Title
                  text={'Rooms'}
                  color={'#313132'}
                  fontSize={14}
                  lineHeight={24}
                  fontFamily={'Poppins'}
                  fontWeight={'600'}
                  textAlign={'left'}
                  margin={'0 0 10px 40px'}
                />
              </div>
              <div className={classes.innerDiv} onClick={() => handleLeftDrawer('left-brand')}>
                <Title
                  text={'Brands'}
                  color={'#313132'}
                  fontSize={14}
                  lineHeight={24}
                  fontFamily={'Poppins'}
                  fontWeight={'600'}
                  textAlign={'left'}
                  margin={'0 0 10px 40px'}
                />
              </div>
              <div className={classes.innerDiv}   onClick={() => handleRoute('book-appointment')}>
                <Title
                    text={'Book an Appointment'}
                    color={'#313132'}
                    fontSize={14}
                    lineHeight={24}
                    fontFamily={'Poppins'}
                    fontWeight={'600'}
                    textAlign={'left'}
                    margin={'0 0 10px 40px'}
                />
              </div>

              <div className={classes.innerDiv} onClick={() => handleRoute('webseries')}>
                <Title
                    text={'Webseries'}
                    color={'#313132'}
                    fontSize={14}
                    lineHeight={24}
                    fontFamily={'Poppins'}
                    fontWeight={'600'}
                    textAlign={'left'}
                    margin={'0 0 10px 40px'}
                />
              </div>
              <div className={classes.innerDiv} onClick={() => handleRoute('blog-and-news')}>
                <Title
                    text={'Blog'}
                    color={'#313132'}
                    fontSize={14}
                    lineHeight={24}
                    fontFamily={'Poppins'}
                    fontWeight={'600'}
                    textAlign={'left'}
                    margin={'0 0 10px 40px'}
                />
              </div>
            </div>
            <Link onClick={() => handleRoute('stories')}>
              <Title
                // text={'Stories'}
                color={'#313132'}
                fontSize={14}
                lineHeight={24}
                fontFamily={'Poppins'}
                fontWeight={'400'}
                textAlign={'left'}
                margin={'0 0 10px 40px'}
              />
            </Link>
            {
              authData?.token ?
                  <Link onClick={() => handleRoute('orders')}>
                    <Title
                        text={'Track Orders'}
                        color={'#313132'}
                        fontSize={14}
                        lineHeight={24}
                        fontFamily={'Poppins'}
                        fontWeight={'400'}
                        textAlign={'left'}
                        margin={'0 0 10px 40px'}
                    />
                  </Link> : null
            }

            <Link onClick={() => handleRoute('faq')}>
              <Title
                text={'FAQ'}
                color={'#313132'}
                fontSize={14}
                lineHeight={24}
                fontFamily={'Poppins'}
                fontWeight={'400'}
                textAlign={'left'}
                margin={'0 0 10px 40px'}
              />
            </Link>
            <Link onClick={() => handleRoute('contact-us')}>
              <Title
                text={'Contact Us'}
                color={'#313132'}
                fontSize={14}
                lineHeight={24}
                fontFamily={'Poppins'}
                fontWeight={'400'}
                textAlign={'left'}
                margin={'0 0 10px 40px'}
              />
            </Link>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    openLeft: state.leftDrawerReducer.openLeft,
    authData: state.authReducer.authData,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    handleLeftDrawer: data => dispatch(openLeftDrawer(data))
  }
}

export default withStyles(style)(connect(mapStateToProps, mapDispatchToProps)(MainLeft))
