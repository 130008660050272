import React, {useEffect, useState} from 'react';
import {forgetReq, handleAuthModal, logout, sendOTP, setPhone} from "../../../redux/actions/auth";
import {withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import {authStyle} from "../authStyle";
import {validatePhone} from "../../../helpers/validators";
import TimerMixin from "react-timer-mixin";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Title from "../../../components/Title";
import CrossIcon from "../../../assets/icons/cross-icon.svg";
import {Col, Form, Row} from "react-bootstrap";
import Button from "../../../components/NewButtonSubmit";

let styles = theme => authStyle(theme)

const SendCode = (props) => {
    let [phone, setPhone] = useState('');
    let [phoneErr, setPhoneErr] = useState('');
    let [submitErr, setSubmitErr] = useState('')
    let [success, setSuccess] = useState('')

    let handlePhone= e => {
        setPhone(e.target.value)
    }

    let handlePhoneBlur = () => {
        let valid = validatePhone(phone)

        if(valid) {
            setPhoneErr('')
        } else {
            setPhoneErr('Phone number is not valid')
        }
    }

    const handleClose = () => {
        props.handleAuthModal('')
    };

    let handleSubmit = () => {
        handlePhoneBlur();

        if(!phoneErr.length) {
            let data = {
                user_phone: phone
            }

            props.sendOTP(data)
        }
    }

    useEffect(() => {
        if(props.codeVerify) {
            if(props.codeVerify.status) {
                props.setAuthPhone(phone)
                setSuccess(props.codeVerify.message)

                TimerMixin.setTimeout(() => {
                    props.handleAuthModal('signup')
                    setSuccess('')
                    setPhoneErr('')
                    setSubmitErr('')
                    props.logout()
                }, 5000)
            } else {
                setSubmitErr(props.codeVerify.message)
            }
        }
    }, [props.codeVerify])

    return (
        <div>
            <Dialog
                open={props.openModal === 'signup-verify'}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogContent className={props.classes.main}>
                    <Title
                        text={'Verify your number'}
                        color={'#313132'}
                        fontSize={24}
                        lineHeight={40}
                        fontWeight={'500'}
                        textAlign={'left'}
                        textTransform={'capitalize'}
                        // margin={'5px 0px 15px 0px'}
                    />
                    <img className={props.classes.crossIcon} src={CrossIcon} onClick={handleClose} />
                    <Form>
                        <Form.Group>
                            <Row>
                                <Col className={props.classes.formField} md={12}>
                                    <Form.Label className={props.classes.formLabel}>Enter Your Phone Number</Form.Label>
                                    <Form.Control
                                        type='text'
                                        name="phone"
                                        value={phone}
                                        className={props.classes.formInput}
                                        onChange={handlePhone}
                                        placeholder={'Your Phone Number'}
                                        onBlur={handlePhoneBlur}
                                    />
                                    {phoneErr.length ? (
                                        <Title
                                            text={phoneErr}
                                            fontWeight={200}
                                            fontSize={14}
                                            lineHeight={20}
                                            color={'red'}
                                        />
                                    ) : null}
                                    {success.length ? (
                                        <Title
                                            text={success}
                                            fontWeight={200}
                                            fontSize={14}
                                            lineHeight={20}
                                            color={'green'}
                                        />
                                    ) : null}
                                    {submitErr.length ? (
                                        <Title
                                            text={submitErr}
                                            fontWeight={200}
                                            fontSize={14}
                                            lineHeight={20}
                                            color={'red'}
                                        />
                                    ) : null}
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>
                    <Button text={'Submit'} handleSubmit={handleSubmit} loading={props.codeVerifyLoading} />
                </DialogContent>
            </Dialog>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        openModal: state.authReducer.openModal,
        codeVerify: state.authReducer.codeVerifyData,
        codeVerifyLoading: state.authReducer.codeVerifyDataLoading,
        phone: state.authReducer.phoneNum
    }
}

function mapDispatchToProps(dispatch) {
    return {
        handleAuthModal: data => dispatch(handleAuthModal(data)),
        setAuthPhone: data => dispatch(setPhone(data)),
        sendOTP: data => dispatch(sendOTP(data)),
        logout: () =>dispatch(logout())
    }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SendCode))