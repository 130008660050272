import React, {useEffect} from 'react'
import {motion} from "framer-motion";
import styled from 'styled-components'
import {PageAnimation} from "../../components/PageAnimation";
import CircularProgress from "@material-ui/core/CircularProgress";
import ContactInner from "./components/inner";
import {connect} from "react-redux";
import Title from "../../components/Title";
import {Col, Container, Row} from "react-bootstrap";
import Divider from "@material-ui/core/Divider";
import {fetchPages, resetPages} from "../../redux/actions/home";

const Contact = (props) => {
  const { allPosts} = props

  useEffect(() => {
    window.scrollTo(0, 0)
    props.resetPages()

    let data = {
      id: 4442
    }
    props.fetchPages(data)
  }, [])

  return (
    <motion.div exit="exit" animate="show" variants={PageAnimation} initial="hidden">
      <StyledAbout>
        <Container>
          <Row className="change-view">
            <Col sm={12}>
              <div
                  style={{ display: "flex", alignItems: "center" }}
              >
                {/*<div className="text-breadcrumb">Products</div>*/}
                {/*<div className="text-breadcrumb-point" />*/}
                <div className="text-breadcrumb">Home</div>
                <div className="text-breadcrumb-point"/>
                <div className="text-breadcrumb">Contact Us</div>
              </div>
              <Divider style={{ marginTop: 6, marginBottom: 30 }} />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div className="subTitle">Contact Us</div>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 24,
                  }}
              >
                {/*<div className="top-title">Find us at:</div>*/}
              </div>
            </Col>
          </Row>
          {props.pages ? <ContactInner/> : (
              <div className="loader">
                <CircularProgress />
              </div>
          )}
        </Container>
      </StyledAbout>
    </motion.div>
  )
}

const StyledAbout = styled.div`
  margin-top: 100px;
  min-height: 50vh;
  
  .container {
    max-width: 95%;
  }
  .change-view {
    display: block;
    @media(max-width: 991px){
      display: none;
    }
  }

  .subTitle {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom: 2px;
    margin-top: 10px;
    color: rgb(156, 148, 52);
    @media(max-width: 991px){
      margin-top: 30px;
    }
  }
  
  .text-breadcrumb {
    font-weight: normal;
    font-size: 10px;
    color: #313132;
    font-family: "Poppins", sans-serif;
  }

  .text-breadcrumb-point {
    width: 3px;
    height: 3px;
    color: #313132;
    border-radius: 50%;
    background-color: #313132;
    margin-right: 8px;
    margin-left: 8px;
  }

  .top-title {
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom: 8px;
    color: #313132;
  }
  
  .loader {
    margin-top: 18%;
    text-align: center;
  }
  
  //@media (min-width: 1800px) {
  //  margin-bottom: 100px !important;
  //}
  //@media (min-width: 1601px) {
  //  margin-bottom: 50px;
  //}
  //@media (max-width: 1600px) {
  //  margin-bottom: 50px;
  //}
  //@media (max-width: 1367px) {
  //  margin-bottom: 0px;
  //}
  //@media (max-width: 768px) {
  //  margin-bottom: 0px;
  //}
  //@media (max-width: 767px) {
  //  padding: 0px;
  //  margin-bottom: 360px;
  //  .container {
  //    max-width: 100%;
  //    padding: 0px;
  //    
  //    .row{
  //      padding: 0px 15px;
  //    }
  //  }
  //}
  //@media(max-width: 660px){
  //  margin-bottom: 300px;
  //}
`

function mapStateToProps(state) {
  return {
    allPosts: state.homeReducer.posts,
    pages: state.homeReducer.pages,
    pageLoading: state.homeReducer.pageLoading
  }
}

function mapDispatchToProps(dispatch) {
  return {
    fetchPages: (data) => dispatch(fetchPages(data)),
    resetPages: () => dispatch(resetPages()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Contact)
