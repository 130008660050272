export const PRE_BOOK = {
  SEARCH_SLOT: {
    MAIN: "SEARCH_SLOT",
    SUCCESS: "SEARCH_SLOT_SUCCESS",
    FAILURE: "SEARCH_SLOT_FAILURE",
  },
  SUBMIT_SLOT: {
    MAIN: "SUBMIT_SLOT",
    SUCCESS: "SUBMIT_SLOT_SUCCESS",
    FAILURE: "SUBMIT_SLOT_FAILURE",
  },
  CLEAR_SUBMIT: "CLEAR_SUBMIT",
  CLEAR_SLOT: "CLEAR_SLOT",
  HANDLE_PREBOOK: "HANDLE_PREBOOK",
};
