import React from "react";
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Divider from "@material-ui/core/Divider";
import ReactHtmlParser from "react-html-parser";
import banner from '../../../assets/images/dynamic/banner-blog.jpeg'

const Details = ({post}) => {
    //console.log(post)
    return(
        <StyledStory>
            <Container>
                <Row className="change-view">
                    <Col>
                        <div style={{display: 'flex' , marginTop: 120, alignItems: 'center'}}>
                            <div className="text-breadcrumb">Menu</div>
                            <div className="text-breadcrumb-point"/>
                            <div className="text-breadcrumb">Blog</div>
                            <div className="text-breadcrumb-point"/>
                            <div className="text-breadcrumb">{post.post_title}</div>
                        </div>
                        <Divider style={{marginTop: 6, marginBottom: 30}}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="subTitle">
                            Lifestyle Blogs
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div style={{display: 'flex', alignItems: 'center', marginBottom: 24}}>
                            <div className="top-title">
                                {post.post_title}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="body-text">
                            {/*<div className="banner-img">*/}
                            {/*    <img src={banner}/>*/}
                            {/*</div>*/}

                            {/*<div className="line"/>*/}

                            {/*<div className="text-data">*/}
                            {/*    <h4>*/}
                            {/*        Bringing Luxury to your home from around the Globe*/}
                            {/*    </h4>*/}
                            {/*    <p>*/}
                            {/*        Solutions that multiply the compositional possibilities, providing the utmost creative freedom, cutting-edge technology and maxmum design customisation: this is the Zalf universe, furniture for the home and the workplace, all products entirely Made in Italy.*/}
                            {/*    </p>*/}
                            {/*    <p>*/}
                            {/*        High quality materials and attention to detail are necessary elements Zalf employs to ensure the products leaving its factory will pass any type of test, putting the safety of the end user as a top priority.*/}
                            {/*    </p>*/}
                            {/*</div>*/}

                            {ReactHtmlParser(post.post_content)}
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledStory>
    )
}

const StyledStory = styled.div`
  padding-bottom: 64px;
  min-height: 60vh;

  .container {
    max-width: 95%;
    @media (max-width: 991px) {
      max-width: 100%;
      padding: 20px;
    }
  }

  .text-breadcrumb {
    font-weight: normal;
    font-size: 10px;
    color: #313132;
    font-family: "Poppins", sans-serif;
  }

  .text-breadcrumb-point {
    width: 3px;
    height: 3px;
    color: #313132;
    border-radius: 50%;
    background-color: #313132;
    margin-right: 8px;
    margin-left: 8px;
  }

  .change-view {
    display: block;
    @media (max-width: 991px) {
      display: none;
    }
  }

  .subTitle {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom: 2px;
    margin-top: 10px;
    color: rgb(156, 148, 52);
    @media (max-width: 991px) {
      margin-top: 120px;
    }
  }

  .top-title {
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 0px;
    padding-bottom: 8px;
    color: #313132;
  }

  .subTitle-text {
    font-family: Renner, sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 0px;
    padding-bottom: 2px;
    margin-top: 55px;
    color: rgb(156, 148, 52);
  }

  .body-text {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: normal;


    .banner-img {
      position: relative;
      width: 100%;
      height: 100%;
      padding-top: 42.71%;

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
      }
    }

    .line {
      border-bottom: 1px solid rgba(136, 136, 136, 0.31);
      width: 100%;
      height: 1px;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    
    .text-data {
      h4 {
        font-family: Renner, sans-serif;
        font-size: 16px;
        font-weight: 700;
      }
      
      p{
        font-family: Poppins, sans-serif;
        font-size: 14px;
        font-weight: 400;
      }
    }

  }

  .top-title-text {
    font-size: 24px;
    font-weight: 500;
    font-family: Renner, sans-serif;
    text-transform: uppercase;
    padding-left: 0px;
    padding-bottom: 8px;
    color: #313132;
  }

  .top-title-text-bold {
    font-size: 18px;
    font-weight: bold;
    font-family: Renner, sans-serif;
    padding-left: 0px;
    padding-bottom: 8px;
    color: #313132;
  }

  .top-title-text-body {
    font-size: 14px;
    font-weight: normal;
    font-family: 'Poppins', sans-serif;
    padding-left: 0px;
    padding-bottom: 8px;
    color: #313132;
  }

  .column-cl {
    margin-bottom: 30px;

    .container {
      max-width: 100%;
    }
  }

  .read-more {
    background-color: #fff;
    border-radius: 50px;
    border: 1px solid #313132;
    cursor: pointer;
    text-align: center;
    width: 76px;

    .load-button {
      color: #313132;
      font-size: 10px;
      font-weight: normal;
      font-family: 'Poppins', sans-serif;
      padding: 3px 8px 3px 8px;
    }
  }
`

export default Details
