import HomeIndex from "./container/home/";
import BrandIndex from "./container/brand";
import RoomIndex from "./container/room";
import ProductList from "./container/product-list";
import React , {useEffect } from "react";
import StyledGlobal from "./globalStyle";
import Footer from "./components/Footer";
import Error from "./container/error";
import { Route, Switch, useLocation, Redirect } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import Menu from "./components/Menu";
import AuthModal from "./container/auth/index";
import RightDrawer from "./container/right-drawer";
import LeftDrawer from "./container/left-drawer";
import TopBar from "./components/Topbar";
import { createMuiTheme } from "@material-ui/core/styles";
import Checkout from "./container/checkout";
import OrderMain from "./container/orders";
import ProductDetails from "./container/product-details";
import BrandProducts from "./container/brand-listing";
import RoomProducts from "./container/room-listing";
import Profile from "./container/profile";
import PaymentProcess from "./container/payment/process";
import PaymentFailure from "./container/payment/failure";
import PaymentCancel from "./container/payment/cancel";
import PreBookDialog from "./container/prebook";
import { connect } from "react-redux";
import About from "./container/about";
import Stories from "./container/stories";
import StoryDetails from "./container/stories/components/StoryDetails";
import FAQ from "./container/faq";
import PreBook from "./container/new-arrivals";
import NewArrivals from "./container/new-arrival"
import FeaturedProducts from "./container/featured";
import Contact from "./container/contact";
import Search from "./container/search";
import "./index.css";

import { submitNewsletter, initNewsLetter } from "./redux/actions/home";
import PageData from "./container/page";
import CustomerService from "./container/customer-service";
import WarrantyPolicy from "./container/warranty-policy/WarrantyPolicy";
import BookAppointment from "./container/book-appointment";
import WebSeries from "./container/webseries";
import Blog from "./container/blog";
import RefundPolicy from "./container/refund-policy/RefundPolicy";
import ReactGA from "react-ga";
import ScopofWorkIndex from "./container/scope-of-work";

const TRACKING_ID = "UA-230662454-1"; // LIVE_TRACKING_ID
//const TRACKING_ID = "UA-230662454-2"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App(props) {
  let { authData, checkout, submitNewsletter, initNewsLetter, newsletter } = props;
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <React.Fragment>
      <MuiThemeProvider theme={theme}>
        <StyledGlobal />
        <AnimatePresence exitBeforeEnter>
          <Menu />
          <LeftDrawer />
          <AuthModal />
          <PreBookDialog />
          <RightDrawer />
          <Switch location={location} key={location.pathname}>
            <Route exact path="/" component={HomeIndex} />
            <Route exact path="/brand" component={BrandIndex} />
            <Route exact path="/scope-of-work" component={ScopofWorkIndex} />
            <Route exact path="/room" component={RoomIndex} />
            {/*<Route exact path="/profile">*/}
            {/*  {authData ? (*/}
            {/*    authData.token ? (*/}
            {/*      <Profile />*/}
            {/*    ) : (*/}
            {/*      <Redirect to="/" />*/}
            {/*    )*/}
            {/*  ) : (*/}
            {/*    <Redirect to="/" />*/}
            {/*  )}*/}
            {/*</Route>*/}
            <Route exact path="/product-list/:id" component={ProductList} />
            {/*<Route exact path="/payment-process" component={PaymentProcess} />*/}
            {/*<Route exact path="/payment-failed" component={PaymentFailure} />*/}
            {/*<Route exact path="/payment-cancel" component={PaymentFailure} />*/}
            <Route exact path="/terms-conditions" component={PageData} />
            <Route exact path="/privacy-policy" component={PageData} />
            <Route exact path="/book-appointment" component={BookAppointment} />
            <Route
              exact
              path="/brand-products/:slug"
              component={BrandProducts}
            />
            <Route exact path="/room-products/:slug" component={RoomProducts} />
            <Route exact path="/about-us" component={About} />
            <Route exact path="/stories" component={Stories} />
            <Route exact path="/blog-and-news" component={Blog} />
            <Route
              exact
              path="/story-detail/:post_name"
              component={StoryDetails}
            />
            <Route exact path="/product/:slug" component={ProductDetails} />
            <Route exact path="/featured" component={FeaturedProducts} />
            <Route exact path="/pre-book-now" component={PreBook} />
            <Route exact path="/new-arrival" component={NewArrivals} />
            <Route exact path="/faq" component={FAQ} />
            <Route exact path="/customer-service" component={CustomerService} />
            <Route exact path="/warranty-policy" component={WarrantyPolicy} />
            <Route exact path="/refund-returns" component={RefundPolicy} />
            <Route exact path="/news-blogs" component={CustomerService} />
            <Route exact path="/webseries" component={WebSeries} />

            <Route exact path="/contact-us" component={Contact} />
            <Route exact path="/search" component={Search} />
            {/*<Route exact path="/checkout">*/}
            {/*  {authData ? (*/}
            {/*    checkout.length ? (*/}
            {/*      <Checkout />*/}
            {/*    ) : (*/}
            {/*      <Redirect to="/" />*/}
            {/*    )*/}
            {/*  ) : (*/}
            {/*    <Redirect to="/" />*/}
            {/*  )}*/}
            {/*</Route>*/}
            {/*<Route exact path="/orders">*/}
            {/*  {authData ? <OrderMain /> : <Redirect to="/" />}*/}
            {/*</Route>*/}
            <Route component={Error} />
          </Switch>
          <Footer
            submitNewsletter={submitNewsletter}
            initNewsLetter={initNewsLetter}
            newsletter={newsletter}
          />
        </AnimatePresence>
      </MuiThemeProvider>
    </React.Fragment>
  );
}

const theme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#313132",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#9C9434",
    },
  },
  overrides: {
    MuiStepIcon: {
      text: {
        display: "none",
      },
      root: {
        color: "rgba(0, 0, 0, 0.38)",
        width: 16,
        "&$completed": {
          color: "#9C9434",
        },
        "&$active": {
          color: "rgba(0, 0, 0, 0.38)",
        },
      },
    },
    MuiTypography: {
      body1: {
        fontSize: 12,
        fontFamily: "Poppins",
      },
    },
    MuiAutocomplete: {
      endAdornment: {
        display: "none",
      },
    },
  },
});

function mapStateToProps(state) {
  return {
    authData: state.authReducer.authData,
    openModal: state.authReducer.openModal,
    openDrawer: state.rightDrawerReducer.openDrawer,
    checkout: state.rightDrawerReducer.checkout,
    openLeft: state.leftDrawerReducer.openLeft,
    newsletter: state.homeReducer.newsletter,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    submitNewsletter: (data) => dispatch(submitNewsletter(data)),
    initNewsLetter: () => dispatch(initNewsLetter()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
