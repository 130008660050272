import React, {useEffect, useState} from "react";
import {PageAnimation} from "../../components/PageAnimation";
import AllProduct from "./components/all-room-products";
import {useParams} from "react-router-dom";
import {motion} from "framer-motion";
import {
    fetchFeaturedProducts,
    fetchFiltered,
    filterInit,
    categoryProductInit, fetchCategoryProducts, fetchCategoryProductsWithPost
} from "../../redux/actions/home";
import {connect} from 'react-redux'
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";

const RoomProductList = (props) => {
    let {
        categories,
        allProducts,
        posts,
        fetchFiltered,
        filteredProduct,
        fetchCategoryProducts,
        categoryProducts,
        filterInit,
        submit,
        categoryProductInit,
        categoryProductsLoading,
        fetchCategoryProductsWithPost
    } = props;
    const {slug} = useParams();

    let [isSearch, setSearch] = useState(false)
    let [data, setData] = useState(
        {
            category_id: null,
            colors: [],
            height: [],
            material: [],
            pageNum: 1,
            post_per_page: '20',
            price: [],
            sort: [],
            width: []
        }
    )

    let roomId = null;

    useEffect(() => {
        window.scrollTo(0, 0);
        categoryProductInit()
    }, [slug])

    const room = categories
        ? categories.length
            ? categories.find((item) => item.slug === slug)
            : null
        : null;

    roomId = room ? room.term_id : null
    const [pageNum, setPageNumber] = useState(1);
    useEffect(() => {
        if (roomId) {
            if (!isSearch) {
                fetchCategoryProductsWithPost({id: roomId, pageNum: pageNum, post_per_page: "20"})
            } else {
                data.category_id = roomId
                data.pageNum = pageNum
                props.fetchCategoryProductsWithPost({id: roomId, pageNum: pageNum, post_per_page: "20"},[],true,data)
            }
        }
    }, [roomId]);

    return (
        <motion.div
            exit="exit"
            animate="show"
            variants={PageAnimation}
            initial="hidden"
        >
            <StyledRoom product={!categoryProductsLoading}>
                {!categoryProductsLoading ? (
                    <AllProduct
                        pageNumber={pageNum}
                        changePageNumber={setPageNumber}
                        slug={slug}
                        submit={submit}
                        categories={categories}
                        allProducts={allProducts}
                        posts={posts}
                        fetchFiltered={fetchFiltered}
                        filteredProduct={filteredProduct}
                        fetchCategoryProducts={fetchCategoryProductsWithPost}
                        categoryProducts={categoryProducts}
                        filterInit={filterInit}
                        id={roomId}
                        catId={roomId}
                        room={room}

                        categoryProductInit={ categoryProductInit }
                        setData={setData}
                        data={data}
                        setSearch={setSearch}
                        isSearch={isSearch}
                    />
                ) : (
                    <div className="loader">
                        <CircularProgress/>
                    </div>
                )}
            </StyledRoom>

        </motion.div>
    );
};

const StyledRoom = styled.div`
  padding-bottom: 64px;
  height: ${(props) => (props.product ? "auto" : "120vh")};

  .loader {
    margin-top: 25%;
    text-align: center;

    @media (max-width: 991px) {
      margin-top: 40%;
    }
  }

  @media (max-width: 991px) {
    margin-top: 100px;
  }
`

function mapStateToProps(state) {
    return {
        categories: state.homeReducer.categories,
        featuredProducts: state.homeReducer.featuredProducts,
        allProducts: state.homeReducer.allProducts,
        posts: state.homeReducer.posts,
        filteredProduct: state.homeReducer.filteredProduct,
        categoryProducts: state.homeReducer.categoryProducts,
        categoryProductsLoading: state.homeReducer.categoryProductsLoading,
        submit: state.prebookReducer.submit,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fetchFeatured: (data) => dispatch(fetchFeaturedProducts(data)),
        fetchFiltered: data => dispatch(fetchFiltered(data)),
        filterInit: () => dispatch(filterInit()),
        fetchCategoryProducts: data => dispatch(fetchCategoryProducts(data)),
        fetchCategoryProductsWithPost: (data, prev_data_list, isSearch, search) => dispatch(fetchCategoryProductsWithPost(data, prev_data_list, isSearch, search)),
        categoryProductInit: () => dispatch(categoryProductInit())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomProductList);
