import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Title from "../../../components/Title";
import Text from "../../../components/Text";
import Divider from "@material-ui/core/Divider";
import ReactHtmlParser from "react-html-parser";
import { Col, Row, Table } from "react-bootstrap";
import styled from "styled-components";
import { red } from "@material-ui/core/colors";
import CardContent from "@material-ui/core/CardContent";
import { Avatar, Card, CardHeader } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { fetchFeaturedProducts } from "../../../redux/actions/home";
import { connect } from "react-redux";
import Product from "../../home/components/Product";
import demo from "../../../assets/images/productImage.png";

let CustomizedAccordions = (props) => {
  const classes = useStyles();
  let { product, active, categories, allProducts, authData, handleOpen } =
    props;
  let featured = categories
    ? categories.length
      ? categories.find((item) => item.slug === "featured-products")
      : null
    : null;

  let id = featured ? featured.term_id : null;

  let featuredProducts = allProducts.length
    ? allProducts.filter((item) => {
        let found = item.category_ids.find((itm) => itm === id);
        if (found) return item;
      })
    : [];

  const [expanded, setExpanded] = useState(true);
  const [expanded1, setExpanded1] = useState(false);
  const [expanded2, setExpanded2] = useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const handleTwo = () => {
    setExpanded1(!expanded1);
  };

  const handleThree = () => {
    setExpanded2(!expanded2);
  };

  let reviews = product?.review ? product?.review : [];

  featuredProducts.length = featuredProducts.length ? 4 : 0;

  return (
    <StyledExpand>
      <Accordion square expanded={expanded} onChange={handleChange}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
        >
          <Title
            text={"Product description"}
            color={"#313132"}
            fontSize={18}
            lineHeight={24}
            fontFamily={"Renner,sans-serif"}
            fontWeight={"bold"}
            textTransform={"none"}
          />
        </AccordionSummary>
        <AccordionDetails>
          <Text color={"#313132"}>
            <p>{ReactHtmlParser(product?.description)}</p>
          </Text>
        </AccordionDetails>
      </Accordion>
      <Divider />
      <Accordion square expanded={expanded1} onChange={handleTwo}>
        <AccordionSummary
          aria-controls="panel2d-content"
          id="panel2d-header"
          expandIcon={<ExpandMoreIcon />}
        >
          <Title
            text={"Product dimension"}
            color={"#313132"}
            fontSize={18}
            lineHeight={24}
            fontFamily={"Renner,sans-serif"}
            fontWeight={"bold"}
            textTransform={"none"}
          />
        </AccordionSummary>
        <AccordionDetails>
          <Table
            responsive
            style={{
              backgroundColor: "#F5F5F5",
              textAlign: "center",
              overflowY: "hidden",
            }}
          >
            <thead>
              <tr>
                <th>
                  <Title
                    text={"Width"}
                    color={"#313132"}
                    fontSize={14}
                    lineHeight={18}
                    fontFamily={"Poppins"}
                    fontWeight={600}
                  />
                </th>
                <th>
                  <Title
                    text={"Depth"}
                    color={"#313132"}
                    fontSize={14}
                    lineHeight={18}
                    fontFamily={"Poppins"}
                    fontWeight={600}
                  />
                </th>
                <th>
                  <Title
                    text={"Height"}
                    color={"#313132"}
                    fontSize={14}
                    lineHeight={18}
                    fontFamily={"Poppins"}
                    fontWeight={600}
                  />
                </th>
                <th>
                  <Title
                    text={"Weight"}
                    color={"#313132"}
                    fontSize={14}
                    lineHeight={18}
                    fontFamily={"Poppins"}
                    fontWeight={600}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Title
                    text={
                      active
                        ? active.dimensions
                          ? active.dimensions.length + " cm"
                          : null
                        : null
                    }
                    color={"#313132"}
                    fontSize={14}
                    lineHeight={18}
                    fontFamily={"Poppins"}
                    fontWeight={"500"}
                  />
                </td>
                <td>
                  <Title
                    text={
                      active
                        ? active.dimensions
                          ? active.dimensions.width + " cm"
                          : null
                        : null
                    }
                    color={"#313132"}
                    fontSize={14}
                    lineHeight={18}
                    fontFamily={"Poppins"}
                    fontWeight={"500"}
                  />
                </td>
                <td>
                  <Title
                    text={
                      active
                        ? active.dimensions
                          ? active.dimensions.height + " cm"
                          : null
                        : null
                    }
                    color={"#313132"}
                    fontSize={14}
                    lineHeight={18}
                    fontFamily={"Poppins"}
                    fontWeight={"500"}
                  />
                </td>
                <td>
                  <Title
                    text={active ? active.weight_html : null}
                    color={"#313132"}
                    fontSize={14}
                    lineHeight={18}
                    fontFamily={"Poppins"}
                    fontWeight={"500"}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </AccordionDetails>
      </Accordion>
      {/*<Divider />*/}
      {/*<Accordion square expanded={expanded2} onChange={handleThree}>*/}
      {/*  <AccordionSummary*/}
      {/*    aria-controls="panel3d-content"*/}
      {/*    id="panel3d-header"*/}
      {/*    expandIcon={<ExpandMoreIcon />}*/}
      {/*  >*/}
      {/*    <Title*/}
      {/*      text={"Reviews"}*/}
      {/*      color={"#313132"}*/}
      {/*      fontSize={18}*/}
      {/*      lineHeight={24}*/}
      {/*      fontFamily={"Renner,sans-serif"}*/}
      {/*      fontWeight={"bold"}*/}
      {/*      textTransform={"none"}*/}
      {/*    />*/}
      {/*  </AccordionSummary>*/}
      {/*  /!*<AccordionDetails>*!/*/}
      {/*  /!*  <div>*!/*/}
      {/*  /!*    <Row className="review-row">*!/*/}
      {/*  /!*      {reviews.length ? (*!/*/}
      {/*  /!*        reviews.map((item, key) => {*!/*/}
      {/*  /!*          return (*!/*/}
      {/*  /!*            <Col key={key} lg={4} md={6} sm={12} className="column-cl">*!/*/}
      {/*  /!*              <ProductCardWrapper>*!/*/}
      {/*  /!*                <CardHeader*!/*/}
      {/*  /!*                  avatar={*!/*/}
      {/*  /!*                    <Avatar*!/*/}
      {/*  /!*                      aria-label="recipe"*!/*/}
      {/*  /!*                      className={classes.avatar}*!/*/}
      {/*  /!*                    >*!/*/}
      {/*  /!*                      {item.reviewer_name[0]}*!/*/}
      {/*  /!*                    </Avatar>*!/*/}
      {/*  /!*                  }*!/*/}
      {/*  /!*                  title={*!/*/}
      {/*  /!*                    <div className={classes.name}>*!/*/}
      {/*  /!*                      {item.reviewer_name}*!/*/}
      {/*  /!*                    </div>*!/*/}
      {/*  /!*                  }*!/*/}
      {/*  /!*                  subheader={*!/*/}
      {/*  /!*                    <StyledRating*!/*/}
      {/*  /!*                      name="size-small"*!/*/}
      {/*  /!*                      defaultValue={item.rating}*!/*/}
      {/*  /!*                      size="small"*!/*/}
      {/*  /!*                      readOnly*!/*/}
      {/*  /!*                    />*!/*/}
      {/*  /!*                  }*!/*/}
      {/*  /!*                />*!/*/}
      {/*  /!*                <CardContent>*!/*/}
      {/*  /!*                  /!* <div className={classes.date}>18-Apr-21</div> *!/*!/*/}
      {/*  /!*                  <Typography*!/*/}
      {/*  /!*                    variant="body2"*!/*/}
      {/*  /!*                    component="p"*!/*/}
      {/*  /!*                    className={classes.text}*!/*/}
      {/*  /!*                  >*!/*/}
      {/*  /!*                    {ReactHtmlParser(item.content)}*!/*/}
      {/*  /!*                  </Typography>*!/*/}
      {/*  /!*                </CardContent>*!/*/}
      {/*  /!*              </ProductCardWrapper>*!/*/}
      {/*  /!*            </Col>*!/*/}
      {/*  /!*          );*!/*/}
      {/*  /!*        })*!/*/}
      {/*  /!*      ) : (*!/*/}
      {/*  /!*        <Title*!/*/}
      {/*  /!*          text={"No reviews yet"}*!/*/}
      {/*  /!*          color={"#313132"}*!/*/}
      {/*  /!*          fontSize={14}*!/*/}
      {/*  /!*          lineHeight={20}*!/*/}
      {/*  /!*          fontFamily={"Renner,sans-serif"}*!/*/}
      {/*  /!*          fontWeight={"bold"}*!/*/}
      {/*  /!*          margin={"30px 16px"}*!/*/}
      {/*  /!*          textTransform={"none"}*!/*/}
      {/*  /!*        />*!/*/}
      {/*  /!*      )}*!/*/}
      {/*  /!*    </Row>*!/*/}
      {/*  /!*    <Row>*!/*/}
      {/*  /!*      <Col md={12}>*!/*/}
      {/*  /!*        {authData ? (*!/*/}
      {/*  /!*          authData.token ? (*!/*/}
      {/*  /!*            <center className="center">*!/*/}
      {/*  /!*              <div*!/*/}
      {/*  /!*                className="read-more"*!/*/}
      {/*  /!*                onClick={() => handleOpen(true)}*!/*/}
      {/*  /!*              >*!/*/}
      {/*  /!*                <div className="load-button">Add Review</div>*!/*/}
      {/*  /!*              </div>*!/*/}
      {/*  /!*            </center>*!/*/}
      {/*  /!*          ) : null*!/*/}
      {/*  /!*        ) : null}*!/*/}
      {/*  /!*      </Col>*!/*/}
      {/*  /!*    </Row>*!/*/}
      {/*  /!*  </div>*!/*/}
      {/*  /!*</AccordionDetails>*!/*/}
      {/*</Accordion>*/}

      {/*For now people who buys will be hidden/commented*/}
      {/*In future / updated version, It will be used*/}
      {/*<Title*/}
      {/*  text={"People who buys this also are also interested in:"}*/}
      {/*  color={"#313132"}*/}
      {/*  fontSize={18}*/}
      {/*  lineHeight={24}*/}
      {/*  fontFamily={"Renner,sans-serif"}*/}
      {/*  fontWeight={"bold"}*/}
      {/*  margin={"30px 0 30px 0"}*/}
      {/*  textTransform={"none"}*/}
      {/*/>*/}
      <Row>
        {featuredProducts.length
          ? featuredProducts.map((item, key) => {
              return (
                <Col key={key} xl={3} lg={6} md={6} sm={12} className="column-cl-product">
                  <Product item={item} />
                </Col>
              );
            })
          : null}
      </Row>
    </StyledExpand>
  );
};

const Accordion = withStyles({
  root: {
    //border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    //backgroundColor: 'rgba(0, 0, 0, .03)',
    //borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    padding: 0,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    //padding: theme.spacing(2),
    padding: "8px 16px 16px 0px",
    overflowY: "hidden",
  },
}))(MuiAccordionDetails);

const StyledRating = withStyles({
  iconFilled: {
    color: "rgba(156,148,52,0.9)",
    height: 10,
    width: 11,
    marginRight: 6,
    marginTop: 8,
  },
  iconEmpty: {
    color: "rgba(122,121,121,0.51)",
    height: 10,
    width: 11,
    marginRight: 6,
    marginTop: 8,
  },
  iconHover: {
    color: "#9C9434",
    height: 10,
    width: 11,
    marginRight: 6,
    marginTop: 8,
  },
})(Rating);

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: red[500],
    width: 40,
    height: 40,
  },
  name: {
    fontSize: 14,
    marginTop: 7,
    fontFamily: "Poppins",
    color: "#313132",
    fontWeight: "500",
  },
  date: {
    fontSize: 10,
    marginBottom: 5,
    fontFamily: "Poppins",
    color: "#707070",
    fontWeight: "400",
  },
  text: {
    fontSize: 14,
    marginBottom: 5,
    fontFamily: "Poppins",
    color: "#000000",
    fontWeight: "400",
  },
}));

const StyledExpand = styled.div`
  .column-cl {
    margin-bottom: 32px;
    @media (max-width: 767px) {
      padding-right: 0px;
    }
  }
  .column-cl-product {
    margin-bottom: 72px;
  }

  .center {
    margin-top: 20px;
  }

  .review-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: nowrap;
    scroll-x: auto;
  }

  .read-more {
    background-color: #fff;
    border-radius: 50px;
    border: 1px solid #313132;
    cursor: pointer;
    text-align: center;
    width: 76px;
    .load-button {
      color: #313132;
      font-size: 10px;
      font-weight: normal;
      font-family: "Poppins", sans-serif;
      padding: 3px 8px 3px 8px;
    }
    &:hover {
      border: 1px solid #9e6c51;
      background-color: #9e6c51;
      .load-button {
        color: #ffffff;
      }
    }
  }
`;

const ProductCardWrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: #fcfcfc;
  border-bottom: 2px solid #efefef;
  position: relative;
  border-radius: 0;
  cursor: pointer;
  margin-right: 30px;

  &:nth-last-of-type(1) {
    margin-right: 0;
  }
`;

function mapStateToProps(state) {
  return {
    categories: state.homeReducer.categories,
    featuredProducts: state.homeReducer.featuredProducts,
    allProducts: state.homeReducer.allProducts,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchFeatured: (data) => dispatch(fetchFeaturedProducts(data)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomizedAccordions);
