import React, { useEffect } from "react";
import styled from "styled-components";
import Title from "../../components/Title";
import { withRouter, useHistory, Link } from "react-router-dom";

let PaymentCancel = (props) => {
  return (
    <StyledSuccess>
      <div className="main">
        <Title
          text={"Order Processing Cancel."}
          fontWeight={"bold"}
          fontSize={24}
          lineHeight={32}
          margin={"30px 0 10px 0"}
          textAlign={"center"}
        />
        <Link to={"/checkout"}>
          <Title
            text={`To Proceed again, Return to checkout again`}
            fontWeight={400}
            fontSize={18}
            lineHeight={24}
            margin={"0 0 10px 0"}
            textAlign={"center"}
            color={"#419AE8"}
            textTransform={"capitalize"}
          />
        </Link>
      </div>
    </StyledSuccess>
  );
};

let StyledSuccess = styled.div`
  background-color: #f8f8f8;
  padding: 120px 40px;
  height: 120vh;

  .main {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      height: 50px;
      width: 50px;
    }
  }
`;

export default withRouter(PaymentCancel);
