import React, { useEffect } from "react";
import { PageAnimation } from "../../components/PageAnimation";
import AllProduct from "./components/all-search-products";
import { useParams, useLocation } from "react-router-dom";
import { motion } from "framer-motion";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SearchProductList = (props) => {
  const { slug } = useParams();
  let query = useQuery()

  let text = query.get('text')

  //console.log("Text", text)

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <motion.div
      exit="exit"
      animate="show"
      variants={PageAnimation}
      initial="hidden"
    >
      <AllProduct slug={slug} text={text} />
    </motion.div>
  );
};

export default SearchProductList;