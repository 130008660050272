import { ORDERS } from "../../constants/orders";

let initState = {
  orders: [],
  orderLoading: false,
  orderErr: null,
  orderFile: '',
  orderFileLoading: false,
  orderFileErr: null,
  ordersAmount: '',
  ordersAmountLoading: false,
  ordersAmountErr: null,
  newOrder: null,
  newOrderLoading: false,
  newOrderErr: null,
  homeAddress: null,
  address1: null,
  payment: "cash",
  phone: null,
  email: null,
  paymentGateway: null,
  paymentGatewayLoading: false,
  paymentGatewayErr: "",
  paymentDoneLoading: false
};

export default function orderReducer(state = initState, action) {
  switch (action.type) {
    case ORDERS.SET_ORDER.MAIN:
      return {
        ...state,
        newOrder: null,
        newOrderLoading: true,
        newOrderErr: null,
      };

    case ORDERS.SET_ORDER.SUCCESS:
      return {
        ...state,
        newOrder: action.result,
        newOrderLoading: false,
        newOrderErr: null,
      };

    case ORDERS.SET_ORDER.FAILURE:
      return {
        ...state,
        newOrder: null,
        newOrderLoading: false,
        newOrderErr: action.result,
      };
    case ORDERS.ORDERS_FILE.MAIN:
      return {
        ...state,
        orderFile: null,
        orderFileLoading: true,
        orderFileErr: null,
      };

    case ORDERS.ORDERS_FILE.SUCCESS:
      return {
        ...state,
        orderFile: action.result,
        orderFileLoading: false,
        orderFileErr: null,
      };

    case ORDERS.ORDERS_FILE.FAILURE:
      return {
        ...state,
        orderFile: null,
        orderFileLoading: false,
        orderFileErr: action.result,
      };
    case ORDERS.ORDERS_AMOUNT.MAIN:
      return {
        ...state,
        ordersAmount: null,
        ordersAmountLoading: true,
        ordersAmountErr: null,
      };

    case ORDERS.ORDERS_AMOUNT.SUCCESS:
      return {
        ...state,
        ordersAmount: action.result,
        ordersAmountLoading: false,
        ordersAmountErr: null,
      };

    case ORDERS.ORDERS_AMOUNT.FAILURE:
      return {
        ...state,
        ordersAmount: null,
        ordersAmountLoading: false,
        ordersAmountErr: action.result,
      };
    case ORDERS.PAYMENT_DONE.MAIN:
      return {
        ...state,
        paymentDoneLoading: true,
      };

    case ORDERS.PAYMENT_DONE.SUCCESS:
      return {
        ...state,
        paymentDoneLoading: false,

      };

    case ORDERS.PAYMENT_DONE.FAILURE:
      return {
        ...state,
        paymentDoneLoading: false,
      };
    case ORDERS.PAYMENT_FAILED.MAIN:
      return {
        ...state,
        paymentDoneLoading: true,
      };
    case ORDERS.PAYMENT_FAILED.SUCCESS:
      return {
        ...state,
        paymentDoneLoading: false,

      };
    case ORDERS.PAYMENT_FAILED.FAILURE:
      return {
        ...state,
        paymentDoneLoading: false,
      };
    case ORDERS.PAYMENT_GATEWAY.MAIN:
      return {
        ...state,
        paymentGatewayLoading: true,
      };

    case ORDERS.PAYMENT_GATEWAY.SUCCESS:
      return {
        ...state,
        paymentGatewayLoading: false,
        paymentGateway: action.result,
      };

    case ORDERS.PAYMENT_GATEWAY.FAILURE:
      return {
        ...state,
        paymentGatewayErr: "Error from payment gateway",
      };

    case ORDERS.PAYMENT_INIT:
      return {
        ...state,
        paymentGateway: null,
        paymentGatewayLoading: false,
        paymentGatewayErr: "",
      };

    case ORDERS.INIT_NEW_ORDER.SUCCESS:
      return {
        ...state,
        newOrder: null,
      };

    case ORDERS.FETCH_ALL_ORDERS.MAIN:
      return {
        ...state,
        orders: [],
        orderLoading: true,
        orderErr: null,
      };

    case ORDERS.FETCH_ALL_ORDERS.SUCCESS:
      return {
        ...state,
        orders: action.result,
        orderLoading: false,
        orderErr: null,
      };

    case ORDERS.FETCH_ALL_ORDERS.FAILURE:
      return {
        ...state,
        orders: [],
        orderLoading: false,
        orderErr: action.result,
      };

    case ORDERS.SET_HOME.SUCCESS:
      return {
        ...state,
        homeAddress: action.result,
      };

    case ORDERS.SET_ADDRESS.SUCCESS:
      return {
        ...state,
        address1: action.result,
      };

    case ORDERS.SET_EMAIL.SUCCESS:
      return {
        ...state,
        email: action.result,
      };

    case ORDERS.SET_PHONE.SUCCESS:
      return {
        ...state,
        phone: action.result,
      };

    case ORDERS.SET_PAYMENT.SUCCESS:
      return {
        ...state,
        payment: action.result,
      };

    default:
      return state;
  }
}
