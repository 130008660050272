import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import Title from "../../../components/Title";
import { authStyle } from "../../auth/authStyle";
import { withStyles } from "@material-ui/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

let styles = (theme) => authStyle(theme);

let AddressField = (props) => {
  let {
    classes,
    home,
    home2,
    homeCity,
    address1,
    address2,
    addressCity,
    handleHomeAddress,
    handleHomeAddress1,
    handleHomeCity,
    handleAddress,
    handleAddress1,
    handleAddressCity,
    homeErr,
    checkHome,
    checkAddr,
    handleCheckHome,
    handleCheckAddr
  } = props;
  return (
    <Form>
      <Form.Group>
        <Row>
          <Col className={classes.formField} md={12}>
            <FormControlLabel
              className={classes.formLabel}
              control={
                <Checkbox
                  checked={checkHome}
                  onChange={handleCheckHome}
                  name="check"
                  color="primary"
                />
              }
              label="Home:"
            />
            <Row>
              <Col className={classes.formField} md={6} xs={12}>
                <Form.Control
                  type="text"
                  name="home"
                  value={home}
                  className={classes.formInput}
                  onChange={handleHomeAddress}
                  placeholder={"Your Home Address"}
                />
              </Col>
              <Col className={classes.formField} md={6} xs={12}>
                <Form.Control
                  type="text"
                  name="home"
                  value={home2}
                  className={classes.formInput}
                  onChange={handleHomeAddress1}
                  placeholder={"Home Address Line 2"}
                />
              </Col>
              <Col className={classes.formField} md={6} xs={12}>
                <Form.Control
                  type="text"
                  name="home"
                  className={classes.formInput}
                  value={homeCity}
                  onChange={handleHomeCity}
                  placeholder={"City"}
                />
              </Col>
            </Row>

            {homeErr ? (
              homeErr.length ? (
                <Title
                  text={homeErr}
                  fontWeight={200}
                  fontSize={14}
                  lineHeight={20}
                  fontFamily={'Poppins'}
                  color={"red"}
                  margin={"10px 0 0 0"}
                />
              ) : null
            ) : null}
          </Col>
          <Col className={classes.formField} md={12}>
            <FormControlLabel
              className={classes.formLabel}
              control={
                <Checkbox
                  checked={checkAddr}
                  onChange={handleCheckAddr}
                  name="check"
                  color="primary"
                />
              }
              label="Address 1:"
            />
            <Row>
              <Col className={classes.formField} md={6} xs={12}>
                <Form.Control
                  type="text"
                  name="home"
                  className={classes.formInput}
                  value={address1}
                  onChange={handleAddress}
                  placeholder={"Your Home Address"}
                />
              </Col>
              <Col className={classes.formField} md={6} xs={12}>
                <Form.Control
                  type="text"
                  name="home"
                  className={classes.formInput}
                  value={address2}
                  onChange={handleAddress1}
                  placeholder={"Home Address Line 2"}
                />
              </Col>
              <Col className={classes.formField} md={6} xs={12}>
                <Form.Control
                  type="text"
                  name="home"
                  className={classes.formInput}
                  value={addressCity}
                  onChange={handleAddressCity}
                  placeholder={"City"}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Form.Group>
    </Form>
  );
};

export default withStyles(styles)(AddressField);
