import React, { useState } from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import { connect } from "react-redux";
import Divider from "@material-ui/core/Divider";
import {
  fetchFeaturedProducts,
  fetchFiltered,
} from "../../../redux/actions/home";
import AvailableProduct from "./AvailableProduct";
import AdvanceFilter from "../../../components/AdvancedFilter";
import CircularProgress from "@material-ui/core/CircularProgress";
import MobileFilter from "../../../components/MobileFilter";
import ReactHtmlParser from "react-html-parser";
import { sortFilter, onlyUnique } from "../../../helpers/globalFunc";
import {Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";

const AllProduct = (props) => {
  let {
    categories,
    catId,
    fetchFiltered,
    filteredProduct,
    categoryProducts,
    filterInit,
    pageNumber,
    submit,
    changePageNumber,
    categoryProductInit,
    fetchCategoryProducts,
    setData,
    data,
    setSearch,
    isSearch,
  } = props;

  let [sort, setSort] = useState([]);
  let [showPopup, setShowPopup] = useState(false);
  let vertical = "bottom";
  let horizontal = "left";

  let subCategory = categories
    ? categories.length
      ? categories.find((item) => item.term_id.toString() === catId.toString())
      : null
    : null;

  let subName = subCategory ? subCategory.name : "";

  let newArrivals = categories
    ? categories.length
      ? categories.find((item) => item.slug === "pre-book-now")
      : null
    : null;

  let newId = newArrivals ? newArrivals.term_id : null;

  let newArrivalList = categoryProducts
    ? categoryProducts.length
      ? categoryProducts.filter((item) =>
          item.category_ids.some((i) => i === newId)
        )
      : []
    : [];

  let featuredProducts = categoryProducts
    ? categoryProducts.length
      ? categoryProducts.filter((item) =>
          item.category_ids.every((i) => i !== newId)
        )
      : []
    : [];
  // //console.log('category data list',featuredProducts)
  let filteredNewArrival = filteredProduct.length
    ? filteredProduct.filter((item) =>
        item.category_ids.some((i) => i === newId)
      )
    : [];

  let filteredFeatured = filteredProduct.length
    ? filteredProduct.filter((item) =>
        item.category_ids.every((i) => i !== newId)
      )
    : [];

  const handleSubmit = (data) => {
    window.scrollTo(0, 0);
    setSearch(true)
    categoryProductInit()
    data.pageNum = 1;
    data.post_per_page = "20";

    //console.log(data)
    setData(data)
    setSort(data.sort);
    changePageNumber(1)
    props.fetchCategoryProducts({id: data.category_id, pageNum: 1, post_per_page: "20"},[],true,data)
  };

  const handleClear = () => {
    window.scrollTo(0, 0);
    setSearch(false)
    filterInit();
    setSort([]);
    changePageNumber(1)
    setData({
      category_id: null,
      colors: [],
      height: [],
      material: [],
      pageNum: 1,
      post_per_page: '20',
      price: [],
      sort: [],
      width: []
    })
    props.fetchCategoryProducts({id: catId, pageNum: 1, post_per_page: "20"},[],false,{})
  };

  if (sort.length) {
    filteredProduct = filteredProduct.length
      ? sortFilter(filteredProduct, sort)
      : sortFilter(categoryProducts, sort);
  }

  let getVariations = categoryProducts
    ? categoryProducts.map((item) => {
        return item.variations;
      })
    : [];

  let variations = getVariations.flat();

  let availableColors = variations.map((item) => {
    let key = item.attributes.attribute_pa_color;
    return key;
  });

  let filteredColors = availableColors.filter(onlyUnique);

  let getColors = categoryProducts
    ? categoryProducts.map((item) => {
        if (item.attributes[0]) {
          if (item.attributes[0].pa_color) {
            let colAttr = item.attributes[0].pa_color;

            if (filteredColors.length) {
              let retCol = filteredColors.map((col) => {
                if (colAttr[col]) {
                  let ret = {
                    name: colAttr[col].name,
                    value: colAttr[col].description,
                    slug: colAttr[col].slug,
                    id: colAttr[col].term_id,
                  };

                  return ret;
                }
              });
              return retCol;
            }
          }
        }
      })
    : [];

  let flatColors = getColors.flat();

  let trueColors = flatColors.filter(Boolean);

  let colors = [];

  for (let i = 0; i < trueColors.length; i++) {
    let found = colors.length
      ? colors.find((item) => item.id === trueColors[i].id)
      : null;
    if (!found) {
      colors.push(trueColors[i]);
    }
  }

  let allMaterials = variations
    ? variations.map((item) => {
        if (item.attributes) {
          if (item.attributes.attribute_pa_materials) {
            let obj = {
              name: item.attributes.attribute_pa_materials,
            };

            return obj;
          }
        }
      })
    : [];

  let materials = [];

  allMaterials = allMaterials.filter(Boolean)

  for (let i = 0; i < allMaterials.length; i++) {
    let found = materials.length
      ? materials.find((item) => item.name === allMaterials[i].name)
      : null;
    if (!found) {
      materials.push(allMaterials[i]);
    }
  }

  let getMaterials = categoryProducts
      ? categoryProducts.map((item) => {
        if (item.attributes[0]) {
          if (item.attributes[0].pa_materials) {
            let colAttr = item.attributes[0].pa_materials;

            //console.log("categoryProducts", colAttr)

            if (materials.length) {
              let retCol = materials.map((col) => {
                if (colAttr[col.name]) {
                  let ret = {
                    name: colAttr[col.name].name,
                    value: colAttr[col.name].description,
                    slug: colAttr[col.name].slug,
                    id: colAttr[col.name].term_id,
                  };

                  return ret;
                }
              });
              return retCol;
            }
          }
        }
      })
      : [];

  let flatMaterials = getMaterials.flat();

  let trueMaterials = flatMaterials.filter(Boolean);

  let finalMaterials = []

  for (let i = 0; i < trueMaterials.length; i++) {
    let found = finalMaterials.length
        ? finalMaterials.find((item) => item.id === trueMaterials[i].id)
        : null;
    if (!found) {
      finalMaterials.push(trueMaterials[i]);
    }
  }

  //console.log("finalMaterials", finalMaterials)

  let handleClose = () => {
    setShowPopup(false);
  };


  return (
    <StyledAllProduct>
      <Container className="Banner-view">
        <Row>
          <Col>
            <div
              style={{ display: "flex", marginTop: 30, alignItems: "center" }}
            >
              <div className="text-breadcrumb">Product</div>
              <div className="text-breadcrumb-point" />
              <div className="text-breadcrumb">Furniture</div>
              <div className="text-breadcrumb-point" />
              <div className="text-breadcrumb">{ReactHtmlParser(subName)}</div>
            </div>
            <Divider style={{ marginTop: 6, marginBottom: 30 }} />
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              style={{ display: "flex", marginTop: 30, alignItems: "center" }}
            >
              <div className="text-filter">
                View for {ReactHtmlParser(subName)}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <MobileFilter
        subName={ReactHtmlParser(subName)}
        id={catId}
        handleFilters={handleSubmit}
        handleClear={handleClear}
        materials={finalMaterials}
        colors={colors}
      />
      <Container className="body-view">
        <Divider style={{ marginTop: 30 }} />
        <Row>
          <Col xs={3} className="filter-view">
            <AdvanceFilter
              id={catId}
              handleFilters={handleSubmit}
              handleClear={handleClear}
              materials={finalMaterials}
              colors={colors}
            />
          </Col>
          <Col xs={9} className="column-cl">
            {
              categoryProducts && categoryProducts.length > 0 ?
                  <Container>
                    {filteredProduct.length ? (
                        <>
                          {filteredNewArrival.length ? (
                              <>
                                <AvailableProduct
                                    dataSize={filteredProduct && filteredProduct.length > 0 ? filteredProduct.length : categoryProducts.length}
                                    categoryProducts={filteredProduct && filteredProduct.length > 0 ? filteredProduct : categoryProducts}
                                    setShowPopup={setShowPopup}
                                    featuredProducts={filteredNewArrival}
                                    fetchCategoryProductsWithPost={props.fetchCategoryProducts}
                                    title={
                                        "Filtered Results of " + ReactHtmlParser(subName)
                                    }
                                    subTitle={"Pre Book Now"}
                                    catId={catId}
                                    pageNumber={pageNumber}
                                    changePageNumber={changePageNumber}
                                    isSearch={isSearch}
                                    data={data}
                                />
                                <Divider style={{ marginTop: 30, marginBottom: 30 }} />
                              </>
                          ) : (
                              ""
                          )}
                          {filteredFeatured.length ? (
                              <AvailableProduct
                                  dataSize={filteredProduct && filteredProduct.length > 0 ? filteredProduct.length : categoryProducts.length}
                                  categoryProducts={filteredProduct && filteredProduct.length > 0 ? filteredProduct : categoryProducts}
                                  featuredProducts={filteredFeatured}
                                  title={"Filtered Results of " + ReactHtmlParser(subName)}
                                  fetchCategoryProductsWithPost={props.fetchCategoryProducts}
                                  catId={catId}
                                  pageNumber={pageNumber}
                                  changePageNumber={changePageNumber}
                                  //subTitle={"Best Sellers"}
                                  isSearch={isSearch}
                                  data={data}
                              />
                          ) : (
                              ""
                          )}
                        </>
                    ) : (
                        <>
                          {newArrivalList.length ? (
                              <>
                                <AvailableProduct
                                    dataSize={filteredProduct && filteredProduct.length > 0 ? filteredProduct.length : categoryProducts.length}
                                    categoryProducts={filteredProduct && filteredProduct.length > 0 ? filteredProduct : categoryProducts}
                                    featuredProducts={newArrivalList}
                                    title={ReactHtmlParser(subName)}
                                    subTitle={"Pre Book Now"}
                                    catId={catId}
                                    pageNumber={pageNumber}
                                    fetchCategoryProductsWithPost={props.fetchCategoryProducts}
                                    changePageNumber={changePageNumber}
                                    isSearch={isSearch}
                                    data={data}
                                />
                                <Divider style={{ marginTop: 30, marginBottom: 30 }} />
                              </>
                          ) : (
                              ""
                          )}

                          {featuredProducts.length ? (
                              <AvailableProduct
                                  dataSize={filteredProduct && filteredProduct.length > 0 ? filteredProduct.length : categoryProducts.length}
                                  categoryProducts={filteredProduct && filteredProduct.length > 0 ? filteredProduct : categoryProducts}
                                  featuredProducts={featuredProducts}
                                  title={ReactHtmlParser(subName)}
                                  fetchCategoryProductsWithPost={props.fetchCategoryProducts}
                                  catId={catId}
                                  pageNumber={pageNumber}
                                  changePageNumber={changePageNumber}
                                  //subTitle={"Best Sellers"}
                                  isSearch={isSearch}
                                  data={data}
                              />
                          ) : (
                              ""
                          )}
                        </>
                    )}
                  </Container>
                  :
                  <h5 style={{marginTop: 20}}>
                    No product found.
                  </h5>
            }
          </Col>
        </Row>
        <Snackbar open={showPopup}
                  autoHideDuration={6000}
                  anchorOrigin={{vertical, horizontal}}
                  key={vertical + horizontal}
                  onClose={handleClose}>
          <Alert onClose={handleClose} sx={{width: '100%'}} color="#ffffff"
                 style={{backgroundColor: '#313132', color: '#ffffff', fontWeight: 450, maxWidth: 500, textAlign: 'left'}}>
            {submit?.message}
          </Alert>
        </Snackbar>
      </Container>
    </StyledAllProduct>
  );
};

const StyledAllProduct = styled.div`
  .container {
    max-width: 95%;
  }

  .Banner-view {
    display: block;
  }

  .text-filter {
    font-weight: 600;
    font-size: 14px;
    color: #313132;
    text-decoration: underline;
    font-family: "Poppins", sans-serif;
  }

  .text-filter-menu {
    font-family: Poppins, sans-serif;
    font-size: 10px;
    font-weight: 400;
    padding: 8px 16px 8px 16px;
    background-color: #d8d8d8;
    border-radius: 25px;
    cursor: pointer;
    white-space: nowrap;
    margin-right: 12px;
  }

  .filter-text-filter {
    display: none;
  }

  .text-breadcrumb {
    font-weight: normal;
    font-size: 10px;
    color: #313132;
    font-family: "Poppins", sans-serif;
  }

  .text-breadcrumb-point {
    width: 3px;
    height: 3px;
    color: #313132;
    border-radius: 50%;
    background-color: #313132;
    margin-right: 8px;
    margin-left: 8px;
  }

  .body-view {
    display: block;
  }

  .subTitle {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 0px;
    padding-bottom: 2px;
    margin-top: 24px;
    color: rgb(156, 148, 52);
  }

  .top-title {
    font-size: 36px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 0px;
    padding-bottom: 8px;
    color: #313132;
  }

  .subTitle-text {
    font-family: Renner, sans-serif;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 0px;
    padding-bottom: 2px;
    margin-top: 30px;
    color: rgb(156, 148, 52);
  }

  .top-title-text {
    font-size: 18px;
    font-weight: bold;
    font-family: Renner, sans-serif;
    text-transform: uppercase;
    padding-left: 0px;
    padding-bottom: 8px;
    color: #313132;
  }

  .filter-view {
    display: block;
  }

  .column-cl {
    margin-bottom: 30px;

    .container {
      max-width: 100%;
    }
  }

  .read-more {
    background-color: #fff;
    border-radius: 50px;
    border: 1px solid #313132;
    cursor: pointer;
    text-align: center;
    width: 76px;

    .load-button {
      color: #313132;
      font-size: 10px;
      font-weight: normal;
      font-family: "Poppins", sans-serif;
      padding: 3px 8px 3px 8px;
    }

    &:hover {
      border: 1px solid #9e6c51;
      background-color: #9e6c51;

      .load-button {
        color: #ffffff;
      }
    }
  }

  .client-single {
    &__inner {
      padding-top: 42.74%;
      border: 0 solid #707070;

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        bottom: 0;
        object-fit: cover;
        width: 98.3%;
      }
    }
  }

  @media (max-width: 991px) {
    .loader {
      margin-top: 30%;
    }

    .col-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .Banner-view {
      display: none;
    }

    .filter-mobile-view {
      display: block;
    }

    .filter-view {
      display: none;
    }
  }

  @media (max-width: 767px) {
    .col-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .filter-mobile-view {
      display: block;
    }

    .body-view {
      display: block;
    }

    .filter-view {
      display: none;
    }

    .MuiLinearProgress-root {
      display: none;
    }

    .texts {
      display: none;

      p {
        font-size: 10px;
      }
    }

    .loader {
      margin-top: 45%;
    }

    .read-more {
      background-color: #fff;
      border-radius: 50px;
      border: 1px solid #313132;
      cursor: pointer;
      text-align: center;
      width: 100%;

      .load-button {
        color: #313132;
        font-size: 10px;
        font-weight: normal;
        font-family: "Poppins", sans-serif;
        padding: 8px 16px 8px 16px;
      }
    }
  }
`;

function mapStateToProps(state) {
  return {
    categories: state.homeReducer.categories,
    featuredProducts: state.homeReducer.featuredProducts,
    filteredProduct: state.homeReducer.filteredProduct,
    submit: state.prebookReducer.submit,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchFeatured: (data) => dispatch(fetchFeaturedProducts(data)),
    fetchFiltered: (data) => dispatch(fetchFiltered(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AllProduct);
