import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Col, Container, Row } from "react-bootstrap";
import bannerProduct from "../../../assets/images/banner-all-product.png";
import aboutUsImage from "../../../assets/images/about-us.png";
import mission from "../../../assets/images/mission.png";
import Divider from "@material-ui/core/Divider";
import Title from "../../../components/Title";
import Text from "../../../components/Text";
import { fetchFeaturedProducts } from "../../../redux/actions/home";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Story from "./Story";
import { loadMore } from "../../../helpers/globalFunc";
import Product from "../../home/components/Product";
import CircularProgress from "@material-ui/core/CircularProgress";


const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 2,
    width: 170,
    marginBottom: 13,
    marginTop: 8,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#989899",
  },
}))(LinearProgress);

const StoryList = (props) => {
  let { stories, child_cat, total, handlerClick, number, setNumber,loading,loadMoreLoading } = props;

  const handleLoad = () => {
    setNumber(number+1)
  };

  return (
    <StyledStoryList>
      <Container>
        <Row className="change-view">
          <Col>
            <div
              style={{ display: "flex", marginTop: 120, alignItems: "center" }}
            >
              <div className="text-breadcrumb">Shanta Lifestyle</div>
              <div className="text-breadcrumb-point" />
              <div className="text-breadcrumb">Web Series</div>
            </div>
            <Divider style={{ marginTop: 6, marginBottom: 30 }} />
          </Col>
        </Row>
        <Row>
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 24,
              }}
            >
              <div className="top-title">Web Series</div>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="category-top">
            {
              child_cat ? child_cat.length > 0 ? child_cat.map((item, key)=>{
                return (
                    <div className={item.is_selected === true ? "series-category-active" : "series-category"}
                         onClick={()=>handlerClick(item.category_slug)}
                         key={key}
                    >{item?.category_name}
                    </div>
                )
              }) : null : null
            }
          </div>
        </Row>
        {
          !loading? (
                  <>
                    <Row className='mt-4'>
                      {stories.length
                          ? stories.map((item, key) => {
                            return (
                                <Col key={key} lg={4} md={4} sm={6} className="column-cl">
                                  <Story item={item} />
                                </Col>
                            );
                          })
                          :  <div className="loader-first">
                            <CircularProgress/>
                          </div>}
                    </Row>
                    <Row>
                      { total > 6 ? (
                          <Col>
                            <center>
                              <Text
                                  text={`Showing ${stories.length} of ${total}`}
                                  fontWeight={"normal"}
                                  fontFamily={"Renner, sans-serif"}
                                  fontSize={10}
                              />
                              {loadMoreLoading ? <p style={{fontSize: "12px",fontFamily: "Poppins,sans-serif"}}>Loading more data...</p> : (
                                  <>
                                    <BorderLinearProgress
                                        variant="determinate"
                                        value={
                                            parseFloat(stories.length / total) * 100
                                        }
                                    />
                                    <div className="read-more" onClick={() => handleLoad()}>
                                      <div className="load-button">Load more</div>
                                    </div>
                                  </>
                              )}
                            </center>
                          </Col>
                      ) : null}
                    </Row>
                  </>
          ): (
          <div className="loader">
            <CircularProgress/>
          </div>
          )
        }
      </Container>
    </StyledStoryList>
  );
};

function mapStateToProps(state) {
  return {
    loading: state.homeReducer.webSeriesLoading
  };
}
const StyledStoryList = styled.div`
  padding-bottom: 64px;
  min-height: 80vh;

  .container {
    max-width: 95%;
    @media (max-width: 991px) {
      max-width: 100%;
      padding: 20px;
    }
  }

  .loader-first {
    margin-top: 10%;
    display: flex;
    text-align: center;
    justify-content: center;
    width: 100%;

    @media (max-width: 991px) {
      margin-top: 20%;
    }
  }
  
  .loader {
    margin-top: 15%;
    text-align: center;

    @media (max-width: 991px) {
      margin-top: 40%;
    }
  }
  .category-top {
    display: flex;
    margin-left: 15px;
    @media (max-width: 991px) {
      margin-top: 15px;
      margin-left: 20px;
    }
    
    .series-category {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-weight: 400;
      padding: 10px 30px;
      margin-right: 10px;
      color: #9C9434;
      background-color: #ffffff;
      border-radius: 50px;
      border: 1px #9C9434 solid;
      cursor: pointer;
    }
    .series-category-active {
      cursor: pointer;
      font-family: Poppins, sans-serif;
      font-size: 14px;
      font-weight: 400;
      padding: 10px 30px;
      margin-right: 10px;
      color: #ffffff;
      background-color: #9C9434;
      border-radius: 50px;
    }
  }
  
  .text-breadcrumb {
    font-weight: normal;
    font-size: 10px;
    color: #313132;
    font-family: Renner, sans-serif;
  }

  .text-breadcrumb-point {
    width: 3px;
    height: 3px;
    color: #313132;
    border-radius: 100%;
    background-color: #313132;
    margin-right: 8px;
    margin-left: 8px;
  }

  .change-view {
    display: block;
    @media (max-width: 991px) {
      display: none;
    }
  }

  .subTitle {
    font-family: Renner, sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom: 2px;
    margin-top: 10px;
    color: rgb(156, 148, 52);
    @media (max-width: 991px) {
      margin-top: 120px;
    }
  }

  .top-title {
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 0px;
    padding-bottom: 8px;
    color: #313132;
    @media (max-width: 991px) {
      margin-top: 100px;
      padding-bottom: 0px;
      padding-left: 8px;
      
    }
  }

  .subTitle-text {
    font-family: Renner, sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 0px;
    padding-bottom: 2px;
    margin-top: 55px;
    color: rgb(156, 148, 52);
  }

  .top-title-text {
    font-size: 24px;
    font-weight: 500;
    font-family: Renner, sans-serif;
    text-transform: uppercase;
    padding-left: 0px;
    padding-bottom: 8px;
    color: #313132;
  }

  .top-title-text-bold {
    font-size: 18px;
    font-weight: bold;
    font-family: Renner, sans-serif;
    padding-left: 0px;
    padding-bottom: 8px;
    color: #313132;
  }

  .top-title-text-body {
    font-size: 14px;
    font-weight: normal;
    font-family: Renner, sans-serif;
    padding-left: 0px;
    padding-bottom: 8px;
    color: #313132;
  }

  .column-cl {
    margin-bottom: 30px;

    .container {
      max-width: 100%;
    }
  }

  .read-more {
    background-color: #fff;
    border-radius: 50px;
    border: 1px solid #313132;
    cursor: pointer;
    text-align: center;
    width: 76px;

    .load-button {
      color: #313132;
      font-size: 10px;
      font-weight: normal;
      font-family: Renner, sans-serif;
      padding: 3px 8px 3px 8px;
    }
    &:hover {
      border: 1px solid #9e6c51;
      background-color: #9e6c51;
      .load-button {
        color: #ffffff;
      }
    }
  }
`;

export default StoryList;
