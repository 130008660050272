import React, { useState } from "react";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import styled from "styled-components";
import Title from "../../../components/Title";
import facebook from "../../../assets/icons/facebook.svg";
import twitter from "../../../assets/icons/twitter.svg";
import link from "../../../assets/icons/link.png";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import TimerMixin from "react-timer-mixin";

const Share = (props) => {
  const { name, index } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [placement, setPlacement] = useState();

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);

    TimerMixin.setTimeout(() => {
      setOpen(false);
    }, 2000);
  };

  const url = window.location.href + "?index=" + index;

  return (
    <StyledShare>
      <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <Title
                text={`Link ${url} copied!`}
                color={"#5D5D5D"}
                fontSize={12}
                fontFamily={"Poppins"}
                fontWeight={"400"}
                lineHeight={16}
                margin={"0"}
                textTransform={"none"}
              />
            </Paper>
          </Fade>
        )}
      </Popper>
      <div className="share-main">
        <div className="item-div">
          <Title
            text="Share: "
            color={"#5D5D5D"}
            fontSize={12}
            fontFamily={"Poppins"}
            fontWeight={"400"}
            lineHeight={14}
            margin={"0"}
          />
        </div>
        <div className="item-div" onClick={handleClick("bottom")}>
          <CopyToClipboard text={url}>
            <img className="image-div" src={link} alt="link-icon" />
          </CopyToClipboard>
        </div>
        <div className="item-div">
          <FacebookShareButton quote={`${name} - Shanta Lifestyle`} url={url}>
            <img className="image-div" src={facebook} alt="facebook-icon" />
          </FacebookShareButton>
        </div>
        <div className="item-div-last">
          <TwitterShareButton quote={`${name} - Shanta Lifestyle`} url={url}>
            <img className="image-div" src={twitter} alt="twitter-icon" />
          </TwitterShareButton>
        </div>
      </div>
    </StyledShare>
  );
};

const StyledShare = styled.div`
  .share-main {
    display: flex;
    flex-directon: row;
    justify-content: flex-end;
    align-items: center;
  }

  .item-div {
    margin-right: 20px;
    cursor: pointer;
  }

  .item-div-last {
    margin-right: 0px;
    cursor: pointer;
  }

  .image-div {
    width: 16px;
    height: 16px;
    color: #5d5d5d;
  }
`;

export default Share;
