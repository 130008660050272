export const rightDrawerStyle = theme => ({
  paper: {
    width: '425px !important',
    [theme.breakpoints.down('md')]: {
      width: '100% !important'
    }
  },
  fullList: {
    width: 'auto',
  },
  drawerHeader: {
    height: 60,
    padding: 10,
    backgroundColor: '#313132',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  drawerHeaderText: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  crossIcon: {
    cursor: 'pointer',
    marginTop: -10,
    marginRight: 8
  },
  drawerBody: {
    padding: '15px',
    position: 'relative',
    overflow: 'auto',
    marginBottom: 64
  }
})
