export const authStyle = (theme) => ({
  main: {
    padding: "36px !important",
    width: 450,
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
  },
  formRow: {
    width: "90%",
  },
  formLabel: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 600,
  },
  formInput: {
    fontFamily: "Poppins",
    fontSize: 14,
    height: 40,
    fontWeight: 400,
    color: "#707070",
  },
  formField: {
    marginTop: 20,
  },
  linkText: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  divider: {
    borderBottom: "1px solid #E0E0E0",
    marginTop: 20,
  },
  drawerHeader: {
    height: 200,
    padding: 10,
    backgroundColor: "#313132",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  drawerHeaderText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: "20px",
    marginTop: 24,
    width: "100%",
  },
  crossIcon: {
    cursor: "pointer",
    position: "absolute",
    top: 32,
    right: 32,
  },
  drawerBody: {
    padding: 15,
  },
  listDiv: {
    fontFamily: "Poppins",
    fontSize: 14,
    fontWeight: 400,
    cursor: "pointer",
  },
  formControl: {
    fontSize: "14px !important",
    fontFamily: "Poppins",
    fontWeight: 400,
    color: "#707070",
  },
});
