import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import { Divider, StepConnector } from "@material-ui/core";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";

import { connect } from "react-redux";

import Title from "../../../components/Title";

function getSteps() {
  let orderStatusList = [
    { label: "Order Placed", id: 1 },
    { label: "Order Confirmed", id: 2 },
    { label: "Order In Transit", id: 3 },
    { label: "Order Picked Up For Delivery", id: 4 },
    { label: "Delivered", id: 5 },
  ];

  return orderStatusList;
}

function getHistory(history, checkStatus) {
  if(history) {
    let historyObj = {}
    let historyValue = history.value
    for(let key in historyValue) {
      let status = checkStatus(historyValue[`${key}`])
      historyObj = {
        ...historyObj,
        [`${status.id}`]: parseInt(key)
      }
    }

    return historyObj
  } else {
    return null
  }
}

let StepperComponent = (props) => {
  const classes = useStyles();
  let { activeOrder, statusCheck } = props;

  let activeStatus = statusCheck(activeOrder.status);

  let historyData = activeOrder.meta_data ? activeOrder.meta_data.find(item => item.key === '_status_history') : null

  const steps = getSteps();

  let final = getHistory(historyData, statusCheck);

  return (
    <div className={classes.root}>
      <div className={classes.cardLabel}>
        <Title
          text={"Order Status"}
          fontSize={16}
          lineHeight={24}
          fontWeight={600}
          fontFamily={'Poppins'}
          margin={"0 0 20px 0"}
        />
        <Title
          text={"In Progress"}
          fontSize={14}
          lineHeight={20}
          fontFamily={'Poppins'}
          fontWeight={"400"}
          margin={"0 0 20px 0"}
        />
      </div>

      <Divider />
      <Stepper
        activeStep={activeStatus.id + 1}
        color="secondary"
        orientation="vertical"
        connector={<QontoConnector />}
      >
        {steps.map((step, index) => {
          let dateTimeUnix = final ? final[`${step.id - 1}`] : null
          let dateTimeReal = step.id === 1 ? activeOrder.date_created : null

          let date = dateTimeReal ? moment(dateTimeReal).format('DD-MMM-YYYY') :
            dateTimeUnix ? moment.unix(dateTimeUnix).format('DD-MMM-YYYY') : ''

          let time = dateTimeReal ? moment(dateTimeReal).format('hh:mm a') :
            dateTimeUnix ? moment.unix(dateTimeUnix).format('hh:mm a') : ''

          return (
            <Step key={step.id}>
              <StepLabel>
                <div className={classes.customLabel}>
                  <Title
                    text={step.label}
                    fontSize={14}
                    fontWeight={600}
                    fontFamily={'Poppins'}
                    lineHeight={20}
                  />
                  <div className={classes.dateTime}>
                    <Title
                      text={date}
                      fontSize={10}
                      fontFamily={'Poppins'}
                      fontWeight={400}
                      lineHeight={20}
                      color={'#707070'}
                    />
                    <Title
                      text={time}
                      fontSize={10}
                      fontFamily={'Poppins'}
                      fontWeight={400}
                      lineHeight={20}
                      color={'#707070'}
                    />
                  </div>
                </div>
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  cardLabel: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  customLabel: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "0.1px solid #F5F5F5",
    borderBottomWidth: "thin",
  },
  dateTime: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
}));

const QontoConnector = withStyles({
  alternativeLabel: {
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  active: {
    "& $line": {
      borderColor: "rgba(0, 0, 0, 0.38)",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#9C9434",
    },
  },
  vertical: {
    padding: 0,
    marginLeft: 7,
  },
  line: {
    borderTopWidth: 3,
    borderRadius: 1,
  },
  lineVertical: {
    minHeight: 30,
    borderLeftStyle: "solid",
    borderLeftWidth: 1,
  },
})(StepConnector);

export default StepperComponent;
