import React,{useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Title from "./Title";
import Divider from "@material-ui/core/Divider";
import {Link} from "react-router-dom";

const Accordion = withStyles({
  root: {
    //border: '1px solid rgba(0, 0, 0, .125)',
    borderLeft: 0,
    borderRight: 0,
    width: '100%',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#EFEFEF',
    //borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -3,
    minHeight: 56,
    padding: 0,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    //padding: theme.spacing(2),
    backgroundColor: '#EFEFEF',
    padding: '8px 16px 16px 0px',
    overflowY: "hidden"
  }
}))(MuiAccordionDetails);

let CustomizedAccordions = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [expanded1, setExpanded1] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false)

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const handleTwo = () => {
    setExpanded1(!expanded1)
  }

  const handleThree = () => {
    setExpanded2(!expanded2)
  }

  const handleFour = () => {
    setExpanded3(!expanded3)
  }

  return (
    <div className="collapsible">
      <Accordion square expanded={expanded} onChange={handleChange}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={<ExpandMoreIcon />}
        >
          <Title
            text={"Support"}
            color={'#313132'}
            fontSize={16}
            fontWeight={'600'}
            lineHeight={24}
            fontFamily={'Renner,sans-serif'}
          />
        </AccordionSummary>
        <AccordionDetails>
          <div className="footer__menu-mobile__single">
            <ul>

              <li>
                <Link to={'/faq'}>FAQ</Link>
              </li>
              <li>
                <Link to={'/book-appointment'}>Book An Appointment</Link>
              </li>
              <li><Link to={'/customer-service'}>Customer Service</Link></li>

            </ul>
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider />
      <Accordion square expanded={expanded1} onChange={handleTwo}>
        <AccordionSummary
          aria-controls="panel2d-content"
          id="panel2d-header"
          expandIcon={<ExpandMoreIcon />}
        >
          <Title
            text={"Shop & Explore"}
            color={'#313132'}
            fontSize={16}
            fontWeight={'600'}
            lineHeight={24}
            fontFamily={'Renner,sans-serif'}
          />
        </AccordionSummary>
        <AccordionDetails>
          <div className="footer__menu-mobile__single">
            <ul>
              <li>
                <Link to={'/featured'}>Shop By Product</Link>
              </li>
              <li>
                <Link to={'/room'}>Shop By Room</Link>
              </li>
              <li>
                <Link to={'/brand'}>Shop By Brand</Link>
              </li>
              {/*<li><Link to={'/'}>Shanta Lifestyle</Link></li>*/}
              {/*<li><Link to={'/stories'}>Stories</Link></li>*/}
              {/*<li><Link to={'/'}>Offers</Link></li>*/}
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider />
      <Accordion square expanded={expanded2} onChange={handleThree}>
        <AccordionSummary
          aria-controls="panel3d-content"
          id="panel3d-header"
          expandIcon={<ExpandMoreIcon />}
        >
          <Title
            text={"About Us"}
            color={'#313132'}
            fontSize={16}
            fontWeight={'600'}
            lineHeight={24}
            fontFamily={'Renner,sans-serif'}
          />
        </AccordionSummary>
        <AccordionDetails>
          <div className="footer__menu-mobile__single">
            <ul>
              <li><Link to={'/about-us'}>Shanta Lifestyle</Link></li>
              <li>
                <Link to={'/news-blogs'}>Blog</Link>
              </li>
              <li>
                <Link to={'/webseries'}>Webseries</Link>
              </li>
              {/*<li><Link to={'/'}>Our Beleifs</Link></li>*/}
              {/*<li><Link to={'/'}>Our Team</Link></li>*/}
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>
      <Divider />
      <Accordion square expanded={expanded3} onChange={handleFour}>
        <AccordionSummary
          aria-controls="panel3d-content"
          id="panel3d-header"
          expandIcon={<ExpandMoreIcon />}
        >
          <Title
            text={"Legal"}
            color={'#313132'}
            fontSize={16}
            fontWeight={'600'}
            lineHeight={24}
            fontFamily={'Renner,sans-serif'}
          />
        </AccordionSummary>
        <AccordionDetails>
          <div className="footer__menu-mobile__single">
            <ul>
              <li>
                <Link to={'/terms-conditions'}>Terms and conditions</Link>
              </li>
              <li>
                <Link to={'/privacy-policy'}>Privacy Policy</Link>
              </li>
              <li>
                <Link to={'/refund-returns'}>Refund Policy</Link>
              </li>


              <li><Link to={'/warranty-policy'}>Warranty Policy</Link></li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default CustomizedAccordions;
