import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Title from "../../../components/Title";
import CheckoutItems from "./CheckoutItems";
import styled from "styled-components";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import NewButtonSubmit from "../../../components/NewButtonSubmit";
import {
  clearCheckout,
  setTempOrder,
} from "../../../redux/actions/rightDrawer";
import {
  setOrder,
  initNewOrder,
  setPayment,
  setEmail,
  setPhone,
  setHome,
  setAddress,
  onlinePayment,
} from "../../../redux/actions/orders";

import { updateUser } from "../../../redux/actions/auth";

import { numberWithCommas } from "../../../helpers/globalFunc";
import { useHistory } from "react-router-dom";
import TimerMixin from "react-timer-mixin";

import { connect } from "react-redux";
import { AMOUNT } from "../../../constants/apiParams";
import { Col, Form, Row } from "react-bootstrap";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

let calculatePrice = (carts) => {
  let price = 0;

  if (carts.length) {
    let len = carts.length;
    for (let i = 0; i < len; i++) {
      let calc = carts[i].count * parseFloat(carts[i].cartItem.price);

      price += calc;
    }
    return price;
  } else {
    return 0;
  }
};

let CheckoutDetails = (props) => {
  const classes = useStyles();

  let history = useHistory();

  let [check, setCheck] = useState(false);
  let [success, setSuccess] = useState("");

  let handleCheck = () => {
    setCheck(!check);
  };

  let {
    checkout,
    clearCheckout,
    homeAddress,
    address1,
    email,
    phone,
    payment,
    setOrder,
    authData,
    newOrder,
    newOrderLoading,
    initNewOrder,
    setPayment,
    setEmail,
    setPhone,
    setHome,
    setAddress,
    paymentGateway,
    paymentGatewayLoading,
    setTempOrder,
    onlinePayment,
    updateUser,
  } = props;

  let [note, setNote] = useState("");
  let price = calculatePrice(checkout);
  let vat = price * (parseFloat(props.cartInfo?.tax_rate) / 100);

  let total = price + parseInt(props.cartInfo?.delivery_fee);
  //let total = (price + 600 + vat) - price * 0.05;

  let amountDue =
    total > parseInt(props.cartInfo?.partial_payment)
      ? total - parseInt(props.cartInfo?.partial_payment)
      : 0;

  //console.log(props.cartInfo);

  let handleSubmit = (e) => {
    e.preventDefault();
    if (authData) {
      if (authData.id) {
        let lineItems = checkout.map((item) => {
          let retData = {
            product_id: item.cartItem.id.toString(),
            product: item.cartItem.name,
            quantity: item.count,
            amount: item.cartItem.price,
          };
          return retData;
        });

        let data = {
          customer_id: authData.id,
          billing: homeAddress ? homeAddress : address1,
          payment_method: payment,
          line_items: lineItems,
          set_paid: false,
        };
        data.billing.email = email;
        if (address1 && homeAddress) data.shipping = address1;
        if (payment === "online") {
          setTempOrder(data);
          let userAddressUpdate = {
            user_id: authData.id,
            billing_address: {
              billing_address_1: data.billing.address_1,
              billing_address_2: data.billing.address_2,
              billing_city: data.billing.city,
            },
          };
          if (data.shipping) {
            userAddressUpdate.shipping_address = {
              shipping_address_1: data.shipping.address_1,
              shipping_address_2: data.shipping.address_2,
              shipping_city: data.shipping.city,
            };
          }

          updateUser(userAddressUpdate);
          let online = {
            ...data,
            set_paid: false,
            payment_method: "sslcommerz",
            payment_method_title: "sslcommerz",
            customer_note: note,
          };
          setOrder(online);
        } else {
          let userAddressUpdate = {
            user_id: authData.id,
            billing_address: {
              billing_address_1: data.billing.address_1,
              billing_address_2: data.billing.address_2,
              billing_city: data.billing.city,
            },
          };
          if (data.shipping) {
            userAddressUpdate.shipping_address = {
              shipping_address_1: data.shipping.address_1,
              shipping_address_2: data.shipping.address_2,
              shipping_city: data.shipping.city,
            };
          }
          // //console.log(data)
          updateUser(userAddressUpdate);
          let bacs = {
            ...data,
            set_paid: false,
            payment_method: "Direct Bank Transfer",
            payment_method_title: "Direct Bank Transfer",
            customer_note: note,
          };
          setOrder(bacs);
        }
      }
    }
  };

  // useEffect(() => {
  //   if (newOrder) {
  //     setSuccess('Order Placed Successfully')
  //     TimerMixin.setTimeout(() => {
  //       clearCheckout();
  //       setPhone(null);
  //       setEmail(null);
  //       setPayment("cash");
  //       setHome(null);
  //       setAddress(null);
  //       initNewOrder();
  //       history.push("/orders");
  //     }, 3500);
  //   }
  // }, [newOrder]);

  useEffect(() => {
    if (newOrder) {
      if (payment === "online") {
        let paymentData = {
          user_id: authData.id,
          order_id: newOrder.id,
        };
        onlinePayment(paymentData);

        setSuccess("Order Placed Successfully");
        TimerMixin.setTimeout(() => {
          clearCheckout();
          setPhone(null);
          setEmail(null);
          setPayment("online");
          setHome(null);
          setAddress(null);
          initNewOrder();
        }, 5000);
      } else {
        setSuccess("Order Placed Successfully");
        TimerMixin.setTimeout(() => {
          clearCheckout();
          setPhone(null);
          setEmail(null);
          setPayment("bacs");
          setHome(null);
          setAddress(null);
          initNewOrder();
          history.push("/orders");
        }, 3500);
      }
    }
  }, [newOrder]);

  useEffect(() => {
    if (paymentGateway) {
      initNewOrder();
      window.location.href = paymentGateway.GatewayPageURL;
    }
  }, [paymentGateway]);

  let handleNote = (e) => {
    setNote(e.target.value);
  };

  let checkValid = () => {
    return (
      (homeAddress && email && phone && payment) ||
      (address1 && email && phone && payment)
    );
  };

  return (
    <StyledItems>
      <Card className={classes.root}>
        <CardContent>
          <Title
            text={"YOUR ORDER"}
            fontSize={16}
            lineHeight={24}
            color={"#313132"}
            fontFamily={"Poppins"}
            fontWeight={"400"}
            margin={"0 0 10px 0"}
          />
          <Divider />
          {checkout
            ? checkout.length
              ? checkout.map((item) => {
                  return (
                    <>
                      <CheckoutItems product={item} />
                      <Divider />
                    </>
                  );
                })
              : null
            : null}
          <div className="price-div">
            <Title
              text={"Sub Total"}
              fontSize={14}
              fontFamily={"Poppins"}
              lineHeight={21}
              color={"#313132"}
              fontWeight={"500"}
              margin={"20px 0 0 0"}
            />
            <div className="price-text">
              <Title
                text={`BDT `}
                fontSize={10}
                lineHeight={20}
                fontWeight={"normal"}
                color={"#313132"}
                textAlign={"right"}
                margin={"18px 0 0 0"}
              />
              <Title
                text={`${numberWithCommas(parseFloat(price).toFixed(2))}`}
                fontSize={14}
                lineHeight={21}
                color={"#313132"}
                fontWeight={"500"}
                margin={"20px 0 0 0"}
              />
            </div>
          </div>
          <div className="price-div">
            <Title
              text={"Delivery Fee"}
              fontSize={14}
              fontFamily={"Poppins"}
              lineHeight={21}
              color={"#313132"}
              fontWeight={"500"}
              margin={"10px 0 0 0"}
            />
            <div className="price-text">
              <Title
                text={`BDT `}
                fontSize={10}
                lineHeight={20}
                fontWeight={"normal"}
                color={"#313132"}
                textAlign={"right"}
                margin={"8px 0 0 0"}
              />
              <Title
                text={props.cartInfo?.delivery_fee}
                fontSize={14}
                lineHeight={21}
                color={"#313132"}
                fontWeight={"500"}
                margin={"10px 0 0 0"}
              />
            </div>
          </div>
          {/*<div className="price-div">*/}
          {/*  <Title*/}
          {/*    text={"Discount 5%"}*/}
          {/*    fontSize={14}*/}
          {/*    fontFamily={'Poppins'}*/}
          {/*    lineHeight={21}*/}
          {/*    color={"#313132"}*/}
          {/*    fontWeight={"500"}*/}
          {/*    margin={"10px 0 10px 0"}*/}
          {/*  />*/}
          {/*  <div className="price-text">*/}
          {/*    <Title*/}
          {/*        text={`BDT `}*/}
          {/*        fontSize={10}*/}
          {/*        lineHeight={20}*/}
          {/*        fontWeight={'normal'}*/}
          {/*        color={'#313132'}*/}
          {/*        textAlign={'right'}*/}
          {/*        margin={'8px 0 0 0'}*/}
          {/*    />*/}
          {/*    <Title*/}
          {/*        text={`${numberWithCommas(*/}
          {/*            parseFloat(Math.floor(price * 0.05))*/}
          {/*        )}`}*/}
          {/*        fontSize={14}*/}
          {/*        lineHeight={21}*/}
          {/*        color={"#313132"}*/}
          {/*        fontWeight={"500"}*/}
          {/*        margin={"10px 0 10px 0"}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div className="price-div">*/}
          {/*  <Title*/}
          {/*    text={`Vat ${parseFloat(props.cartInfo.tax_rate).toFixed(1)}%`}*/}
          {/*    fontSize={14}*/}
          {/*    fontFamily={"Poppins"}*/}
          {/*    lineHeight={21}*/}
          {/*    color={"#313132"}*/}
          {/*    fontWeight={"500"}*/}
          {/*    margin={"10px 0 10px 0"}*/}
          {/*  />*/}
          {/*  <div className="price-text">*/}
          {/*    <Title*/}
          {/*      text={`BDT `}*/}
          {/*      fontSize={10}*/}
          {/*      lineHeight={20}*/}
          {/*      fontWeight={"normal"}*/}
          {/*      color={"#313132"}*/}
          {/*      textAlign={"right"}*/}
          {/*      margin={"8px 0 0 0"}*/}
          {/*    />*/}
          {/*    <Title*/}
          {/*      text={`${numberWithCommas(*/}
          {/*        parseFloat(Math.floor(vat)).toFixed(2)*/}
          {/*      )}`}*/}
          {/*      fontSize={14}*/}
          {/*      lineHeight={21}*/}
          {/*      color={"#313132"}*/}
          {/*      fontWeight={"500"}*/}
          {/*      margin={"10px 0 10px 0"}*/}
          {/*    />*/}
          {/*  </div>*/}
          {/*</div>*/}
          <Divider />
          <div className="price-div">
            <Title
              text={"Total"}
              fontSize={14}
              lineHeight={21}
              color={"#313132"}
              fontFamily={"Poppins"}
              fontWeight={"500"}
              margin={"20px 0 30px 0"}
            />
            <div className="price-text">
              <Title
                text={`BDT `}
                fontSize={10}
                lineHeight={20}
                fontWeight={"normal"}
                color={"#313132"}
                textAlign={"right"}
                margin={"18px 0 0 0"}
              />
              <Title
                text={`${numberWithCommas(
                  parseFloat(Math.floor(total)).toFixed(2)
                )}`}
                fontSize={14}
                lineHeight={21}
                color={"#313132"}
                fontWeight={"500"}
                margin={"20px 0 30px 0"}
              />
            </div>
          </div>
          {payment === "online" ? (
            <>
              {total > AMOUNT ? (
                <>
                  <div className="price-div">
                    <Title
                      text={"Partial Payment"}
                      fontSize={14}
                      lineHeight={21}
                      color={"#313132"}
                      fontFamily={"Poppins"}
                      fontWeight={"500"}
                      margin={"20px 0 30px 0"}
                    />
                    <div className="price-text">
                      <Title
                        text={`BDT `}
                        fontSize={10}
                        lineHeight={20}
                        fontWeight={"normal"}
                        color={"#313132"}
                        textAlign={"right"}
                        margin={"18px 0 0 0"}
                      />
                      <Title
                        text={`${numberWithCommas(
                          parseFloat(
                            Math.floor(props.cartInfo.partial_payment)
                          ).toFixed(2)
                        )}`}
                        fontSize={14}
                        lineHeight={21}
                        color={"#313132"}
                        fontWeight={"500"}
                        margin={"20px 0 30px 0"}
                      />
                    </div>
                  </div>
                  <div className="price-div">
                    <Title
                      text={"Due Amount"}
                      fontSize={14}
                      lineHeight={21}
                      color={"#313132"}
                      fontFamily={"Poppins"}
                      fontWeight={"500"}
                      margin={"20px 0 30px 0"}
                    />
                    <div className="price-text">
                      <Title
                        text={`BDT `}
                        fontSize={10}
                        lineHeight={20}
                        fontWeight={"normal"}
                        color={"#313132"}
                        textAlign={"right"}
                        margin={"18px 0 0 0"}
                      />
                      <Title
                        text={`${numberWithCommas(
                          parseFloat(Math.floor(amountDue)).toFixed(2)
                        )}`}
                        fontSize={14}
                        lineHeight={21}
                        color={"#313132"}
                        fontWeight={"500"}
                        margin={"20px 0 30px 0"}
                      />
                    </div>
                  </div>
                </>
              ) : null}
            </>
          ) : null}

          <FormControlLabel
              className="form-label"
              control={
                <Checkbox
                    checked={check}
                    onChange={handleCheck}
                    name="check"
                    color="primary"
                />
              }
              label= {<div>I accept all the <span onClick={()=>history.push('/terms-conditions')} style={{color: '#007fae'}}>terms and conditions</span></div>}
          />
          {check && checkValid() ? (
              <>
                <Form>
                  <Form.Group>
                    <Form.Control
                        type='text'
                        name="phone"
                        value={note}
                        onChange={handleNote}
                        className=""
                        placeholder={'Add Note'}
                        autoComplete="off"
                    />
                  </Form.Group>
                </Form>
                <NewButtonSubmit
                    text={"Checkout and Confirm"}
                    handleSubmit={handleSubmit}
                    loading={
                      payment === "bacs" ? newOrderLoading : paymentGatewayLoading
                    }
                />
              </>

          ) : null}
          {success.length ? (
              <Title
                  text={success}
                  fontWeight={200}
                  fontSize={14}
                  lineHeight={20}
                  fontFamily={'Poppins'}
                  color={'green'}
                  margin={'20px 0 0 0'}
              />
          ) : null}
        </CardContent>
      </Card>
    </StyledItems>
  );
};

let StyledItems = styled.div`
  margin-bottom: 300px;
  .MuiPaper-elevation1 {
    box-shadow: 0px 0px 1px -1px rgb(228 228 228 / 20%),
      0px 0px 0px 0px rgb(228 228 228 / 14%),
      0px 1px 15px 0px rgb(228 228 228 / 55%);
  }

  .price-div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .title {
      margin: 14px 0px 0px 0px;
    }
  }

  .price-text {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: absolute;
    right: 36px;
  }

  @media (max-width: 767px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .form-label {
    font-family: Poppins;
    font-size: 10px !important;
    line-height: 20px;
  }
`;

function mapStateToProps(state) {
  return {
    checkout: state.rightDrawerReducer.checkout,
    cartInfo: state.rightDrawerReducer.cartInfo,
    homeAddress: state.orderReducer.homeAddress,
    address1: state.orderReducer.address1,
    payment: state.orderReducer.payment,
    phone: state.orderReducer.phone,
    email: state.orderReducer.email,
    newOrder: state.orderReducer.newOrder,
    newOrderLoading: state.orderReducer.newOrderLoading,
    authData: state.authReducer.authData,
    tempOrder: state.rightDrawerReducer.tempOrder,
    paymentGatewayLoading: state.orderReducer.paymentGatewayLoading,
    paymentGateway: state.orderReducer.paymentGateway,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearCheckout: () => dispatch(clearCheckout()),
    setOrder: (data) => dispatch(setOrder(data)),
    setEmail: (data) => dispatch(setEmail(data)),
    setPhone: (data) => dispatch(setPhone(data)),
    setHome: (data) => dispatch(setHome(data)),
    setAddress: (data) => dispatch(setAddress(data)),
    setPayment: (data) => dispatch(setPayment(data)),
    initNewOrder: () => dispatch(initNewOrder()),
    setTempOrder: (data) => dispatch(setTempOrder(data)),
    onlinePayment: (data) => dispatch(onlinePayment(data)),
    updateUser: (data) => dispatch(updateUser(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutDetails);
