import React from "react";
import Drawer from "@material-ui/core/Drawer";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { openLeftDrawer } from "../../../redux/actions/left-drawer";
import CrossIcon from "../../../assets/icons/cross-icon.svg";
import LeftButton from "../../../assets/images/navLeftBlack.svg";
import { leftDrawerStyle } from "../leftDrawerStyle";
import LogoImage from "../../../assets/images/shanta-logo.png";
import Title from "../../../components/Title";

import { useHistory, Link } from "react-router-dom";
import TimerMixin from "react-timer-mixin";

let style = (theme) => leftDrawerStyle(theme);



let RoomDrawer = (props) => {
  let { classes, openLeft, handleLeftDrawer, categories } = props;
  
  let room = categories && categories.splice()
    ? categories.length
      ? categories.find((item) => item.slug === "room")
      : null
    : null;
  // //console.log('categories',categories);
  // //console.log('classes',classes);

  let roomId = room ? room.term_id : null;
  let roomChild = roomId
    ? categories.length
      ? categories.filter((item) => item.parent === roomId)
      : []
    : [];
  roomChild?.length>0 && roomChild.sort((x, y) => x.sort_order - y.sort_order);
  // //console.log('roomchild',roomChild)
  
  let history = useHistory();

  let handleClose = () => {
    handleLeftDrawer("");
  };

  const handleRoute = (route) => {
    handleClose();
    TimerMixin.setTimeout(() => {
      history.push(`/room-products/${route}`);
    }, 500);
  };

  return (
    <div>
      <Drawer
        anchor={"left"}
        open={openLeft === "left-room"}
        onClose={handleClose}
      >
        <div className={classes.drawerHeader}>
          <div
            onClick={() => handleLeftDrawer("")}
            className={classes.crossIcon}
          >
            <img src={CrossIcon} />
          </div>
          <div onClick={() => handleRoute('')}>
            <img src={LogoImage} />
          </div>
        </div>
        <div className={classes.secondaryHeader}>
          <div>
            <img
              src={LeftButton}
              onClick={() => handleLeftDrawer("left-main")}
              className={classes.leftIcon}
            />
          </div>
          <Title
            text={"Rooms"}
            color={"#313132"}
            fontSize={14}
            lineHeight={24}
            fontFamily={"Poppins"}
            fontWeight={"600"}
            textAlign={"left"}
            margin={"0 0 0px 28px"}
          />
        </div>
        <div className={classes.drawerBody}>
          <div role="presentation">
            <div className={classes.itemDivMain}>
              {roomChild.length
                ? roomChild.map((item, key) => {
                    return (
                      item?.slug.toLowerCase() !=="kitchen" && "Dining Room" &&
                      <div
                          key={key}
                        className={classes.itemDiv}
                        onClick={() => handleRoute(item.slug)}
                    >
                      <img
                        className={classes.itemImage}
                        src={item.image_url}
                        alt={`${item.name} image`}
                      />
                      <Title
                        text={item.name}
                        color={"#313132"}
                        fontSize={14}
                        lineHeight={24}
                        fontFamily={"Poppins"}
                        fontWeight={"400"}
                        textAlign={"left"}
                        margin={"10px 0 0px 0px"}
                      />
                    </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    openLeft: state.leftDrawerReducer.openLeft,
    categories: state.homeReducer.categories,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleLeftDrawer: (data) => dispatch(openLeftDrawer(data)),
  };
}

export default withStyles(style)(
  connect(mapStateToProps, mapDispatchToProps)(RoomDrawer)
);
