import React, { useState, useEffect } from "react";
import Button from "../../../components/NewButtonSubmit";
import Title from "../../../components/Title";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CrossIcon from "../../../assets/icons/cross-icon.svg";
import { Form, Row, Col } from "react-bootstrap";
import { handleAuthModal } from "../../../redux/actions/auth";
import {
  mergeCart,
  fetchAllCart,
  getAllWishlist,
  mergeWishlist,
} from "../../../redux/actions/rightDrawer";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { signUp, logout } from "../../../redux/actions/auth";
import {
  validatePhone,
  passwordValidator,
  confirmPassValidator,
} from "../../../helpers/validators";
import TimerMixin from "react-timer-mixin";

import { authStyle } from "../authStyle";

let styles = (theme) => authStyle(theme);

const convertCart = (data) => {
  let len = data.length;
  let products = [];

  for (let i = 0; i < len; i++) {
    let item = data[i];
    let retData = {
      product_id: item.cartItem.id.toString(),
      count: item.count,
    };

    products.push(retData);
  }

  return products;
};

let Signup = (props) => {
  let {
    openModal,
    handleAuthModal,
    classes,
    authData,
    signUp,
    authDataLoading,
    authDataErr,
    cart,
    mergeCart,
    fetchAllCart,
    wishlist,phone,
    mergeWishlist,
    getAllWishlist,
    allCartLoading,
    allWishlistLoading,
  } = props;

  let [name, setName] = useState("");
  let [nameErr, setNameErr] = useState("");
  let [code, setCode] = useState("");
  let [codeErr, setCodeErr] = useState("");
  let [password, setPassword] = useState("");
  let [confirm, setConfirm] = useState("");
  let [passwordErr, setPasswordErr] = useState("");
  let [confirmErr, setConfirmErr] = useState("");
  let [authError, setAuthError] = useState("");

  let handleName = (e) => {
    setName(e.target.value);
  };

  let handleCode = (e) => {
    setCode(e.target.value);
  };

  let passwordBlur = () => {
    let valid = passwordValidator(password);

    if (valid) {
      setPasswordErr("");
    } else {
      setPasswordErr(
        "Password Must be of 6 Characters and spaces are not allowed"
      );
    }
  };

  let confirmBlur = () => {
    let valid = confirmPassValidator(password, confirm);

    if (valid) {
      setConfirmErr("");
    } else {
      setConfirmErr("Passwords did not match");
    }
  };

  let handlePassword = (e) => {
    setPassword(e.target.value);
  };

  let handleConfirm = (e) => {
    setConfirm(e.target.value);
  };

  const handleClose = () => {
    handleAuthModal("");
  };

  let handleSubmit = () => {
    if (name.length) {
      if (code.length) {
        if (!codeErr.length && !passwordErr.length && !confirmErr.length) {
          let data = {
            user_login: phone,
            display_name: name,
            user_phone: phone,
            user_pass: password,
            user_pass_confirm: confirm,
            otp: code,
          };
          signUp(data);
          setCodeErr("");
          setNameErr("");
        }
      } else {
        setCodeErr("Code Should Not be empty");
      }
    } else {
      setNameErr("Name Should Not be empty");
    }
  };

  useEffect(() => {
    if (authData && openModal === "signup") {
      if (authData.token) {
        let userId = authData.id;

        if (cart.length && !allCartLoading) {
          let mergeData = {
            user_id: userId.toString(),
            product_cart: convertCart(cart),
          };

          mergeCart(mergeData);
        } else {
          let data = { user_id: userId };
          fetchAllCart(data);
        }

        if (wishlist.length && !allWishlistLoading) {
          let productIds = wishlist.map((item) => {
            let obj = {
              product_id: item.id.toString(),
            };

            return obj;
          });
          let mergeData = {
            user_id: userId.toString(),
            product_wish_cart: productIds,
          };

          mergeWishlist(mergeData);
        } else {
          let data = { user_id: userId };
          getAllWishlist(data);
        }

        handleAuthModal("");
      } else {
        setAuthError(authData.message);
        TimerMixin.setTimeout(() => {
          logout();
        }, 3000);
      }
    }
  }, [authData]);

  useEffect(() => {
    if (authDataErr) {
      setAuthError("An error occured when signing up");
    }
  }, [authDataErr]);

  return (
    <div>
      <Dialog
        open={openModal === "signup"}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className={classes.main}>
          <Title
            text={"Welcome"}
            color={"#9C9434"}
            fontSize={12}
            lineHeight={18}
            textAlign={"left"}
            fontFamily={"Poppins"}
            fontWeight={500}
            letterSpacing={60}
            textTransform={"uppercase"}
            // margin={'15px 0px 15px 0px'}
          />
          <Title
            text={"SIGN UP"}
            color={"#313132"}
            fontSize={24}
            lineHeight={40}
            textAlign={"left"}
            textTransform={"uppercase"}
            fontWeight={500}
            // margin={'5px 0px 15px 0px'}
          />
          <img
            className={classes.crossIcon}
            src={CrossIcon}
            alt=""
            onClick={handleClose}
          />
          <Form>
            <Form.Group>
              <Row>
                <Col className={classes.formField} md={12}>
                  <Form.Label className={classes.formLabel}>Name*</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={name}
                    className={classes.formInput}
                    onChange={handleName}
                    placeholder={"Your Name"}
                  />
                  {nameErr.length ? (
                    <Title
                      text={nameErr}
                      fontWeight={200}
                      fontSize={14}
                      lineHeight={20}
                      color={"red"}
                    />
                  ) : null}
                </Col>
                <Col className={classes.formField} md={12}>
                  <Form.Label className={classes.formLabel}>
                    Mobile Number*
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="Phone"
                    value={phone}
                    disabled
                    className={classes.formInput}
                    //onChange={handlePhone}
                    placeholder={"Your Phone No"}
                  />
                </Col>
                <Col className={classes.formField} md={12}>
                  <Form.Label className={classes.formLabel}>Code*</Form.Label>
                  <Form.Control
                      type="number"
                      name="code"
                      value={code}
                      className={classes.formInput}
                      onChange={handleCode}
                      placeholder={"Enter Code"}
                  />
                  {codeErr.length ? (
                      <Title
                          text={codeErr}
                          fontWeight={200}
                          fontSize={14}
                          lineHeight={20}
                          color={"red"}
                      />
                  ) : null}
                </Col>
                <Col className={classes.formField} md={12}>
                  <Form.Label className={classes.formLabel}>
                    Password*
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={password}
                    className={classes.formInput}
                    placeholder={"Your Password"}
                    onChange={handlePassword}
                    onBlur={passwordBlur}
                  />
                  {passwordErr.length ? (
                    <Title
                      text={passwordErr}
                      fontWeight={200}
                      fontSize={14}
                      lineHeight={20}
                      color={"red"}
                    />
                  ) : null}
                </Col>

                <Col className={classes.formField} md={12}>
                  <Form.Label className={classes.formLabel}>
                    Confirm Password*
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={confirm}
                    className={classes.formInput}
                    placeholder={"Confirm Password"}
                    onChange={handleConfirm}
                    onBlur={confirmBlur}
                  />
                  {confirmErr.length ? (
                    <Title
                      text={confirmErr}
                      fontWeight={200}
                      fontSize={14}
                      lineHeight={20}
                      color={"red"}
                    />
                  ) : null}
                </Col>
              </Row>
            </Form.Group>
          </Form>
          {authError.length ? (
            <Title
              text={authError}
              fontWeight={200}
              fontSize={14}
              lineHeight={20}
              color={"red"}
            />
          ) : null}
          <Title
            text={"By signing up, you agree to Shanta Lifestyle's"}
            color={"#313132"}
            fontSize={14}
            lineHeight={20}
            fontWeight={400}
            fontFamily={"Poppins"}
            textAlign={"center"}
            textTransform={"normal"}
            margin={"20px 0px 0px 0px"}
          />
          <Link style={{ textDecoration: "underline", color: "#9C9434" }}>
            <Title
              text={"Terms & Conditions"}
              color={"#9C9434"}
              fontSize={14}
              fontFamily={"Poppins"}
              fontWeight={400}
              lineHeight={20}
              textAlign={"center"}
              textTransform={"capitalize"}
              margin={"0 0 20px 0"}
            />
          </Link>
          <Button
            text={"Sign Up"}
            handleSubmit={handleSubmit}
            loading={authDataLoading}
          />
          <div className={classes.linkText}>
            <Title
              text={"Already have account?"}
              color={"#313132"}
              fontSize={14}
              lineHeight={20}
              fontFamily={"Poppins"}
              fontWeight={400}
              textAlign={"left"}
              textTransform={"capitalize"}
              margin={"20px 0px 0px 0px"}
            />
            <Link
              onClick={() => handleAuthModal("login")}
              style={{ textDecoration: "underline", color: "#9C9434" }}
            >
              <Title
                text={"Log In"}
                color={"#9C9434"}
                fontSize={14}
                fontFamily={"Poppins"}
                fontWeight={400}
                lineHeight={20}
                textAlign={"left"}
                textTransform={"capitalize"}
                margin={"20px 0 0 5px"}
              />
            </Link>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    openModal: state.authReducer.openModal,
    authData: state.authReducer.authData,
    authDataLoading: state.authReducer.authDataLoading,
    authDataErr: state.authReducer.authDataErr,
    cart: state.rightDrawerReducer.cart,
    phone: state.authReducer.phoneNum,
    wishlist: state.rightDrawerReducer.wishlist,
    allCartLoading: state.rightDrawerReducer.allCartLoading,
    allWishlistLoading: state.rightDrawerReducer.allWishlistLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleAuthModal: (data) => dispatch(handleAuthModal(data)),
    signUp: (data) => dispatch(signUp(data)),
    logout: () => dispatch(logout()),
    mergeCart: (data) => dispatch(mergeCart(data)),
    fetchAllCart: (data) => dispatch(fetchAllCart(data)),
    getAllWishlist: (data) => dispatch(getAllWishlist(data)),
    mergeWishlist: (data) => dispatch(mergeWishlist(data)),
  };
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(Signup)
);
