import React from 'react';
import styled from 'styled-components'
import Map from '../../../assets/images/shanta-map.png'
import Title from "../../../components/Title";
import Divider from "@material-ui/core/Divider";
import Facebook from '../../../assets/images/Icon awesome-facebook.svg';
import whatsapp from '../../../assets/images/Icon awesome-whatsapp.svg'
import {Col, Row} from "react-bootstrap";
import {fetchPages, resetPages} from "../../../redux/actions/home";
import {connect} from "react-redux";

const ContactInner = props => {
  let {post} = props;

  let lat = post && post.meta && post.meta.lat && post.meta.lat.length > 0 && post.meta.lat[0]
  let long = post && post.meta && post.meta.long && post.meta.long.length > 0 && post.meta.long[0]
  let phone = post && post.meta && post.meta.phone && post.meta.phone.length > 0 && post.meta.phone[0]
  let address = post && post.meta && post.meta.address && post.meta.address.length > 0 && post.meta.address[0]
  let email = post && post.meta && post.meta.email && post.meta.email.length > 0 && post.meta.email[0]
  let hotline = post && post.meta && post.meta.hotline && post.meta.hotline.length > 0 && post.meta.hotline[0]

  return (
    <StyledInner>
      {
        post &&
        <Row>
          <Col sm={12} md={8}>
             {/*Previously map was included with a tag*/}
            {/*<a href={`https://maps.google.com/?q=${lat},${long}`} target="_blank">*/}
            {/*  <img src={post?.thumbnail} alt="" />*/}
            {/*</a>*/}
            <a href={post?.thumbnail} target="_blank">
              <img src={post?.thumbnail} alt="" />
            </a>
          </Col>
          <Col sm={12} md={4}>
            <div className="address">
              <Title
                  text={"Reach Us"}
                  color={"#9C9434"}
                  fontSize={12}
                  fontFamily={'Poppins'}
                  textTransform={'uppercase'}
                  lineHeight={18}
                  fontWeight={"500"}
                  textAlign={"left"}
              />
              <div style={{backgroundColor: 'rgba(122,121,121,0.1)', height: 1, width: '100%', marginBottom: 30, marginTop: 5}}/>
              <Title
                  text={"Hotline Number:"}
                  color={"#313132"}
                  fontSize={14}
                  lineHeight={20}
                  fontFamily={'Poppins'}
                  textTransform={'uppercase'}
                  fontWeight={"600"}
                  textAlign={"left"}
                  margin={"0px 0 5px 0"}
              />
              <Title
                  text={hotline}
                  color={"#9C9434"}
                  fontSize={20}
                  lineHeight={28}
                  fontFamily={'Poppins'}
                  fontWeight={"600"}
                  textAlign={"left"}
                  margin={"0px 0px"}
                  textTransform={'italic'}
              />
              <div className="address-line-icon">
                <a href={`https://api.whatsapp.com/send?phone=${phone}`} target="_blank">
                  <img src={whatsapp} alt="" />
                </a>
                <a href={'https://www.facebook.com/shantalifestyle'} target="_blank ">
                  <img src={Facebook} alt="" />
                </a>
              </div>
              <div style={{backgroundColor: 'rgba(122,121,121,0.1)', height: 1, width: '100%', marginBottom: 30, marginTop: 30}}/>
              <div className="address-line">
                <Title
                    text={"Address:"}
                    color={"#313132"}
                    fontSize={14}
                    fontFamily={'Poppins'}
                    fontWeight={"600"}
                    lineHeight={20}
                    textAlign={"left"}
                    margin={"30px 0 5px 0"}
                />
                <Title
                    text={address}
                    color={"#313132"}
                    fontSize={14}
                    lineHeight={20}
                    fontFamily={'Poppins'}
                    fontWeight={"400"}
                    textAlign={"left"}
                    margin={"30px 0 5px 10px"}
                />
              </div>
              <div className="address-line">
                <Title
                    text={"Phone:"}
                    color={"#313132"}
                    fontSize={14}
                    lineHeight={20}
                    fontFamily={'Poppins'}
                    fontWeight={"600"}
                    textAlign={"left"}
                    margin={"30px 0 5px 0"}
                />
                <Title
                    text={phone}
                    color={"#313132"}
                    fontSize={14}
                    lineHeight={20}
                    fontFamily={'Poppins'}
                    fontWeight={"400"}
                    textAlign={"left"}
                    margin={"30px 0 5px 10px"}
                />
              </div>
              <div className="address-line">
                <Title
                    text={"Email:"}
                    color={"#313132"}
                    fontSize={14}
                    lineHeight={20}
                    fontFamily={'Poppins'}
                    fontWeight={"600"}
                    textAlign={"left"}
                    margin={"30px 0 5px 0"}
                />
                <Title
                    text={email}
                    color={"#313132"}
                    fontSize={14}
                    lineHeight={20}
                    fontFamily={'Poppins'}
                    fontWeight={"400"}
                    textAlign={"left"}
                    margin={"30px 0 5px 10px"}
                    textTransform={'none'}
                />
              </div>
            </div>
          </Col>
        </Row>
      }
    </StyledInner>
  )
}

const StyledInner = styled.div`
  .row {
    margin-bottom: 64px;
    padding: 0px 15px;
  }
  .col-md-8{
   padding-right: 0;
  } 
  .col-sm-12{
    padding-left: 0;
  }
  a{
    padding-top: 51.95%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  
  .address {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
    height: 100%;
    box-shadow: 0px 0px 10px #0000000D;
  }

  .address-line-icon {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    a{
      img {
        width: 20px;
        height: 20px;
        object-fit: fill;
      }
    }
    img {
      margin-right: 10px;
      width: 20px;
      height: 20px;
      object-fit: fill;
    }
  }
  
  .address-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    img {
      margin-right: 10px;
    }
  }
  
  @media (max-width: 767px) {
    .col-md-8{
      padding: 0;
    }
    .col-sm-12{
      padding: 0;
    }
    .address {
      padding: 40px 15px;
    }
  }
`

function mapStateToProps(state) {
  return {
    post: state.homeReducer.pages,
  }
}

export default connect(mapStateToProps)(ContactInner)
