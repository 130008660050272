import styled from "styled-components";
import css from "@styled-system/css";
import { variant } from "styled-system";

export const CounterBox = styled.div(
  css({
    display: "flex",
    backgroundColor: "#e8e8e8",
    color: "#313132",
    fontSize: "base",
    fontWeight: "regular",
  }),
  {
    borderRadius: 3,
    justifyContent: "space-around",
    alignItems: "center",
    overflow: "hidden",
    flexShrink: 0,
    "&:focus": {
      outline: "none",
    },
  },
  variant({
    variants: {
      horizontal: {
        width: 90,
        height: 30,
      },
      vertical: {
        width: 20,
        height: 40,
        flexDirection: "column-reverse",
      },
      verticalMobile: {
        width: 30,
        height: 90,
        flexDirection: "column-reverse",
        "@media (max-width: 580px)": {
          width: 28,
          height: 72,
          position: "absolute",
          bottom: "18px",
          right: "18px",
        },
      },
      lightHorizontal: {
        width: 120,
        height: 40,
        backgroundColor: "#F5F5F5",
        color: "text.bold",
      },
      darkHorizontal: {
        width: 72,
        height: 24,
        backgroundColor: "#313132",
        color: "#FAFAFA",
        // "@media (max-width: 580px)": {
        //   flexDirection: "column-reverse",
        //   width: 28,
        //   height: 72,
        //   position: "absolute",
        //   bottom: "18px",
        //   right: "64px",
        // },
      },
      lightVertical: {
        width: 30,
        height: 70,
        flexDirection: "column-reverse",
        backgroundColor: "gray.200",
        color: "text.bold",
      },
    },
  })
);

export const CounterButton = styled.button(
  {
    border: "none",
    backgroundColor: "transparent",
    color: "#313132",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "auto",
    cursor: "pointer",
  },
  variant({
    variants: {
      lightHorizontal: {
        color: "text.regular",
      },
      lightVertical: {
        color: "text.regular",
      },
    },
  })
);

export const CounterValue = styled.span({
  pointerEvents: "none",
  fontSize: 12,
  fontFamily: 'Poppins'
});

CounterValue.displayName = "CounterValue";
CounterButton.displayName = "CounterButton";
CounterBox.displayName = "CounterBox";

CounterBox.defaultProps = {
  variant: "horizontal",
};
